import styled from 'styled-components'
import { ReactComponent as WaveSvg } from '../../../../assets/svg.svg'

export const FolderContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  position: relative;
`

export const TextContainer = styled.div`
  text-align: left;
`

export const WaveContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  bottom: 15.625%;
  left: 8%;
  right: 8%;
  top: 15.625%;
`

export const WaveBase = styled.div<{ height: number; color: string }>`
  width: 100%;
  height: ${props => props.height}%;
  background-color: ${props => props.color};
`

export const StyledWaveSvg = styled(WaveSvg).attrs(({ color }) => ({
  fill: color,
}))`
  position: relative;
  width: 300%;
  animation: wave 2s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);

  @keyframes wave {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: -100%;
    }
  }
`
