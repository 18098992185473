import { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFile } from '@fortawesome/free-solid-svg-icons'
import { clientDocumentsService } from '../../../../services/SpecificDocumentService/clientDocumentsService'
import DownloadService from '../../../../services/DownloadService/downloadService'

import * as S from './WellingtonWidget.styles'
import { ThemeContext } from '../../../../context/ThemeProvider'

function WellingtonWidget() {
  const [documentsName, setDocumentsName] = useState<string[]>([])

  const { mainColor, mainColorLight } = useContext(ThemeContext)

  useEffect(() => {
    clientDocumentsService.findAllDocumentsName().then(res => {
      setDocumentsName(res.data)
    })
  }, [])

  const handleDownload = (documentName: string) => {
    clientDocumentsService.downloadSpecificDocumentsByName(documentName).then(res => {
      console.log(res.data)
      DownloadService.downloadDoc(res.data, documentName)
    })
  }

  return (
    <S.DocumentsContainer>
      {documentsName.map((documentName, index) => {
        const isLastDocument = index === documentsName.length - 1

        return (
          <S.DocumentElement
            key={documentName}
            isLastDocument={isLastDocument}
            onClick={() => handleDownload(documentName)}>
            <S.FileIconContainer color={mainColorLight}>
              <FontAwesomeIcon icon={faFile} color={mainColor} />
            </S.FileIconContainer>

            <S.FileName>{documentName}</S.FileName>
            <S.DownloadIconContainer>
              <FontAwesomeIcon icon={faDownload} />
            </S.DownloadIconContainer>
          </S.DocumentElement>
        )
      })}
    </S.DocumentsContainer>
  )
}

export default WellingtonWidget
