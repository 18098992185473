import React, { useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-date-picker'
import { MRT_ColumnDef } from 'material-react-table'
import { toast, Toaster } from 'react-hot-toast'
import http from '../../services/http'
import MaterialTable from '../../components/table/MaterialTable/MaterialTable'
import { PortfolioType } from './Portfolio.types'
import HandleRestOfColumnsCell from './components/HandleRestOfColumnsCell'
import HandleProducedAndAvailabilityCell from './components/HandleProducedAndAvailabilityCell'
import { ProductionService } from '../../services/production/ProductionService'

function Portfolio() {
  localStorage.setItem('localState', 'portfolio')
  // Manage data
  const [rawData, setRawData] = useState<PortfolioType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // Manage filters
  const [filterDate, setFilterDate] = useState<any>(null)
  // Define initial state
  const [initialState] = useState({
    density: 'compact',
  })

  useEffect(() => {
    let date = ''
    if (filterDate != null) {
      const d = filterDate.getDate()
      const m = filterDate.getMonth() + 1
      const y = filterDate.getFullYear()
      date = `${y}-${m < 10 ? '0' : ''}${m}-${d < 10 ? '0' : ''}${d}`
    }

    ProductionService.getPortfolioDashboard(date)
      .then(({ data }: any) => {
        const { fileNameList } = data
        const formatedRawData = data.zestDtoList.map((elem: any) => {
          const rest: any = {}
          elem.zestDtoList.forEach((zestDto: any) => {
            if (zestDto.name.startsWith('Recon')) {
              rest.Recon = zestDto.exist
            } else {
              rest[fileNameList.find((sub: any) => sub[0] === zestDto.name)[1]] = zestDto.exist
            }
          })
          return {
            name: elem.name,
            produced: elem.production,
            hasAllFiles: elem.hasAllFiles,
            ...rest,
          }
        })
        setRawData(formatedRawData)
      })
      .catch(e => {
        console.log(e)
        toast.error('Failed to load data')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [filterDate])

  const columns = useMemo<MRT_ColumnDef<PortfolioType>[]>(() => {
    let restOfColumns: MRT_ColumnDef<PortfolioType>[] = []
    if (rawData.length) {
      restOfColumns = Object.keys(rawData[0])
        .filter(elem => !['name', 'produced', 'hasAllFiles'].includes(elem))
        .map((elem: any) => {
          return {
            header: elem,
            accessorKey: elem,
            enableColumnActions: false,
            enableSorting: false,
            Cell: options => HandleRestOfColumnsCell(options),
          }
        })
    }

    return [
      {
        header: '',
        accessorKey: 'name',
        enableColumnActions: false,
        enableSorting: false,
        muiTableBodyCellProps: {
          sx: {
            paddingTop: '5px',
            paddingBottom: '5px',
          },
        },
      },
      {
        header: 'Produced',
        accessorKey: 'produced',
        enableColumnActions: false,
        enableSorting: false,
        Cell: options => HandleProducedAndAvailabilityCell(options),
      },
      {
        header: 'Data availability',
        accessorKey: 'hasAllFiles',
        enableColumnActions: false,
        enableSorting: false,
        Cell: options => HandleProducedAndAvailabilityCell(options),
      },
      ...restOfColumns,
    ]
  }, [rawData])

  const renderTopToolbar = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', padding: '5px 0px' }}>
        <DatePicker
          value={filterDate || new Date()}
          //selectedDate
          onChange={(e: Date) => {
            setFilterDate(e)
          }}
          format='dd/MM/yyyy'
        />
      </div>
    )
  }

  return (
    <>
      <Toaster />
      <div style={{ marginBottom: 8 }} />
      <MaterialTable
        data={rawData}
        columns={columns}
        enableSelectAll={false}
        isLoading={isLoading}
        initialState={initialState}
        allowPagination={false}
        renderTopToolbar={renderTopToolbar}
      />
    </>
  )
}

export default Portfolio
