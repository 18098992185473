import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: relative;
  width: 160px;
  height: 160px;

  @media (max-height: 880px) {
    width: 128px;
    height: 128px;
  }
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const LinkCss = css<{ color?: string }>`
  color: ${props => props.color};
  &:hover {
    text-decoration: underline;
  }
`

export const StyledLink = styled(Link)`
  ${LinkCss}
  position: absolute;
  bottom: 10px;
  right: 10px;
`

export const StyledExternalLink = styled.a`
  ${LinkCss}
`

export const Text = styled.div<{ color?: string; bold?: boolean }>`
  color: ${props => props.color ?? '#000'};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: 16px;
`

export const HugeText = styled(Text)`
  font-size: 48px;
`

export const BigText = styled(Text)`
  font-size: 20px;
`

export const SmallText = styled(Text)`
  font-size: 12px;
`
