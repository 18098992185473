import React, { useContext, useEffect, useState } from 'react'
import { faFileCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { ROUTES } from '../../../../constants/routes'
import { ThemeContext } from '../../../../context/ThemeProvider'
import { DashboardContext } from '../../../../context/DashboardProvider'
import Loader from '../../../../components/loader/Loader'
import * as S from './DocumentValidationWidget.styles'
import { BigText, Row, StyledFontAwesomeIcon, StyledLink, HugeText } from '../Global.styles'

function DocumentValidationWidget() {
  const { mainColor } = useContext(ThemeContext)
  const { documentValidation, updateDocumentValidation } = useContext(DashboardContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    updateDocumentValidation()
  }, [])
  useEffect(() => {
    if (documentValidation || documentValidation === 0) {
      setIsLoading(false)
    }
  }, [documentValidation])

  if (isLoading) {
    return <Loader />
  }

  if (documentValidation === -2) {
    return (
      <>
        <Row>
          <BigText>Unable to retrieve information</BigText>
        </Row>
        <StyledLink to={ROUTES.DOCUMENT_VALIDATION} state='document_validation' color={mainColor}>
          {'Go to Validation page >'}
        </StyledLink>
      </>
    )
  }

  return (
    <>
      <Row>
        <StyledFontAwesomeIcon icon={faFileCircleCheck} color={mainColor} />
        {documentValidation === 0 ? (
          <S.TextContainer>
            <BigText>No documents to validate</BigText>
          </S.TextContainer>
        ) : (
          <S.TextContainer>
            <HugeText bold color={mainColor}>
              {documentValidation}
            </HugeText>
            <BigText>Documents to validate</BigText>
          </S.TextContainer>
        )}
      </Row>
      <StyledLink to={ROUTES.DOCUMENT_VALIDATION} state='document_validation' color={mainColor}>
        {'Go to Validation page >'}
      </StyledLink>
    </>
  )
}

export default DocumentValidationWidget
