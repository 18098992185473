import React from 'react';
import { MRT_Row } from 'material-react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { ReportTypeNeededData } from '../../../../types/dtos/FollowupDto';
import { COLOR } from '../../../../constants/theme';

export default function HandleExtraColumnsCell(
  options: { row: MRT_Row<ReportTypeNeededData> },
  column: string
) {
  const {
    row: { original },
  } = options;
  return (
    <FontAwesomeIcon
      icon={original[column as keyof typeof original] ? faCheck : faClose}
      color={original[column as keyof typeof original] ? COLOR.GREEN : COLOR.RED}
    />
  );
}
