import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-hot-toast'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { COLOR } from '../../constants/theme'

interface Props {
  reportDay: string | undefined
  setReportDay: React.Dispatch<React.SetStateAction<string | undefined>>
  reportMonth: string | undefined
  setReportMonth: React.Dispatch<React.SetStateAction<string | undefined>>
  reportYear: string | undefined
  setReportYear: React.Dispatch<React.SetStateAction<string | undefined>>
  onBlurCallBack?: () => void
}

const DatePickerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  box-sizing: border-box;
  /* gap: 5px; */
  align-items: center;
  border: 1px solid ${COLOR.BORDER_LIGHT_GRAY};
  border-radius: 5px;
  padding: 2px;
`
const InputContainer = styled.input<{ year?: boolean; validDay?: boolean }>`
  border: 0px;
  max-width: ${props => (props.year ? '40px' : '30px')};
  color: ${props => (props.validDay ? `` : 'red')};
  height: 100%;
  text-align: center;
  padding: 0;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :focus {
    outline: 2px solid ${COLOR.LIGHT_BLUE};
    border-radius: 5px;
  }
`

function DateSelector(props: Props) {
  const {
    reportDay,
    setReportDay,
    reportMonth,
    setReportMonth,
    reportYear,
    setReportYear,
    onBlurCallBack,
  } = props

  const [displayCalendar, setDisplayCalendar] = useState<boolean>(false)

  function handleValidDay() {
    // If reportMonth is falsy, return true indicating successful validation
    if (!reportMonth) {
      return true
    }

    // If reportMonth is truthy, perform additional validations
    if (reportMonth) {
      // Check if reportMonth is one of the months with 30 days and reportDay is less than or equal to 30
      if ([4, 6, 9, 9, 11].includes(Number(reportMonth)) && Number(reportDay) <= 30) {
        return true
      }

      // Check if reportMonth is one of the months with 31 days and reportDay is less than or equal to 31
      if ([1, 3, 5, 7, 8, 10, 12].includes(Number(reportMonth)) && Number(reportDay) <= 31) {
        return true
      }

      // Check if reportMonth is February (2)
      if (Number(reportMonth) === 2) {
        // Check if reportYear is a leap year and reportDay is less than or equal to 29
        // or if reportYear is not a leap year and reportDay is less than or equal to 28
        if (
          (Number(reportYear) % 4 === 0 && Number(reportDay) <= 29) ||
          (Number(reportYear) % 4 !== 0 && Number(reportDay) <= 28)
        ) {
          return true
        }
      }
    }
    // If none of the above conditions are met, return false indicating validation failure
    return false
  }

  useEffect(() => {
    if (reportYear && reportMonth && reportDay && !displayCalendar && onBlurCallBack) {
      onBlurCallBack()
    }
  }, [displayCalendar])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
      <DatePickerContainer
        onBlur={e => {
          // Check if the related target (element receiving focus) is not contained within the current target (element losing focus)
          // It's used to fetch the data only when the user clicks outside the date picker
          if (!e.currentTarget.contains(e.relatedTarget)) {
            if (reportMonth && reportYear && onBlurCallBack) {
              onBlurCallBack()
              if (reportDay && reportDay?.length < 2) {
                setReportDay(prev => `0${prev}`)
              }
              if (reportMonth && reportMonth?.length < 2) {
                setReportMonth(prev => `0${prev}`)
              }
              setDisplayCalendar(false)
            } else {
              toast.error('The selected date is invalid')
              setDisplayCalendar(false)
              setReportDay('')
              setReportMonth('')
              setReportYear('')
            }
          }
        }}>
        <InputContainer
          id='day'
          type='number'
          value={reportDay}
          placeholder='DD'
          min='01'
          max='31'
          onChange={e => {
            const { value } = e.target
            if (value.length <= 2 && Number(value) <= 31) {
              setReportDay(value)
              if (value.length === 2) {
                const nextFocus = document.getElementById('month')
                nextFocus?.focus()
              }
            }
          }}
          validDay={handleValidDay()}
        />
        /
        <InputContainer
          id='month'
          type='number'
          value={reportMonth}
          placeholder='MM'
          min='01'
          max='12'
          maxLength={2}
          onChange={e => {
            const { value } = e.target
            if (value.length <= 2 && Number(value) <= 12) {
              setReportMonth(value)
              if (value.length === 2) {
                const nextFocus = document.getElementById('year')
                nextFocus?.focus()
              }
            }
          }}
          validDay
        />
        /
        <InputContainer
          id='year'
          type='number'
          value={reportYear}
          placeholder='YYYY'
          maxLength={4}
          onChange={e => {
            const { value } = e.target
            if (value.length <= 4) {
              setReportYear(value)
            }
          }}
          validDay
          year
        />
        <FontAwesomeIcon
          icon={faCalendarDays}
          style={{ padding: '0px 5px', cursor: 'pointer' }}
          onClick={() => {
            setReportDay('')
            setReportMonth('')
            setReportYear('')
            setDisplayCalendar(prev => !prev)
          }}
        />
        {displayCalendar && (
          <DateCalendar
            autoFocus
            sx={{
              position: 'absolute',
              top: '37px',
              left: '0px',
              zIndex: 4,
              backgroundColor: 'white',
              outline: `2px solid ${COLOR.LIGHT_BLUE}`,
              borderRadius: '5px',
              '& .Mui-selected': {
                backgroundColor: 'white !important',
              },
            }}
            openTo='year'
            views={['year', 'month', 'day']}
            onYearChange={(e: any) => {
              setReportYear(String(e.$y))
            }}
            onMonthChange={(e: any) => {
              setReportMonth(String(e.$M + 1).length === 2 ? String(e.$M + 1) : `0${e.$M + 1}`)
            }}
            onChange={(e: any) => {
              if (reportMonth && reportYear) {
                setReportDay(String(e.$D).length === 2 ? String(e.$D) : `0${e.$D}`)
                setDisplayCalendar(false)
              }
            }}
          />
        )}
      </DatePickerContainer>
    </LocalizationProvider>
  )
}

export default DateSelector
