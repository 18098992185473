import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BigText } from '../Global.styles'

export const InfoBox = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: ${props => props.color};
  margin: 5px 0 30px 0;
`

export const BigTextResized = styled(BigText)`
  @media (max-width: 1000px) {
    font-size: 16px;
  }
`
