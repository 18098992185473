import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../../context/ThemeProvider'
import Loader from '../../../../components/loader/Loader'
import { ROUTES } from '../../../../constants/routes'
import { DashboardContext } from '../../../../context/DashboardProvider'
import * as S from './ProductionStatusWidget.styles'
import { Row, StyledLink, HugeText, BigText } from '../Global.styles'

function ProductionStatusWidget() {
  const { productionStatus, updateProductionStatus } = useContext(DashboardContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { mainColor, mainColorVeryLight } = useContext(ThemeContext)

  useEffect(() => {
    updateProductionStatus()
  }, [])
  useEffect(() => {
    if (productionStatus) {
      setIsLoading(false)
    }
  }, [productionStatus])

  if (isLoading) {
    return <Loader />
  }

  if (productionStatus?.length === 1 && productionStatus[0].idStatus === -2) {
    return (
      <>
        <Row>
          <BigText>Unable to retrieve information</BigText>
        </Row>
        <StyledLink to={ROUTES.PRODUCTION_STATUS} state='production_status' color={mainColor}>
          {'Go to Production page >'}
        </StyledLink>
      </>
    )
  }

  return (
    <>
      <Row style={{ padding: '0 1%', gap: '5px', alignItems: 'stretch' }}>
        <S.InfoBox
          to={ROUTES.PRODUCTION_STATUS}
          state='production_status'
          color={mainColorVeryLight}>
          <HugeText bold color={mainColor}>
            {productionStatus?.find(elem => elem.idStatus === 1)
              ? productionStatus?.find(elem => elem.idStatus === 1)?.totalCount
              : 0}
          </HugeText>
          <S.BigTextResized>In data sources</S.BigTextResized>
        </S.InfoBox>
        <S.InfoBox
          to={ROUTES.PRODUCTION_STATUS}
          state='production_status'
          color={mainColorVeryLight}>
          <HugeText bold color={mainColor}>
            {productionStatus?.find(elem => elem.idStatus === 2)
              ? productionStatus?.find(elem => elem.idStatus === 2)?.totalCount
              : 0}
          </HugeText>
          <S.BigTextResized>In production</S.BigTextResized>
        </S.InfoBox>
        <S.InfoBox
          to={ROUTES.PRODUCTION_STATUS}
          state='production_status'
          color={mainColorVeryLight}>
          <HugeText bold color={mainColor}>
            {productionStatus?.find(elem => elem.idStatus === 3)
              ? productionStatus?.find(elem => elem.idStatus === 3)?.totalCount
              : 0}
          </HugeText>
          <S.BigTextResized>In control</S.BigTextResized>
        </S.InfoBox>
        <S.InfoBox
          to={ROUTES.PRODUCTION_STATUS}
          state='production_status'
          color={mainColorVeryLight}>
          <HugeText bold color={mainColor}>
            {productionStatus?.find(elem => elem.idStatus === 4)
              ? productionStatus?.find(elem => elem.idStatus === 4)?.totalCount
              : 0}
          </HugeText>
          <S.BigTextResized>In client validation</S.BigTextResized>
        </S.InfoBox>
        <S.InfoBox
          to={ROUTES.PRODUCTION_STATUS}
          state='production_status'
          color={mainColorVeryLight}>
          <HugeText bold color={mainColor}>
            {productionStatus?.find(elem => elem.idStatus === 5)
              ? productionStatus?.find(elem => elem.idStatus === 5)?.totalCount
              : 0}
          </HugeText>
          <S.BigTextResized>In 3rd party sent</S.BigTextResized>
        </S.InfoBox>
      </Row>
      <StyledLink to={ROUTES.PRODUCTION_STATUS} state='production_status' color={mainColor}>
        {'Go to Production page >'}
      </StyledLink>
    </>
  )
}

export default ProductionStatusWidget
