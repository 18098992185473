import React, { createContext, useEffect, useState } from 'react';
import { pSBC } from '../helpers/pSBC';

const defaultMainColor = '#FF6900'; // Alpha omega Color

export type Theme = {
  mainColor: string;
  setMainColor: React.Dispatch<React.SetStateAction<string>>;
  mainColorLight: string;
  setMainColorLight: React.Dispatch<React.SetStateAction<string>>;
  mainColorVeryLight: string;
  setMainColorVeryLight: React.Dispatch<React.SetStateAction<string>>;
  clientLogoSrc: string;
  setClientLogoSrc: React.Dispatch<React.SetStateAction<string>>;
  parentLogoSrc: string;
  setParentLogoSrc: React.Dispatch<React.SetStateAction<string>>;
};

type ThemeProviderProps = {
  children: React.ReactNode;
};

export const ThemeContext = createContext<Theme>({
  mainColor: defaultMainColor,
  mainColorLight: defaultMainColor,
  mainColorVeryLight: defaultMainColor,
  setMainColor: () => {},
  setMainColorLight: () => {},
  setMainColorVeryLight: () => {},
  clientLogoSrc: '',
  setClientLogoSrc: () => {},
  parentLogoSrc: '',
  setParentLogoSrc: () => {},
});

/**
 * This provider contains all parameters about the theme of the application (colors,fonts, etc)
 * @param children
 * @constructor
 */
export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  // --- COLORS --- we define a main color and its light versions
  const [mainColor, setMainColor] = useState<string>(defaultMainColor);
  const [mainColorLight, setMainColorLight] = useState<string>(
    pSBC(0.6, defaultMainColor) as string
  );
  const [mainColorVeryLight, setMainColorVeryLight] = useState<string>(
    pSBC(0.85, defaultMainColor) as string
  );
  const [clientLogoSrc, setClientLogoSrc] = useState<string>('');
  const [parentLogoSrc, setParentLogoSrc] = useState<string>('');

  useEffect(() => {
    setMainColorLight(pSBC(0.6, mainColor) as string);
    setMainColorVeryLight(pSBC(0.85, mainColor) as string);
  }, [mainColor]);

  return (
    <ThemeContext.Provider
      value={{
        mainColor,
        setMainColor,
        mainColorLight,
        setMainColorLight,
        mainColorVeryLight,
        setMainColorVeryLight,
        setClientLogoSrc,
        clientLogoSrc,
        parentLogoSrc,
        setParentLogoSrc,
      }}>
      {children}
    </ThemeContext.Provider>
  );
};
