import React from 'react'
import ConfirmModal from '../../../../components/modal/ConfirmModal'
import { TemplateValidationScopeType } from '../../TemplateValidation'

type Props = {
  isModalOpenned: boolean
  setModalOpenned: React.Dispatch<React.SetStateAction<boolean>>
  selectedRows: TemplateValidationScopeType[]
  setTemplateValidationData: React.Dispatch<React.SetStateAction<TemplateValidationScopeType[]>>
}

function TemplateValidationApproveModal({
  isModalOpenned,
  setModalOpenned,
  selectedRows,
  setTemplateValidationData,
}: Props) {
  return (
    <ConfirmModal
      width={350}
      height={150}
      title='Approve templates'
      text={
        selectedRows.length === 1
          ? `1 template will be approved, are you sure ?`
          : `${selectedRows.length} templates will be approved, are you sure ?`
      }
      isModalOpenned={isModalOpenned}
      closeModalHandler={() => setModalOpenned(false)}
      actionCallback={() => {
        setModalOpenned(false)
        setTemplateValidationData(prevState => {
          return prevState.filter(scope => {
            // Remove selected rows from scopeListData
            return !selectedRows.some(selectedRow => {
              return selectedRow.id === scope.id
            })
          })
        })
      }}
    />
  )
}

export default TemplateValidationApproveModal
