import React, { useEffect, useMemo, useState } from 'react'
import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { CheckBox } from '@mui/icons-material'
import { Checkbox } from '@mui/material'
import MaterialTable from '../../components/table/MaterialTable/MaterialTable'
import { Followup } from '../ProductionStatus/ProductionStatus.types'
import ChangelogCell from './components/ChangelogCell'
import NarrativeValidationModal, {
  ChangelogNarrative,
} from './components/narrativeValidationModal/NarrativeValidationModal'
import Button from '../../components/button/Button'
import * as S from './NarrativeValidation.styles'
import NarrativeValidationApproveModal from './components/narrativeValidationDeclineApproveModal/NarrativeValidationApproveModal'
import NarrativeValidationDeclineModal from './components/narrativeValidationDeclineApproveModal/NarrativeValidationDeclineModal'

export type NarrativeValidationScopeType = {
  shareclassId: number
  shareclassName: string
  code_isin: string
  languageId: number
  languageName: string
  languageIsoCode2: string
  countryId: number
  countryName: string
  countryIsoCode2: string
  id: string
  narratives: ChangelogNarrative[]
  filePath: string
}

// TODO REMOVE TEMPORARY BEHIND
const scopeListData = [
  {
    shareclassId: 1,
    shareclassName: 'Shareclass 1',
    code_isin: 'LUXXXXXXXX12',
    languageId: 1,
    languageName: 'English',
    languageIsoCode2: 'en',
    countryId: 5,
    countryName: 'Luxembourg',
    countryIsoCode2: 'lu',
    id: 'LUXXXXXXXX12' + '-' + 'en' + '-' + 'lu',
    narratives: [
      {
        id: 'what_product_part-term_text',
        old: '<div class="simple_text" id="what_product_part-term_text" path="">The Sub-Fund is established for an unlimited duration. However, the board of directors may also propose to dissolve a<delation path=""> Compartment </delation>at a General Meeting of that<delation path=""> Compartment.</delation></div>',
        new: '<div class="simple_text" id="what_product_part-term_text" path="">The Sub-Fund is established for an unlimited duration. However, the board of directors may also propose to dissolve a<insertion path=""> sub-fund </insertion>at a General Meeting of that<insertion path=""> sub-fund.</insertion></div>',
      },
      {
        id: 'what_product_part-objectives_narrative-2',
        old: '<div class="list_text" id="what_product_part-objectives_narrative-2" path="" style="page-break-inside: avoid;">This<delation path=""> Sub-fund </delation>is a medium/high risk vehicle aiming to provide capital growth. It may be suitable for investors who are seeking capital growth opportunities with income coming from both the equity and the debt markets over the long term.</div>',
        new: '<div class="list_text" id="what_product_part-objectives_narrative-2" path="" style="page-break-inside: avoid;">This<insertion path=""> Sub-Fund </insertion>is a medium/high risk vehicle aiming to provide capital growth. It may be suitable for investors who are seeking capital growth opportunities with income coming from both the equity and the debt markets over the long term.</div>',
      },
      {
        id: 'what_product_part-objectives_narrative-3',
        old: '<div class="list_text" id="what_product_part-objectives_narrative-3" path="" style="page-break-inside: avoid;">The objective of this<delation path=""> Sub-fund </delation>is to achieve capital appreciation over the long term by investing mainly in equities and debt securities. The<delation path=""> Subfund </delation>will mainly offer an exposure to equity and equity related securities (including but not limited to ordinary or preferred shares, notes, closedended REITs), and debt instruments (including Money Market Instruments) of any type issued by corporate or sovereign issuers.<br path=""/>In order to achieve its objective, the<delation path=""> Sub-fund </delation>may invest directly in the securities mentioned in the previous paragraph; and/or in UCITS and/or other UCIs (such as funds, funds of funds). The<delation path=""> Sub-fund </delation>can be exposed to fixed-income or debt securities, without constraint on average credit rating or maturity.</div>',
        new: '<div class="list_text" id="what_product_part-objectives_narrative-3" path="" style="page-break-inside: avoid;">The objective of this<insertion path=""> Sub-Fund </insertion>is to achieve capital appreciation over the long term by investing mainly in equities and debt securities. The<insertion path=""> Sub-Fund </insertion>will mainly offer an exposure to equity and equity related securities (including but not limited to ordinary or preferred shares, notes, closedended REITs), and debt instruments (including Money Market Instruments) of any type issued by corporate or sovereign issuers.<br path=""/>In order to achieve its objective, the<insertion path=""> Sub-Fund </insertion>may invest directly in the securities mentioned in the previous paragraph; and/or in UCITS and/or other UCIs (such as funds, funds of funds). The<insertion path=""> Sub-Fund </insertion>can be exposed to fixed-income or debt securities, without constraint on average credit rating or maturity.</div>',
      },
      {
        id: 'what_product_part-objectives_narrative-4',
        old: '<div class="list_text" id="what_product_part-objectives_narrative-4" path="" style="page-break-inside: avoid;">The choice of investments will not be limited to a geographic sector (including emerging countries), a particular sector of economic activity or a given currency. However, depending on market conditions, this exposure may be limited to a specific sector or currency. On an ancillary basis, the<delation path=""> Sub-fund </delation>may also invest in cash and cash equivalent (within a limit of 20% of its net assets, in normal financial market conditions).<br path=""/>It is understood that due to the fact that the<delation path=""> Sub-fund </delation>invests a substantial body of its assets in UCITS and other UCIs, the Shareholder is exposed to a possible duplication of fees and charges. However, by derogation to the Section 21 “Investment Restrictions” of the main body of the Prospectus, the maximum percentage of the fixed management fee at the level of the target UCITS and/or other UCIs will be 2%.</div>',
        new: '<div class="list_text" id="what_product_part-objectives_narrative-4" path="" style="page-break-inside: avoid;">The choice of investments will not be limited to a geographic sector (including emerging countries), a particular sector of economic activity or a given currency. However, depending on market conditions, this exposure may be limited to a specific sector or currency. On an ancillary basis, the<insertion path=""> Sub-Fund </insertion>may also invest in cash and cash equivalent (within a limit of 20% of its net assets, in normal financial market conditions).<br path=""/>It is understood that due to the fact that the<insertion path=""> Sub-Fund </insertion>invests a substantial body of its assets in UCITS and other UCIs, the Shareholder is exposed to a possible duplication of fees and charges. However, by derogation to the Section 21 “Investment Restrictions” of the main body of the Prospectus, the maximum percentage of the fixed management fee at the level of the target UCITS and/or other UCIs will be 2%.</div>',
      },
      {
        id: 'what_product_part-objectives_narrative-5',
        old: '<div class="list_text" id="what_product_part-objectives_narrative-5" path="" style="page-break-inside: avoid;">It is understood that the<delation path=""> Sub-fund </delation>will not invest more than 12% of its net assets in non-investment grade debt securities (including non-rated bonds investments, Distressed Securities and Defaulted Securities).<br path=""/>It is understood that the<delation path=""> Sub-fund </delation>will not invest more than 5% of its net assets in inflation linked securities.<br path=""/>It is understood that the<delation path=""> Sub-fund </delation>does not intent to be exposed to contingent convertible bonds.</div>',
        new: '<div class="list_text" id="what_product_part-objectives_narrative-5" path="" style="page-break-inside: avoid;">It is understood that the<insertion path=""> Sub-Fund </insertion>will not invest more than 12% of its net assets in non-investment grade debt securities (including non-rated bonds investments, Distressed Securities and Defaulted Securities).<br path=""/>It is understood that the<insertion path=""> Sub-Fund </insertion>will not invest more than 5% of its net assets in inflation linked securities.<br path=""/>It is understood that the<insertion path=""> Sub-Fund </insertion>does not intent to be exposed to contingent convertible bonds.</div>',
      },
      {
        id: 'what_product_part-objectives_narrative-6',
        old: '<div class="list_text" id="what_product_part-objectives_narrative-6" path="" style="page-break-inside: avoid;">If the Investment Manager considers it to be in the best interests of the Shareholders, the<delation path=""> Sub-fund </delation>may hold up to 100% of its net assets in cash and cash equivalents (cash deposits, money market UCIs and Money Market Instruments), on a temporary basis and for defensive purposes. The<delation path=""> Sub-fund </delation>will not use SFTs nor TRS. For the purpose of Taxonomy Regulation, the investments underlying the<delation path=""> Sub-fund </delation>do not take into account the EU criteria for environmentally sustainable economic activities. The total commitment arising from financial derivative instruments, for purposes other than hedging, will not exceed 100% of the<delation path=""> Sub-fund\'s </delation>net assets.</div>',
        new: '<div class="list_text" id="what_product_part-objectives_narrative-6" path="" style="page-break-inside: avoid;">If the Investment Manager considers it to be in the best interests of the Shareholders, the<insertion path=""> Sub-Fund </insertion>may hold up to 100% of its net assets in cash and cash equivalents (cash deposits, money market UCIs and Money Market Instruments), on a temporary basis and for defensive purposes. The<insertion path=""> Sub-Fund </insertion>will not use SFTs nor TRS. For the purpose of Taxonomy Regulation, the investments underlying the<insertion path=""> Sub-Fund </insertion>do not take into account the EU criteria for environmentally sustainable economic activities. The total commitment arising from financial derivative instruments, for purposes other than hedging, will not exceed 100% of the<insertion path=""> Sub-Fund\'s </insertion>net assets.</div>',
      },
      {
        id: 'what_product_part-objectives_derivatives',
        old: '<div class="simple_text" id="what_product_part-objectives_derivatives" path=""> <b path=""><span class="to_remove" hash-id="8468463468539564969" id="58d8c39b-df3b-4faf-a299-35ffbc4c1513-8468463468539564969" path="">Derivatives</span></b>For hedging and for investment purposes, the<delation path=""> Sub-fund </delation>may use all types of financial derivative instruments traded on a Regulated Market and/or OTC Derivatives provided they are contracted with leading financial institutions specialised in this type of transactions and subject to regulatory supervision. However, in normal market conditions the Investment Manager intends to use principally, for hedging purposes, currency derivatives such as forward exchange contracts. Those investments may not be used to elude the investment policy of the Subfund.</div>',
        new: '<div class="simple_text" id="what_product_part-objectives_derivatives" path=""> <b path=""><span class="to_remove" hash-id="8468463468539564969" id="58d8c39b-df3b-4faf-a299-35ffbc4c1513-8468463468539564969" path="">Derivatives</span></b>For hedging and for investment purposes, the<insertion path=""> Sub-Fund </insertion>may use all types of financial derivative instruments traded on a Regulated Market and/or OTC Derivatives provided they are contracted with leading financial institutions specialised in this type of transactions and subject to regulatory supervision. However, in normal market conditions the Investment Manager intends to use principally, for hedging purposes, currency derivatives such as forward exchange contracts. Those investments may not be used to elude the investment policy of the Subfund.</div>',
      },
      {
        id: 'what_product_part-objectives_benchmark',
        old: '<div class="simple_text" id="what_product_part-objectives_benchmark" path=""> <b path=""><span class="to_remove" hash-id="-3626751812532914028" id="bdcb75a5-3f75-4339-a41c-9bff0e624080--3626751812532914028" path="">Benchmark</span></b>The<delation path=""> Sub-fund </delation>is actively managed. The following composite benchmark index of the<delation path=""> Sub-fund </delation>is mentioned only for performance comparison purposes (50% MSCI All Country World NTR Index in USD and 50% JP Morgan GBI US 1-10 Year TR Index in USD) The<delation path=""> Sub-fund </delation>does not track the composite index and can deviate significantly or entirely from the composite benchmark index.</div>',
        new: '<div class="simple_text" id="what_product_part-objectives_benchmark" path=""> <b path=""><span class="to_remove" hash-id="-3626751812532914028" id="bdcb75a5-3f75-4339-a41c-9bff0e624080--3626751812532914028" path="">Benchmark</span></b>The<insertion path=""> Sub-Fund </insertion>is actively managed. The following composite benchmark index of the<insertion path=""> Sub-Fund </insertion>is mentioned only for performance comparison purposes (50% MSCI All Country World NTR Index in USD and 50% JP Morgan GBI US 1-10 Year TR Index in USD) The<insertion path=""> Sub-Fund </insertion>does not track the composite index and can deviate significantly or entirely from the composite benchmark index.</div>',
      },
      {
        id: 'what_product_part-intended_retail_content',
        old: '<div class="text_title_text" id="what_product_part-intended_retail_content" path="">This Class is reserved<delation path=""> Investors </delation>having entered into an agreement with the sponsor of the<delation path=""> Compartment,</delation>Condesa Limited. The product is compatible with investors who may bear capital losses and who do not need capital guarantee. The product is compatible with clients looking for growing their capital and who wish to hold their investment over 5 years.</div>',
        new: '<div class="text_title_text" id="what_product_part-intended_retail_content" path="">This Class is reserved<insertion path=""> to investors </insertion>having entered into an agreement with the sponsor of the<insertion path=""> Sub-Fund,</insertion>Condesa Limited. The product is compatible with investors who may bear capital losses and who do not need capital guarantee. The product is compatible with clients looking for growing their capital and who wish to hold their investment over 5 years.</div>',
      },
      {
        id: 'how_complain_part-content_website',
        old: '<div class="simple_text" id="how_complain_part-content_website" path=""><delation path="">https://www.alphaomega.lu/</delation></div>',
        new: '<div class="simple_text" id="how_complain_part-content_website" path=""><insertion path="">hhttps://www.alphaomega.lu/</insertion></div>',
      },
    ],
    filePath: './KID_en_LU_4_LU1372006947.pdf',
  },
  {
    shareclassId: 1,
    shareclassName: 'Shareclass 1',
    code_isin: 'FRXXXXXXXX12',
    languageId: 1,
    languageName: 'English',
    languageIsoCode2: 'en',
    countryId: 1,
    countryName: 'France',
    countryIsoCode2: 'fr',
    id: 'LUXXXXXXXX12' + '-' + 'en' + '-' + 'fr',
    narratives: [
      {
        id: 'what_product_part-term_text',
        old: '<div class="simple_text" id="what_product_part-term_text" path="">The Sub-Fund is established for an unlimited duration. However, the board of directors may also propose to dissolve a<delation path=""> Compartment </delation>at a General Meeting of that<delation path=""> Compartment.</delation></div>',
        new: '<div class="simple_text" id="what_product_part-term_text" path="">The Sub-Fund is established for an unlimited duration. However, the board of directors may also propose to dissolve a<insertion path=""> sub-fund </insertion>at a General Meeting of that<insertion path=""> sub-fund.</insertion></div>',
      },
      {
        id: 'what_product_part-objectives_narrative-2',
        old: '<div class="list_text" id="what_product_part-objectives_narrative-2" path="" style="page-break-inside: avoid;">This<delation path=""> Sub-fund </delation>is a medium/high risk vehicle aiming to provide capital growth. It may be suitable for investors who are seeking capital growth opportunities with income coming from both the equity and the debt markets over the long term.</div>',
        new: '<div class="list_text" id="what_product_part-objectives_narrative-2" path="" style="page-break-inside: avoid;">This<insertion path=""> Sub-Fund </insertion>is a medium/high risk vehicle aiming to provide capital growth. It may be suitable for investors who are seeking capital growth opportunities with income coming from both the equity and the debt markets over the long term.</div>',
      },
      {
        id: 'what_product_part-objectives_narrative-3',
        old: '<div class="list_text" id="what_product_part-objectives_narrative-3" path="" style="page-break-inside: avoid;">The objective of this<delation path=""> Sub-fund </delation>is to achieve capital appreciation over the long term by investing mainly in equities and debt securities. The<delation path=""> Subfund </delation>will mainly offer an exposure to equity and equity related securities (including but not limited to ordinary or preferred shares, notes, closedended REITs), and debt instruments (including Money Market Instruments) of any type issued by corporate or sovereign issuers.<br path=""/>In order to achieve its objective, the<delation path=""> Sub-fund </delation>may invest directly in the securities mentioned in the previous paragraph; and/or in UCITS and/or other UCIs (such as funds, funds of funds). The<delation path=""> Sub-fund </delation>can be exposed to fixed-income or debt securities, without constraint on average credit rating or maturity.</div>',
        new: '<div class="list_text" id="what_product_part-objectives_narrative-3" path="" style="page-break-inside: avoid;">The objective of this<insertion path=""> Sub-Fund </insertion>is to achieve capital appreciation over the long term by investing mainly in equities and debt securities. The<insertion path=""> Sub-Fund </insertion>will mainly offer an exposure to equity and equity related securities (including but not limited to ordinary or preferred shares, notes, closedended REITs), and debt instruments (including Money Market Instruments) of any type issued by corporate or sovereign issuers.<br path=""/>In order to achieve its objective, the<insertion path=""> Sub-Fund </insertion>may invest directly in the securities mentioned in the previous paragraph; and/or in UCITS and/or other UCIs (such as funds, funds of funds). The<insertion path=""> Sub-Fund </insertion>can be exposed to fixed-income or debt securities, without constraint on average credit rating or maturity.</div>',
      },
      {
        id: 'what_product_part-objectives_narrative-4',
        old: '<div class="list_text" id="what_product_part-objectives_narrative-4" path="" style="page-break-inside: avoid;">The choice of investments will not be limited to a geographic sector (including emerging countries), a particular sector of economic activity or a given currency. However, depending on market conditions, this exposure may be limited to a specific sector or currency. On an ancillary basis, the<delation path=""> Sub-fund </delation>may also invest in cash and cash equivalent (within a limit of 20% of its net assets, in normal financial market conditions).<br path=""/>It is understood that due to the fact that the<delation path=""> Sub-fund </delation>invests a substantial body of its assets in UCITS and other UCIs, the Shareholder is exposed to a possible duplication of fees and charges. However, by derogation to the Section 21 “Investment Restrictions” of the main body of the Prospectus, the maximum percentage of the fixed management fee at the level of the target UCITS and/or other UCIs will be 2%.</div>',
        new: '<div class="list_text" id="what_product_part-objectives_narrative-4" path="" style="page-break-inside: avoid;">The choice of investments will not be limited to a geographic sector (including emerging countries), a particular sector of economic activity or a given currency. However, depending on market conditions, this exposure may be limited to a specific sector or currency. On an ancillary basis, the<insertion path=""> Sub-Fund </insertion>may also invest in cash and cash equivalent (within a limit of 20% of its net assets, in normal financial market conditions).<br path=""/>It is understood that due to the fact that the<insertion path=""> Sub-Fund </insertion>invests a substantial body of its assets in UCITS and other UCIs, the Shareholder is exposed to a possible duplication of fees and charges. However, by derogation to the Section 21 “Investment Restrictions” of the main body of the Prospectus, the maximum percentage of the fixed management fee at the level of the target UCITS and/or other UCIs will be 2%.</div>',
      },
      {
        id: 'what_product_part-objectives_narrative-5',
        old: '<div class="list_text" id="what_product_part-objectives_narrative-5" path="" style="page-break-inside: avoid;">It is understood that the<delation path=""> Sub-fund </delation>will not invest more than 12% of its net assets in non-investment grade debt securities (including non-rated bonds investments, Distressed Securities and Defaulted Securities).<br path=""/>It is understood that the<delation path=""> Sub-fund </delation>will not invest more than 5% of its net assets in inflation linked securities.<br path=""/>It is understood that the<delation path=""> Sub-fund </delation>does not intent to be exposed to contingent convertible bonds.</div>',
        new: '<div class="list_text" id="what_product_part-objectives_narrative-5" path="" style="page-break-inside: avoid;">It is understood that the<insertion path=""> Sub-Fund </insertion>will not invest more than 12% of its net assets in non-investment grade debt securities (including non-rated bonds investments, Distressed Securities and Defaulted Securities).<br path=""/>It is understood that the<insertion path=""> Sub-Fund </insertion>will not invest more than 5% of its net assets in inflation linked securities.<br path=""/>It is understood that the<insertion path=""> Sub-Fund </insertion>does not intent to be exposed to contingent convertible bonds.</div>',
      },
      {
        id: 'what_product_part-objectives_narrative-6',
        old: '<div class="list_text" id="what_product_part-objectives_narrative-6" path="" style="page-break-inside: avoid;">If the Investment Manager considers it to be in the best interests of the Shareholders, the<delation path=""> Sub-fund </delation>may hold up to 100% of its net assets in cash and cash equivalents (cash deposits, money market UCIs and Money Market Instruments), on a temporary basis and for defensive purposes. The<delation path=""> Sub-fund </delation>will not use SFTs nor TRS. For the purpose of Taxonomy Regulation, the investments underlying the<delation path=""> Sub-fund </delation>do not take into account the EU criteria for environmentally sustainable economic activities. The total commitment arising from financial derivative instruments, for purposes other than hedging, will not exceed 100% of the<delation path=""> Sub-fund\'s </delation>net assets.</div>',
        new: '<div class="list_text" id="what_product_part-objectives_narrative-6" path="" style="page-break-inside: avoid;">If the Investment Manager considers it to be in the best interests of the Shareholders, the<insertion path=""> Sub-Fund </insertion>may hold up to 100% of its net assets in cash and cash equivalents (cash deposits, money market UCIs and Money Market Instruments), on a temporary basis and for defensive purposes. The<insertion path=""> Sub-Fund </insertion>will not use SFTs nor TRS. For the purpose of Taxonomy Regulation, the investments underlying the<insertion path=""> Sub-Fund </insertion>do not take into account the EU criteria for environmentally sustainable economic activities. The total commitment arising from financial derivative instruments, for purposes other than hedging, will not exceed 100% of the<insertion path=""> Sub-Fund\'s </insertion>net assets.</div>',
      },
      {
        id: 'what_product_part-objectives_derivatives',
        old: '<div class="simple_text" id="what_product_part-objectives_derivatives" path=""> <b path=""><span class="to_remove" hash-id="8468463468539564969" id="58d8c39b-df3b-4faf-a299-35ffbc4c1513-8468463468539564969" path="">Derivatives</span></b>For hedging and for investment purposes, the<delation path=""> Sub-fund </delation>may use all types of financial derivative instruments traded on a Regulated Market and/or OTC Derivatives provided they are contracted with leading financial institutions specialised in this type of transactions and subject to regulatory supervision. However, in normal market conditions the Investment Manager intends to use principally, for hedging purposes, currency derivatives such as forward exchange contracts. Those investments may not be used to elude the investment policy of the Subfund.</div>',
        new: '<div class="simple_text" id="what_product_part-objectives_derivatives" path=""> <b path=""><span class="to_remove" hash-id="8468463468539564969" id="58d8c39b-df3b-4faf-a299-35ffbc4c1513-8468463468539564969" path="">Derivatives</span></b>For hedging and for investment purposes, the<insertion path=""> Sub-Fund </insertion>may use all types of financial derivative instruments traded on a Regulated Market and/or OTC Derivatives provided they are contracted with leading financial institutions specialised in this type of transactions and subject to regulatory supervision. However, in normal market conditions the Investment Manager intends to use principally, for hedging purposes, currency derivatives such as forward exchange contracts. Those investments may not be used to elude the investment policy of the Subfund.</div>',
      },
      {
        id: 'what_product_part-objectives_benchmark',
        old: '<div class="simple_text" id="what_product_part-objectives_benchmark" path=""> <b path=""><span class="to_remove" hash-id="-3626751812532914028" id="bdcb75a5-3f75-4339-a41c-9bff0e624080--3626751812532914028" path="">Benchmark</span></b>The<delation path=""> Sub-fund </delation>is actively managed. The following composite benchmark index of the<delation path=""> Sub-fund </delation>is mentioned only for performance comparison purposes (50% MSCI All Country World NTR Index in USD and 50% JP Morgan GBI US 1-10 Year TR Index in USD) The<delation path=""> Sub-fund </delation>does not track the composite index and can deviate significantly or entirely from the composite benchmark index.</div>',
        new: '<div class="simple_text" id="what_product_part-objectives_benchmark" path=""> <b path=""><span class="to_remove" hash-id="-3626751812532914028" id="bdcb75a5-3f75-4339-a41c-9bff0e624080--3626751812532914028" path="">Benchmark</span></b>The<insertion path=""> Sub-Fund </insertion>is actively managed. The following composite benchmark index of the<insertion path=""> Sub-Fund </insertion>is mentioned only for performance comparison purposes (50% MSCI All Country World NTR Index in USD and 50% JP Morgan GBI US 1-10 Year TR Index in USD) The<insertion path=""> Sub-Fund </insertion>does not track the composite index and can deviate significantly or entirely from the composite benchmark index.</div>',
      },
      {
        id: 'what_product_part-intended_retail_content',
        old: '<div class="text_title_text" id="what_product_part-intended_retail_content" path="">This Class is reserved<delation path=""> Investors </delation>having entered into an agreement with the sponsor of the<delation path=""> Compartment,</delation>Condesa Limited. The product is compatible with investors who may bear capital losses and who do not need capital guarantee. The product is compatible with clients looking for growing their capital and who wish to hold their investment over 5 years.</div>',
        new: '<div class="text_title_text" id="what_product_part-intended_retail_content" path="">This Class is reserved<insertion path=""> to investors </insertion>having entered into an agreement with the sponsor of the<insertion path=""> Sub-Fund,</insertion>Condesa Limited. The product is compatible with investors who may bear capital losses and who do not need capital guarantee. The product is compatible with clients looking for growing their capital and who wish to hold their investment over 5 years.</div>',
      },
      {
        id: 'how_complain_part-content_website',
        old: '<div class="simple_text" id="how_complain_part-content_website" path=""><delation path="">https://www.alphaomega.lu/</delation></div>',
        new: '<div class="simple_text" id="how_complain_part-content_website" path=""><insertion path="">hhttps://www.alphaomega.lu/</insertion></div>',
      },
    ],
    filePath: './KID_en_LU_4_LU1372006947.pdf',
  },
]

// TODO REMOVE TEMPORARY UP

function NarrativeValidation() {
  localStorage.setItem('localState', 'narrative_validation')

  const [isNarrativeValidationModalOpen, setIsNarrativeValidationModalOpen] = React.useState(false)
  const [selectedRows, setSelectedRows] = React.useState<NarrativeValidationScopeType[]>([])
  const [approveModalOpenned, setApproveModalOpenned] = useState<boolean>(false)
  const [declineModalOpenned, setDeclineModalOpenned] = useState<boolean>(false)
  const [narrativeValidationData, setNarrativeValidationData] =
    useState<NarrativeValidationScopeType[]>(scopeListData)

  const [currentChangelogData, setCurrentChangelogData] = useState<ChangelogNarrative[]>([])
  const [currentFilePath, setCurrentFilePath] = useState<string>('')

  const handleRowSelection = (row: MRT_Row<NarrativeValidationScopeType>) => {
    const isRowAlreadySelected = selectedRows.some(selectedRow => {
      return selectedRow.id === row.original.id
    })

    if (isRowAlreadySelected) {
      setSelectedRows(prevState =>
        prevState.filter(selectedRow => {
          return selectedRow.id !== row.original.id
        })
      )
    } else {
      setSelectedRows(prevState => [...prevState, row.original])
    }
  }

  const handleSelectAll = () => {
    if (selectedRows.length < scopeListData.length) {
      const addRemainingRows = scopeListData.filter(scope => {
        return !selectedRows.some(selectedRow => {
          return selectedRow.id === scope.id
        })
      })

      setSelectedRows(prevState => [...prevState, ...addRemainingRows])
    } else if (selectedRows.length === scopeListData.length) {
      setSelectedRows([])
    }
  }

  function CustomCheckbox(row: MRT_Row<NarrativeValidationScopeType>) {
    return (
      <Checkbox
        onClick={() => handleRowSelection(row)}
        checked={selectedRows.some(selectedRow => {
          return selectedRow.id === row.original.id
        })}
      />
    )
  }

  function CustomParentCheckbox() {
    return (
      <Checkbox
        onClick={handleSelectAll}
        indeterminate={selectedRows.length > 0 && selectedRows.length < scopeListData.length}
        checked={selectedRows.length === scopeListData.length}
        sx={{
          '&.Mui-checked': { color: 'white' },
          '&.MuiCheckbox-root': { color: 'white', padding: '0px' },
        }}
      />
    )
  }

  const columns = useMemo<MRT_ColumnDef<NarrativeValidationScopeType>[]>(() => {
    return [
      {
        header: 'Row selection',
        Header: () => CustomParentCheckbox(),
        Cell: ({ row }) => CustomCheckbox(row),
      },
      {
        header: 'ISIN',
        accessorKey: 'code_isin',
      },
      {
        header: 'Language',
        accessorKey: 'languageName',
      },
      {
        header: 'Country',
        accessorKey: 'countryName',
      },
      {
        header: 'Changelog',
        // @ts-ignore
        Cell: options =>
          ChangelogCell(
            options.row.original as NarrativeValidationScopeType,
            setIsNarrativeValidationModalOpen,
            setCurrentChangelogData,
            setCurrentFilePath
          ),
      },
    ]
  }, [scopeListData, selectedRows])

  return (
    <>
      <NarrativeValidationApproveModal
        isModalOpenned={approveModalOpenned}
        setModalOpenned={setApproveModalOpenned}
        selectedRows={selectedRows}
        setNarrativeValidationData={setNarrativeValidationData}
      />
      <NarrativeValidationDeclineModal
        isModalOpenned={declineModalOpenned}
        setModalOpenned={setDeclineModalOpenned}
        selectedRows={selectedRows}
        setNarrativeValidationData={setNarrativeValidationData}
      />
      <NarrativeValidationModal
        isOpen={isNarrativeValidationModalOpen}
        setIsOpen={setIsNarrativeValidationModalOpen}
        changelogData={currentChangelogData}
        filePath={currentFilePath}
      />
      <MaterialTable
        data={narrativeValidationData}
        columns={columns}
        isLoading={false}
        enableSelectAll={false}
        // initialState={initialStateCodeisin}
        // renderTopToolbar={renderTopToolbar}

        initialState={{}}
      />
      {selectedRows.length > 0 && (
        <S.ActionButtonsContainer>
          <Button
            text='Decline'
            secondary
            handleClick={() => {
              if (selectedRows.length) {
                setDeclineModalOpenned(true)
              }
            }}
          />
          <div style={{ marginRight: 8 }} />
          <Button
            text='Approve'
            handleClick={() => {
              if (selectedRows?.length) {
                setApproveModalOpenned(true)
              }
            }}
            // disabled={!selectedNodes?.length}
          />
        </S.ActionButtonsContainer>
      )}
    </>
  )
}

export default NarrativeValidation
