import { createTheme, ThemeProvider } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import './App.css'
import { boolean } from 'zod'
import Header from './components/header/Header'
import SideBar from './components/sidebar/Sidebar'
import { MODULES_LIST, ROLES } from './constants/constants'
import { ROUTES } from './constants/routes'
import { ThemeContext } from './context/ThemeProvider'
import { UserContext } from './context/UserProvider'
import Dashboard from './pages/dashboard/Dashboard'
import ForgotPassword from './pages/forgotPassword/ForgotPassword'
import { GedProvider } from './pages/ged/context/GedProvider'
import DocumentValidation from './pages/ged/DocumentValidation'
import Library from './pages/ged/Library'
import Invoice from './pages/Invoicing/Invoices/Invoices'
import InvoicingData from './pages/Invoicing/InvoicingData/InvoicingData'
import Kpi from './pages/Kpi/Kpi'
import Login from './pages/login/Login'
import PageNotFound from './pages/pageNotFound/PageNotFound'
import DataSources from './pages/ProductionStatus/DataSources/DataSources'
import ProductionStatus from './pages/ProductionStatus/ProductionStatus'
import ReportValidation from './pages/plannedprod/ReportValidation/ReportValidation'
import ExistingServices from './pages/ScopeManagement/ExistingServices/ExistingServices'
import DisseminationList from './pages/ScopeManagement/DisseminationList/DisseminationList'
import Platform from './pages/ScopeManagement/Platform/Platform'
import RegistrationMatrix from './pages/ScopeManagement/RegistrationMatrix/RegistrationMatrix'
import ResetPassword from './pages/resetPassword/ResetPassword'
import Validation from './pages/validation/Validation'
import http from './services/http'
import './translations/i18n'
import * as S from './App.styles'
import Portfolio from './pages/Portfolio/Portfolio'
import DashboardProvider from './context/DashboardProvider'
import NarrativeValidation from './pages/narrativeValidation/NarrativeValidation'
import { NarrativeValidationProvider } from './pages/narrativeValidation/context/NarrativeContextProvider'
import { TemplateValidationProvider } from './pages/templateValidation/context/TemplateValidationContextProvider'
import TemplateValidation from './pages/templateValidation/TemplateValidation'
import { SingleSignOnService } from './services/SingleSignOnService/SingleSignOnService'
import Logout from './pages/logout/Logout'
import SsoLogin from './pages/login/SsoLogin'
import SsoUserNotFound from './pages/userNotFound/SsoUserNotFound'

const GlobalStyle = createGlobalStyle<any>`
  /* Track */
  ::-webkit-scrollbar-track {
    background: #e9e9e9;
    /* border: 1px solid black; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.bgColor};
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.bgColor};
  }
`

function RequireAuth({
  children,
  role,
  hasParent,
}: {
  children: JSX.Element
  role?: string
  hasParent?: boolean
}) {
  const { user, getUser } = useContext(UserContext)

  const location = useLocation()
  const navigate = useNavigate()
  console.log('RequireAuth()')

  //  check if an token is present to avoid go into the login page
  if (!user) {
    if (localStorage.getItem('access_token')) {
      return getUser()
    }
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
  }

  if (role && !user.roles.includes(role)) {
    return <Navigate to={ROUTES.DASHBOARD} />
  }
  if (hasParent) {
    return <Navigate to={ROUTES.DASHBOARD} />
  }

  return children
}

/**
 * Check if the user has access to the module, if not redirect to the dashboard
 * If the user is not logged in, redirect to the login page
 * @param children
 * @param module
 * @constructor
 */
export function HasAccessToModule({ children, module }: { children: JSX.Element; module: string }) {
  const { user, getUser } = useContext(UserContext)
  const navigate = useNavigate()
  if (!user) {
    if (localStorage.getItem('access_token')) {
      return getUser()
    }
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
  }

  if (
    module &&
    !user?.moduleAccessList.map(module => module.name.toUpperCase()).includes(module.toUpperCase())
  ) {
    return <Navigate to={ROUTES.DASHBOARD} />
  }

  return children
}

function App() {
  const { user, logout } = useContext(UserContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  //THEME
  const { mainColor } = useContext(ThemeContext)

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins',
    },
    palette: {
      primary: {
        main: mainColor,
      },
    },
  })

  const responseHandling = (response: any) => response

  const errorHandling = (error: any) => {
    if (error.response) {
      const { status, data } = error.response
      if (status === 401) {
        if (data && typeof data === 'string' && data.includes('User not found')) {
          // User not found code
        } else {
          logout()
          toast(t('session-expired'))
        }
      }
    }
    return Promise.reject(error)
  }

  useEffect(() => {
    const interceptor = http.interceptors.response.use(responseHandling, errorHandling)
    return () => {
      http.interceptors.response.eject(interceptor)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle bgColor={mainColor} />
      <S.AppContainer>
        {user && <SideBar />}
        <S.ContentContainer>
          {user && <Header />}
          <Toaster containerStyle={{ zIndex: 10000 }} />
          <div style={{ padding: '0px 20px' }}>
            <Routes>
              <Route path='/404' element={<PageNotFound />} />
              <Route
                path={ROUTES.DASHBOARD}
                element={
                  <RequireAuth>
                    <DashboardProvider>
                      <Dashboard />
                    </DashboardProvider>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.PRODUCTION_STATUS}
                element={
                  <RequireAuth>
                    <HasAccessToModule module={MODULES_LIST.PRODUCTION_STATUS}>
                      <ProductionStatus />
                    </HasAccessToModule>
                  </RequireAuth>
                }
              />
              {user?.clientId === 7 && (
                <Route
                  path={ROUTES.PORTFOLIO}
                  element={
                    <RequireAuth>
                      <HasAccessToModule module={MODULES_LIST.PORTFOLIO}>
                        <Portfolio />
                      </HasAccessToModule>
                    </RequireAuth>
                  }
                />
              )}
              <Route
                path={ROUTES.DATA}
                element={
                  <RequireAuth>
                    <DataSources />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.REPORT_VALIDATION}
                element={
                  <RequireAuth hasParent={user?.hasParent}>
                    <ReportValidation />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.SCOPE_MANAGEMENT_EXISTING_SERVICES}
                element={
                  <RequireAuth hasParent={user?.hasParent}>
                    <HasAccessToModule module={MODULES_LIST.SCOPE_MANAGEMENT}>
                      <ExistingServices />
                    </HasAccessToModule>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.SCOPE_MANAGEMENT_PLATFORM}
                element={
                  <RequireAuth hasParent={user?.hasParent}>
                    <HasAccessToModule module={MODULES_LIST.SCOPE_MANAGEMENT}>
                      <Platform />
                    </HasAccessToModule>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.SCOPE_MANAGEMENT_DISSEMINATION_LIST}
                element={
                  <RequireAuth hasParent={user?.hasParent}>
                    <HasAccessToModule module={MODULES_LIST.SCOPE_MANAGEMENT}>
                      <DisseminationList />
                    </HasAccessToModule>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.SCOPE_MANAGEMENT_REGISTRATION_MATRIX}
                element={
                  <RequireAuth hasParent={user?.hasParent}>
                    <HasAccessToModule module={MODULES_LIST.SCOPE_MANAGEMENT}>
                      <RegistrationMatrix />
                    </HasAccessToModule>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.DOCUMENT_VALIDATION}
                element={
                  <RequireAuth hasParent={user?.hasParent}>
                    <GedProvider>
                      <HasAccessToModule module={MODULES_LIST.DOCUMENT_VALIDATION}>
                        <DocumentValidation />
                      </HasAccessToModule>
                    </GedProvider>
                  </RequireAuth>
                }
              />
              {user?.clientId === 10066 && (
                <Route
                  path={ROUTES.TEMPLATE_VALIDATION}
                  element={
                    <RequireAuth>
                      <TemplateValidationProvider>
                        <TemplateValidation />
                      </TemplateValidationProvider>
                    </RequireAuth>
                  }
                />
              )}
              {user?.clientId === 10066 && (
                <Route
                  path={ROUTES.NARRATIVE_VALIDATION}
                  element={
                    <RequireAuth>
                      <NarrativeValidationProvider>
                        <NarrativeValidation />
                      </NarrativeValidationProvider>
                    </RequireAuth>
                  }
                />
              )}
              <Route
                path={ROUTES.LIBRARY}
                element={
                  <RequireAuth>
                    <GedProvider>
                      <HasAccessToModule module={MODULES_LIST.LIBRARY}>
                        <Library />
                      </HasAccessToModule>
                    </GedProvider>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.SCOPE_VALIDATION}
                element={
                  <RequireAuth role={ROLES.MANAGE} hasParent={user?.hasParent}>
                    <HasAccessToModule module={MODULES_LIST.SCOPE_MANAGEMENT}>
                      <Validation />
                    </HasAccessToModule>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.INVOICING_INVOICES}
                element={
                  <RequireAuth role={ROLES.INVOICE} hasParent={user?.hasParent}>
                    <HasAccessToModule module={MODULES_LIST.INVOICING}>
                      <Invoice />
                    </HasAccessToModule>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.INVOICING_DATA}
                element={
                  <RequireAuth role={ROLES.INVOICE} hasParent={user?.hasParent}>
                    <HasAccessToModule module={MODULES_LIST.INVOICING}>
                      <InvoicingData />
                    </HasAccessToModule>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.KPI}
                element={
                  <RequireAuth>
                    <HasAccessToModule module={MODULES_LIST.KPI}>
                      <Kpi />
                    </HasAccessToModule>
                  </RequireAuth>
                }
              />
              <Route path='*' element={<Navigate to={ROUTES.DASHBOARD} />} />
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.SSO_LOGIN} element={<SsoLogin />} />
              <Route path={ROUTES.SSO_USER_NOT_FOUND} element={<SsoUserNotFound />} />
              <Route path={ROUTES.SSO_LOGOUT} element={<Logout />} />
              <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
              <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
            </Routes>
          </div>
        </S.ContentContainer>
      </S.AppContainer>
    </ThemeProvider>
  )
}

export default App
