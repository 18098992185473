import styled from 'styled-components'

const AllPagesContainer = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
`

const ButtonContainer = styled.div`
  display: inline-flex;
  position: absolute;
  bottom: 5%;
  left: 50%;
  border-radius: 5px;
`

const Button = styled.div<{ leftButton: boolean }>`
  background-color: #4b525a;
  cursor: pointer;
  padding: 5px;
  border-top-left-radius: ${props => (props.leftButton ? '5px' : '0px')};
  border-bottom-left-radius: ${props => (props.leftButton ? '5px' : '0px')};
  border-top-right-radius: ${props => (props.leftButton ? '0px' : '5px')};
  border-bottom-right-radius: ${props => (props.leftButton ? '0px' : '5px')};
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
`

export { AllPagesContainer, ButtonContainer, Button }
