/**
 * Service used to get data about clients,funds,subfunds and shareclasses
 */
import { z } from 'zod'
import http from '../http'
import { CurrencyDto, CurrencyDtoSchema } from '../../types/dtos/CurrencyDto'
import { SubfundDto, SubfundDtoSchema } from '../../types/dtos/SubfundDto'
import { ShareclassDto, ShareclassDtoSchema } from '../../types/dtos/ShareclassDto'

export const StructureService = {
  getAllSubfundsDtoByFundList(fundIdsList: number[]) {
    return http.post<SubfundDto[]>('/subfunds-by-fund-list', fundIdsList).then(resp => {
      z.array(SubfundDtoSchema).parse(resp.data)
      return resp
    })
  },

  getAllShareclassDtoBySubfundList(subfundIdsList: number[]) {
    return http.post<ShareclassDto[]>('/shareclass-by-subfund-list', subfundIdsList).then(resp => {
      z.array(ShareclassDtoSchema).parse(resp.data)
      return resp
    })
  },

  /**
   * Get all dividend policies for the connected client AND ITS SUBCLIENTS
   */
  getAllDividendPolicies(fundIds?: number[], subfundIds?: number[]) {
    return http
      .post<string[]>('/shareclass/all-dividend-policies', {
        fundIds,
        subfundIds,
      })
      .then(resp => {
        z.array(z.string()).parse(resp.data)
        return resp
      })
  },

  /**
   * Get all investor types for the connected client AND ITS SUBCLIENTS.
   */
  getAllInvestorTypes(fundIds?: number[], subfundIds?: number[]) {
    return http
      .post<string[]>('/shareclass/all-investor-types', {
        fundIds,
        subfundIds,
      })
      .then(resp => {
        z.array(z.string()).parse(resp.data)
        return resp
      })
  },

  /**
   * Get all shareclasses hedged for the connected client AND ITS SUBCLIENTS.
   */
  getAllHedged(fundIds?: number[], subfundIds?: number[]) {
    return http
      .post<boolean[]>('/shareclass/all-hedged', {
        fundIds,
        subfundIds,
      })
      .then(resp => {
        z.array(z.boolean()).parse(resp.data)
        return resp
      })
  },

  /**
   * Get all shareclasseses names for the connected client AND ITS SUBCLIENTS.
   */
  getAllShareclassesNames(fundIds?: number[], subfundIds?: number[]) {
    return http
      .post<string[]>('/shareclass/all-shareclasses', {
        fundIds,
        subfundIds,
      })
      .then(resp => {
        z.array(z.string()).parse(resp.data)
        return resp
      })
  },

  /**
   * Get all shareclasses currencies for the connected client AND ITS SUBCLIENTS.
   */
  getAllCurrencies(fundIds?: number[], subfundIds?: number[]) {
    return http
      .post<CurrencyDto[]>('/shareclass/all-currencies', {
        fundIds,
        subfundIds,
      })
      .then(resp => {
        z.array(CurrencyDtoSchema).parse(resp.data)
        return resp
      })
  },
  getAllShareclassDtoLike(
    shareclassName?: string,
    codeIsin?: string,
    fundIds?: number[],
    subfundIds?: number[]
  ) {
    return http
      .post<ShareclassDto[]>('/shareclass/all-dto-like/', {
        shareclassName,
        codeIsin,
        fundIds,
        subfundIds,
      })
      .then(resp => {
        z.array(ShareclassDtoSchema).parse(resp.data)
        return resp
      })
  },
}

export default StructureService
