import React, { createContext, useContext, useState } from 'react'
import { toast } from 'react-hot-toast'
import SubscribedReportsService from '../services/SubscribedReportsService/subscribedReportsService'
import { ClientSubscribedReport, SubscribedReport } from '../types/dtos/SubscribedReportDto'
import { UserContext } from './UserProvider'

type SubscribedReportContextType = {
  clientSubscribedReports: ClientSubscribedReport[]
  distinctSubscribedReports: SubscribedReport[]
}

export const SubscribedReportContext = createContext<SubscribedReportContextType>({
  clientSubscribedReports: [],
  distinctSubscribedReports: [],
})

type Props = {
  children: React.ReactNode
}

function SubscribedReportProvider({ children }: Props) {
  const [clientSubscribedReports, setClientSubscribedReports] = React.useState<
    ClientSubscribedReport[]
  >([])
  const [distinctSubscribedReports, setDistinctSubscribedReports] = useState<SubscribedReport[]>([])
  const { user } = useContext(UserContext)

  React.useEffect(() => {
    // Since the reportsTypes depends on the user information, this provider will only fetch that data after a user is set and avoid the F5 in login trying to fetch the reports
    if (user) {
      SubscribedReportsService.getSubscribedReports()
        .then(resp => {
          setClientSubscribedReports(resp.data)
          extractDistinctReportTypes(resp.data)
        })
        .catch(e => {
          toast.error('Failed to fetch the reports')
          console.log(e)
        })
    }
  }, [user])

  /**
   * Extract the distinct report types of the connected client
   * @param clientSubscribedReports list of report subscribed report type by client
   */
  const extractDistinctReportTypes = (clientSubscribedReports: ClientSubscribedReport[]) => {
    const distinctSubscribedReportsCopy: SubscribedReport[] = JSON.parse(
      JSON.stringify(distinctSubscribedReports)
    )

    // For each row
    clientSubscribedReports.forEach(row => {
      // We take the subscribed report type
      const subscribedReportType: SubscribedReport = {
        frequencyDecList: row.frequencyDecList,
        reportTypeId: row.reportTypeId,
        reportTypeLevel: row.reportTypeLevel,
        reportTypeName: row.reportTypeName,
        reportTypeShortName: row.reportTypeShortName,
      }

      // And if it's not inside the list yet, we add it
      const IsReportTypeAlreadyInList = distinctSubscribedReportsCopy.some(
        reportType => reportType.reportTypeId == subscribedReportType.reportTypeId
      )
      if (!IsReportTypeAlreadyInList) {
        distinctSubscribedReportsCopy.push(subscribedReportType)
      }
    })

    setDistinctSubscribedReports(distinctSubscribedReportsCopy)
  }

  return (
    <SubscribedReportContext.Provider
      value={{ clientSubscribedReports, distinctSubscribedReports }}>
      {children}
    </SubscribedReportContext.Provider>
  )
}

export default SubscribedReportProvider
