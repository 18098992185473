import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import './react-datepicker.css'
import {
  faCheckSquare,
  faEye,
  faEyeSlash,
  faFileDownload,
  faSquareXmark,
} from '@fortawesome/free-solid-svg-icons'
import { GedContext } from './context/GedProvider'
import GedFilterSection from './components/GedFilterSection'
import ButtonWithIcon from '../../components/button/ButtonWithIcon'
import GedDeclineModal from './components/GedDeclineModal'
import ConfirmModal from '../../components/modal/ConfirmModal'
import Button from '../../components/button/Button'
import DownloadFinalDocsModal from './components/DownloadFinalDocsModal'
import DownloadValidationDocsModal from './components/DownloadValidationDocsModal'
import ReportValidationGed from './ReportValidationGed'
import { UserContext } from '../../context/UserProvider'
import { ROLES } from '../../constants/constants'
import { ProductionService } from '../../services/production/ProductionService'
import SortButton from './components/SortButton'

/**
 * This page displays the documents produced by ALpha Omega
 * 2 views are available :
 * - Report Validation Ged : contains all documents in validation mode
 * @constructor
 */
function DocumentValidation() {
  localStorage.setItem('localState', 'document_validation')

  const { user } = useContext(UserContext)

  const { reloadData } = useContext(GedContext)

  // Download Modal
  const [isDownloadFinalModalOpened, setIsDownloadFinalModalOpened] = useState<boolean>(false)
  const [isDownloadValidationModalOpened, setIsDownloadValidationModalOpened] =
    useState<boolean>(false)

  const [isFilterSectionHidden, setIsFilterSectionHidden] = useState<boolean>(false)

  const [approveModalOpenned, setApproveModalOpenned] = useState<boolean>(false)
  const [declineModalOpenned, setDeclineModalOpenned] = useState<boolean>(false)

  const [currentPage, setCurrentPage] = useState<number>(0)

  const {
    isLoading,
    deliverableValidationData,
    selectedRowsValidation,
    setSelectedRowsValidation,
    getValidationDocuments,
    allDeliverableValidationIds,
    selectAllDeliverableValidation,
  } = useContext(GedContext)

  const handleDownloadValidationButtonClick = () => {
    setIsDownloadValidationModalOpened(prevState => !prevState)
  }

  return (
    <Container>
      <GedDeclineModal
        callback={comment => {
          const promise = ProductionService.approveOrDeclineReports(
            selectedRowsValidation,
            false,
            comment
          )

          toast.promise(promise, {
            loading: 'Loading',
            success: () => {
              setSelectedRowsValidation([])
              setDeclineModalOpenned(false)
              getValidationDocuments(currentPage)
              return 'Successfully updated'
            },
            error: () => {
              return 'There was a problem during update'
            },
          })
        }}
        items={selectedRowsValidation}
        isOpened={declineModalOpenned}
        setIsOpened={() => {
          setDeclineModalOpenned(false)
        }}
      />
      <ConfirmModal
        width={350}
        height={150}
        title='Document to be updated'
        text={`${selectedRowsValidation.length} documents go to the next step`}
        isModalOpenned={approveModalOpenned}
        closeModalHandler={() => setApproveModalOpenned(false)}
        actionCallback={() => {
          const promise = ProductionService.approveOrDeclineReports(selectedRowsValidation, true)

          toast.promise(promise, {
            loading: 'Loading',
            success: () => {
              setSelectedRowsValidation([])
              setApproveModalOpenned(false)
              getValidationDocuments(currentPage)
              return 'Successfully updated'
            },
            error: () => {
              return 'There was a problem during update'
            },
          })
        }}
      />
      <DownloadFinalDocsModal
        open={isDownloadFinalModalOpened}
        setOpen={setIsDownloadFinalModalOpened}
      />

      <DownloadValidationDocsModal
        open={isDownloadValidationModalOpened}
        setOpen={setIsDownloadValidationModalOpened}
      />

      <GedFilterSection isSectionHidden={isFilterSectionHidden} setCurrentPage={setCurrentPage} />
      {deliverableValidationData && !isLoading && (
        <BtnContainer>
          <LeftButtons>
            <SortButton reloadData={reloadData} setCurrentPage={setCurrentPage} />
            <ButtonWithIcon
              onClick={() => {
                setIsFilterSectionHidden(prevState => !prevState)
              }}
              icon={isFilterSectionHidden ? faEye : faEyeSlash}
              text={isFilterSectionHidden ? 'Show filters' : 'Hide filters'}
            />
          </LeftButtons>
          <RightButtons>
            <ButtonWithIcon
              text={
                selectedRowsValidation.length === allDeliverableValidationIds.length
                  ? 'Unselect all'
                  : 'Select all'
              }
              icon={
                selectedRowsValidation.length === allDeliverableValidationIds.length
                  ? faSquareXmark
                  : faCheckSquare
              }
              onClick={selectAllDeliverableValidation}
            />
            <ButtonWithIcon
              text={`Download ${
                selectedRowsValidation.length === 0 ? 'All' : selectedRowsValidation.length
              }`}
              icon={faFileDownload}
              onClick={handleDownloadValidationButtonClick}
            />
          </RightButtons>
        </BtnContainer>
      )}
      <ReportValidationGed currentPage={currentPage} setCurrentPage={setCurrentPage} />
      {user?.roles.includes(ROLES.CONTROL) && selectedRowsValidation.length > 0 && (
        <ActionButtonsContainer>
          <Button
            text='Decline'
            secondary
            handleClick={() => {
              if (selectedRowsValidation?.length) {
                setDeclineModalOpenned(true)
              }
            }}
          />
          <div style={{ marginRight: 8 }} />
          <Button
            text='Approve'
            handleClick={() => {
              if (selectedRowsValidation?.length) {
                setApproveModalOpenned(true)
              }
            }}
            // disabled={!selectedNodes?.length}
          />
        </ActionButtonsContainer>
      )}
    </Container>
  )
}

export default DocumentValidation

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 10px;
`
const RightButtons = styled.div`
  display: flex;
  gap: 10px;
`
const LeftButtons = styled.div`
  display: flex;
  gap: 10px;
`

const ActionButtonsContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 14px;
  z-index: 1;
  display: flex;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 70vh;
`
