import React from 'react'
import ConfirmModal from '../../../../components/modal/ConfirmModal'
import { TemplateValidationScopeType } from '../../TemplateValidation'

type Props = {
  isModalOpenned: boolean
  setModalOpenned: React.Dispatch<React.SetStateAction<boolean>>
  selectedRows: TemplateValidationScopeType[]
  setTemplateValidationData: React.Dispatch<React.SetStateAction<TemplateValidationScopeType[]>>
}

function TemplateValidationDeclineModal({
  isModalOpenned,
  setModalOpenned,
  selectedRows,
  setTemplateValidationData,
}: Props) {
  const [value, setValue] = React.useState('')

  return (
    <ConfirmModal
      title='Decline templates'
      actionCallback={() => {
        setModalOpenned(false)
        setTemplateValidationData(prevState => {
          return prevState.filter(scope => {
            // Remove selected rows from scopeListData
            return !selectedRows.some(selectedRow => {
              return selectedRow.id === scope.id
            })
          })
        })
      }}
      isModalOpenned={isModalOpenned}
      closeModalHandler={setModalOpenned}
      height={350}
      width={500}>
      <div>
        {selectedRows.length === 1
          ? `1 template will be declined. Please provide a description of the required changes:`
          : `${selectedRows.length} template will be declined. Please provide a description of the required changes: `}
      </div>
      <textarea
        style={{ flex: 1, marginBottom: 4, resize: 'none' }}
        value={value}
        onChange={e => setValue(e.target.value)}
        maxLength={500}
      />
    </ConfirmModal>
  )
}

export default TemplateValidationDeclineModal
