import { AxiosResponse } from 'axios/index'
import http from '../http'
import { ProductionDocumentsPaginatedDto } from '../../types/dtos/Ged/ProductionDocumentsPaginatedDto'

const baseUrl = '/production'

const ProductionDocumentService = {
  getAllDocumentsWithAllLanguagesAndCountries: (
    page: number,
    isFinalDocument: boolean,
    sortAsc: boolean,
    fundIds?: number[] | null,
    subfundIds?: number[] | null,
    shareclassIds?: number[] | undefined,
    reportTypeIds?: number[] | null,
    reportDay?: number | undefined,
    reportMonth?: number | undefined,
    reportYear?: number | undefined,
    countryId?: number[] | null,
    languageId?: number[] | null,
    dividendPolicy?: string[],
    shareclassInvestorType?: string[],
    hedged?: boolean,
    currencyId?: number[],
    shareclassName?: string[],
    sortList?: string[]
  ) => {
    return http.post<ProductionDocumentsPaginatedDto>(
      `${baseUrl}/document/all-with-all-languages-countries`,
      {
        pageNumber: page,
        isFinalDocument,
        fundIds,
        subfundIds,
        shareclassIds,
        reportTypeIds,
        reportDay,
        reportMonth,
        reportYear,
        countryIds: countryId,
        languageIds: languageId,
        dividendPolicys: dividendPolicy,
        shareclassInvestorTypes: shareclassInvestorType,
        hedged,
        currencyIds: currencyId,
        shareclassNames: shareclassName,
        sortList,
        sortAsc,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )
  },

  downloadByIds: (
    documentIds: number[],
    splitZipStructure?: boolean,
    maxZipFilesSize?: number,
    aggregateExcelReports?: boolean
  ) => {
    return http.post(
      `${baseUrl}/document/download-by-id-list`,
      {
        documentIds,
        splitZipStructure,
        maxZipFilesSize,
        aggregateExcelReports,
      },
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  },

  getFileNameFromHeader: (resp: AxiosResponse) => {
    if (resp.headers['content-disposition']) {
      return resp.headers['content-disposition'].split('filename=')[1].replaceAll('"', '')
    }
    throw new Error('File name is missing')
  },
  sendDocumentsByEmail: (documentIds: number[]) => {
    return http.post(`${baseUrl}/document/send-documents-by-email`, documentIds, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}

export default ProductionDocumentService
