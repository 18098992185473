import React from 'react'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MRT_Row } from 'material-react-table'
import handleDocumentActions from '../utils/handleDocumentAction'
import { ProductionValidationDto } from '../../../../types/dtos/production/productionDto'

export default function HandleSendByEmailCell(
  options: { row: MRT_Row<ProductionValidationDto> },
  mainColor: string
) {
  const {
    row: { original },
  } = options
  const returnList = original.documentId
  return (
    <FontAwesomeIcon
      cursor='pointer'
      icon={faEnvelope}
      color={mainColor}
      onClick={() => {
        handleDocumentActions(returnList, 'SEND_BY_EMAIL')
      }}
    />
  )
}
