import { MRT_Column, MRT_Row } from 'material-react-table'
import React from 'react'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Scopes } from '../DisseminationList.types'
import { SubscribedReport } from '../../../../types/dtos/SubscribedReportDto'
import { getEmails } from '../utilities/utilities'

export default function HandleReportCell(
  distinctReport: SubscribedReport,
  options: { row: MRT_Row<Scopes>; column: MRT_Column<Scopes> },
  distinctSubscribedReports: SubscribedReport[],
  mainColor: string,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setEmailListLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setEmailList: any
) {
  const {
    row: { original },
    column,
  } = options
  const reportTypeId = distinctSubscribedReports.find(
    elem => elem.reportTypeShortName === column.id
  )?.reportTypeId
  if (original[distinctReport.reportTypeName as keyof typeof original]) {
    return (
      <FontAwesomeIcon
        icon={faInfo}
        color={mainColor}
        onClick={() => {
          getEmails(
            reportTypeId as number,
            original.fundId,
            original.subfundId,
            original.shareclassId,
            setIsModalOpen,
            setEmailListLoading,
            setEmailList
          )
        }}
        style={{ cursor: 'pointer' }}
      />
    )
  }
  return null
}
