import styled from 'styled-components'
import { COLOR } from '../../../../constants/theme'

export const NarrativeBlockContainer = styled.div`
  position: relative;
  border-radius: 5px;
  padding: 15px;
  background-color: white;
  width: 43%;
  word-wrap: break-word;
`
export const versionNumber = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 4px;
  font-size: 0.8rem;
  background-color: ${COLOR.SEMI_LIGHT_GRAY};
  border-radius: 5px;
  color: white;
  max-width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
