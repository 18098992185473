import React, { useCallback, useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import styled from 'styled-components'
import { useCurrentPng } from 'recharts-to-png'
import FileSaver from 'file-saver'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../../../../components/loader/Loader'
import Title from '../../../../../components/text/Title'
import { getWeekdaysInMonth } from '../../../../../helpers/helpers'
import http from '../../../../../services/http'
import Icon from '../../../../../components/icon/Icon'

const c1 = 'Total delivered on time'
const c2 = 'Total overdue'

type Props = {
  timelinessRespectDeadline: any
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

function TimelinessRespectDeadline({ timelinessRespectDeadline }: Props) {
  const [isLoading, setIsLoading] = useState(true)

  const [getPng, { ref }] = useCurrentPng()

  const handleDownload = useCallback(async () => {
    const png = await getPng()
    if (png) {
      FileSaver.saveAs(png, 'TimelinessRespectDeadline.png')
    }
  }, [getPng])

  useEffect(() => {
    if (timelinessRespectDeadline) {
      setIsLoading(false)
    }
  }, [timelinessRespectDeadline])

  if (isLoading)
    return (
      <div style={{ position: 'relative', height: '100%' }}>
        <Loader />
      </div>
    )

  return (
    <Container>
      <TitleContainer>
        <Icon icon={faDownload} onClick={() => handleDownload()} pointer notCentered />
        <Title center>Timeliness and respect of deadline</Title>
      </TitleContainer>
      <ResponsiveContainer width='100%' height='80%'>
        <BarChart
          ref={ref}
          width={500}
          height={300}
          data={timelinessRespectDeadline}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine y={0} stroke='#000' />
          <Bar dataKey={c1} fill='#8884d8' />
          <Bar dataKey={c2} fill='#82ca9d' />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default TimelinessRespectDeadline
