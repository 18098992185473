import Select, { StylesConfig } from 'react-select'
import { OptionType } from '../../types/OptionType'

type Props = {
  options: OptionType[]
  onChangeCallback?: (option: OptionType | null) => void
  isDisabled?: boolean
  placeholder: string
  defaultValue?: OptionType
  value: OptionType | null
  isMulti?: boolean
  removeClear?: boolean
  onMenuCloseCallback?: () => void
  menuPortalTarget?: HTMLElement | null | undefined
  // https://react-select.com/styles#styles
  // This component used the style api from react select
  // We can define custom style by setting a StylesConfig object
  // It contains objects named with different parts of the select input
  // Example :
  // control: (provided, state) => {
  //     return {
  //         ...provided,
  //         ...controlCustomStyle // if there is custom style, we add it
  //     };
  // },
  // // Container style
  // container: (provided, state) => {
  //     return {
  //         ...provided,
  //         ...containerCustomStyle // if there is custom style, we add it
  //     };
  // },
  customStyle?: StylesConfig<OptionType, false>
}

/**
 * This select input is controlled, it means that we can control its value
 * @param options Options that sould be displayed in the select input
 * @param onChangeCallback Callback function that is called on change
 * @param isDisabled boolean to disable or enable the input
 * @param placeholder placeholder to display
 * @param defaultValue default value (preselected value)
 * @param value current value of the input
 * @constructor
 */
export function SelectInputSearchControlled({
  options,
  onChangeCallback,
  isDisabled,
  placeholder,
  value,
  customStyle,
  removeClear,
  onMenuCloseCallback,
  menuPortalTarget,
}: Props) {
  return (
    <Select
      value={value || null}
      onChange={(option: OptionType | null) => {
        if (onChangeCallback) {
          onChangeCallback(option)
        }
      }}
      closeMenuOnSelect
      placeholder={placeholder}
      isDisabled={isDisabled}
      onMenuClose={onMenuCloseCallback}
      isSearchable
      isClearable={!removeClear}
      options={options}
      menuPortalTarget={menuPortalTarget}
      // set the z-index of the menu to 9999 to always display it in front
      styles={{
        menu: provided => ({ ...provided, zIndex: 9999, width: 'max-content' }),
        container: provided => ({ ...provided, width: 'max-content' }),
        menuPortal: provided => ({
          ...provided,
          zIndex: 9999,
          fontSize: '0.8rem',
        }),
        ...customStyle,
      }}
    />
  )
}
