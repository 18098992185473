import FileSaver from 'file-saver'

const DownloadService = {
  /**
   * Download one or many from documents infos in DocumentDownloadDtoFormat
   * @param bytes
   * @param filename
   */
  downloadDoc(bytes: string, filename: string) {
    const blob = new Blob([bytes])
    try {
      FileSaver.saveAs(blob, filename)
    } catch (e) {
      console.log(e)
    }
  },
}

export default DownloadService
