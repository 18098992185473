// import { t } from 'i18next'
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import Separator from '../../components/separator/Separator';
import http from '../../services/http';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation(['Login']);

  const handleClick = () => {
    http
      .post('/forgot-password', {
        email,
      })
      .then(r => {
        console.log(r);
      })
      .finally(() => {
        navigate('/login');
        toast('If a user exists with this email he will be able to reset his password');
      });
  };

  return (
    <Container>
      <HeaderPart>
        <a href='/login' style={{ marginRight: '30px' }}>
          <Button bgColor='#1e10bf' text='Login' />
        </a>
      </HeaderPart>
      <BodyPart>
        <Card>
          <div>Forgot password ?</div>
          <Separator />
          <Input
            type='text'
            name='email'
            placeholder='Email'
            data={email}
            setter={(e: any) => setEmail(e.target.value)}
            onEnterCallback={handleClick}
          />
          <Separator />
          <Button text={t('Continue')} handleClick={handleClick} bgColor='#1e10bf' />
        </Card>
      </BodyPart>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;
const HeaderPart = styled.div`
  width: 100%;
  z-index: 5;
  background: white;
  height: 80px;
  border-bottom: 1px solid rgb(183, 183, 183);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;
const BodyPart = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;
const Card = styled.div`
  text-align: left;
`;

export default ForgotPassword;
