import ReactCountryFlag from 'react-country-flag'
import { Tooltip } from '@mui/material'
import styled from 'styled-components'

type Props = {
  countryIsoCode2: string
  countryName: string
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
`

function CountryWithFlagAndTooltip({ countryIsoCode2, countryName }: Props) {
  return (
    <Tooltip title={countryName} arrow>
      <Container>
        <ReactCountryFlag countryCode={countryIsoCode2} svg />
        <span>{countryIsoCode2}</span>
      </Container>
    </Tooltip>
  )
}

export default CountryWithFlagAndTooltip
