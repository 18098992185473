import { faBell, faChevronRight, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Client } from '@stomp/stompjs'
import React, { useContext } from 'react'
import toast from 'react-hot-toast'
import M from 'react-modal'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ROLES } from '../../constants/constants'
import { ROUTES } from '../../constants/routes'
import { COLOR } from '../../constants/theme'
import { NotificationContext } from '../../context/NotificationProvider'
import { ThemeContext } from '../../context/ThemeProvider'
import { UserContext } from '../../context/UserProvider'
import NotYet from '../notYet/NotYet'

function Notifications() {
  const [isNotificationModalOpenned, setIsNotificationModalOpenned] = React.useState(false)
  const {
    notifications: changeScopeNotifications,
    gatherNotifications: gatherChangeScopeNotifications,
  } = React.useContext(NotificationContext)

  // THEME
  const { mainColor } = useContext(ThemeContext)

  const [reportToValidateNotifications] = React.useState<any>([])

  const [loading, setLoading] = React.useState(false)

  const navigate = useNavigate()

  const { user, hasRole } = React.useContext(UserContext)

  React.useEffect(() => {
    if (!user || hasRole(ROLES.MANAGE) === false) return

    const {
      REACT_APP_API_WEBSOCKET_PROTOCOL,
      REACT_APP_API_WEBSOCKET_ENDPOINT,
      REACT_APP_API_ENDPOINT,
    } = process.env

    const client = new Client()

    client.configure({
      brokerURL: `${REACT_APP_API_WEBSOCKET_PROTOCOL}://${REACT_APP_API_ENDPOINT}/${REACT_APP_API_WEBSOCKET_ENDPOINT}`,
      onConnect: () => {
        client.subscribe(`/topic/notifications/${user?.clientId}`, message => {
          toast(
            <div style={{ cursor: 'pointer' }} onClick={() => navigate(ROUTES.SCOPE_VALIDATION)}>
              New change scope request
            </div>
          )
          gatherChangeScopeNotifications(setLoading)
        })
      },
    })

    client.activate()

    return () => {
      client.deactivate()
    }
  }, [user])

  React.useEffect(() => {
    if (!user || hasRole(ROLES.MANAGE) === false) return

    gatherChangeScopeNotifications(setLoading)
  }, [user])

  const handleClick = () => {
    setIsNotificationModalOpenned(true)
  }
  return (
    <>
      {!user?.hasParent && (
        <ShadowCard onClick={handleClick}>
          <BellIcon icon={faBell} color={mainColor} />
          <Count>{changeScopeNotifications.length + reportToValidateNotifications.length}</Count>
        </ShadowCard>
      )}
      <M
        isOpen={isNotificationModalOpenned}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={() => {
          setIsNotificationModalOpenned(false)
        }}
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: 'none',
          },
          content: {
            width: 300,
            top: 14,
            bottom: 'auto',
            right: 92,
            left: 'auto',
            borderRadius: 10,
            borderColor: COLOR.WHITE,
            padding: 7,
            boxShadow: 'rgba(0, 0, 0, 0.1) 0 0 10px 0',
          },
        }}>
        {changeScopeNotifications.length > 0 ? (
          <Row
            onClick={() => {
              navigate(ROUTES.SCOPE_VALIDATION)
              setIsNotificationModalOpenned(false)
            }}
            bgColor={user?.clientMainRgbColor}>
            <div>
              <div>
                {changeScopeNotifications?.reduce(
                  (acc: any, e: any) => acc + e.changeScopeList?.length,
                  0
                )}{' '}
                scope changes waiting
              </div>
              <div>
                <FontAwesomeIcon icon={faChevronRight} color={COLOR.MAIN} />
              </div>
            </div>
          </Row>
        ) : (
          <NotYet notAbsolute text='No changes to validate' icon={faClipboardCheck} />
        )}
      </M>
    </>
  )
}

const ShadowCard = styled.div`
  display: flex;
  position: relative;
  border-radius: 10px;
  padding: 10px;
  background-color: ${COLOR.WHITE};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`

const BellIcon = styled(FontAwesomeIcon)`
  width: 32px;
  height: 32px;
`

const Row = styled.div<any>`
  border: 2px solid ${props => props.bgColor};
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  > div {
    display: flex;
    justify-content: space-between;
  }
`

const Count = styled.div`
  position: absolute;
  bottom: 13px;
  right: 10px;
  background: ${COLOR.BLACK};
  border-radius: 100%;
  height: 15px;
  width: 15px;
  color: ${COLOR.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  user-select: none;
`

export default Notifications
