import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'
import { Toaster, toast } from 'react-hot-toast'
import { StylesConfig } from 'react-select'
import Modal from '../../../components/modal/Modal'
import { COLOR } from '../../../constants/theme'
import { groupByObjBisBis } from '../../../helpers/helpers'
import http from '../../../services/http'
import Loader from '../../../components/loader/Loader'
import { SubscribedReportContext } from '../../../context/SubscribedReportProvider'
import { ThemeContext } from '../../../context/ThemeProvider'
import MaterialTable from '../../../components/table/MaterialTable/MaterialTable'
import { OptionType } from '../../../types/OptionType'
import { SelectInputSearchControlled } from '../../../components/input/SelectInputSearchControlled'
import {
  HandleDefaultAggregatedCell,
  HandleDefaultCodeIsinCell,
  HandleMuiTableBodyCellPropsLevels,
  RenderDefaultTopToolBar,
} from '../../../components/table/MaterialTable/ColumnUtilities'
import { Scopes } from './DisseminationList.types'
import HandleReportCell from './components/HandleReportCell'
import HandleReportAggregatedCell from './components/HandleReportAggregatedCell'

function DisseminationList() {
  localStorage.setItem('localState', 'dissemination_list')
  // Manage context
  const { mainColor, mainColorLight, mainColorVeryLight } = useContext(ThemeContext)
  const { distinctSubscribedReports } = useContext(SubscribedReportContext)
  // Manage data
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [rawData, setRawData] = useState<any>([])
  const [rawsubfundData, setRawsubfundData] = useState<any>([])
  const [reportLevel, setReportLevel] = useState<'Fund' | 'Subfund' | 'CodeIsin'>('CodeIsin')
  // Manage filters
  const [filterFund, setFilterFund] = useState<OptionType | null>(null)
  const [filterSubfund, setFilterSubfund] = useState<OptionType | null>(null)
  const [filterCodeIsin, setFilterCodeIsin] = useState<OptionType | null>(null)
  //  Manage all filters options
  const [allFund, setAllFund] = useState<OptionType[]>([])
  const [allSubfund, setAllSubfund] = useState<OptionType[]>([])
  const [allCodeIsin, setAllCodeIsin] = useState<OptionType[]>([])

  //  Define initial config for the table
  const [initialState] = useState({
    grouping: ['fundName', 'subfundName'],
    columnVisibility: {
      fundName: false,
      subfundName: false,
      codeIsin: true,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
    ],
    density: 'compact',
    columnPinning: { left: ['mrt-row-expand', 'codeIsin'] },
    columnOrder: ['mrt-row-expand', 'fundName', 'subfundName', 'codeIsin'],
  })
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const [emailListLoading, setEmailListLoading] = React.useState<boolean>(false)
  const [emailList, setEmailList] = React.useState<any>([])

  // const getEmails = (reportId: number, fundId: number, subfundId: number, shareclassId: number) => {
  //   setIsModalOpen(true);
  //   setEmailListLoading(true);
  //   let request = `?reportTypeId=${reportId}&fundId=${fundId}&subfundId=${subfundId}`;
  //   if (shareclassId) {
  //     request += `&shareclassId=${shareclassId}`;
  //   }
  //   http
  //     .get(`/disseminations/emails${request}`)
  //     .then(({ data }) => {
  //       setEmailList(data);
  //     })
  //     .finally(() => {
  //       setEmailListLoading(false);
  //     });
  // };

  // @ts-ignore
  const columns = useMemo<MRT_ColumnDef<Scopes>[]>(() => {
    return [
      {
        header: 'Fund',
        accessorKey: 'fundName',
        filterFn: 'equalsString',
      },
      {
        header: 'SubFund',
        accessorKey: 'subfundName',
        filterFn: 'equalsString',
      },
      {
        header: '',
        accessorKey: 'codeIsin',
        columnDefType: 'display',
        filterFn: 'equalsString',
        AggregatedCell: (options: any) => HandleDefaultAggregatedCell(options),
        Cell: options => HandleDefaultCodeIsinCell(options),
        muiTableBodyCellProps: (options: any) =>
          HandleMuiTableBodyCellPropsLevels(options, mainColorLight, mainColorVeryLight),
      },
      ...distinctSubscribedReports.map(distinctReport => {
        return {
          header: distinctReport.reportTypeShortName,
          columnDefType: 'display',
          Cell: (options: any) =>
            HandleReportCell(
              distinctReport,
              options,
              distinctSubscribedReports,
              mainColor,
              setIsModalOpen,
              setEmailListLoading,
              setEmailList
            ),
          AggregatedCell: (options: any) =>
            HandleReportAggregatedCell(
              rawsubfundData,
              options,
              distinctSubscribedReports,
              mainColor,
              setIsModalOpen,
              setEmailListLoading,
              setEmailList
            ),
        }
      }),
    ]
  }, [rawData, rawsubfundData])

  useEffect(() => {
    setIsLoading(true)
    http
      .get('/scopes')
      .then(resp => {
        setRawsubfundData(resp.data.filter((elem: Scopes) => !elem.shareclassId))
        const rawShareclassData = resp.data.filter((elem: Scopes) => elem.shareclassId)
        const rawShareclassUniqueData = resp.data
          .filter((elem: Scopes) => elem.shareclassId)
          .filter(
            (elem: Scopes, index: number, array: Scopes[]) =>
              array.findIndex(
                (sb: Scopes) =>
                  sb.fundId === elem.fundId &&
                  sb.subfundId === elem.subfundId &&
                  sb.shareclassId === elem.shareclassId
              ) === index
          )
          .map((elem: Scopes) => ({
            codeIsin: elem.codeIsin,
            fundId: elem.fundId,
            fundName: elem.fundName,
            shareclassId: elem.shareclassId,
            shareclassName: elem.shareclassName,
            subfundId: elem.subfundId,
            subfundName: elem.subfundName,
          }))

        rawShareclassData.forEach((elem: Scopes) => {
          rawShareclassUniqueData.forEach((sbElem: any) => {
            if (
              sbElem.fundId === elem.fundId &&
              sbElem.subfundId === elem.subfundId &&
              sbElem.shareclassId === elem.shareclassId
            ) {
              sbElem[elem.reportTypeName] = true
            }
          })
        })
        setRawData(rawShareclassUniqueData)
      })
      .catch(e => {
        console.log(e)
        toast.error('Failed to load data')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [distinctSubscribedReports])

  // Manage the data that will be displayed according to previous filters
  useEffect(() => {
    //for fund
    const rawFund = rawData
      .map((elem: Scopes) => elem.fundName)
      .filter((elem: Scopes, index: number, array: Scopes[]) => array.indexOf(elem) === index)
      .sort()
      .map((elem: string) => ({ label: elem, value: elem }))
    setAllFund(rawFund)
  }, [rawData, isLoading])
  useEffect(() => {
    //for subfund
    const rawsubFund = rawData
      .filter((elem: Scopes) => (filterFund?.label ? elem.fundName === filterFund.label : true))
      .map((elem: Scopes) => elem.subfundName)
      .filter((elem: Scopes, index: number, array: Scopes[]) => array.indexOf(elem) === index)
      .sort()
      .map((elem: string) => ({ label: elem, value: elem }))
    setAllSubfund(rawsubFund)
  }, [rawData, isLoading, filterFund])
  useEffect(() => {
    //for codeIsin
    const rawCodeIsin = rawData
      .filter((elem: Scopes) => (filterFund?.value ? elem.fundName === filterFund.label : true))
      .filter((elem: Scopes) =>
        filterSubfund?.value ? elem.subfundName === filterSubfund.label : true
      )
      .map((elem: Scopes) => elem.codeIsin)
      .filter((elem: Scopes, index: number, array: Scopes[]) => array.indexOf(elem) === index)
      .sort()
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllCodeIsin(rawCodeIsin)
  }, [rawData, isLoading, filterFund, filterSubfund])

  //Definitions for the filter bar
  const renderTopToolbar = (props: { table: MRT_TableInstance }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <RenderDefaultTopToolBar
          allFund={allFund}
          allSubfund={allSubfund}
          allCodeIsin={allCodeIsin}
          filterFund={filterFund}
          filterSubfund={filterSubfund}
          filterCodeIsin={filterCodeIsin}
          setFilterFund={setFilterFund}
          setFilterSubfund={setFilterSubfund}
          setFilterCodeIsin={setFilterCodeIsin}
          isLoading={isLoading}
          reportLevel={reportLevel}
          table={props.table}
        />
      </div>
    )
  }

  return (
    <>
      <Toaster />
      <Modal
        isModalOpenned={isModalOpen}
        setIsModalOpenned={() => {
          setIsModalOpen(false)
        }}>
        {emailListLoading ? (
          <Loader />
        ) : (
          <div>
            {emailList.length === 0 ? (
              <div>No dissemination for the selected item</div>
            ) : (
              Object.entries(groupByObjBisBis(emailList, ['emailType'])).map(
                ([emailType, emails]: any) => {
                  return (
                    <>
                      <div style={{ textTransform: 'capitalize' }}>{emailType}</div>
                      {emails.map(({ emailAddress }: { emailAddress: string }) => {
                        return <div key={emailAddress}>{emailAddress}</div>
                      })}
                      <br />
                    </>
                  )
                }
              )
            )}
          </div>
        )}
      </Modal>

      <MaterialTable
        data={rawData}
        columns={columns}
        enableSelectAll={false}
        initialState={initialState}
        isLoading={isLoading}
        maxHeight={80}
        renderTopToolbar={renderTopToolbar}
      />
    </>
  )
}

export default DisseminationList
