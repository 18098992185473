import React, { useContext } from 'react';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { ThemeContext } from '../../context/ThemeProvider';

type Props = {
  callback?: () => void;
  size?: SizeProp;
};

function DownloadIcon({ callback, size }: Props) {
  const { mainColor } = useContext(ThemeContext);
  return (
    <FontAwesomeIcon
      style={{ cursor: 'pointer' }}
      icon={faFileDownload}
      color={mainColor}
      onClick={callback}
      size={size}
    />
  );
}

export default DownloadIcon;
