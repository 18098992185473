import { faDownload } from '@fortawesome/free-solid-svg-icons'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import FileSaver from 'file-saver'
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'
import { toast, Toaster } from 'react-hot-toast'
import { StylesConfig } from 'react-select'
import http from '../../../services/http'
import { ThemeContext } from '../../../context/ThemeProvider'
import MaterialTable from '../../../components/table/MaterialTable/MaterialTable'
import { SelectInputSearchControlled } from '../../../components/input/SelectInputSearchControlled'
import ButtonWithIcon from '../../../components/button/ButtonWithIcon'
import { OptionType } from '../../../types/OptionType'
import {
  HandleDefaultAggregatedCell,
  HandleDefaultCodeIsinCell,
  HandleMuiTableBodyCellPropsLevels,
  RenderDefaultTopToolBar,
  filtersStyle,
} from '../../../components/table/MaterialTable/ColumnUtilities'
import { PlatformType } from './Platform.types'
import HandleDataCell from './components/HandleDataCell'
import HandledataAggregatedCell from './components/HandledataAggregatedCell'
import { countHeaderItems } from './utilities/utilities'

function Platform() {
  localStorage.setItem('localState', 'platform')
  // Manage context
  const { mainColorLight, mainColorVeryLight } = useContext(ThemeContext)
  // Manage data
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [rawData, setRawData] = React.useState<PlatformType[]>([])
  const [reportLevel, setReportLevel] = useState<'Fund' | 'Subfund' | 'CodeIsin'>('CodeIsin')
  // Manage filters
  const [filterPlatform, setFilterPlatform] = useState<OptionType | null>(null)
  const [filterFund, setFilterFund] = useState<OptionType | null>(null)
  const [filterSubfund, setFilterSubfund] = useState<OptionType | null>(null)
  const [filterCodeIsin, setFilterCodeIsin] = useState<OptionType | null>(null)
  // Manage all filters options
  const [allPlatform, setAllPlatform] = useState<OptionType[]>([])
  const [allFund, setAllFund] = useState<OptionType[]>([])
  const [allSubfund, setAllSubfund] = useState<OptionType[]>([])
  const [allCodeIsin, setAllCodeIsin] = useState<OptionType[]>([])
  // Define initial config for the table
  const [initialState] = useState({
    grouping: ['fundName', 'subfundName'],
    columnVisibility: {
      fundName: false,
      subfundName: false,
      platformName: false,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
    ],
    density: 'compact',
  })

  const downloadExport = () => {
    http.get(`/platforms/export`, { responseType: 'arraybuffer' }).then((r: any): any => {
      const blob = new Blob([r.data], {
        type: 'text/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
      })
      FileSaver.saveAs(blob, 'AO_PLATFORM_OVERVIEW.xlsx')
    })
  }

  const columns = useMemo<MRT_ColumnDef<PlatformType>[]>(
    () => [
      {
        header: 'Fund',
        accessorKey: 'fundName',
        filterFn: 'equalsString',
        enableGlobalFilter: true,
      },
      {
        header: 'Subfund',
        accessorKey: 'subfundName',
        filterFn: 'equalsString',
        enableGlobalFilter: true,
      },
      {
        header: '',
        accessorKey: 'codeIsin',
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        filterFn: 'equalsString',
        AggregatedCell: (props: any) => HandleDefaultAggregatedCell(props),
        Cell: (props: any) => HandleDefaultCodeIsinCell(props),
        muiTableBodyCellProps: (props: any) =>
          HandleMuiTableBodyCellPropsLevels(props, mainColorLight, mainColorVeryLight),
      },
      {
        header: 'Platform',
        accessorKey: 'platformName',
        filterFn: 'equalsString',
      },
      {
        header: `Static data (${countHeaderItems(
          rawData,
          'staticData',
          filterPlatform,
          filterFund,
          filterSubfund,
          filterCodeIsin
        )})`,
        accessorKey: 'staticData',
        enableGrouping: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: options => HandleDataCell(options),
        AggregatedCell: options => HandledataAggregatedCell(options, 'staticData'),
      },
      {
        header: `Dynamic data (${countHeaderItems(
          rawData,
          'dynamicData',
          filterPlatform,
          filterFund,
          filterSubfund,
          filterCodeIsin
        )})`,
        accessorKey: 'dynamicData',
        enableGrouping: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: options => HandleDataCell(options),
        AggregatedCell: options => HandledataAggregatedCell(options, 'dynamicData'),
      },
      {
        header: `Document data (${countHeaderItems(
          rawData,
          'documentData',
          filterPlatform,
          filterFund,
          filterSubfund,
          filterCodeIsin
        )})`,
        accessorKey: 'documentData',
        enableGrouping: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: options => HandleDataCell(options),
        AggregatedCell: options => HandledataAggregatedCell(options, 'documentData'),
      },
    ],
    [rawData, filterPlatform, filterFund, filterSubfund, filterCodeIsin]
  )

  //Definitions for the filter bar
  const renderTopToolbar = (props: { table: MRT_TableInstance }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <RenderDefaultTopToolBar
          allFund={allFund}
          allSubfund={allSubfund}
          allCodeIsin={allCodeIsin}
          filterFund={filterFund}
          filterSubfund={filterSubfund}
          filterCodeIsin={filterCodeIsin}
          setFilterFund={setFilterFund}
          setFilterSubfund={setFilterSubfund}
          setFilterCodeIsin={setFilterCodeIsin}
          isLoading={isLoading}
          reportLevel={reportLevel}
          table={props.table}
          preComponent={
            <SelectInputSearchControlled
              isDisabled={isLoading}
              customStyle={filtersStyle}
              value={filterPlatform}
              options={allPlatform}
              placeholder='Select a platform'
              onChangeCallback={option => {
                console.log(props.table)
                setFilterPlatform(option)
                setFilterFund(null)
                setFilterSubfund(null)
                setFilterCodeIsin(null)
                props.table.setColumnFilters(() => {
                  if (option) {
                    return [{ id: 'platformName', value: option?.value }]
                  }
                  return []
                })
              }}
            />
          }
        />
        <ButtonWithIcon
          icon={faDownload}
          customButtonStyle={{ margin: '5px' }}
          onClick={() => {
            downloadExport()
          }}
        />
      </div>
    )
  }

  // Manage the data that will be displayed according to previous filters
  useEffect(() => {
    //for fund
    const rawFund = rawData
      .filter((elem: PlatformType) =>
        filterPlatform?.label ? elem.platformName === filterPlatform.label : true
      )
      .map((elem: PlatformType) => elem.fundName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: string) => ({ label: elem, value: elem }))
    setAllFund(rawFund)
  }, [rawData, isLoading, filterPlatform])
  useEffect(() => {
    //for subfund
    const rawsubFund = rawData
      .filter((elem: PlatformType) =>
        filterPlatform?.label ? elem.platformName === filterPlatform.label : true
      )
      .filter((elem: PlatformType) =>
        filterFund?.label ? elem.fundName === filterFund.label : true
      )
      .map((elem: PlatformType) => elem.subfundName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: string) => ({ label: elem, value: elem }))
    setAllSubfund(rawsubFund)
  }, [rawData, isLoading, filterPlatform, filterFund])
  useEffect(() => {
    //for codeIsin
    const rawCodeIsin = rawData
      .filter((elem: PlatformType) =>
        filterPlatform?.label ? elem.platformName === filterPlatform.label : true
      )
      .filter((elem: PlatformType) =>
        filterFund?.value ? elem.fundName === filterFund.label : true
      )
      .filter((elem: PlatformType) =>
        filterSubfund?.value ? elem.subfundName === filterSubfund.label : true
      )
      .map((elem: PlatformType) => elem.codeIsin)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllCodeIsin(rawCodeIsin)
  }, [rawData, isLoading, filterPlatform, filterFund, filterSubfund])

  // Manage fetch of data
  useEffect(() => {
    http
      .get('/shareclasses/disseminated')
      .then(resp => {
        setRawData(resp.data)
        const rawPlatform = resp.data
          .map((elem: PlatformType) => elem.platformName)
          .filter((elem: string | null) => elem)
          .filter(
            (elem: PlatformType, index: number, array: PlatformType[]) =>
              array.indexOf(elem) === index
          )
          .map((elem: string) => ({ label: elem, value: elem }))
        setAllPlatform(rawPlatform)
      })
      .catch(error => {
        console.log(error)
        toast.error('There was an error during data fetching')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      <Toaster />
      <MaterialTable
        data={rawData}
        columns={columns}
        initialState={initialState}
        isLoading={isLoading}
        maxHeight={80}
        enableSelectAll={false}
        renderTopToolbar={renderTopToolbar}
      />
    </>
  )
}

export default Platform
