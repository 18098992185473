import React, { createContext } from 'react';

export const RssContext = createContext<any>(null);

type Props = {
  children: React.ReactNode;
};

function RssProvider({ children }: Props) {
  const [rssFlux, setRssFlux] = React.useState<any>([]);

  return <RssContext.Provider value={{ rssFlux, setRssFlux }}>{children}</RssContext.Provider>;
}

export default RssProvider;
