import translation from './fr/translation.json';
import Login from '../pages/login/i18n/login-fr.json';
import Error from './fr/errors.json';

const translationFR = {
  translation,
  Login,
  Error,
};

export default translationFR;
