import { faDownload } from '@fortawesome/free-solid-svg-icons'
import FileSaver from 'file-saver'
import React, { useCallback } from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useCurrentPng } from 'recharts-to-png'
import styled from 'styled-components'
import Icon from '../../../../../components/icon/Icon'
import Title from '../../../../../components/text/Title'

function AverageTicketResponseTimeOnPeriod() {
  const [getPng, { ref }] = useCurrentPng()

  const handleDownload = useCallback(async () => {
    const png = await getPng()
    if (png) {
      FileSaver.saveAs(png, 'AverageTicketResponseTimeOnPeriod.png')
    }
  }, [getPng])

  const data = [
    {
      id: 1,
      libelle: 'January',
      Hours: 11,
    },
    {
      id: 2,
      libelle: 'February',
      Hours: 4,
    },
    {
      id: 3,
      libelle: 'March',
      Hours: 7,
    },
    {
      id: 4,
      libelle: 'April',
      Hours: 8,
    },
    {
      id: 5,
      libelle: 'May',
      Hours: 4,
    },
    {
      id: 6,
      libelle: 'June',
      Hours: 2,
    },
    {
      id: 7,
      libelle: 'July',
      Hours: 10,
    },
    {
      id: 8,
      libelle: 'August',
      Hours: 7,
    },
    {
      id: 9,
      libelle: 'September',
      Hours: 6,
    },
    {
      id: 10,
      libelle: 'October',
      Hours: 9,
    },
    {
      id: 11,
      libelle: 'November',
      Hours: 3,
    },
    {
      id: 12,
      libelle: 'December',
      Hours: 2,
    },
  ].splice(0, new Date().getMonth() + 1)

  return (
    <Container>
      <TitleContainer>
        <Icon icon={faDownload} onClick={() => handleDownload()} pointer notCentered />
        <Title center>Average response time on the period</Title>
      </TitleContainer>
      <ResponsiveContainer width='100%' height='80%'>
        <AreaChart
          ref={ref}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='libelle' />
          <YAxis domain={[0, 14]} />
          <Tooltip />
          <Area type='monotone' dataKey='Hours' stroke='#8884d8' fill='#8884d8' />
          <ReferenceLine y={12} label='Internal standard' stroke='red' strokeDasharray='3 3' />
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default AverageTicketResponseTimeOnPeriod

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
