import React, { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
  center?: boolean;
};

function Title({ children, center }: Props) {
  return <Container center={center}>{children}</Container>;
}

export default Title;

const Container = styled.div<{ center?: boolean }>`
  font-size: 24px;
  text-align: ${({ center }) => (center ? 'center' : 'unset')};
`;
