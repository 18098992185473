import React from 'react';
import { MRT_Row } from 'material-react-table';
import { monthNamer } from '../utilities/utilities';
import { InvoiceClean } from '../Invoices.types';

export default function HandleDateAggregatedCell(options: { row: MRT_Row<InvoiceClean> }) {
  const {
    row: { groupingColumnId, groupingValue },
  } = options;
  if (groupingColumnId === 'month') {
    return <div>{monthNamer(groupingValue as string)}</div>;
  }
  return <div>{groupingValue as string}</div>;
}
