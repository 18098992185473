import translation from './de/translation.json';
import Login from '../pages/login/i18n/login-de.json';
import Error from './de/errors.json';

const translationDE = {
  translation,
  Login,
  Error,
};

export default translationDE;
