import { z } from 'zod'
import { ScopeDtoSchema, TreeWithCountryLanguageSchema } from '../ScopeDto'

export const ProductionStatusDashboardSchema = z.object({
  clientId: z.number(),
  clientName: z.string(),
  reportTypeId: z.number(),
  reportTypeName: z.string(),
  reportDate: z.string(),
  reportLevel: z.number(),
  inDataReceptionCount: z.number(),
  inProductionCount: z.number(),
  inControlCount: z.number(),
  inClientValidationCount: z.number(),
  inSendingCount: z.number(),
  totalDeliverable: z.number(),
  reportDateSort: z.string(),
  reportDateFormatted: z.string(),
  productionTypeId: z.number(),
  productionTypeName: z.string(),
  clientValidationEnabled: z.boolean(),
})

//--------------------------------------------------- PRODUCTION VALIDATION PART  --------------------------------------

export type ProductionStatusDashboardDto = z.infer<typeof ProductionStatusDashboardSchema>

export const ProductionValidationSchema = z
  .object({
    documentId: z.number(),
    productionValidationId: z.number(),
    documentName: z.string(),
    documentExtension: z.string().nullable(),
    clientComment: z.string().nullable(),
    clientValidation: z.boolean().nullable(),
    generationTimestamp: z.string().nullable(),
    languageId: z.number().nullable(),
    languageName: z.string().nullable(),
    languageIsoCode2: z.string().nullable(),
    countryId: z.number().nullable(),
    countryName: z.string().nullable(),
    countryWithLanguage: z.string().nullable(),
  })
  .merge(ScopeDtoSchema)

export type ProductionValidationDto = z.infer<typeof ProductionValidationSchema>

//--------------------------------------------------- PRODUCTION SCOPE MATRIX PART  ------------------------------------

export const DeliveryEstimationSchema = z
  .object({
    productionStatusId: z.number(),
    productionStatusName: z.string().nullable(),
    clientFinalDeliveryDeadline: z.number().nullable(),
    enteringDate: z.string().nullable(),
  })
  .merge(TreeWithCountryLanguageSchema)

export type DeliveryEstimationDto = z.infer<typeof DeliveryEstimationSchema>

export const DataSourcesSchema = z
  .object({
    dataTypeName: z.string().nullable(),
    isComplete: z.boolean().nullable(),
    dataDate: z.string().nullable(),
    dataDateSort: z.string().nullable(),
    dataDateFormatted: z.string().nullable(),
  })
  .merge(TreeWithCountryLanguageSchema)

export type DataSourcesDto = z.infer<typeof DataSourcesSchema>

// Used to get the count of each production step
export const ProductionStatusSchema = z.object({
  idStatus: z.number(),
  statusName: z.string(),
  totalCount: z.number(),
})

export type ProductionStatusDto = z.infer<typeof ProductionStatusSchema>
