import { t } from 'i18next'
import React, { createContext, useContext } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROLES } from '../constants/constants'
import { ROUTES } from '../constants/routes'
import { getExpFromJwt, getImgSrcByBase64AndFileExtension } from '../helpers/helpers'
import http from '../services/http'
import UserService, { UserDto } from '../services/UserService/userService'
import { ThemeContext, Theme } from './ThemeProvider'
import { ClientService } from '../services/ClientService/clientService'
import AuthService from '../services/AuthService/authService'
import { SingleSignOnService } from '../services/SingleSignOnService/SingleSignOnService'

type Context = {
  user: UserDto | null
  login: any
  logout: any
  getUser: any
  hasRole: any
}

export const UserContext = createContext<Context>({
  user: null,
  login: undefined,
  logout: undefined,
  getUser: undefined,
  hasRole: undefined,
})

type Props = {
  children: React.ReactNode
}

/**
 * Return the src string of the client logo
 */
const getClientLogoSrc = async (clientHasLogoInDb: boolean, clientLogoUrl: string | null) => {
  if (clientHasLogoInDb) {
    let src = ''
    let base64String = ''
    let fileExtension = ''
    await ClientService.getClientLogo().then(({ data }) => {
      src = data.logoByte
      base64String = data.logoByte
      fileExtension = data.logoFileExtension
    })
    src = getImgSrcByBase64AndFileExtension(base64String, fileExtension)
    return src
  }
  if (clientLogoUrl) {
    return clientLogoUrl
  }
}

const getParentClientLogoSrc = async (
  parentClientHasLogoInDb: boolean,
  parentLogoUrl: string | null
) => {
  if (parentClientHasLogoInDb) {
    let src = ''
    let base64String = ''
    let fileExtension = ''
    await ClientService.getParentClientLogo().then(({ data }) => {
      src = data.logoByte
      base64String = data.logoByte
      fileExtension = data.logoFileExtension
    })
    src = getImgSrcByBase64AndFileExtension(base64String, fileExtension)
    return src
  }
  if (parentLogoUrl) {
    return parentLogoUrl
  }
}

function UserProvider({ children }: Props) {
  //const [user, setUser] = React.useState<User | null>(null)
  const [user, setUser] = React.useState<UserDto | null>(null)

  // THEME CONTEXT
  const { setParentLogoSrc, setMainColor, setClientLogoSrc } = useContext<Theme>(ThemeContext)

  const navigate = useNavigate()
  const location = useLocation()

  const logout = () => {
    setUser(null)
    const token = localStorage.getItem('id_token')
    if (token != null) {
      console.log('revoke token')
      SingleSignOnService.revokeToken()
      SingleSignOnService.logout()
    }
    localStorage.removeItem('access_token')
    delete http.defaults.headers.common.Authorization

    navigate(ROUTES.LOGIN, { replace: true })
  }

  const getUser = () => {
    const token = localStorage.getItem('access_token')
    if (!token) return
    http.defaults.headers.common.Authorization = token

    const exp = getExpFromJwt(token)
    const now = (Date.now() / 1000).toFixed(0)

    if (exp < now) {
      setUser(null)
      logout()
      navigate(ROUTES.LOGIN, { replace: true, state: { from: location } })
      toast(t('session-expired'))
    }

    UserService.getUser()
      .then(async ({ data }) => {
        const { parentMainRgbColor } = data

        // Return the main rgb color, in priority the one of the parent, else the client, else alpha omega color
        const rgbColor = () => {
          if (parentMainRgbColor) {
            return parentMainRgbColor
          }
          if (
            data.clientId === 10175 // ID of allfunds
          ) {
            return '#D9183B'
          }
          return '#FF6900'
        }
        document.documentElement.style.setProperty('--main-color', rgbColor())
        setMainColor(rgbColor())
        setUser(data)

        getClientLogoSrc(data.clientHasLogoInDb, data.clientLogoUrl).then(logoSrc => {
          if (logoSrc) {
            setClientLogoSrc(logoSrc)
          }
        })

        getParentClientLogoSrc(data.parentHasLogoInDb, data.parentLogoUrl).then(logoSrc => {
          if (logoSrc) {
            setParentLogoSrc(logoSrc)
          }
        })

        navigate(location.pathname === ROUTES.LOGIN ? ROUTES.DASHBOARD : location.pathname, {
          replace: true,
        })
      })
      .catch(error => {
        console.log(error)

        if (error.response && error.response.status === 401) {
          // Invalid credential code
          if (
            error.response.data &&
            typeof error.response.data === 'string' &&
            error.response.data.includes('User not found')
          ) {
            navigate(ROUTES.SSO_USER_NOT_FOUND)
          } else {
            toast('Impossible to login, please try again', {
              id: 'LoginErrorToast',
            })
            navigate(ROUTES.LOGIN)
          }
        } else {
          toast('Impossible to login, please try again', {
            id: 'LoginErrorToast',
          })
          // in case of failed with wrong token send the user to the login page
          navigate(ROUTES.LOGIN)
        }
      })
  }

  const hasRole = (role: keyof typeof ROLES) => {
    if (!user) return false
    return !!~user?.roles.findIndex(name => name === role)
  }

  const login = (username: string, password: string) => {
    AuthService.postAuthenticate(username, password)
      .then(({ data }: any) => {
        if (data?.token) {
          localStorage.setItem('access_token', data.token)
          getUser()
        }
      })
      .catch((e: any) => {
        toast(e.response.data.message)
      })
  }

  return (
    <UserContext.Provider value={{ user, login, logout, getUser, hasRole }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
