import DatePicker from 'react-date-picker'
import styled from 'styled-components'

export const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 25px;
  transition: height 1s ease;
  height: max-content;
`

export const DateContainer = styled(DatePicker)`
  text-align: center;
  height: 32px;
  border-radius: 4px;
  border: 1px solid black;
  font-size: 16px;
  color: black;
`

export const Row = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`

export const GedFilterSectionContainer = styled.div`
  padding: 10px;
`
