import styled from 'styled-components'

export const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 10px;
`
export const RightButtons = styled.div`
  display: flex;
  gap: 10px;
`
export const ActionButtonsContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 14px;
  z-index: 1;
  display: flex;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 70vh;
`
