import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { COLOR } from '../../constants/theme';

function CheckIcon() {
  return <FontAwesomeIcon icon={faCheck} color={COLOR.GREEN} />;
}

export default CheckIcon;
