import React, { useContext, useEffect } from 'react';
import { Cell, CellProps, Row, TableRowProps, useTable } from 'react-table';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { COLOR } from '../../constants/theme';
import Loader from '../loader/Loader';
import { UserContext } from '../../context/UserProvider';
import { pSBC } from '../../helpers/pSBC';
import { ThemeContext } from '../../context/ThemeProvider';

export type TableHeaderType = {
  Header: string;
  accessor: string;
};

export type TableBody = {};

type Props = {
  head: {
    Header: string;
    accessor: string;
    accessorFn?: (originalRow: any, index: number) => any;
  }[];
  data: any;
  hideHeader?: boolean;
  fullWidth?: boolean;
  center?: boolean;
  fixedHeader?: boolean;
  loading?: boolean;
  selectionState?: [number[], React.Dispatch<React.SetStateAction<number[]>>];
  handleSelectionOnClick?: (row: Row<any>, cell: Cell) => void;
};
/**
 * @example
 * const head = [
 *   {
 *     Header: 'Colmun title 1',
 *     accessor: 'col1'
 *   },
 *   {
 *     Header: 'Header title 2',
 *     accessor: 'col2'
 *   }
 * ]
 *
 * const data = [
 *   {
 *     col1: 'cell 0 0',
 *     col2: 'cell 0 1',
 *   },
 *   {
 *     col1: 'cell 1 0',
 *     col2: 'cell 1 1',
 *
 *   },
 *   {
 *     col1: 'cell 2 0',
 *     col2: 'cell 2 1'
 *   }
 * ]
 */
function Table({
  head,
  data,
  hideHeader = false,
  fullWidth = false,
  center = false,
  fixedHeader = false,
  loading = false,
  selectionState = undefined,
  handleSelectionOnClick,
}: Props) {
  const { user } = useContext(UserContext);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state
  } = useTable({ columns: head, data });

  // THEME
  const { mainColor, mainColorLight } = useContext(ThemeContext);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [data, loading]);

  useEffect(() => {
    COLOR.MAIN = user?.clientMainRgbColor as string;
    COLOR.MAINLIGHT = pSBC(0.65, user?.clientMainRgbColor as string) as string;
  }, [user]);

  const [selectedRows, setSelectedRows] = selectionState ?? [undefined, undefined];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <StyledTable fullwidth={fullWidth} center={center} {...getTableProps()}>
          {!hideHeader && (
            <TableHead fixedHeader={fixedHeader} bgColor={mainColor}>
              {headerGroups.map(headerGroup => (
                <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableHeadCell
                      fixedHeader={fixedHeader}
                      {...column.getHeaderProps()}
                      bgColor={mainColor}>
                      {column.render('Header')}
                    </TableHeadCell>
                  ))}
                </TableHeadRow>
              ))}
            </TableHead>
          )}

          <TableBody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  style={{
                    background: selectedRows?.includes(+row.original.c0) ? 'rgb(229,229,229)' : '',
                  }}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell
                        className='lel'
                        fixedHeader={fixedHeader}
                        hideHeader={hideHeader}
                        onClick={() => {
                          if (handleSelectionOnClick) {
                            handleSelectionOnClick(row, cell);
                          } else {
                            if (
                              !selectedRows ||
                              !setSelectedRows ||
                              cell.column.Header === 'Download' ||
                              cell.column.Header === 'Send'
                            )
                              return;
                            const alreadyPressed = selectedRows.find(id => id === +row.original.c0);
                            setSelectedRows(
                              alreadyPressed !== undefined
                                ? selectedRows.filter(id => id !== +row.original.c0)
                                : [...selectedRows, +row.original.c0]
                            );
                          }
                        }}
                        {...cell.getCellProps()}
                        bcolor={mainColorLight}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      )}
      <ReactTooltip />
    </>
  );
}

const StyledTable = styled.table<{ fullwidth: boolean; center: boolean }>`
  border-spacing: 0px;
  border-collapse: collapse;
  border-radius: 8px;

  ${({ fullwidth, center }) => {
    let res = '';
    if (fullwidth) res += 'width: 100%;';
    if (center) res += 'margin: 0 auto;';
    return res;
  }}
`;

const TableHead = styled.thead<any>`
  z-index: 1;
  background-color: ${props => props.bgColor};
  color: ${COLOR.WHITE};
  height: 0px;
  ${({ fixedHeader }) =>
    fixedHeader &&
    `
      position: sticky;
      top: 0;
    `}
`;

const TableHeadRow = styled.tr`
  height: 0px;
`;

const TableHeadCell = styled.th<any>`
  font-weight: normal;
  font-size: 20px;
  &:first-child {
    border-left: none;
    ${({ fixedHeader, mainColor }) =>
      fixedHeader &&
      `
        position: sticky;
        left: 0;
        background: ${mainColor};
    `}
  }
  min-width: 100px;
  padding: 8px;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  &:last-child td {
    border-bottom: none;
  }
`;

const TableCell = styled.td<{
  hideHeader: boolean;
  fixedHeader: boolean;
  bcolor: string;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    white-space: normal;
    height: auto;
    overflow: visible;
  }

  ${({ hideHeader }) => !hideHeader && 'max-width: 0;'}

  padding: 10px;
  border: solid 1px ${props => props.bcolor};
  text-align: center;

  &:first-child {
    ${({ fixedHeader }) =>
      fixedHeader &&
      `
      position: sticky;
      left: 0;
    `}
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

export default Table;
