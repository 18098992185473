import React from 'react';
import styled from 'styled-components';
import { COLOR, DS } from '../../constants/theme';

type Props = {
  scale?: number;
  line?: boolean;
  color?: string;
};

function Separator({ scale, line, color }: Props) {
  return <Container scale={scale} line={line === true} color={color} />;
}

export default Separator;

const Container = styled.div<Props>`
  width: 100%;
  ${({ scale }) => `
        margin-top: ${DS.SIZE * (scale ?? 1) - 2}px;
        margin-bottom: ${DS.SIZE * (scale ?? 1) - 2}px;
    `}
  ${({ line, color }) =>
    line &&
    `
        background: ${color ?? COLOR.BLACK};
    `}
    height: 1px;
  box-sizing: border-box;
`;
