import React, { useContext, useEffect, useState } from 'react'
import {
  faCheckSquare,
  faEye,
  faEyeSlash,
  faFileDownload,
  faSquareXmark,
} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import Layout from '../../components/layout/Layout'
import './react-datepicker.css'

import { GedContext } from './context/GedProvider'
import GedFilterSection from './components/GedFilterSection'
import ButtonWithIcon from '../../components/button/ButtonWithIcon'
import FinalDocumentGed from './FinalDocumentGed'
import DownloadFinalDocsModal from './components/DownloadFinalDocsModal'
import SortButton from './components/SortButton'

/**
 * This page displays the documents produced by ALpha Omega
 * 2 views are available :
 * - Report Validation Ged : contains all documents in validation mode
 * - Final documents Ged : contains all final documents that are ready to be downloaded and send to the client
 * On both view we can download and send the documents by mail
 * We can switch between views by clicking on the button next to the title of the page
 * @constructor
 */
function Library() {
  localStorage.setItem('localState', 'library')

  // Download Modal
  const [isDownloadFinalModalOpened, setIsDownloadFinalModalOpened] = useState<boolean>(false)
  const [isFilterSectionHidden, setIsFilterSectionHidden] = useState<boolean>(false)

  const [currentPage, setCurrentPage] = useState<number>(0)

  const { reloadData, filterState } = useContext(GedContext)

  useEffect(() => {
    reloadData(currentPage)
  }, [currentPage, filterState])

  const {
    selectedRows,
    paginatedDocuments,
    isLoading,
    selectAll,
    allDocumentsIds,
    displayFinalDocuments,
  } = useContext(GedContext)

  const handleDownloadFinalButtonClick = () => {
    setIsDownloadFinalModalOpened(prevState => !prevState)
  }

  return (
    <Container>
      <DownloadFinalDocsModal
        open={isDownloadFinalModalOpened}
        setOpen={setIsDownloadFinalModalOpened}
      />
      <GedFilterSection isSectionHidden={isFilterSectionHidden} setCurrentPage={setCurrentPage} />
      {paginatedDocuments && displayFinalDocuments && !isLoading && (
        <BtnContainer>
          <LeftButtons>
            <SortButton reloadData={reloadData} setCurrentPage={setCurrentPage} />
            <ButtonWithIcon
              onClick={() => {
                setIsFilterSectionHidden(prevState => !prevState)
              }}
              icon={isFilterSectionHidden ? faEye : faEyeSlash}
              text={isFilterSectionHidden ? 'Show filters' : 'Hide filters'}
            />
          </LeftButtons>
          <RightButtons>
            <ButtonWithIcon
              text={selectedRows.length === allDocumentsIds.length ? 'Unselect all' : 'Select all'}
              icon={selectedRows.length === allDocumentsIds.length ? faSquareXmark : faCheckSquare}
              onClick={selectAll}
            />
            <ButtonWithIcon
              text={`Download ${selectedRows.length === 0 ? 'All' : selectedRows.length}`}
              icon={faFileDownload}
              onClick={handleDownloadFinalButtonClick}
            />
          </RightButtons>
        </BtnContainer>
      )}
      <FinalDocumentGed currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </Container>
  )
}

export default Library

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 10px;
`
const RightButtons = styled.div`
  display: flex;
  gap: 10px;
`
const LeftButtons = styled.div`
  display: flex;
  gap: 10px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 70vh;
`
