import React, { useContext, useEffect, useMemo, useState } from 'react'
import { faClose, faDownload, faEraser } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { Checkbox, Modal } from '@mui/material'
import { MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { StylesConfig } from 'react-select'
import Title from '../../../components/text/Title'
import { ROLES } from '../../../constants/constants'
import { UserContext } from '../../../context/UserProvider'
import { DeliverableValidationDto } from '../../../types/dtos/FollowupDto'
import { OptionType } from '../../../types/OptionType'
import { ThemeContext } from '../../../context/ThemeProvider'
import MaterialTable from '../../../components/table/MaterialTable/MaterialTable'
import { SelectInputSearchControlled } from '../../../components/input/SelectInputSearchControlled'
import ButtonWithIcon from '../../../components/button/ButtonWithIcon'
import ConfirmModal from '../../../components/modal/ConfirmModal'
import ReportValidationDeclineModal from './components/ReportValidationDeclineModal/ReportValidationDeclineModal'
import * as S from './ReportValidation.styles'
import HandleDocumentCell from './components/HandleDocumentCell'
import HandleDocumentAggregatedCell from './components/HandleDocumentAggregatedCell'
import {
  HandleMuiTableBodyCellPropsLevels,
  RenderDefaultTopToolBar,
} from '../../../components/table/MaterialTable/ColumnUtilities'
import HandleStatusCell from './components/HandleStatusCell'
import HandleOpenCell from './components/HandleOpenCell'
import HandleDownloadCell from './components/HandleDownloadCell'
import HandleDownloadAggregatedCell from './components/HandleDownloadAggregatedCell'
import HandleSendByEmailCell from './components/HandleSendByEmailCell'
import { LocationState } from './ReportValidation.types'
import GoBack from './components/GoBack'
import { ProductionService } from '../../../services/production/ProductionService'
import { ProductionValidationDto } from '../../../types/dtos/production/productionDto'
import ProductionDocumentService from '../../../services/Document/productionDocumentService'
import DownloadService from '../../../services/DownloadService/downloadService'

function ReportValidation() {
  // Manage context
  const { user } = useContext(UserContext)
  const { mainColor, mainColorLight, mainColorVeryLight } = useContext(ThemeContext)
  // Manage data
  const [rawData, setRawData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [changeListener, setChangeListener] = useState<boolean>(false)
  const { state }: any = useLocation()
  const [locationState, setLocationState] = useState<LocationState>()
  const [reportLevel, setReportLevel] = useState<'Fund' | 'Subfund' | 'CodeIsin'>('CodeIsin')
  // Manage filters
  const [filterSubClient, setFilterSubClient] = useState<OptionType | null>(null)
  const [filterFund, setFilterFund] = useState<OptionType | null>(null)
  const [filterSubfund, setFilterSubfund] = useState<OptionType | null>(null)
  const [filterCodeIsin, setFilterCodeIsin] = useState<OptionType | null>(null)
  const [filterLanguage, setFilterLanguage] = useState<OptionType | null>(null)
  //  Manage all filters options
  const [allClient, setAllClient] = useState<OptionType[]>([])
  const [allFund, setAllFund] = useState<OptionType[]>([])
  const [allSubfund, setAllSubfund] = useState<OptionType[]>([])
  const [allCodeIsin, setAllCodeIsin] = useState<OptionType[]>([])
  const [allLanguage, setAllLanguage] = useState<OptionType[]>([])
  // Manage documents with more than one version
  const [allUpdatedDocuments, setAllUpdatedDocuments] = useState<number[]>([])
  const [ignoredList, setIgnoredList] = useState<number[]>()
  // Manage modals
  const [downloadModalOpened, setDownloadModalOpened] = useState<boolean>(false)
  const [approveModalOpenned, setApproveModalOpenned] = useState<boolean>(false)
  const [declineModalOpenned, setDeclineModalOpenned] = useState<boolean>(false)
  const [splitZipStructure, setSplitZipStructure] = useState<boolean>(false)
  // Manage selected rows
  const [rowSelection, setRowSelection] = useState([])
  // Manage exception reports in subclient level
  // const [codeIsinLevel, setCodeIsinLevel] = useState<boolean>(true);
  //  Define initial config for the table
  const [initialState, setInitialState] = useState({
    grouping: ['fundName', 'subfundName', 'codeIsin', 'countryWithLanguage'],
    columnVisibility: {
      fundName: false,
      subfundName: false,
      codeIsin: false,
      languageName: false,
      subclientName: false,
      countryWithLanguage: false,
      clientName: false,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
      { id: 'codeIsin', desc: false },
    ],
    density: 'compact',
    columnPinning: { left: ['mrt-row-expand', 'documentName'] },
    columnOrder: [
      'mrt-row-expand',
      'fundName',
      'subfundName',
      'codeIsin',
      'documentName',
      'clientValidation',
      'mrt-row-open',
      'mrt-row-download',
      'mrt-row-sendByEmail',
      'mrt-row-select',
    ],
  })

  const getLocationState = () => {
    const reportValidationLocationState = localStorage.getItem('reportValidationLocationState')
    const savedState: LocationState | null = reportValidationLocationState
      ? JSON.parse(reportValidationLocationState)
      : null

    if (savedState) {
      setLocationState(savedState)
    } else {
      setLocationState(state)
      localStorage.setItem('reportValidationLocationState', JSON.stringify(state))
    }
  }
  useEffect(() => {
    getLocationState()

    return () => {
      localStorage.removeItem('reportValidationLocationState')
    }
  }, [])

  useEffect(() => {
    if (locationState) {
      ProductionService.getAllProductionValidationDto(
        locationState.reportDate,
        locationState.reportTypeId
      )
        .then(resp => {
          setRawData(resp.data)
        })
        .catch(error => {
          console.log(error)
          toast.error('There was an error during data fetching')
        })
        .finally(() => setIsLoading(false))
    }
  }, [locationState, changeListener])

  // Manage documents that have an older version for the select and for the download all button
  useEffect(() => {
    if (rawData.some((elem: DeliverableValidationDto) => !elem.codeIsin)) {
      setReportLevel('Subfund')
      setInitialState(prev => ({
        ...prev,
        grouping: ['fundName', 'subfundName'],
        sorting: [
          { id: 'fundName', desc: false },
          { id: 'subfundName', desc: false },
        ],
      }))
    }

    const rawIgnoredList: any = rawData
      .filter(
        (elem: ProductionValidationDto, index: number, array: ProductionValidationDto[]) =>
          array.findIndex(
            (obj: ProductionValidationDto) => obj.documentName === elem.documentName
          ) !== index
      )
      .map((elem: ProductionValidationDto) => elem.documentId)
    const rawAllUpdatedDocuments: any = rawData
      .filter(
        (elem: ProductionValidationDto, index: number, array: ProductionValidationDto[]) =>
          array.findIndex(
            (obj: ProductionValidationDto) => obj.documentName === elem.documentName
          ) === index
      )
      .map((elem: ProductionValidationDto) => elem.documentId)
    setIgnoredList(rawIgnoredList)
    setAllUpdatedDocuments(rawAllUpdatedDocuments)
    const rawLanguage = rawData
      .map((elem: ProductionValidationDto) => elem.languageName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllLanguage(rawLanguage)
  }, [isLoading])

  // Manage the data that will be displayed according to previous filters
  useEffect(() => {
    //for subclient
    const rawSubclient = rawData
      .map((elem: ProductionValidationDto) => elem.clientName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllClient(rawSubclient)
  }, [isLoading])

  useEffect(() => {
    //for fund
    const rawFund = rawData
      .filter((elem: ProductionValidationDto) =>
        filterSubClient?.label ? elem.clientName === filterSubClient.label : true
      )
      .map((elem: ProductionValidationDto) => elem.fundName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: string) => ({ label: elem, value: elem }))
    setAllFund(rawFund)
  }, [isLoading, filterSubClient])
  useEffect(() => {
    //for subfund
    const rawsubFund = rawData
      .filter((elem: ProductionValidationDto) =>
        filterSubClient?.label ? elem.clientName === filterSubClient.label : true
      )
      .filter((elem: ProductionValidationDto) =>
        filterFund?.label ? elem.fundName === filterFund.label : true
      )
      .map((elem: ProductionValidationDto) => elem.subfundName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map(elem => ({ label: elem as string, value: elem as string }))
    setAllSubfund(rawsubFund)
  }, [isLoading, filterSubClient, filterFund])
  useEffect(() => {
    //for codeIsin
    const rawCodeIsin = rawData
      .filter((elem: ProductionValidationDto) =>
        filterSubClient?.label ? elem.clientName === filterSubClient.label : true
      )
      .filter((elem: ProductionValidationDto) =>
        filterFund?.value ? elem.fundName === filterFund.label : true
      )
      .filter((elem: ProductionValidationDto) =>
        filterSubfund?.value ? elem.subfundName === filterSubfund.label : true
      )
      .map((elem: ProductionValidationDto) => elem.codeIsin)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllCodeIsin(rawCodeIsin)
  }, [isLoading, filterSubClient, filterFund, filterSubfund])

  // styling for the buttons
  const filtersStyle: StylesConfig<OptionType, false> = {
    indicatorSeparator: provided => ({ ...provided, display: 'none' }),
    container: provided => ({ ...provided, fontSize: '0.8rem', width: 'max-content' }),
    dropdownIndicator: provided => ({ ...provided, padding: '0px 2px' }),
    valueContainer: provided => ({ ...provided, padding: '0px 2px' }),
  }

  //Definitions for the filter bar
  const renderTopToolbar = (options: { table: MRT_TableInstance }) => {
    const { table } = options
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <RenderDefaultTopToolBar
          table={table}
          isLoading={isLoading}
          reportLevel={reportLevel}
          filterFund={filterFund}
          filterSubfund={filterSubfund}
          filterCodeIsin={filterCodeIsin}
          setFilterFund={setFilterFund}
          setFilterSubfund={setFilterSubfund}
          setFilterCodeIsin={setFilterCodeIsin}
          allFund={allFund}
          allSubfund={allSubfund}
          allCodeIsin={allCodeIsin}
          preComponent={
            user &&
            user.hasSubclient && (
              <SelectInputSearchControlled
                isDisabled={isLoading}
                customStyle={filtersStyle}
                value={filterSubClient}
                options={allClient}
                placeholder='Select a subclient'
                onChangeCallback={option => {
                  setFilterCodeIsin(null)
                  setFilterSubfund(null)
                  setFilterFund(null)
                  setFilterSubClient(option)
                  table.setColumnFilters((prev: any) => {
                    const otherFilters = prev
                      .filter((elem: any) => elem?.id !== 'clientName')
                      .filter((elem: any) => elem?.id !== 'fundName')
                      .filter((elem: any) => elem?.id !== 'subfundName')
                      .filter((elem: any) => elem?.id !== 'codeIsin')
                    if (option) {
                      return [...otherFilters, { id: 'clientName', value: option?.label }]
                    }
                    return [...otherFilters]
                  })
                }}
              />
            )
          }
          afterComponent={
            <>
              <SelectInputSearchControlled
                isDisabled={isLoading}
                customStyle={filtersStyle}
                value={filterLanguage}
                options={allLanguage}
                placeholder='Select a language'
                onChangeCallback={option => {
                  setFilterLanguage(option)
                  table.setColumnFilters((prev: any) => {
                    const otherFilters = prev.filter((elem: any) => elem?.id !== 'languageName')
                    if (option) {
                      return [...otherFilters, { id: 'languageName', value: option?.label }]
                    }
                    return [...otherFilters]
                  })
                }}
              />
              <ButtonWithIcon
                icon={faEraser}
                text='Reset filters'
                onClick={() => {
                  table.resetColumnFilters()
                  setFilterSubClient(null)
                  setFilterFund(null)
                  setFilterSubfund(null)
                  setFilterCodeIsin(null)
                  setFilterLanguage(null)
                }}
              />
              <ButtonWithIcon
                icon={faDownload}
                text={
                  rowSelection.length === 0
                    ? 'Download all'
                    : `Download ${rowSelection.length} documents`
                }
                onClick={() => {
                  setDownloadModalOpened(true)
                }}
              />
            </>
          }
        />
      </div>
    )
  }

  const createMainElementsInColumn = () => {
    let mainGroupedElements: MRT_ColumnDef<ProductionValidationDto>[] = []
    switch (reportLevel) {
      case 'Subfund':
        mainGroupedElements = [
          {
            header: 'Fund',
            accessorKey: 'fundName',
            enableGlobalFilter: false,
            filterFn: 'equalsString',
          },
          {
            header: 'SubfundName',
            accessorKey: 'subfundName',
            enableSorting: true,
            enableGlobalFilter: false,
            filterFn: 'equalsString',
          },
        ]
        break
      default:
        mainGroupedElements = [
          {
            header: 'Fund',
            accessorKey: 'fundName',
            enableGlobalFilter: false,
            filterFn: 'equalsString',
          },
          {
            header: 'SubfundName',
            accessorKey: 'subfundName',
            enableGlobalFilter: false,
            filterFn: 'equalsString',
          },
          {
            header: 'CodeIsin',
            accessorKey: 'codeIsin',
            enableGlobalFilter: false,
            filterFn: 'equalsString',
          },
        ]
        break
    }
    return mainGroupedElements
  }

  // @ts-ignore
  const columns = useMemo<MRT_ColumnDef<ProductionValidationDto>[]>(() => {
    return [
      ...createMainElementsInColumn(),
      {
        header: 'Document',
        accessorKey: 'documentName',
        enableColumnActions: false,
        Cell: (options: any) => HandleDocumentCell(options, reportLevel),
        AggregatedCell: (options: any) => HandleDocumentAggregatedCell(options),
        muiTableBodyCellProps: (options: any) =>
          HandleMuiTableBodyCellPropsLevels(options, mainColorLight, mainColorVeryLight),
      },
      {
        header: 'Status',
        accessorKey: 'clientValidation',
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: (options: any) => HandleStatusCell(options, mainColor),
      },
      {
        header: 'Language',
        accessorKey: 'languageName',
      },
      {
        header: 'countryWithLanguage',
        accessorKey: 'countryWithLanguage',
      },
      {
        header: 'Client',
        accessorKey: 'clientName',
      },
      {
        header: 'Open',
        accessorKey: 'mrt-row-open',
        columnDefType: 'display',
        Cell: options => HandleOpenCell(options, mainColor),
      },
      {
        header: 'Download',
        accessorKey: 'mrt-row-download',
        columnDefType: 'display',
        Cell: options => HandleDownloadCell(options, mainColor),
        AggregatedCell: options => HandleDownloadAggregatedCell(options, mainColor),
      },
      {
        header: 'Send by email',
        accessorKey: 'mrt-row-sendByEmail',
        columnDefType: 'display',
        Cell: options => HandleSendByEmailCell(options, mainColor),
      },
    ]
  }, [reportLevel, initialState])

  const handleRowSelection = (row: MRT_Row<ProductionValidationDto>) => {
    // user need control role
    if (user) {
      if (user.roles.includes(ROLES.CONTROL)) {
        // the document need to be in status null
        if (row.original.clientValidation === null) {
          // document can't be in the list of older versions
          if (row.original.documentId) {
            if (!ignoredList?.includes(row.original.documentId)) {
              // remove the checkbox in the codeIsin aggregated level
              // if (row?.groupingColumnId === 'codeIsin') {
              //   return false
              // }
              return true
            }
          }
        }
      }
    }
    return false
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <GoBack locationState={locationState as LocationState} />
      <div>
        <Toaster />
        <Modal
          open={downloadModalOpened}
          onClose={() => {
            setDownloadModalOpened(false)
          }}>
          <S.ModalBox>
            <S.ModalBody>
              <Title>Download documents</Title>

              {rowSelection.length > 0 ? (
                <div>
                  You are going to download {rowSelection.length} documents, are you sure you want
                  to download it ?
                </div>
              ) : (
                <div>
                  You are going to download {allUpdatedDocuments.length} documents, are you sure you
                  want to download it ?
                </div>
              )}

              <div>
                <span>Do you want to split zip structure ?</span>
                <Checkbox
                  onChange={() => {
                    setSplitZipStructure(prevState => !prevState)
                  }}
                  style={{ color: mainColor }}
                />
                <span>{splitZipStructure ? 'Yes' : 'No'}</span>
              </div>
              <S.ButtonsContainer>
                <ButtonWithIcon
                  text='Yes, download the documents'
                  icon={faDownload}
                  customButtonStyle={{ backgroundColor: mainColorLight }}
                  onClick={() => {
                    let promise
                    if (rowSelection.length > 0) {
                      promise = ProductionDocumentService.downloadByIds(
                        rowSelection,
                        splitZipStructure
                      )
                    } else {
                      promise = ProductionDocumentService.downloadByIds(
                        allUpdatedDocuments,
                        splitZipStructure
                      )
                    }

                    toast.promise(promise, {
                      loading: 'Downloading documents...',
                      success: resp => {
                        const filename = ProductionDocumentService.getFileNameFromHeader(resp)
                        DownloadService.downloadDoc(resp.data, filename)
                        return 'Documents downloaded successfully.'
                      },
                      error: error => {
                        console.error(error)
                        return 'An error occured while downloading the documents. Please try again.'
                      },
                    })
                  }}
                />
                <ButtonWithIcon
                  text='Cancel'
                  icon={faClose}
                  onClick={() => {
                    setDownloadModalOpened(false)
                  }}
                />
              </S.ButtonsContainer>
            </S.ModalBody>
          </S.ModalBox>
        </Modal>
        <ConfirmModal
          width={350}
          height={150}
          title='Document to be updated'
          text={`${rowSelection.length} documents go to the next step`}
          isModalOpenned={approveModalOpenned}
          closeModalHandler={() => setApproveModalOpenned(false)}
          actionCallback={() => {
            // setRowSelection([])
            // return setChangeListener(prev=>!prev)
            const promise = ProductionService.approveOrDeclineReports(rowSelection, true)
            toast.promise(promise, {
              loading: 'Loading',
              success: () => {
                setChangeListener(prev => !prev)
                setRowSelection([])
                setApproveModalOpenned(false)
                return 'Successfully updated'
              },
              error: e => {
                console.log(e)

                return 'There was a problem during update'
              },
            })
          }}
        />
        <ReportValidationDeclineModal
          isOpened={declineModalOpenned}
          setIsOpened={() => setDeclineModalOpenned(false)}
          items={rowSelection}
          callback={comment => {
            const promise = ProductionService.approveOrDeclineReports(rowSelection, false, comment)
            toast.promise(promise, {
              loading: 'Loading',
              success: () => {
                setChangeListener(prev => !prev)
                setRowSelection([])
                setDeclineModalOpenned(false)
                return 'Successfully updated'
              },
              error: () => {
                return 'There was a problem during update'
              },
            })
          }}
        />
        <MaterialTable
          data={rawData}
          columns={columns}
          initialState={initialState}
          isLoading={isLoading}
          maxHeight={72}
          // @ts-ignore
          enableRowSelection={handleRowSelection}
          enableSelectAll
          renderTopToolbar={renderTopToolbar}
          setRowSelected={setRowSelection}
          getRowId={(originalRow: ProductionValidationDto) => originalRow.documentId}
          changeListener={changeListener}
        />
      </div>
      {rowSelection.length !== 0 && (
        <S.ActionButtonsContainer>
          <ButtonWithIcon text='Decline' onClick={() => setDeclineModalOpenned(true)} />
          <div style={{ marginRight: 8 }} />
          <ButtonWithIcon
            text='Approve'
            onClick={() => setApproveModalOpenned(true)}
            customButtonStyle={{ backgroundColor: mainColorLight }}
          />
        </S.ActionButtonsContainer>
      )}
    </div>
  )
}

export default ReportValidation
