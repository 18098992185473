import React from 'react';
import { MRT_Row } from 'material-react-table';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleDownload } from '../utilities/utilities';
import { InvoiceClean } from '../Invoices.types';

export default function HandleDateAggregatedCell(
  options: { row: MRT_Row<InvoiceClean> },
  mainColor: string
) {
  const {
    row: {
      original: { id, docName, extension },
    },
  } = options;
  return (
    <FontAwesomeIcon
      icon={faDownload}
      cursor='pointer'
      color={mainColor}
      onClick={() => {
        handleDownload(id, docName, extension);
      }}
    />
  );
}
