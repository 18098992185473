import Select, { ActionMeta, CSSObjectWithLabel, InputActionMeta, StylesConfig } from 'react-select'
import { OptionType } from '../../types/OptionType'

type Props = {
  options: OptionType[]
  onChangeCallback?: (option: OptionType[] | null) => void
  isDisabled?: boolean
  placeholder: string
  defaultValue?: OptionType[]
  noOptionsMessage?: string
  value: OptionType[] | null
  onMenuCloseCallback?: () => void
  onInputChangeCallback?: (newValue: string, actionMeta: InputActionMeta) => void
  // https://react-select.com/styles#styles
  // This component used the style api from react select
  // We can define custom style by setting a StylesConfig object
  // It contains objects named with different parts of the select input
  // Example :
  // control: (provided, state) => {
  //     return {
  //         ...provided,
  //         ...controlCustomStyle // if there is custom style, we add it
  //     };
  // },
  // // Container style
  // container: (provided, state) => {
  //     return {
  //         ...provided,
  //         ...containerCustomStyle // if there is custom style, we add it
  //     };
  // },
  customStyle?: StylesConfig<OptionType, true>
}

/**
 * This select input is controlled, it means that we can control its value
 * @param options Options that sould be displayed in the select input
 * @param onChangeCallback Callback function that is called on change
 * @param isDisabled boolean to disable or enable the input
 * @param placeholder placeholder to display
 * @param defaultValue default value (preselected value)
 * @param value current value of the input
 * @constructor
 */
export function SelectMultiInputSearchControlled({
  options,
  onChangeCallback,
  isDisabled,
  placeholder,
  value,
  customStyle,
  onMenuCloseCallback,
  onInputChangeCallback,
  noOptionsMessage = undefined,
}: Props) {
  const defaultFiltersStyle: StylesConfig<OptionType, true> = {
    indicatorSeparator: (provided: CSSObjectWithLabel) => ({ ...provided, display: 'none' }),
    container: (provided: CSSObjectWithLabel) => ({
      ...provided,
      fontSize: '0.8rem',
      width: 'max-content',
    }),
    dropdownIndicator: (provided: CSSObjectWithLabel) => ({ ...provided, padding: '0px 2px' }),
    valueContainer: (provided: CSSObjectWithLabel) => ({ ...provided, padding: '0px 2px' }),
    menu: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999, width: 'max-content' }),
  }

  return (
    <Select
      value={value || null}
      onChange={(option: readonly OptionType[], actionMeta: ActionMeta<OptionType>) => {
        if (onChangeCallback) {
          onChangeCallback(option.length ? (option as OptionType[]) : null)
        }
      }}
      onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
        if (onInputChangeCallback) {
          onInputChangeCallback(newValue, actionMeta)
        }
      }}
      closeMenuOnSelect={false}
      isMulti
      placeholder={placeholder}
      isDisabled={isDisabled}
      isSearchable
      isClearable
      onMenuClose={onMenuCloseCallback}
      options={options}
      noOptionsMessage={noOptionsMessage ? () => <div>{noOptionsMessage}</div> : undefined}
      styles={{
        ...defaultFiltersStyle,
        ...customStyle,
      }}
    />
  )
}
