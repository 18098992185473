import React, { useContext, useMemo, useState } from 'react'
import Modal from 'react-modal'
import ReactTooltip from 'react-tooltip'
import { MRT_ColumnDef } from 'material-react-table'
import ConfirmModal from '../../../../components/modal/ConfirmModal'
import Title from '../../../../components/text/Title'
import http from '../../../../services/http'
import MaterialTable from '../../../../components/table/MaterialTable/MaterialTable'
import { OptionType } from '../../../../types/OptionType'
import { ThemeContext } from '../../../../context/ThemeProvider'
import ButtonWithIcon from '../../../../components/button/ButtonWithIcon'
import { AllUpdates } from '../ExistingServices.types'
import HandleSCMCodeIsinCell from './SaveScopeModalComponents/HandleSCMCodeIsinCell'
import HandleSCMShareClassCell from './SaveScopeModalComponents/HandleSCMShareClassCell'
import HandleSCMPeriodicityCell from './SaveScopeModalComponents/HandleSCMPeriodicityCell'
import HandleSCMSubfundCell from './SaveScopeModalComponents/HandleSCMSubfundCell'

type Props = {
  isOpen: boolean
  closeModal: any
  selectedService: OptionType | null
  changedScope: AllUpdates[]
}
type Body = {
  reportId: number
  reportName: string
} & AllUpdates

function SaveScopeModal({ isOpen, closeModal, selectedService, changedScope }: Props) {
  // Manage context
  const { mainColorLight } = useContext(ThemeContext)

  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false)
  //  Define initial config for the table
  const [initialState] = useState({
    density: 'compact',
  })

  const columns = useMemo<MRT_ColumnDef<AllUpdates>[]>(() => {
    return [
      {
        header: 'Fund',
        accessorKey: 'fundName',
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        header: 'Subfund',
        accessorKey: 'subfundName',
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: options => HandleSCMSubfundCell(options),
      },
      {
        header: 'Code Isin',
        accessorKey: 'codeIsin',
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: options => HandleSCMCodeIsinCell(options),
      },
      {
        header: 'Shareclass',
        accessorKey: 'shareclassName',
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: options => HandleSCMShareClassCell(options),
      },
      {
        header: 'Action type',
        accessorKey: 'changeStatus',
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        header: 'Periodicity',
        accessorKey: 'frequency',
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: options => HandleSCMPeriodicityCell(options),
      },
    ]
  }, [changedScope])

  const save = () => {
    const body: Body[] = changedScope.map((e): any => {
      return {
        ...e,
        reportId: selectedService?.value,
        reportName: selectedService?.label,
      }
    })
    http.post('/scopes/change', body).then(() => {
      closeModal(true)
      setIsConfirmModalOpen(false)
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={() => {
        closeModal(false)
      }}
      style={{
        overlay: {
          zIndex: 2,
        },
        content: {
          top: '25%',
          left: '30%',
          transform: 'translate(-20%, -15%)',
        },
      }}>
      <ConfirmModal
        isModalOpenned={isConfirmModalOpen}
        actionCallback={() => {
          save()
          setIsConfirmModalOpen(false)
        }}
        closeModalHandler={() => setIsConfirmModalOpen(false)}
        text='An email will be sent to your managers regarding this request'
      />
      <ReactTooltip />

      <Title center>Change to the {selectedService?.label ?? ''} scope</Title>
      <br />
      <MaterialTable
        columns={columns}
        data={changedScope}
        initialState={initialState}
        enableSelectAll={false}
        isLoading={false}
        allowPagination={false}
      />
      <div style={{ position: 'sticky', bottom: 0, left: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonWithIcon
            text='Apply'
            onClick={() => setIsConfirmModalOpen(true)}
            customButtonStyle={{ backgroundColor: mainColorLight }}
          />
          <ButtonWithIcon text='Close' onClick={() => closeModal(false)} />
        </div>
      </div>
    </Modal>
  )
}

export default SaveScopeModal
