import React, { useContext } from 'react';
import styled from 'styled-components';
import { COLOR, MOBILE_MEDIA } from '../../constants/theme';
import { ThemeContext } from '../../context/ThemeProvider';

type Commmon = {
  setter: any;
  name?: string;
  onEnterCallback?: any;
  style?: any;
};

type Radio = Commmon & {
  type: 'radio';
  data: boolean;
  label: string;
};

type Checkbox = Commmon & {
  type: 'checkbox';
  checked?: boolean;
  defaultChecked?: boolean;
  label?: string;
  color?: string;
  borderColored?: boolean;
  disabled?: boolean;
  setter: any;
};

type Text = Commmon & {
  type: 'text';
  data: string;
  placeholder?: string;
  invalid?: boolean;
};

type Password = Commmon & {
  type: 'password';
  data: string;
  placeholder?: string;
};
type Select = Commmon & {
  type: 'select';
  items: any[];
  selector?: {
    keySelector: string;
    libelleSelector: string;
  };
  defaultItem?: any;
  hasDefaultItemEmpty?: boolean;
  defaultItemEmptyPlaceHolder?: string;
  disabled?: boolean;
  selected?: any;
  draft?: boolean;
};

function Input(input: Radio | Text | Password | Select | Checkbox) {
  const [isFocused, setIsFocused] = React.useState(false);
  const { mainColor } = useContext(ThemeContext);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    if (input.type !== 'select') return;

    if (input.selector) {
      const item = input.items.find(i => {
        // e.target.value is a string and item selector can be number
        // need an improved type handling ?
        return String(i[input?.selector?.keySelector ?? 0]) === value;
      });
      input.setter(item);
    } else {
      input.setter(value);
    }
  };

  return (
    // <Container>
    <>
      {input.type === 'password' && (
        <SInput
          name={input.name}
          type={input.type}
          value={input.data}
          onChange={input.setter}
          placeholder={input.placeholder}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              input.onEnterCallback();
            }
          }}
        />
      )}
      {input.type === 'select' && (
        <Select
          // inde
          value={
            (input.selector?.keySelector &&
              input.selected &&
              input.selected[input.selector?.keySelector]) ??
            input.selected
          }
          disabled={input.disabled}
          onChange={handleChange}
          draft={input.draft}
          mainColor={mainColor}
          defaultValue={
            input.defaultItem && input.selector
              ? input.defaultItem[input.selector.keySelector]
              : input.defaultItem
          }>
          {input.hasDefaultItemEmpty && (
            <option value=''>{input.defaultItemEmptyPlaceHolder}</option>
          )}
          {input.items.map(item => {
            return (
              <option
                key={`${item}`}
                value={input.selector ? item[input.selector.keySelector] : item}>
                {input.selector ? item[input.selector.libelleSelector] : item}
              </option>
            );
          })}
        </Select>
      )}
      {input.type === 'text' && (
        <TextInputContainer>
          <SInput
            name={input.name}
            type={input.type}
            value={input.data}
            onChange={input.setter}
            placeholder={input.placeholder}
            invalid={input.invalid}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                input.onEnterCallback();
              }
            }}
          />
          {!isFocused && input.invalid && <div>*Email invalid</div>}
        </TextInputContainer>
      )}
      {input.type === 'radio' && (
        <>
          <input
            checked={input.data}
            id={input.label + input.name}
            name={input.name}
            type={input.type}
            onChange={input.setter}
            style={{ cursor: 'pointer' }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                input.onEnterCallback();
              }
            }}
          />
          <label htmlFor={input.label + input.name}>{input.label}</label>
        </>
      )}

      {
        input.type === 'checkbox' && (
          <Checkbox color={mainColor} style={input.style}>
            <input
              type='checkbox'
              checked={input.checked ?? undefined}
              defaultChecked={input.defaultChecked ?? undefined}
              onChange={input.setter}
              disabled={input.disabled}
            />
            <div className='checkmark' />
          </Checkbox>
        )
        /* <CheckboxInputContainer>
                            <input
                            checked={input.checked}
                            id={input.label ? input.label + input.name : input.name}
                            name={input.name}
                            type={input.type}
                            onChange={input.setter}
                            style={{cursor: 'pointer'}}
                            />
                            <span className='mark'/>
                            {
                                input.label ?
                                <label htmlFor={input.label+input.name}>{input.label}</label>
                                :<></>
                            }
                        </CheckboxInputContainer> */
      }
    </>
    // </Container>
  );
}

export default Input;

const Checkbox = styled.label<{ color?: string; borderColored?: boolean }>`
  height: 18px;
  width: 18px;
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: auto;

  /* Hide the browser's default checkbox */

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    background-color: white;
    border: 2px solid;
    ${({ color, borderColored }) => `border-color: ${borderColored && color ? color : COLOR.GRAY};`}
    box-sizing: border-box;
  }

  /* On mouse-over, add a grey background color */

  :hover input ~ .checkmark {
    ${({ color, borderColored }) =>
      `border-color: ${borderColored && color ? color : COLOR.BLACK};`}
  }

  /* When the checkbox is checked, add a blue background */

  input:checked ~ .checkmark {
    background-color: ${({ color }) => color ?? COLOR.MAIN};
    border-color: ${({ color }) => color ?? COLOR.MAIN};
  }

  /* Create the checkmark/indicator (hidden when not checked) */

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */

  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */

  .checkmark:after {
    left: 4px;
    top: 0px;
    width: 3px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
  }
`;
// const Container = styled.div`
// `

const TextInputContainer = styled.div`
  > div {
    font-size: 12px;
    color: ${COLOR.RED};
  }
`;

const SInput = styled.input<{ invalid?: boolean }>`
  border-radius: 4px;
  width: 300px;
  height: 40px;
  padding: 8px;
  border: 1px solid ${({ invalid }) => (invalid ? COLOR.RED : COLOR.BLACK)};
  box-sizing: border-box;

  /* ${({ invalid }) => invalid && `background-color: ${COLOR.RED}`} */

  ${MOBILE_MEDIA} {
    width: 100%;
    max-width: 300px;
  }
`;

const Select = styled.select<{ draft?: boolean; mainColor: string }>`
  height: 36px;
  text-align: center;
  font-size: 16px;
  border-radius: 4px;
  min-width: 120px;
  ${({ draft, mainColor }) => draft && `border-color: ${mainColor}; color: ${mainColor};`}
`;
