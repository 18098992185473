import { MRT_ColumnDef } from 'material-react-table'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { SelectInputSearchControlled } from '../../../components/input/SelectInputSearchControlled'
import MaterialTable from '../../../components/table/MaterialTable/MaterialTable'
import { ThemeContext } from '../../../context/ThemeProvider'
import { formatDate } from '../../../helpers/helpers'
import http from '../../../services/http'
import { OptionType } from '../../../types/OptionType'
import { filtersStyle } from '../../../components/table/MaterialTable/ColumnUtilities'
import { InvoiceClean } from './Invoices.types'
import HandleDateAggregatedCell from './components/HandleDateAggregatedCell'
import HandleDownloadCell from './components/HandleDownloadCell'

function Invoices() {
  localStorage.setItem('localState', 'invoices')
  // Manage context
  const { mainColor } = useContext(ThemeContext)
  // Manage data
  const [rawData, setRawData] = useState<InvoiceClean[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // Manage filters
  const [filterInvoiceTypeName, setFilterInvoiceTypeName] = useState<OptionType | null>(null)
  //  Manage all filters options
  const [allInvoiceTypeName, setAllInvoiceTypeName] = useState<OptionType[]>([])
  //  Define initial config for the table
  const [initialState] = useState({
    grouping: ['year', 'month'],
    columnVisibility: {
      year: false,
      month: false,
    },
    density: 'compact',
  })

  // @ts-ignore
  const columns = useMemo<MRT_ColumnDef<InvoiceClean>[]>(() => {
    return [
      {
        header: 'Year',
        accessorKey: 'year',
      },
      {
        header: 'Month',
        accessorKey: 'month',
      },
      {
        header: 'Date',
        accessorKey: 'date',
        enableColumnActions: false,
        enableColumnFilter: false,
        AggregatedCell: options => HandleDateAggregatedCell(options),
      },
      {
        header: 'Invoice number',
        accessorKey: 'invoiceNumber',
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        header: 'Invoice type',
        accessorKey: 'invoicingTypeName',
        enableColumnActions: false,
        enableFilterMatchHighlighting: false,
      },
      {
        header: 'Download',
        accessorKey: 'mrt-row-download',
        columnDefType: 'display',
        Cell: options => HandleDownloadCell(options, mainColor),
      },
    ]
  }, [])

  //Definitions for the filter bar
  const renderTopToolbar = (props: any) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', padding: '5px 5px' }}>
        <SelectInputSearchControlled
          customStyle={filtersStyle}
          value={filterInvoiceTypeName}
          options={allInvoiceTypeName}
          placeholder='Select an invoice type'
          onChangeCallback={option => {
            setFilterInvoiceTypeName(option)
            props.table.setColumnFilters(() => {
              return [{ id: 'invoicingTypeName', value: option?.value }]
            })
          }}
        />
      </div>
    )
  }

  useEffect(() => {
    http
      .get('/invoices?page=0')
      .then(resp => {
        const cleanData = resp.data.map((elem: any) => {
          return {
            date: formatDate(elem.date),
            month: formatDate(elem.date).split('/')[1],
            year: formatDate(elem.date).split('/')[2],
            invoiceNumber: elem.invoiceNumber,
            id: elem.id,
            invoicingTypeName: elem.invoicingType.name,
            docName: elem.docName,
            extension: elem.extension,
          }
        })
        setRawData(cleanData)
      })
      .then(() => {
        http.get('/invoices/types').then(resp => {
          setAllInvoiceTypeName(
            resp.data.map((elem: any) => ({ label: elem.name, value: elem.name }))
          )
        })
      })
      .catch(e => {
        console.log(e)
        toast.error('Failed to retrieve invoices')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      <Toaster />
      <MaterialTable
        data={rawData}
        columns={columns}
        enableSelectAll={false}
        initialState={initialState}
        isLoading={isLoading}
        maxHeight={80}
        renderTopToolbar={renderTopToolbar}
      />
    </>
  )
}

export default Invoices
