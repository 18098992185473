import { faDownload } from '@fortawesome/free-solid-svg-icons'
import FileSaver from 'file-saver'
import React, { useCallback } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useCurrentPng } from 'recharts-to-png'
import styled from 'styled-components'
import Icon from '../../../../../components/icon/Icon'
import Title from '../../../../../components/text/Title'

const months = ['January', 'February', 'March']

function TicketNumberOnPeriod() {
  const [getPng, { ref }] = useCurrentPng()

  const handleDownload = useCallback(async () => {
    const png = await getPng()
    if (png) {
      FileSaver.saveAs(png, 'TicketNumberOnPeriod.png')
    }
  }, [getPng])

  const data = [
    {
      id: 1,
      libelle: 'January',
      'New tickets': 7,
      'Pending tickets': 2,
      'Done tickets': 5,
    },
    {
      id: 2,
      libelle: 'February',
      'New tickets': 3,
      'Pending tickets': 0,
      'Done tickets': 5,
    },
    {
      id: 3,
      libelle: 'March',
      'New tickets': 4,
      'Pending tickets': 1,
      'Done tickets': 3,
    },
    {
      id: 4,
      libelle: 'April',
      'New tickets': 5,
      'Pending tickets': 0,
      'Done tickets': 6,
    },
    {
      id: 5,
      libelle: 'May',
      'New tickets': 3,
      'Pending tickets': 1,
      'Done tickets': 2,
    },
    {
      id: 6,
      libelle: 'June',
      'New tickets': 2,
      'Pending tickets': 1,
      'Done tickets': 4,
    },
    {
      id: 7,
      libelle: 'July',
      'New tickets': 6,
      'Pending tickets': 2,
      'Done tickets': 5,
    },
    {
      id: 8,
      libelle: 'August',
      'New tickets': 1,
      'Pending tickets': 0,
      'Done tickets': 3,
    },
    {
      id: 9,
      libelle: 'September',
      'New tickets': 3,
      'Pending tickets': 0,
      'Done tickets': 3,
    },
    {
      id: 10,
      libelle: 'October',
      'New tickets': 5,
      'Pending tickets': 1,
      'Done tickets': 4,
    },
    {
      id: 11,
      libelle: 'November',
      'New tickets': 1,
      'Pending tickets': 1,
      'Done tickets': 1,
    },
    {
      id: 12,
      libelle: 'December',
      'New tickets': 2,
      'Pending tickets': 0,
      'Done tickets': 3,
    },
  ].splice(0, new Date().getMonth() + 1)

  return (
    <Container>
      <TitleContainer>
        <Icon icon={faDownload} onClick={() => handleDownload()} pointer notCentered />
        <Title center>Number of tickets on period</Title>
      </TitleContainer>
      <ResponsiveContainer width='100%' height='80%'>
        <LineChart
          ref={ref}
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='libelle' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type='monotone' dataKey='New tickets' stroke='#FF6700' />
          <Line type='monotone' dataKey='Pending tickets' stroke='#C0C0C0' />
          <Line type='monotone' dataKey='Done tickets' stroke='#004E98' />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default TicketNumberOnPeriod

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
