import {
  faArrowDownAZ,
  faArrowUpAZ,
  faCheck,
  faSort,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons'
import { useContext, useState } from 'react'
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GedContext, SortValue } from '../context/GedProvider'
import ButtonWithIcon from '../../../components/button/ButtonWithIcon'
import * as S from './SortButton.styles'
import { ThemeContext } from '../../../context/ThemeProvider'

const sortValues: SortValue[] = [
  {
    id: 1,
    displayName: 'Report Date',
    databaseName: 'referenceDate',
  },
  {
    id: 2,
    displayName: 'Report Type',
    databaseName: 'reportTypeName',
  },
  {
    id: 3,
    displayName: 'Fund',
    databaseName: 'fundName',
  },
  {
    id: 4,
    displayName: 'Subfund',
    databaseName: 'subfundName',
  },
  {
    id: 5,
    displayName: 'Shareclass name',
    databaseName: 'shareclassName',
  },
  {
    id: 6,
    displayName: 'Code ISIN',
    databaseName: 'codeIsin',
  },
  {
    id: 7,
    displayName: 'Country',
    databaseName: 'countryName',
  },
  {
    id: 8,
    displayName: 'Language',
    databaseName: 'languageName',
  },
]

type Props = {
  reloadData: (currentPage: number) => void
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

function SortButton({ reloadData, setCurrentPage }: Props) {
  const { setSortList, sortList, sortAsc, setSortAsc } = useContext(GedContext)
  const { mainColor } = useContext(ThemeContext)

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [alignment, setAlignment] = useState<string>(sortAsc ? 'left' : 'right')

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState)
  }

  const handleToggle = (value: SortValue) => () => {
    const currentIndex = sortList.indexOf(value)
    const newChecked = [...sortList]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setSortList(newChecked)
  }

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment)
    newAlignment === 'left' ? setSortAsc(true) : setSortAsc(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      <ButtonWithIcon icon={faSort} onClick={toggleMenu} text='Sort' />
      <S.SortMenu isOpen={isMenuOpen}>
        <S.SortValuesList>
          {sortValues.map(value => {
            const labelId = `checkbox-list-label-${value}`

            return (
              <div key={value.id}>
                <S.SortValueButton onClick={handleToggle(value)}>
                  <S.CheckboxContainer>
                    <Checkbox
                      edge='start'
                      checked={sortList.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      style={{ padding: 0, margin: 0 }}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </S.CheckboxContainer>
                  <ListItemText id={labelId} primary={value.displayName} />
                </S.SortValueButton>
              </div>
            )
          })}
        </S.SortValuesList>
        <ToggleButtonGroup size='small' exclusive value={alignment} onChange={handleChange}>
          <ToggleButton
            value='left'
            key='left'
            style={{ display: 'inline-flex', gap: '3px', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faArrowUpAZ} color={alignment === 'left' ? mainColor : ''} />
            <span>Ascending</span>
          </ToggleButton>
          <ToggleButton value='right' key='right'>
            <FontAwesomeIcon icon={faArrowDownAZ} color={alignment === 'right' ? mainColor : ''} />
            <span>Descending</span>
          </ToggleButton>
        </ToggleButtonGroup>
        <S.ApplyButtonContainer>
          <ButtonWithIcon
            icon={faCheck}
            onClick={() => {
              setCurrentPage(0)
              reloadData(0)
            }}
            text='Apply'
          />
        </S.ApplyButtonContainer>
      </S.SortMenu>
    </div>
  )
}

export default SortButton
