import { z } from 'zod'
import http from '../http'
import {
  SinceLastTimeDto,
  SinceLastTimeSchema,
} from '../../types/dtos/ClientUserStatistics/ClientUserStatisticsDto'

export const DocumentsInValidationSchema = z.number()

export type DocumentsInValidationDto = z.infer<typeof DocumentsInValidationSchema>

export const ProductionStatusSchema = z.object({
  idStatus: z.number(),
  statusName: z.string(),
  totalCount: z.number(),
})

export type ProductionStatusDto = z.infer<typeof ProductionStatusSchema>

const DashboardService = {
  getSinceLastTimeStatistics() {
    return http.get<SinceLastTimeDto>('/client-user-statistics/since-last-time').then(resp => {
      SinceLastTimeSchema.parse(resp.data)
      return resp
    })
  },
}

export default DashboardService
