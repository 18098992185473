import React, { useContext } from 'react';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../../context/ThemeProvider';
import Title from '../../../../components/text/Title';
import { LocationState } from '../ReportValidation.types';
import { ROUTES } from '../../../../constants/routes';

export default function GoBack(props: { locationState: LocationState }) {
  const { locationState } = props;
  const { mainColor } = useContext(ThemeContext);
  const navigate = useNavigate();
  return (
    <div style={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', paddingLeft: '3vw' }}>
      <div style={{ cursor: 'pointer', marginRight: 24 }}>
        <FontAwesomeIcon
          icon={faArrowLeftLong}
          size='2x'
          color={mainColor}
          onClick={() => navigate(ROUTES.PRODUCTION_STATUS, { state: 'production_status' })}
        />
      </div>
      <Title>
        {locationState?.reportDateFormatted} - {locationState?.reportTypeName}
      </Title>
    </div>
  );
}
