import React from 'react';
import styled from 'styled-components';
import { MOBILE_MEDIA } from '../constants/theme';

type Props = {
  isTitle?: boolean;
  children: any;
};

function Text({ children, isTitle }: Props) {
  return <Container isTitle={isTitle}>{children}</Container>;
}

export default Text;

const Container = styled.div<Props>`
  ${MOBILE_MEDIA} {
    font-size: ${({ isTitle }) => (isTitle ? 20 : 16)}px;
  }
  font-size: ${({ isTitle }) => (isTitle ? 24 : 16)}px;
`;
