import React, { useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'
import http from '../../../services/http'
import { ThemeContext } from '../../../context/ThemeProvider'
import { OptionType } from '../../../types/OptionType'
import MaterialTable from '../../../components/table/MaterialTable/MaterialTable'
import {
  HandleDefaultAggregatedCell,
  HandleDefaultCodeIsinCell,
  HandleMuiTableBodyCellPropsLevels,
  RenderDefaultTopToolBar,
} from '../../../components/table/MaterialTable/ColumnUtilities'
import { Delivery, DeliveryPlanningProps } from './DeliveryPlanning.types'
import HandleStatusCell from './components/HandleStatusCell'
import HandleEstimatedDeadlineCell from './components/HandleEstimatedDeadlineCell'
import { ProductionService } from '../../../services/production/ProductionService'
import { DeliveryEstimationDto } from '../../../types/dtos/production/productionDto'

function DeliveryPlanning(props: DeliveryPlanningProps) {
  // localStorage.setItem('localState', 'delivery')
  const { reportDate, reportLevel, reportTypeId, productionStatusId } = props
  //  Manage context
  const { mainColorLight, mainColorVeryLight } = useContext(ThemeContext)
  // Manage data
  const [rawData, setRawData] = useState<DeliveryEstimationDto[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // Manage filters
  const [filterFund, setFilterFund] = useState<OptionType | null>(null)
  const [filterSubfund, setFilterSubfund] = useState<OptionType | null>(null)
  const [filterCodeIsin, setFilterCodeIsin] = useState<OptionType | null>(null)
  //  Manage all filters options
  const [allFund, setAllFund] = useState<OptionType[]>([])
  const [allSubfund, setAllSubfund] = useState<OptionType[]>([])
  const [allCodeIsin, setAllCodeIsin] = useState<OptionType[]>([])
  // Define initial state
  const [initialCodeIsinState] = useState({
    grouping: ['fundName', 'subfundName'],
    columnVisibility: {
      fundName: false,
      subfundName: false,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
    ],
    density: 'compact',
    columnOrder: [
      'mrt-row-expand',
      'codeIsin',
      'mrt-row-dataSources',
      'mrt-row-inProduction',
      'mrt-row-control',
      // 'mrt-row-clientValidationDeadline',
      'mrt-row-estimatedDeadline',
    ],
  })
  const [initialSubfundState] = useState({
    grouping: ['fundName'],
    columnVisibility: {
      fundName: false,
    },
    sorting: [{ id: 'fundName', desc: false }],
    density: 'compact',
  })

  const createMainColumns = () => {
    let mainColumns: MRT_ColumnDef<DeliveryEstimationDto>[] = []
    switch (reportLevel) {
      case 3:
        mainColumns = [
          {
            header: 'Fund',
            accessorKey: 'fundName',
            enableSorting: true,
            enableGlobalFilter: true,
            enableFilterMatchHighlighting: false,
            filterFn: 'equalsString',
          },
          {
            header: '',
            accessorKey: 'subfundName',
            enableSorting: false,
            enableColumnActions: false,
            enableColumnFilter: false,
            enableFilterMatchHighlighting: false,
            filterFn: 'equalsString',
            AggregatedCell: (options: any) => HandleDefaultAggregatedCell(options),
            muiTableBodyCellProps: (options: any) => {
              let backgroundColor = mainColorVeryLight
              let paddingLeft = '25px'
              if (options.row.groupingColumnId === 'fundName') {
                backgroundColor = mainColorLight
                paddingLeft = '0px'
              }
              return {
                align: 'left',
                sx: {
                  padding: '0px',
                  paddingLeft,
                  backgroundColor,
                  boxShadow: 'none',
                },
              }
            },
          },
        ]
        break
      default:
        mainColumns = [
          {
            header: 'Fund',
            accessorKey: 'fundName',
            enableSorting: true,
            enableGlobalFilter: true,
            enableFilterMatchHighlighting: false,
            filterFn: 'equalsString',
          },
          {
            header: 'Subfund',
            accessorKey: 'subfundName',
            enableSorting: false,
            enableColumnActions: false,
            enableColumnFilter: false,
            enableFilterMatchHighlighting: false,
            filterFn: 'equalsString',
          },
          {
            header: '',
            accessorKey: 'codeIsin',
            enableSorting: false,
            enableColumnActions: false,
            enableColumnFilter: false,
            enableFilterMatchHighlighting: false,
            filterFn: 'equalsString',
            Cell: (options: any) => HandleDefaultCodeIsinCell(options),
            AggregatedCell: (options: any) => HandleDefaultAggregatedCell(options),
            muiTableBodyCellProps: (options: any) =>
              HandleMuiTableBodyCellPropsLevels(options, mainColorLight, mainColorVeryLight),
          },
        ]
        break
    }
    return mainColumns
  }

  // @ts-ignore
  const columns = useMemo<MRT_ColumnDef<DeliveryEstimationDto>[]>(() => {
    return [
      ...createMainColumns(),
      {
        header: 'Data sources',
        accessorKey: 'mrt-row-dataSources',
        columnDefType: 'display',
        Cell: options => HandleStatusCell(options, 1),
      },
      {
        header: 'In production',
        accessorKey: 'mrt-row-inProduction',
        columnDefType: 'display',
        Cell: options => HandleStatusCell(options, 2),
      },
      {
        header: 'Control',
        accessorKey: 'mrt-row-control',
        columnDefType: 'display',
        Cell: options => HandleStatusCell(options, 3),
      },
      {
        header: 'Estimated deadline',
        accessorKey: 'mrt-row-estimatedDeadline',
        columnDefType: 'display',
        Cell: options => HandleEstimatedDeadlineCell(options),
      },
    ]
  }, [rawData])

  const renderTopToolbar = (options: { table: MRT_TableInstance }) => {
    const { table } = options
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <RenderDefaultTopToolBar
          table={table}
          isLoading={isLoading}
          reportLevel={reportLevel === 3 ? 'Subfund' : 'CodeIsin'}
          filterFund={filterFund}
          filterSubfund={filterSubfund}
          filterCodeIsin={filterCodeIsin}
          setFilterFund={setFilterFund}
          setFilterSubfund={setFilterSubfund}
          setFilterCodeIsin={setFilterCodeIsin}
          allFund={allFund}
          allSubfund={allSubfund}
          allCodeIsin={allCodeIsin}
        />
      </div>
    )
  }

  useEffect(() => {
    //for fund
    const rawFund = rawData
      .map((elem: DeliveryEstimationDto) => elem.fundName)
      .filter(elem => elem)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map(elem => ({ label: elem as string, value: elem as string }))

    setAllFund(rawFund)
  }, [isLoading])
  useEffect(() => {
    //for subfund
    const rawsubFund = rawData
      .filter((elem: DeliveryEstimationDto) =>
        filterFund?.label ? elem.fundName === filterFund.label : true
      )
      .map((elem: DeliveryEstimationDto) => elem.subfundName)
      .filter(elem => elem)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map(elem => ({ label: elem as string, value: elem as string }))
    setAllSubfund(rawsubFund)
  }, [isLoading, filterFund])
  useEffect(() => {
    //for codeIsin
    const rawCodeIsin = rawData
      .filter((elem: DeliveryEstimationDto) =>
        filterFund?.value ? elem.fundName === filterFund.label : true
      )
      .filter((elem: DeliveryEstimationDto) =>
        filterSubfund?.value ? elem.subfundName === filterSubfund.label : true
      )
      .map((elem: DeliveryEstimationDto) => elem.codeIsin)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map(elem => ({ label: elem as string, value: elem as string }))
    setAllCodeIsin(rawCodeIsin)
  }, [isLoading, filterFund, filterSubfund])

  useEffect(() => {
    setIsLoading(true)

    ProductionService.getDeliveryEstimationDeadline(
      reportDate,
      reportTypeId,
      productionStatusId || undefined
    )
      .then(resp => {
        setRawData(resp.data)
        const rawFund = resp.data
          .map((elem: DeliveryEstimationDto) => elem.fundName)
          .filter((elem: string, index: number, array: string[]) => array.indexOf(elem) === index)
          .sort()
          .map((elem: string) => ({ label: elem, value: elem }))
        setAllFund(rawFund)
        setFilterSubfund(null)
        setFilterCodeIsin(null)
      })
      .catch(e => {
        console.log(e)
        toast.error('There was an error while loading the data. Please try again later.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      {reportLevel === 4 && (
        <MaterialTable
          data={rawData}
          columns={columns}
          initialState={initialCodeIsinState}
          isLoading={isLoading}
          enableSelectAll={false}
          maxHeight={70}
          renderTopToolbar={renderTopToolbar}
        />
      )}
      {reportLevel === 3 && (
        <MaterialTable
          data={rawData}
          columns={columns}
          initialState={initialSubfundState}
          isLoading={isLoading}
          enableSelectAll={false}
          maxHeight={70}
          renderTopToolbar={renderTopToolbar}
        />
      )}
    </>
  )
}

export default DeliveryPlanning
