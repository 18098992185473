import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { SingleSignOnService } from '../../services/SingleSignOnService/SingleSignOnService'

function Logout() {
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('access_token')
    SingleSignOnService.logout()
    navigate(ROUTES.LOGIN, {
      replace: true,
    })
  })

  return (
    <div>
      <h1>Logout</h1>
    </div>
  )
}

export default Logout
