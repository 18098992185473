import React from 'react';
import { MRT_Cell, MRT_Row } from 'material-react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { DeliverableValidationDto } from '../../../../types/dtos/FollowupDto';
import { COLOR } from '../../../../constants/theme';

export default function HandleStatusCell(
  options: {
    cell: MRT_Cell<DeliverableValidationDto>;
    row: MRT_Row<DeliverableValidationDto>;
  },
  mainColor: string
) {
  const {
    cell,
    row: { original },
  } = options;
  const tooltipMessage = original.clientComment ? `${original.clientComment}` : `No comment`;

  if (cell.getValue()) {
    return <FontAwesomeIcon icon={faCheck} color={COLOR.GREEN} />;
  }
  if (typeof options.cell.getValue() === 'boolean' && !options.cell.getValue()) {
    return (
      <Tooltip title={tooltipMessage} placement='left'>
        <span style={{ display: 'inline-block', width: '40px' }}>
          <FontAwesomeIcon icon={faXmark} color={COLOR.RED} />
        </span>
      </Tooltip>
    );
  }
  return <FontAwesomeIcon icon={faSpinner} color={mainColor} />;
}
