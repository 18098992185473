import styled from 'styled-components';
import { MOBILE_MEDIA } from './constants/theme';

export const AppContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #f4f4f4;
`;

export const ContentContainer = styled.div`
  flex: 1;
  /* display: flex; */
  overflow: auto;
  flex-direction: column;
  /* padding-left: 64px;
  padding-right: 64px;
  padding-top: 32px; */

  ${MOBILE_MEDIA} {
    /* padding: 16px; */
  }
`;
