import React from 'react';
import { ScopeItem } from '../ExistingServices.types';

export default function HeaderForFrequency(rawData: ScopeItem[]) {
  const numberOfSubs = rawData.filter(elem => elem.reporting).length;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        padding: '5px 0',
      }}>
      <div style={{ width: '400px' }}>Periodicity</div>
      <div style={{ width: '400px' }}>Subscribed ({numberOfSubs})</div>
    </div>
  );
}
