import { z } from 'zod';

export const CurrencyDtoSchema = z.object({
  actif: z.boolean(),
  ccyCode: z.string(),
  identificationNumber: z.number(),
  decimals: z.number(),
  ccyName: z.string(),
  id: z.number(),
  lastUpdate: z.string(),
  supprime: z.boolean(),
});

export type CurrencyDto = z.infer<typeof CurrencyDtoSchema>;
