// Create a context

import React, { createContext, useState } from 'react'

type Props = {
  children: React.ReactNode
}

type ContextProps = {
  selectedText: string
  isCommentWindowOpen: boolean
  setIsCommentWindowOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedText: React.Dispatch<React.SetStateAction<string>>
}

//@ts-ignore
export const NarrativeContext = createContext<ContextProps>(null)

export function NarrativeValidationProvider({ children }: Props) {
  const [isCommentWindowOpen, setIsCommentWindowOpen] = useState<boolean>(false)
  const [selectedText, setSelectedText] = useState<string>('')

  return (
    <NarrativeContext.Provider
      value={{ isCommentWindowOpen, selectedText, setIsCommentWindowOpen, setSelectedText }}>
      {children}
    </NarrativeContext.Provider>
  )
}
