import styled from "styled-components";
import { COLOR } from "../../../constants/theme";

export const ModalBox = styled.div`
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px 30px;
  border-radius: 5px;
  color: #4b525a;
  width: 40vw;
`;

export const ModalBody = styled.div`
  height: 90%;
  text-align: center;
`;
export const Title = styled.div`
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 1.2em;
`;

export const CloseButton = styled.div`
  background-color: ${COLOR.PASTEL_LIGHT_RED};
  padding: 5px 12px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  gap: 5px;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;

export const DownloadBtn = styled.div`
  background-color: ${COLOR.PASTEL_LIGHT_GREEN};
  padding: 5px 12px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  gap: 5px;
  align-items: center;
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 50%;
  margin: auto;
`;
