import React, { CSSProperties, useContext } from 'react'
import styled from 'styled-components'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { GenericBtnWithIcon } from '../../commonStyles'
import { ThemeContext } from '../../context/ThemeProvider'

type Props = {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  text?: string
  icon?: IconDefinition
  iconColor?: string
  iconSize?: SizeProp
  textToLeft?: boolean
  customButtonStyle?: CSSProperties
  customIconStyle?: CSSProperties
  customTextStyle?: CSSProperties
}

const ButtonContainer = styled(GenericBtnWithIcon)<{
  onClick?: React.MouseEventHandler<HTMLDivElement>
}>`
  font-size: 15px;
  ${({ onClick }) => !onClick && 'cursor: default;'}
`

export default function ButtonWithIcon({
  onClick,
  text,
  icon,
  iconColor,
  textToLeft,
  customButtonStyle,
  customIconStyle,
  customTextStyle,
  iconSize,
}: Props) {
  const { mainColor } = useContext(ThemeContext)

  return (
    <ButtonContainer onClick={onClick} style={{ ...customButtonStyle }}>
      {text && textToLeft && <span style={{ ...customTextStyle }}>{text}</span>}
      {icon && (
        <FontAwesomeIcon
          style={{ ...customIconStyle }}
          icon={icon}
          color={iconColor || mainColor}
          size={iconSize}
        />
      )}
      {text && !textToLeft && <span style={{ ...customTextStyle }}>{text}</span>}
    </ButtonContainer>
  )
}
