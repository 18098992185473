import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Loader from '../../../components/loader/Loader'
import { DashboardContext } from '../../../context/DashboardProvider'
import { ThemeContext } from '../../../context/ThemeProvider'
import { decodeHtmlEntity } from '../../../helpers/helpers'
import { BigText, Text, StyledExternalLink, Row } from './Global.styles'

function NewsWidget() {
  const { mainColor } = useContext(ThemeContext)
  const { newsFeed } = useContext(DashboardContext)

  const [currentIndex, setCurrentIndex] = useState<[number, number]>([0, 0])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (newsFeed !== undefined) {
      setIsLoading(false)
      setCurrentIndex([0, newsFeed?.length - 1])
    } else {
      setIsLoading(false)
    }
  }, [newsFeed])
  useEffect(() => {
    if (!isLoading && newsFeed) {
      const interval = setInterval(() => {
        setCurrentIndex(prev => {
          if (prev[0] === prev[1]) {
            return [0, prev[1]]
          }
          return [prev[0] + 1, prev[1]]
        })
      }, 10000)
      return () => clearInterval(interval)
    }
  }, [isLoading, currentIndex]) // reset timer after manually change

  if (isLoading) {
    return <Loader />
  }

  if (newsFeed === undefined) {
    return (
      <Row>
        <BigText>Unable to retrieve information</BigText>
      </Row>
    )
  }

  return (
    <>
      <NewsContainer>
        <Text>{new Date(newsFeed[currentIndex[0]].pubDate).toLocaleDateString('en-GB')}</Text>
        <BigText>{decodeHtmlEntity(newsFeed[currentIndex[0]].title)}</BigText>
        <StyledExternalLink href={newsFeed[currentIndex[0]].link} target='_blank' color={mainColor}>
          {'More information >'}
        </StyledExternalLink>
      </NewsContainer>
      <ButtonContainer>
        <FontAwesomeIcon
          icon={faChevronLeft}
          size='2x'
          color={mainColor}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            setCurrentIndex(prev => {
              if (prev[0] === 0) {
                return [prev[1], prev[1]]
              }
              return [prev[0] - 1, prev[1]]
            })
          }
        />
        <Text style={{ width: '50px' }}>
          {currentIndex[0] + 1}/{currentIndex[1] + 1}
        </Text>
        <FontAwesomeIcon
          icon={faChevronRight}
          size='2x'
          color={mainColor}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            setCurrentIndex(prev => {
              if (prev[0] === prev[1]) {
                return [0, prev[1]]
              }
              return [prev[0] + 1, prev[1]]
            })
          }
        />
      </ButtonContainer>
    </>
  )
}

export default NewsWidget

const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
  padding: 0 10px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`
