import { z } from 'zod'
import http from '../http'

const frequencyDec = z.object({
  frequencyId: z.number(),
  keyword: z.string(),
  libelle: z.string(),
})

export const subscribedReportsSchema = z.object({
  clientSubscribedReportTypeId: z.number(),
  defaultFrequencyDec: frequencyDec,
  frequencyDecList: frequencyDec.array(),
  reportTypeId: z.number(),
  reportTypeLevel: z.number(),
  reportTypeName: z.string(),
  reportTypeShortName: z.string().nullable(),
})

export type SubscribedReportsDto = z.infer<typeof subscribedReportsSchema>

const SubscribedReportsService = {
  getSubscribedReports() {
    return http.get<SubscribedReportsDto[]>('/subscribed-reports').then(resp => {
      z.array(subscribedReportsSchema).parse(resp.data)
      return resp
    })
  },
}

export default SubscribedReportsService
