import React, { useContext, useEffect, useState } from 'react'
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  sidebarClasses,
  /* useProSidebar, */
} from 'react-pro-sidebar'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faArrowRightFromBracket,
  faBriefcase,
  faChartPie,
  faClipboardList,
  faEnvelope,
  faFileCircleCheck,
  faFileInvoiceDollar,
  faFileLines,
  faIndustry,
  faPhone,
  faHouse,
  faListCheck,
  faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from '@mui/material'
import ReactCountryFlag from 'react-country-flag'
import parsePhoneNumber from 'libphonenumber-js'
import { UserContext } from '../../context/UserProvider'
import { ThemeContext } from '../../context/ThemeProvider'
import { MODULES_LIST, ROLES } from '../../constants/constants'
import { ROUTES } from '../../constants/routes'
import { COLOR } from '../../constants/theme'
import * as S from './SideBar.styles'

export function HasAccessToModule({ children, module }: { children: JSX.Element; module: string }) {
  const { user } = useContext(UserContext)

  if (
    module &&
    !user?.moduleAccessList.map(module => module.name.toUpperCase()).includes(module.toUpperCase())
  ) {
    return <></>
  }

  return children
}

function SideBar() {
  // const { collapseSidebar, collapsed } = useProSidebar();
  const location = useLocation()
  const { user, logout } = useContext(UserContext)
  const { mainColor, mainColorLight, mainColorVeryLight } = useContext(ThemeContext)
  // const { state } = useLocation()
  const [locationState, setLocationState] = useState<string | null>(
    localStorage.getItem('localState')
  )
  const [openedMenu, setOpenedMenu] = useState<string | null>(null)
  useEffect(() => {
    setLocationState(localStorage.getItem('localState'))
  }, [location, locationState])

  const formattedCrmPhone = parsePhoneNumber(user?.crmMobile ?? '')
  const formattedCrmBackupPhone = parsePhoneNumber(user?.backupCrmMobile ?? '')

  return (
    <Sidebar
      collapsedWidth='60px'
      // defaultCollapsed
      rootStyles={{
        height: '100vh',
        width: '260px',
        fontSize: '14px',
        backgroundColor: COLOR.WHITE,
        [`.${sidebarClasses.container}`]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}>
      <Menu
        menuItemStyles={{
          button: options => {
            const baseStyle = {
              height: '40px',
              padding: '0px 15px',
            }
            if (options.level === 0) {
              if (options.active) {
                return {
                  ...baseStyle,
                  borderRadius: '10px 10px 0px 0px',
                  color: COLOR.WHITE,
                  backgroundColor: mainColor,
                  '&:hover': {
                    color: COLOR.WHITE,
                    backgroundColor: mainColor,
                  },
                }
              }
            }
            if (options.level === 1) {
              if (options.active) {
                return {
                  ...baseStyle,
                  backgroundColor: mainColorLight,
                  '&:hover': {
                    color: COLOR.BLACK,
                    backgroundColor: mainColorLight,
                  },
                }
              }
            }
            return {
              ...baseStyle,
              '&:hover': {
                color: 'black',
                backgroundColor: COLOR.GRAYLIGHT,
              },
            }
          },
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            height: '55px',
            padding: '15px 0px',
          }}>
          <img
            // src={collapsed ? '/img/ao-logo-min.png' : '/AO-logo-full-min.png'}
            src={user?.hasParent ? (user.parentLogoUrl as string) : '/AO-logo-full-min.png'}
            alt='AlphaOmega logo'
            // onClick={() => {
            //   collapseSidebar()
            // }}
          />
        </div>
        <MenuItem
          icon={<FontAwesomeIcon icon={faHouse} />}
          component={<Link to={ROUTES.DASHBOARD} state='dashboard' />}
          active={['dashboard'].includes(locationState as string)}
          onClick={() => {
            setOpenedMenu('dashboard')
            setLocationState('dashboard')
          }}>
          Dashboard
        </MenuItem>
        <HasAccessToModule module={MODULES_LIST.PRODUCTION_STATUS}>
          <MenuItem
            icon={<FontAwesomeIcon icon={faIndustry} />}
            component={<Link to={ROUTES.PRODUCTION_STATUS} state='production_status' />}
            active={['production_status'].includes(locationState as string)}
            onClick={() => {
              setOpenedMenu('production_status')
              setLocationState('production_status')
            }}>
            Production Status
          </MenuItem>
        </HasAccessToModule>
        {user?.clientId === 7 && (
          <HasAccessToModule module={MODULES_LIST.PORTFOLIO}>
            <MenuItem
              icon={<FontAwesomeIcon icon={faBriefcase} />}
              component={<Link to={ROUTES.PORTFOLIO} state='portfolio' />}
              active={['portfolio'].includes(locationState as string)}
              rootStyles={{
                borderRadius: '10px',
                backgroundColor: ['portfolio'].includes(locationState as string)
                  ? mainColor
                  : undefined,
                color: ['portfolio'].includes(locationState as string) ? COLOR.WHITE : COLOR.BLACK,
              }}
              onClick={() => {
                setOpenedMenu('portfolio')
                setLocationState('portfolio')
              }}>
              Portfolio
            </MenuItem>
          </HasAccessToModule>
        )}
        {!user?.hasParent && (
          <HasAccessToModule module={MODULES_LIST.SCOPE_MANAGEMENT}>
            <SubMenu
              icon={<FontAwesomeIcon icon={faClipboardList} />}
              label='Scope Management'
              active={[
                'existing_services',
                'platform',
                'dissemination_list',
                'registration_matrix',
              ].includes(locationState as string)}
              onClick={() => {
                if (openedMenu === 'scope_management') {
                  setOpenedMenu(null)
                } else {
                  setOpenedMenu('scope_management')
                }
              }}
              open={
                !!(
                  openedMenu === 'scope_management' ||
                  [
                    'existing_services',
                    'platform',
                    'dissemination_list',
                    'registration_matrix',
                  ].includes(locationState as string)
                )
              }>
              <MenuItem
                component={
                  <Link
                    style={{
                      paddingLeft: ['existing_services'].includes(locationState as string)
                        ? '53px'
                        : '70px',
                    }}
                    to={ROUTES.SCOPE_MANAGEMENT_EXISTING_SERVICES}
                    state='existing_services'
                  />
                }
                active={['existing_services'].includes(locationState as string)}
                prefix={
                  ['existing_services'].includes(locationState as string) ? (
                    <FontAwesomeIcon icon={faArrowRight} />
                  ) : undefined
                }
                rootStyles={{
                  backgroundColor: (() => {
                    if (
                      [
                        'existing_services',
                        'platform',
                        'dissemination_list',
                        'registration_matrix',
                      ].includes(locationState as string)
                    ) {
                      return mainColorVeryLight
                    }
                    return undefined
                  })(),
                }}
                onClick={() => {
                  setLocationState('existing_services')
                }}>
                Existing Services
              </MenuItem>
              <MenuItem
                component={
                  <Link
                    style={{
                      paddingLeft: ['platform'].includes(locationState as string) ? '53px' : '70px',
                    }}
                    to={ROUTES.SCOPE_MANAGEMENT_PLATFORM}
                    state='platform'
                  />
                }
                active={['platform'].includes(locationState as string)}
                prefix={
                  ['platform'].includes(locationState as string) ? (
                    <FontAwesomeIcon icon={faArrowRight} />
                  ) : undefined
                }
                rootStyles={{
                  backgroundColor: (() => {
                    if (
                      [
                        'existing_services',
                        'platform',
                        'dissemination_list',
                        'registration_matrix',
                      ].includes(locationState as string)
                    ) {
                      return mainColorVeryLight
                    }
                    return undefined
                  })(),
                }}
                onClick={() => {
                  setLocationState('platform')
                }}>
                Platform
              </MenuItem>
              <MenuItem
                component={
                  <Link
                    style={{
                      paddingLeft: ['dissemination_list'].includes(locationState as string)
                        ? '53px'
                        : '70px',
                    }}
                    to={ROUTES.SCOPE_MANAGEMENT_DISSEMINATION_LIST}
                    state='dissemination_list'
                  />
                }
                active={['dissemination_list'].includes(locationState as string)}
                prefix={
                  ['dissemination_list'].includes(locationState as string) ? (
                    <FontAwesomeIcon icon={faArrowRight} />
                  ) : undefined
                }
                rootStyles={{
                  backgroundColor: (() => {
                    if (
                      [
                        'existing_services',
                        'platform',
                        'dissemination_list',
                        'registration_matrix',
                      ].includes(locationState as string)
                    ) {
                      return mainColorVeryLight
                    }
                    return undefined
                  })(),
                }}
                onClick={() => {
                  setLocationState('dissemination_list')
                }}>
                Dissemination List
              </MenuItem>
              <MenuItem
                component={
                  <Link
                    style={{
                      paddingLeft: ['registration_matrix'].includes(locationState as string)
                        ? '53px'
                        : '70px',
                    }}
                    to={ROUTES.SCOPE_MANAGEMENT_REGISTRATION_MATRIX}
                    state='registration_matrix'
                  />
                }
                active={['registration_matrix'].includes(locationState as string)}
                prefix={
                  ['registration_matrix'].includes(locationState as string) ? (
                    <FontAwesomeIcon icon={faArrowRight} />
                  ) : undefined
                }
                rootStyles={{
                  borderRadius: '0px 0px 10px 10px',
                  backgroundColor: (() => {
                    if (
                      [
                        'existing_services',
                        'platform',
                        'dissemination_list',
                        'registration_matrix',
                      ].includes(locationState as string)
                    ) {
                      return mainColorVeryLight
                    }
                    return undefined
                  })(),
                }}
                onClick={() => {
                  setLocationState('registration_matrix')
                }}>
                Registration Matrix
              </MenuItem>
            </SubMenu>
          </HasAccessToModule>
        )}
        {!user?.hasParent && (
          <HasAccessToModule module={MODULES_LIST.DOCUMENT_VALIDATION}>
            <MenuItem
              icon={<FontAwesomeIcon icon={faFileCircleCheck} />}
              component={<Link to={ROUTES.DOCUMENT_VALIDATION} state='document_validation' />}
              active={['document_validation'].includes(locationState as string)}
              onClick={() => {
                setOpenedMenu('document_validation')
                setLocationState('document_validation')
              }}>
              Document Validation
            </MenuItem>
          </HasAccessToModule>
        )}
        {/*{user?.clientId === 10066 && (*/}
        {/*  <MenuItem*/}
        {/*    icon={<FontAwesomeIcon icon={faClipboardCheck} />}*/}
        {/*    component={<Link to={ROUTES.TEMPLATE_VALIDATION} state='template_validation' />}*/}
        {/*    active={['template_validation'].includes(locationState as string)}*/}
        {/*    onClick={() => {*/}
        {/*      setOpenedMenu('template_validation')*/}
        {/*      setLocationState('template_validation')*/}
        {/*    }}>*/}
        {/*    Template validation*/}
        {/*  </MenuItem>*/}
        {/*)}*/}
        {/*{user?.clientId === 10066 && (*/}
        {/*  <MenuItem*/}
        {/*    icon={<FontAwesomeIcon icon={faListCheck} />}*/}
        {/*    component={<Link to={ROUTES.NARRATIVE_VALIDATION} state='narrative_validation' />}*/}
        {/*    active={['narrative_validation'].includes(locationState as string)}*/}
        {/*    onClick={() => {*/}
        {/*      setOpenedMenu('narrative_validation')*/}
        {/*      setLocationState('narrative_validation')*/}
        {/*    }}>*/}
        {/*    Narrative validation*/}
        {/*  </MenuItem>*/}
        {/*)}*/}
        <HasAccessToModule module={MODULES_LIST.LIBRARY}>
          <MenuItem
            icon={<FontAwesomeIcon icon={faFileLines} />}
            component={<Link to={ROUTES.LIBRARY} state='library' />}
            active={['library'].includes(locationState as string)}
            onClick={() => {
              setOpenedMenu('library')
              setLocationState('library')
            }}>
            Library
          </MenuItem>
        </HasAccessToModule>
        {!user?.hasParent && user?.roles.includes(ROLES.INVOICE) && (
          <HasAccessToModule module={MODULES_LIST.INVOICING}>
            <SubMenu
              icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
              active={['invoices', 'data'].includes(locationState as string)}
              label='Invoicing'
              onClick={() => {
                if (openedMenu === 'invoicing') {
                  setOpenedMenu(null)
                } else {
                  setOpenedMenu('invoicing')
                }
              }}
              open={
                !!(
                  openedMenu === 'invoicing' ||
                  ['invoices', 'data'].includes(locationState as string)
                )
              }>
              <MenuItem
                component={
                  <Link
                    style={{
                      paddingLeft: ['invoices'].includes(locationState as string) ? '53px' : '70px',
                    }}
                    to={ROUTES.INVOICING_INVOICES}
                    state='invoices'
                  />
                }
                active={['invoices'].includes(locationState as string)}
                prefix={
                  ['invoices'].includes(locationState as string) ? (
                    <FontAwesomeIcon icon={faArrowRight} />
                  ) : undefined
                }
                rootStyles={{
                  backgroundColor: (() => {
                    if (['invoices', 'data'].includes(locationState as string)) {
                      return mainColorVeryLight
                    }
                    return undefined
                  })(),
                  color: COLOR.BLACK,
                }}
                onClick={() => {
                  setLocationState('invoices')
                }}>
                Invoices
              </MenuItem>
              <MenuItem
                component={
                  <Link
                    style={{
                      paddingLeft: ['data'].includes(locationState as string) ? '53px' : '70px',
                    }}
                    to={ROUTES.INVOICING_DATA}
                    state='data'
                  />
                }
                active={['data'].includes(locationState as string)}
                prefix={
                  ['data'].includes(locationState as string) ? (
                    <FontAwesomeIcon icon={faArrowRight} />
                  ) : undefined
                }
                rootStyles={{
                  borderRadius: '0px 0px 10px 10px',
                  backgroundColor: (() => {
                    if (['invoices', 'data'].includes(locationState as string)) {
                      return mainColorVeryLight
                    }
                    return undefined
                  })(),
                  color: COLOR.BLACK,
                }}
                onClick={() => {
                  setLocationState('data')
                }}>
                Data
              </MenuItem>
            </SubMenu>
          </HasAccessToModule>
        )}
        <HasAccessToModule module={MODULES_LIST.KPI}>
          <MenuItem
            icon={<FontAwesomeIcon icon={faChartPie} />}
            component={<Link to={ROUTES.KPI} state='KPI' />}
            active={['KPI'].includes(locationState as string)}
            onClick={() => {
              setLocationState('KPI')
            }}>
            KPI
          </MenuItem>
        </HasAccessToModule>
      </Menu>

      <Menu
        rootStyles={{
          fontSize: '12px',
        }}
        menuItemStyles={{
          button: {
            height: '40px',
            padding: '0px 10px',
          },
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          {!user?.hasParent && (
            <>
              <S.FloatLine>Help</S.FloatLine>
              <Tooltip title='Click to send email' placement='top'>
                <S.HelpItem
                  onClick={() => {
                    window.location.href = 'mailto:dec@alphaomega.lu'
                  }}>
                  <FontAwesomeIcon icon={faEnvelope} style={{ paddingRight: '5px' }} />
                  dec@alphaomega.lu
                </S.HelpItem>
              </Tooltip>
              <Tooltip title='Click to call and copy' placement='top'>
                <S.HelpItem
                  onClick={() => {
                    window.location.href = 'tel:+3522060344044'
                    navigator.clipboard.writeText('+3522060344044')
                  }}>
                  <ReactCountryFlag countryCode='GB' svg style={{ paddingRight: '5px' }} />
                  +352 206 034 40 44
                </S.HelpItem>
              </Tooltip>
              <Tooltip title='Click to call and copy' placement='top'>
                <S.HelpItem
                  onClick={() => {
                    window.location.href = 'tel:+3522060344055'
                    navigator.clipboard.writeText('+3522060344055')
                  }}>
                  <ReactCountryFlag countryCode='FR' svg style={{ paddingRight: '5px' }} />
                  +352 206 034 40 55
                </S.HelpItem>
              </Tooltip>
              {(user?.crmEmail || formattedCrmPhone) && <S.FloatLine>CRM</S.FloatLine>}
              {user?.crmEmail && (
                <Tooltip title='Click to send email' placement='top'>
                  <S.HelpItem
                    onClick={() => {
                      window.location.href = `mailto:${user?.crmEmail}`
                    }}>
                    <FontAwesomeIcon icon={faEnvelope} style={{ paddingRight: '5px' }} />
                    {user.crmEmail}
                  </S.HelpItem>
                </Tooltip>
              )}
              {formattedCrmPhone && (
                <Tooltip title='Click to call and copy' placement='top'>
                  <S.HelpItem
                    onClick={() => {
                      window.location.href = formattedCrmPhone.getURI()
                      navigator.clipboard.writeText(formattedCrmPhone.formatInternational())
                    }}>
                    <FontAwesomeIcon icon={faPhone} style={{ paddingRight: '5px' }} />
                    {formattedCrmPhone.formatInternational()}
                  </S.HelpItem>
                </Tooltip>
              )}
              {(user?.backupCrmEmail || formattedCrmBackupPhone) && (
                <S.FloatLine>CRM 2</S.FloatLine>
              )}
              {/* TODO - CHANGE TO CORRECT VALUE */}
              {user?.backupCrmEmail && (
                <Tooltip title='Click to send email' placement='top'>
                  <S.HelpItem
                    onClick={() => {
                      window.location.href = `mailto:${user?.backupCrmEmail}`
                    }}>
                    <FontAwesomeIcon icon={faEnvelope} style={{ paddingRight: '5px' }} />
                    {user.backupCrmEmail}
                  </S.HelpItem>
                </Tooltip>
              )}
              {formattedCrmBackupPhone && (
                <Tooltip title='Click to call and copy' placement='top'>
                  <S.HelpItem
                    onClick={() => {
                      window.location.href = formattedCrmBackupPhone.getURI()
                      navigator.clipboard.writeText(formattedCrmBackupPhone.formatInternational())
                    }}>
                    <FontAwesomeIcon icon={faPhone} style={{ paddingRight: '5px' }} />
                    {formattedCrmBackupPhone.formatInternational()}
                  </S.HelpItem>
                </Tooltip>
              )}
            </>
          )}
          <S.FloatLineEmpty />
        </div>
        <MenuItem
          icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
          onClick={() => {
            logout()
          }}>
          Logout
        </MenuItem>
      </Menu>
    </Sidebar>
  )
}

export default SideBar
