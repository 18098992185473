import http from '../http'
import {
  RegistrationMatrixDto,
  RegistrationMatrixDtoSchema,
} from '../../types/dtos/RegistrationMatrix/RegistrationMatrixDto'

const baseUrl = '/registration-matrix'

const RegistrationMatrixService = {
  getAllRegistrationMatrix() {
    return http.get<RegistrationMatrixDto>(`${baseUrl}/all`).then(resp => {
      RegistrationMatrixDtoSchema.parse(resp.data)
      return resp
    })
  },
  exportExcel() {
    return http.get(`${baseUrl}/export`, {
      responseType: 'blob',
    })
  },
}

export default RegistrationMatrixService
