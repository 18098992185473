import styled from 'styled-components'

export const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 10px;
  height: 100%;
  overflow-y: auto;
  padding: 5px;
`

export const DocumentElement = styled.div<{ isLastDocument: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  ${({ isLastDocument }) =>
    isLastDocument &&
    `
    border-bottom: none;
    `}
`

export const FileIconContainer = styled.span<{ color: string }>`
  background-color: ${({ color }) => color};
  padding: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`
export const DownloadIconContainer = styled.span`
  margin-left: auto;
  margin-right: 10px;
`
export const FileName = styled.span`
  text-align: left;
`
