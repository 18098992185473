import { useContext } from 'react'
import { TextareaAutosize } from '@mui/material'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import * as S from './NarrativeValidationCommentWindow.styles'
import {
  NarrativeContext,
  NarrativeValidationProvider,
} from '../../../context/NarrativeContextProvider'
import ButtonWithIcon from '../../../../../components/button/ButtonWithIcon'

function NarrativeValidationCommentWindow() {
  const { setIsCommentWindowOpen, setSelectedText, selectedText } = useContext(NarrativeContext)

  return (
    <S.NarrativeValidationCommentWindowContainer>
      <S.Header>
        <S.NarrativeValidationCommentTitle>Add a comment</S.NarrativeValidationCommentTitle>
        <ButtonWithIcon
          icon={faXmark}
          onClick={() => {
            setIsCommentWindowOpen(false)
          }}
          customButtonStyle={{
            height: '20px',
            width: '16px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </S.Header>
      <S.Body>
        <span>Selected text : </span>
        <div>{selectedText}</div>
        <S.CustomTextArea />
      </S.Body>
      <S.Footer>
        <ButtonWithIcon
          icon={faCheck}
          text='Validate'
          onClick={() => {
            setIsCommentWindowOpen(false)
          }}
        />
      </S.Footer>
    </S.NarrativeValidationCommentWindowContainer>
  )
}

export default NarrativeValidationCommentWindow
