import {
  faCloud,
  faCloudBolt,
  faCloudRain,
  faCloudShowersHeavy,
  faCloudSun,
  faSmog,
  faSnowflake,
  faSun,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Loader from '../../../../components/loader/Loader'
import { COLOR } from '../../../../constants/theme'
import { ThemeContext } from '../../../../context/ThemeProvider'
import { BigText, Row, Text } from '../Global.styles'
import { DashboardContext } from '../../../../context/DashboardProvider'
import { UserContext } from '../../../../context/UserProvider'

const weatherIcons: { [index: number]: IconDefinition } = {
  1000: faSun,
  1003: faCloudSun,
  1006: faCloud,
  1009: faCloud,
  1030: faSmog,
  1063: faCloudRain,
  1066: faSnowflake,
  1069: faCloudRain,
  1072: faCloudRain,
  1087: faCloudBolt,
  1114: faSnowflake,
  1117: faSnowflake,
  1135: faSmog,
  1147: faSmog,
  1150: faCloudRain,
  1153: faCloudRain,
  1168: faCloudRain,
  1171: faCloudShowersHeavy,
  1180: faCloudRain,
  1183: faCloudRain,
  1186: faCloudRain,
  1189: faCloudRain,
  1192: faCloudShowersHeavy,
  1195: faCloudShowersHeavy,
  1198: faCloudRain,
  1201: faCloudShowersHeavy,
  1204: faCloudRain,
  1207: faCloudShowersHeavy,
  1210: faSnowflake,
  1213: faSnowflake,
  1216: faSnowflake,
  1219: faSnowflake,
  1222: faSnowflake,
  1225: faSnowflake,
  1237: faSnowflake,
  1240: faCloudRain,
  1243: faCloudShowersHeavy,
  1246: faCloudShowersHeavy,
  1249: faCloudRain,
  1252: faCloudRain,
  1255: faSnowflake,
  1258: faCloudShowersHeavy,
  1261: faSnowflake,
  1264: faSnowflake,
  1273: faCloudBolt,
  1276: faCloudBolt,
  1279: faCloudBolt,
  1282: faCloudBolt,
}

function Weather() {
  const { mainColor } = useContext(ThemeContext)
  const { weather } = useContext(DashboardContext)
  const { user } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)

  const currentDate = new Date().toLocaleDateString('en-GB')

  useEffect(() => {
    if (weather || weather === undefined) {
      setIsLoading(false)
    }
  }, [weather])

  if (isLoading) return <Loader />

  if (weather === undefined) {
    return (
      <Row>
        <BigText>Unable to retrieve information</BigText>
      </Row>
    )
  }

  return (
    <>
      {user?.clientLogoUrl && <CompanyLogo src={user.clientLogoUrl} alt='weather' />}
      <Text>{currentDate}</Text>
      <FixedRow>
        <FlexText>Morning</FlexText>
        <IconContainer>
          <FlexFontAwesomeIcon
            icon={weatherIcons[weather.morning.condition.code]}
            color={mainColor}
          />
        </IconContainer>
        <TemperatureContainer>
          <TextContainer>
            <BigText bold>{weather.morning.temp_c}°C</BigText>
            <Text>Felt: {weather.morning.feelslike_c}°C</Text>
          </TextContainer>
        </TemperatureContainer>
        <WeatherContainer>
          <TextContainer>
            <BigText bold>{weather.morning.condition.text}</BigText>
            <Text>Rain probability: {weather.morning.chance_of_rain}%</Text>
          </TextContainer>
        </WeatherContainer>
      </FixedRow>
      <Separator />
      <FixedRow>
        <FlexText>Afternoon</FlexText>
        <IconContainer>
          <FlexFontAwesomeIcon
            icon={weatherIcons[weather.afternoon.condition.code]}
            color={mainColor}
          />
        </IconContainer>
        <TemperatureContainer>
          <TextContainer>
            <BigText bold>{weather.afternoon.temp_c}°C</BigText>
            <Text>Felt: {weather.afternoon.feelslike_c}°C</Text>
          </TextContainer>
        </TemperatureContainer>
        <WeatherContainer>
          <TextContainer>
            <BigText bold>{weather.afternoon.condition.text}</BigText>
            <Text>Rain probability: {weather.afternoon.chance_of_rain}%</Text>
          </TextContainer>
        </WeatherContainer>
      </FixedRow>
    </>
  )
}

const FixedRow = styled(Row)`
  gap: 5px;
  height: 50%;
  padding: 0 0 0 10px;
`

const FlexText = styled(Text)`
  flex: 0.5 1;
  text-align: left;
`

const IconContainer = styled.div`
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const FlexFontAwesomeIcon = styled(FontAwesomeIcon)`
  height: 80%;
  width: 40%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 5px;
`

const TemperatureContainer = styled.div`
  display: flex;
  flex: 0.8 1;
  justify-content: center;
`

const WeatherContainer = styled.div`
  display: flex;
  flex: 1.5 1;
`

const Separator = styled.div`
  height: 1px;
  background-color: ${COLOR.LIGHT_GRAY};
`

const CompanyLogo = styled.img`
  position: absolute;
  top: 15px;
  right: 15px;
  object-fit: cover;
  max-height: 55px;
  max-width: 220px;
  width: auto;
  height: auto;
`

export default Weather
