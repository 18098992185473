import styled from 'styled-components'
import { GenericBtnWithIcon } from '../../../commonStyles'

export const SortMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  background-color: white;
  border-radius: 5px;
  min-width: 200px;
  z-index: 999;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`
export const ApplyButton = styled(GenericBtnWithIcon)`
  margin-left: auto;
`
export const ApplyButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px;
`

export const SortValuesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const SortValueButton = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 5px;

  &:hover {
    background-color: #f5f5f5;
  }
`
export const CheckboxContainer = styled.div``
