import React from 'react';
import toast from 'react-hot-toast';
import ConfirmModal from '../../../components/modal/ConfirmModal';

type Props = {
  isOpened: boolean;
  setIsOpened: any;
  items: any[];
  callback: (comment: string) => void;
};

function GedDeclineModal({ isOpened, setIsOpened, items, callback }: Props) {
  const [value, setValue] = React.useState('');

  return (
    <ConfirmModal
      text=''
      title={items.length === 1 ? `Document to be updated` : `Documents to be updated`}
      isModalOpenned={isOpened}
      closeModalHandler={setIsOpened}
      actionCallback={() => {
        if (value === '') {
          toast('Please leave a comment');
        } else {
          callback(value);
        }
      }}
      height={350}
      width={500}>
      <div>
        {items.length === 1
          ? `1 document needs an update. Please provide a description of the required changes:`
          : `${items.length} documents need an update. Please provide a description of the required changes: `}
      </div>
      <textarea
        style={{ flex: 1, marginBottom: 4, resize: 'none' }}
        value={value}
        onChange={e => setValue(e.target.value)}
        maxLength={500}
      />
      {value.length}/500
      <div>We will contact you as soon as possible if we have any questions</div>
    </ConfirmModal>
  );
}

export default GedDeclineModal;
