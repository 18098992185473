import Tabulation from '../components/separator/Tabulation'
import { STANDARD_SCOPE_SELECTORS } from '../constants/constants'

const decodeHtmlEntity = (str: string) => {
  const parser = new DOMParser()
  const dom = parser.parseFromString(`<!doctype html><body>${str}`, 'text/html')
  return dom.body.textContent
}

const getExpFromJwt = (token: string | null) => {
  try {
    if (!token) return
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
        })
        .join('')
    )
    return JSON.parse(jsonPayload).exp
  } catch (error) {}
}

const StringFileToArrayBuffer = (fileString: string) => {
  const binaryString = window.btoa(unescape(encodeURIComponent(fileString)))
  const binaryLen = binaryString.length
  const bytes = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}

/**
 * Convert base 64 string to bytes array
 * @param base64
 */
const base64ToArrayBuffer = (base64: any) => {
  const binaryString = window.atob(base64)
  const binaryLen = binaryString.length
  const bytes = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}

const formatTextToElipsis = (t: string) => {
  if (t.length > 17) {
    return `${t.slice(0, 17)}...`
  }
  return t
}

const formatDate = (date: string, daysToAdd?: number): string => {
  const d = new Date(date)
  if (daysToAdd) {
    d.setDate(d.getDate() + daysToAdd)
  }
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  const day = d.getDate()

  return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`
}

const groupBy = function (xs: any, key: any, subkey?: any, subsubkey?: any) {
  return xs.reduce(function (acc: any, x: any) {
    if (subsubkey) {
      return Object.entries(groupBy(xs, key)).reduce((ac: any, [k, v]: any) => {
        if (!ac[k]) {
          ac[k] = groupBy(v, subkey, subsubkey)
        }
        return ac
      }, {})
    }
    if (subkey) {
      return Object.entries(groupBy(xs, key)).reduce((ac: any, [k, v]: any) => {
        if (!ac[k]) {
          ac[k] = groupBy(v, subkey)
        }
        return ac
      }, {})
    }
    ;(acc[x[key]] = acc[x[key]] || []).push(x)
    return acc
  }, {})
}

const groupByObj = (datas: any, selectors: any) => {
  const r = {}
  datas.forEach((d: any) => {
    selectors.reduce((a: any, e: any, i: any) => {
      a[d[e]] = a[d[e]] || (i + 1 === selectors.length ? [1] : {})
      return a[d[e]]
    }, r)[0] += 1
  })
  return r
}

const groupByObjBis = (datas: any, selectors: any) => {
  const r = {}
  datas.forEach((d: any) => {
    selectors.reduce((a: any, e: any, i: any) => {
      a[d[e]] = a[d[e]] || (i + 1 === selectors.length ? [d] : {})
      return a[d[e]]
    }, r)
  })
  return r
}

const groupByObjBisBis = (datas: any, selectors: any): any => {
  const r = {}
  datas.forEach((d: any) => {
    selectors
      .reduce((a: any, e: any, i: any) => {
        a[d[e]] = a[d[e]] || (i + 1 === selectors.length ? [] : {})
        return a[d[e]]
      }, r)
      .push(d)
  })
  return r
}

/**
 *
 * @param scope
 * @returns
 */
export const formatScope = (scope: any): any => {
  const datas = scope.map((e: any) => {
    e.fundName = e.shareclass ? e.shareclass.subfund.fund.fundName : e.subfund.fund.fundName
    e.subfundName = e.shareclass ? e.shareclass.subfund.subfundName : e.subfund.subfundName
    e.shareclassName = e.shareclass ? e.shareclass.codeIsin : null
    return e
  })

  const r = {}
  datas.forEach((d: any) => {
    STANDARD_SCOPE_SELECTORS.reduce((a: any, e: any, i: any) => {
      a[d[e]] = a[d[e]] || (i + 1 === STANDARD_SCOPE_SELECTORS.length ? [] : {})
      return a[d[e]]
    }, r).push(d)
  })
  return Object.entries(r).map(([k, v]: any) => {
    v = Object.entries(v)
    return [
      k,
      v.map(([ke, va]: any) => {
        va = Object.entries(va)
        return [ke, va]
      }),
    ]
  })
}

const groupByArray = (datas: any, selectors: any) => {
  const r: any = []
  datas.forEach((d: any) => {
    const index = r.findIndex((e: any) => e.name === d[selectors[0]])
    if (~index) {
    } else {
      r.push({
        name: d[selectors[0]],
        [selectors[1]]: {
          name: d[selectors[1]],
        },
      })
    }
  })
  return r
}

export const makeUsableObjForTreeTableFromShareclassDBObj = (
  arrayContainingShareclassesObject: any[]
) => {
  const datas = arrayContainingShareclassesObject.map((e: any) => {
    e.fundName = e.shareclass ? e.shareclass.subfund.fund.fundName : e.subfund.fund.fundName
    e.subfundName = e.shareclass ? e.shareclass.subfund.subfundName : e.subfund.subfundName
    e.shareclassName = e.shareclass ? e.shareclass.codeIsin : null
    return e
  })

  return datas
}

const shareclassDBObjectToTree = (arrayContainingShareclassesObject: any) => {
  const datas = arrayContainingShareclassesObject.map((e: any) => {
    e.fundName = e.shareclass.subfund.fund.fundName
    e.subfundName = e.shareclass.subfund.subfundName
    e.shareclassName = e.shareclass.codeIsin
    return e
  })

  return groupByObj(datas, ['fundName', 'subfundName', 'shareclassName'])
}

/**
 *  fill scope tree with filler values
 * */

const makeTree = (
  tree: any,
  callbacks: {
    fundCallback: any
    subfundCallback: any
    shareclassCallback: any
  }
) => {
  const r: any = []
  Object.entries(tree).forEach(([fundName, subfunds]: any) => {
    r.push({ c1: <>{fundName}</> })
    Object.entries(subfunds).forEach(([subfundName, isins]: any) => {
      r.push({
        c1: <Tabulation>{subfundName}</Tabulation>,
        ...callbacks.subfundCallback(isins),
      })
      Object.entries(isins).forEach(([isinName, element]: any) => {
        r.push({
          c1: (
            <Tabulation>
              <Tabulation>{isinName}</Tabulation>
            </Tabulation>
          ),

          ...callbacks.shareclassCallback(element),
        })
      })
    })
  })

  return r
}

// display scope as tree
const makeRealTreeFromScope = (tree: any) => {
  const realTree = (
    <ul>
      {Object.entries(tree).map(([subclientName, funds]: any) => {
        return (
          <li>
            {subclientName}
            <ul>
              {Object.entries(funds).map(([fundName, subfunds]: any) => {
                return (
                  <li>
                    {fundName}
                    <ul>
                      {Object.entries(subfunds).map(([subfundName, isins]: any) => {
                        return (
                          <li>
                            {subfundName}
                            {isins.null ? (
                              <></>
                            ) : (
                              <ul>
                                {Object.entries(isins).map(([shareclassIsin]: any) => {
                                  return <li>{shareclassIsin}</li>
                                })}
                              </ul>
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                )
              })}
            </ul>
          </li>
        )
      })}
    </ul>
  )

  return realTree
}

const getTreeTableExpandedFromData = (data: any) => {
  return data.reduce((acc: any, e: any, i: number) => {
    e.subRows.forEach((ee: any, ii: any) => {
      acc[`${i}.${ii}`] = true
      ee.subRows?.forEach((eee: any, iii: any) => {
        acc[`${i}.${ii}.${iii}`] = true
      })
    })
    acc[i] = true
    return acc
  }, {})
}

const getTreeTableExpandedFromDataWithSubclientLevel = (data: any) => {
  return data.reduce((acc: any, e: any, i: number) => {
    e.subRows.forEach((ee: any, ii: any) => {
      acc[`${i}.${ii}`] = true
      ee.subRows?.forEach((eee: any, iii: any) => {
        acc[`${i}.${ii}.${iii}`] = true
        // Add another loop here to iterate over the subRows at the fourth level
        eee.subRows?.forEach((eeee: any, iiii: any) => {
          acc[`${i}.${ii}.${iii}.${iiii}`] = true
        })
      })
    })
    acc[i] = true
    return acc
  }, {})
}

const daysInMonth = (month: number, year: number) => {
  return 32 - new Date(year, month, 32).getDate()
}

const isWeekday = (year: number, month: number, day: number) => {
  const date = new Date(year, month, day).getDay()
  return date != 0 && date != 6
}

/**
 * Function that capitalizes a string (Example : LONDON => London OR london = London
 * @param s
 */
const capitalizeString = (s: string) => {
  const sLower = s.toLowerCase()
  const firstLetterUpper = s.charAt(0).toUpperCase()
  const sWithoutFirstLetter = sLower.slice(1)
  return `${firstLetterUpper}${sWithoutFirstLetter}`
}

/**
 * Convert a string from camelCase to uppercase, Example : bonjourToutLeMonde -> bonjour Tout Le Monde
 * @param s
 */
const camelCaseToNormalString = (s: string) => {
  // This adds a space between each Uppercase letter
  return s.replace(/([A-Z])/g, ' $1')
}

export const getWeekdaysInMonth = (month: number, year: number) => {
  const days = daysInMonth(month, year)
  let weekdays = 0
  for (let i = 0; i < days; i++) {
    if (isWeekday(year, month, i + 1)) weekdays++
  }
  return weekdays
}

const getImgSrcByBase64AndFileExtension = (base64String: string, fileExtension: string) => {
  if (fileExtension == 'svg') {
    fileExtension = 'svg+xml'
  }
  return `data:image/${fileExtension};base64,${base64String}`
}

export {
  decodeHtmlEntity,
  getExpFromJwt,
  formatTextToElipsis,
  groupBy,
  groupByObj,
  groupByObjBis,
  groupByObjBisBis,
  groupByArray,
  shareclassDBObjectToTree,
  makeTree,
  makeRealTreeFromScope,
  formatDate,
  base64ToArrayBuffer,
  getTreeTableExpandedFromData,
  StringFileToArrayBuffer,
  getTreeTableExpandedFromDataWithSubclientLevel,
  capitalizeString,
  camelCaseToNormalString,
  getImgSrcByBase64AndFileExtension,
}
