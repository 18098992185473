import toast from 'react-hot-toast'
import DownloadService from '../../../../services/DownloadService/downloadService'
import documentService from '../../../../services/Document/productionDocumentService'

const downloadZipFromDocumentList = (docList: number[], splitZipStructure: boolean) => {
  const getZipBytesPromise = documentService.downloadByIds(docList, splitZipStructure)

  toast.promise(
    getZipBytesPromise,
    {
      loading: 'Downloading documents ',
      error: 'Impossible to download documents',
      success: resp => {
        if (resp.headers['content-disposition']) {
          const filename = resp.headers['content-disposition']
            .split('filename=')[1]
            .replaceAll('"', '')
          DownloadService.downloadDoc(resp.data, filename)
        } else {
          throw new Error('File name is missing')
        }

        return 'Documents were downloaded successfully'
      },
    },
    {
      success: {
        duration: 4000,
      },
    }
  )
}

export default downloadZipFromDocumentList
