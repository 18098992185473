import { useContext, useEffect, useState } from 'react'
import { BigText, Row, Text } from '../Global.styles'
import Loader from '../../../../components/loader/Loader'
import { ThemeContext } from '../../../../context/ThemeProvider'
import * as S from './LastTimeWidget.styles'
import { DashboardContext } from '../../../../context/DashboardProvider'
import { ROUTES } from '../../../../constants/routes'

function parseDate(date: string | undefined) {
  if (date) {
    return new Date(date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }
  return 'Last connection'
}

function LastTimeWidget({ layout }: { layout: 'lg' | 'sm' }) {
  const [isLoading, setIsLoading] = useState(true)
  const { mainColor } = useContext(ThemeContext)
  const { sinceLastTime, updateSinceLastTime } = useContext(DashboardContext)

  useEffect(() => {
    updateSinceLastTime()
  }, [])
  useEffect(() => {
    if (sinceLastTime || sinceLastTime === undefined) {
      setIsLoading(false)
    }
  }, [sinceLastTime])

  if (isLoading) return <Loader />

  if (sinceLastTime === undefined) {
    return (
      <Row>
        <BigText>Unable to retrieve information</BigText>
      </Row>
    )
  }

  return (
    <>
      <Text>{parseDate(sinceLastTime?.lastConnectionTimestamp)}</Text>
      <S.ListContainer>
        <S.TextContainer bottomBorder sideBorder={layout === 'sm'}>
          <S.TextBlock to={ROUTES.LIBRARY}>
            <S.BigTextBlock bold style={{ fontSize: '32px' }} color={mainColor}>
              {sinceLastTime?.statSentDocuments}
            </S.BigTextBlock>{' '}
            Sent documents
          </S.TextBlock>
        </S.TextContainer>
        <S.TextContainer bottomBorder={layout === 'lg'} sideBorder={layout === 'sm'}>
          <S.TextBlock to={ROUTES.DOCUMENT_VALIDATION}>
            <S.BigTextBlock bold style={{ fontSize: '32px' }} color={mainColor}>
              {sinceLastTime?.statValidatedDocuments}
            </S.BigTextBlock>{' '}
            Validated documents
          </S.TextBlock>
        </S.TextContainer>
        <S.TextContainer bottomBorder>
          <S.TextBlock to={ROUTES.DOCUMENT_VALIDATION}>
            <S.BigTextBlock bold style={{ fontSize: '32px' }} color={mainColor}>
              {sinceLastTime?.statRejectedDocuments}
            </S.BigTextBlock>{' '}
            Rejected documents
          </S.TextBlock>
        </S.TextContainer>
        <S.TextContainer>
          <S.TextBlock to={ROUTES.SCOPE_MANAGEMENT_EXISTING_SERVICES}>
            <S.BigTextBlock bold style={{ fontSize: '32px' }} color={mainColor}>
              {sinceLastTime?.statScopeChanges}
            </S.BigTextBlock>{' '}
            Scope changes made
          </S.TextBlock>
        </S.TextContainer>
      </S.ListContainer>
    </>
  )
}

export default LastTimeWidget
