import styled from 'styled-components';

export const ActionButtonsContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 14px;
  z-index: 1;
  display: flex;
`;

export const ModalBox = styled.div`
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px 30px;
  border-radius: 5px;
  color: #4b525a;
  width: 40vw;
`;
export const ModalBody = styled.div`
  height: 90%;
  text-align: center;
`;
export const ButtonsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;
