import React from 'react';
import { MRT_Row } from 'material-react-table';
import { InvoiceData } from '../InvoicingData.types';
import { OptionType } from '../../../../types/OptionType';

export default function HandleSumAggregatedCell(
  options: { row: MRT_Row<InvoiceData> },
  rawSubfundData: any[],
  filterReportType: OptionType | null,
  reportLevel: 'Fund' | 'Subfund' | 'CodeIsin'
) {
  const { row } = options;
  const sumPrice = row
    .getLeafRows()
    .filter((elem: any) => !elem.id.startsWith('fund'))
    .reduce((acc: number, current: any) => acc + current.original.price, 0);

  if (row.groupingColumnId === 'fundName') {
    if (filterReportType) {
      const fundSumPrice = rawSubfundData
        .filter((elem: InvoiceData) => elem.fundName === row.groupingValue)
        .filter((elem: InvoiceData) => elem.reportType === filterReportType.value);
      if (reportLevel === 'CodeIsin') {
        return (
          <div>
            {sumPrice + fundSumPrice.reduce((acc: number, current: any) => acc + current.price, 0)}
          </div>
        );
      }
      return <div>{sumPrice}</div>;
    }
    const fundSumPrice = rawSubfundData.filter(
      (elem: InvoiceData) => elem.fundName === row.groupingValue
    );
    if (reportLevel === 'CodeIsin') {
      return (
        <div>
          {sumPrice + fundSumPrice.reduce((acc: number, current: any) => acc + current.price, 0)}
        </div>
      );
    }
    return <div>{sumPrice}</div>;
  }
  if (row.groupingColumnId === 'subfundName') {
    if (filterReportType) {
      const subfundSumPrice = rawSubfundData
        .filter((elem: InvoiceData) => elem.subfundName === row.groupingValue)
        .filter((elem: InvoiceData) => elem.reportType === filterReportType.value);
      if (reportLevel === 'CodeIsin') {
        return (
          <div>
            {sumPrice +
              subfundSumPrice.reduce((acc: number, current: any) => acc + current.price, 0)}
          </div>
        );
      }
      return <div>{sumPrice}</div>;
    }
    const subfundSumPrice = rawSubfundData.filter(
      (elem: InvoiceData) => elem.subfundName === row.groupingValue
    );
    if (reportLevel === 'CodeIsin') {
      return (
        <div>
          {sumPrice + subfundSumPrice.reduce((acc: number, current: any) => acc + current.price, 0)}
        </div>
      );
    }
    return <div>{sumPrice}</div>;
  }
}
