import { MRT_Cell, MRT_Column, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react'
import { ThemeContext } from '../../../context/ThemeProvider'
import { TemplateValidationScopeType } from '../TemplateValidation'

function OpenPdfModalButton(
  row: TemplateValidationScopeType,
  setIsPdfModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentFilePath: React.Dispatch<React.SetStateAction<string>>
) {
  const { mainColor } = useContext(ThemeContext)

  return (
    <div
      onClick={() => {
        setIsPdfModalOpen(true)
        setCurrentFilePath(row.filePath)
      }}>
      <FontAwesomeIcon icon={faInfoCircle} style={{ color: mainColor }} />
    </div>
  )
}

export default OpenPdfModalButton
