import React, { useContext, useEffect, useMemo, useState } from 'react'
import Modal from 'react-modal'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import FileSaver from 'file-saver'
import toast from 'react-hot-toast'
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'
import http from '../../../services/http'
import SaveScopeModal from './components/SaveScopeModal'
import { ThemeContext } from '../../../context/ThemeProvider'
import { SubscribedReportContext } from '../../../context/SubscribedReportProvider'
import MaterialTable from '../../../components/table/MaterialTable/MaterialTable'
import { SelectInputSearchControlled } from '../../../components/input/SelectInputSearchControlled'
import { OptionType } from '../../../types/OptionType'
import ButtonWithIcon from '../../../components/button/ButtonWithIcon'
import {
  HandleDefaultAggregatedCell,
  HandleDefaultCodeIsinCell,
  RenderDefaultTopToolBar,
  filtersStyle,
  HandleMuiTableBodyCellPropsLevels,
  HandleDisplayFundCell,
} from '../../../components/table/MaterialTable/ColumnUtilities'
import { AllUpdates, ScopeItem } from './ExistingServices.types'
import PeriodicityAndSubscribed from './components/PeriodicityAndSubscribed'
import HeaderForFrequency from './components/HeaderForFrequency'
import * as S from './ExistingServices.styles'
import AggregatedCellForFrequency from './components/AggregatedCellForFrequency'

Modal.setAppElement('#root')

function ExistingServices() {
  localStorage.setItem('localState', 'existing_services')

  // Manage context
  const { clientSubscribedReports, distinctSubscribedReports } = useContext(SubscribedReportContext)
  const { mainColorLight, mainColorVeryLight } = useContext(ThemeContext)
  // Manage data
  const [rawData, setRawData] = useState<ScopeItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [defaultFrequency, setDefaultFrequency] = useState<OptionType | null>(null)
  // Manage filters
  const [filterReportsSubscribed, setFilterReportsSubscribed] = useState<OptionType | null>(null)
  const [filterFund, setFilterFund] = useState<OptionType | null>(null)
  const [filterSubfund, setFilterSubfund] = useState<OptionType | null>(null)
  const [filterCodeIsin, setFilterCodeIsin] = useState<OptionType | null>(null)
  //  Manage all filters options
  const [allReportsSubscribed, setAllReportsSubscribed] = useState<OptionType[]>([])
  const [allReportsFrequency, setAllReportsFrequency] = useState<OptionType[]>([])
  const [allFund, setAllFund] = useState<OptionType[]>([])
  const [allSubfund, setAllSubfund] = useState<OptionType[]>([])
  const [allCodeIsin, setAllCodeIsin] = useState<OptionType[]>([])
  // Manage exception reports in subclient level
  const [reportLevel, setReportLevel] = useState<'Fund' | 'Subfund' | 'CodeIsin'>('CodeIsin')
  // Manage all changes for status
  const [allUpdates, setAllUpdates] = useState<AllUpdates[]>([])
  // Manage modals
  const [isSaveModalOpened, setIsSaveModalOpened] = useState<boolean>(false)
  //  Define initial config for the table
  const [initialStateFund] = useState({
    sorting: [{ id: 'fundName', desc: false }],
    columnVisibility: {
      fundName: false,
    },
    density: 'compact',
    columnOrder: ['fundName', 'fundNameDisplay', 'frequency'],
  })
  const [initialStateSubfund] = useState({
    grouping: ['fundName'],
    columnVisibility: {
      fundName: false,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
    ],
    density: 'compact',
    columnOrder: ['mrt-row-expand', 'subfundName', 'frequency'],
  })
  const [initialStateCodeisin] = useState({
    grouping: ['fundName', 'subfundName'],
    columnVisibility: {
      fundName: false,
      subfundName: false,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
    ],
    density: 'compact',
    columnOrder: ['mrt-row-expand', 'codeIsin', 'frequency'],
  })

  const downloadExport = () => {
    http.get(`/scopes/export`, { responseType: 'arraybuffer' }).then((r: any): any => {
      const blob = new Blob([r.data], {
        type: 'text/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
      })
      FileSaver.saveAs(blob, 'AO_FULL_SCOPE_HISTORY.xlsx')
    })
  }

  //Definitions for the filter bar
  const renderTopToolbar = (props: { table: MRT_TableInstance }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
          <RenderDefaultTopToolBar
            table={props.table}
            isLoading={isLoading}
            reportLevel={reportLevel}
            filterFund={filterFund}
            filterSubfund={filterSubfund}
            filterCodeIsin={filterCodeIsin}
            setFilterFund={setFilterFund}
            setFilterSubfund={setFilterSubfund}
            setFilterCodeIsin={setFilterCodeIsin}
            allFund={allFund}
            allSubfund={allSubfund}
            allCodeIsin={allCodeIsin}
            preComponent={
              <SelectInputSearchControlled
                isDisabled={isLoading}
                removeClear
                customStyle={filtersStyle}
                value={filterReportsSubscribed}
                options={allReportsSubscribed}
                placeholder='Select a report type'
                onChangeCallback={option => {
                  props.table.setExpanded({ updater: false })
                  setFilterReportsSubscribed(option)
                  props.table.setColumnFilters(() => {
                    return []
                  })
                }}
              />
            }
          />
        </div>
        <ButtonWithIcon
          icon={faDownload}
          customButtonStyle={{ margin: '5px' }}
          text=''
          onClick={() => {
            downloadExport()
          }}
        />
      </div>
    )
  }

  // Manage the options for periodicity according to the report type selected
  useEffect(() => {
    const rawAllReportsSubscribed = distinctSubscribedReports.map((report: any) => ({
      label: report.reportTypeName,
      value: report.reportTypeId,
    }))
    setAllReportsSubscribed(rawAllReportsSubscribed)
    if (filterReportsSubscribed === null && rawAllReportsSubscribed[0]) {
      setFilterReportsSubscribed(rawAllReportsSubscribed[0])
    }
  }, [distinctSubscribedReports])

  // fetch new data if filterReportsSubscribed change
  useEffect(() => {
    setIsLoading(true)
    if (filterReportsSubscribed) {
      http
        .get(`/scopes/${filterReportsSubscribed?.value}`)
        .then(resp => {
          setAllUpdates([])
          setRawData(resp.data)
          switch (
            distinctSubscribedReports.find(
              elem => elem.reportTypeId === filterReportsSubscribed.value
            )?.reportTypeLevel
          ) {
            case 2:
              setReportLevel('Fund')
              break
            case 3:
              setReportLevel('Subfund')
              break
            case 4:
              setReportLevel('CodeIsin')
              break
            default:
              console.log('default')
              break
          }
          setAllReportsFrequency(
            // @ts-ignore
            distinctSubscribedReports
              .find(elem => elem.reportTypeId === filterReportsSubscribed.value)
              .frequencyDecList.map(elem => ({ label: elem.libelle, value: elem.frequencyId }))
          )
          if (clientSubscribedReports) {
            const defaultFrequencyOption = {
              label: clientSubscribedReports.find(
                elem => elem.reportTypeId === filterReportsSubscribed.value
              )?.defaultFrequencyDec.libelle as string,
              value: clientSubscribedReports.find(
                elem => elem.reportTypeId === filterReportsSubscribed.value
              )?.defaultFrequencyDec.frequencyId as number,
            }
            setDefaultFrequency(defaultFrequencyOption)
          }
          setFilterFund(null)
          setFilterSubfund(null)
          setFilterCodeIsin(null)
          setIsLoading(false)
        })
        .catch(e => {
          console.log(e)
          toast.error('Failed to load data')
        })
    }
  }, [filterReportsSubscribed])

  // Manage the data that will be displayed according to previous filters
  useEffect(() => {
    //for fund
    const rawFund = rawData
      .map((elem: ScopeItem) => elem.fundName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: string) => ({ label: elem, value: elem }))
    setAllFund(rawFund)
  }, [rawData, isLoading])
  useEffect(() => {
    //for subfund
    const rawsubFund = rawData
      .filter((elem: ScopeItem) => (filterFund?.label ? elem.fundName === filterFund.label : true))
      .map((elem: ScopeItem) => elem.subfundName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: string) => ({ label: elem, value: elem }))
    setAllSubfund(rawsubFund)
  }, [rawData, isLoading, filterFund])
  useEffect(() => {
    //for codeIsin
    const rawCodeIsin = rawData
      .filter((elem: ScopeItem) => (filterFund?.value ? elem.fundName === filterFund.label : true))
      .filter((elem: ScopeItem) =>
        filterSubfund?.value ? elem.subfundName === filterSubfund.label : true
      )
      .map((elem: ScopeItem) => elem.codeIsin)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllCodeIsin(rawCodeIsin)
  }, [rawData, isLoading, filterFund, filterSubfund])

  const createMaingroupElements = () => {
    let mainGroupedElements: MRT_ColumnDef<ScopeItem>[]
    switch (reportLevel) {
      case 'Fund': {
        mainGroupedElements = [
          {
            header: 'Fund',
            accessorKey: 'fundName',
            enableSorting: true,
            enableColumnActions: false,
            enableGlobalFilter: false,
            filterFn: 'equalsString',
          },
          {
            header: '',
            columnDefType: 'display',
            id: 'fundNameDisplay',
            Cell: options => HandleDisplayFundCell(options),
          },
        ]
        break
      }

      case 'Subfund': {
        mainGroupedElements = [
          {
            header: 'Fund',
            accessorKey: 'fundName',
            enableGlobalFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            enableColumnFilter: false,
            filterFn: 'equalsString',
          },
          {
            header: '',
            accessorKey: 'subfundName',
            enableGlobalFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            enableColumnFilter: false,
            filterFn: 'equalsString',
            AggregatedCell: (props: any) => HandleDefaultAggregatedCell(props),
            muiTableBodyCellProps: (props: any) =>
              HandleMuiTableBodyCellPropsLevels(props, mainColorLight, mainColorVeryLight),
          },
        ]
        break
      }
      default: {
        mainGroupedElements = [
          {
            header: 'Fund',
            accessorKey: 'fundName',
            enableSorting: true,
            enableGlobalFilter: false,
            filterFn: 'equalsString',
          },
          {
            header: 'Subfund',
            accessorKey: 'subfundName',
            enableSorting: true,
            enableGlobalFilter: false,
            enableColumnActions: false,
            enableColumnFilter: false,
            filterFn: 'equalsString',
          },
          {
            header: '',
            accessorKey: 'codeIsin',
            enableGlobalFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            enableColumnFilter: false,
            filterFn: 'equalsString',
            Cell: (props: any) => HandleDefaultCodeIsinCell(props),
            AggregatedCell: (props: any) => HandleDefaultAggregatedCell(props),
            muiTableBodyCellProps: (props: any) =>
              HandleMuiTableBodyCellPropsLevels(props, mainColorLight, mainColorVeryLight),
          },
        ]
        break
      }
    }
    return mainGroupedElements
  }

  const columns = useMemo<MRT_ColumnDef<ScopeItem>[]>(
    () => [
      ...createMaingroupElements(),
      {
        accessorKey: 'frequency',
        columnDefType: 'display',
        enableColumnActions: false,
        header: 'Frequency',
        Header: () => HeaderForFrequency(rawData),
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: options => {
          const { original } = options.row
          return (
            <PeriodicityAndSubscribed
              original={original}
              allUpdates={allUpdates}
              setAllUpdates={setAllUpdates}
              allReportsFrequency={allReportsFrequency}
              reportLevel={reportLevel}
              defaultFrequency={defaultFrequency}
            />
          )
        },
        AggregatedCell: (options: any) =>
          AggregatedCellForFrequency(options, allUpdates, defaultFrequency, setAllUpdates),
      },
    ],
    [allReportsFrequency, reportLevel, allUpdates]
  )

  return (
    <div>
      <SaveScopeModal
        selectedService={filterReportsSubscribed}
        isOpen={isSaveModalOpened}
        closeModal={(e: boolean) => {
          if (e) {
            setAllUpdates([])
          }
          setIsSaveModalOpened(false)
        }}
        changedScope={allUpdates}
      />
      {reportLevel === 'Fund' && (
        <MaterialTable
          data={rawData}
          columns={columns}
          isLoading={isLoading}
          enableSelectAll={false}
          initialState={initialStateFund}
          renderTopToolbar={renderTopToolbar}
          maxHeight={78}
        />
      )}
      {reportLevel === 'Subfund' && (
        <MaterialTable
          data={rawData}
          columns={columns}
          isLoading={isLoading}
          enableSelectAll={false}
          initialState={initialStateSubfund}
          renderTopToolbar={renderTopToolbar}
          maxHeight={78}
        />
      )}
      {reportLevel === 'CodeIsin' && (
        <MaterialTable
          data={rawData}
          columns={columns}
          isLoading={isLoading}
          enableSelectAll={false}
          initialState={initialStateCodeisin}
          renderTopToolbar={renderTopToolbar}
          maxHeight={78}
        />
      )}
      {allUpdates.length !== 0 && (
        <S.SaveButton>
          <ButtonWithIcon
            text='Save changes'
            onClick={() => setIsSaveModalOpened(true)}
            customButtonStyle={{ backgroundColor: mainColorLight }}
          />
        </S.SaveButton>
      )}
    </div>
  )
}

export default ExistingServices
