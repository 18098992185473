import styled from 'styled-components'
import { Tooltip } from '@mui/material'

type Props = {
  languageName: string
  languageIsoCode2: string
}

const Container = styled.div`
  display: inline-flex;
`

function LanguageWithTooltip({ languageName, languageIsoCode2 }: Props) {
  return (
    <Tooltip title={languageName} arrow>
      <Container>{languageIsoCode2.toUpperCase()}</Container>
    </Tooltip>
  )
}

export default LanguageWithTooltip
