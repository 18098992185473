import http from '../http'

const baseUrl = '/client_document'
export const clientDocumentsService = {
  findAllDocumentsName: () => {
    return http.get<string[]>(`${baseUrl}/all-name`)
  },

  downloadSpecificDocumentsByName: (documentName: string) => {
    const formData = new FormData()
    formData.append('filename', documentName)

    return http.post<string>(`${baseUrl}/download-by-name`, formData, {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
}
