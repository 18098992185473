import styled from 'styled-components'

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px 30px;
  border-radius: 5px;
  color: #4b525a;
  background-color: #eff1f3;
  width: 50vw;
  height: 90vh;
  outline: none;
`

const ModalHeader = styled.div``
const ModalBody = styled.div`
  height: 100%;
  position: relative;
`

const ModalFooter = styled.div``

const PdfFlexContainer = styled.div`
  display: flex;
  gap: 20px;
  height: 100%;
`

const DocumentContainer = styled.div`
  width: 96%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
`

const NarrativeValidationCommentWindow = styled.div`
  position: absolute;
`

const NarrativeChangelogContainer = styled.div`
  display: flex;
  gap: 20px;
`
const NarrativesRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 100%;
  overflow-y: auto;
`

const PartTitle = styled.div`
  font-weight: bold;
`

export {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContainer,
  PdfFlexContainer,
  DocumentContainer,
  PartTitle,
  NarrativeChangelogContainer,
  NarrativesRows,
}
