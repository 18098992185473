import http from '../../../../services/http';

// eslint-disable-next-line import/prefer-default-export
export const getEmails = (
  reportId: number,
  fundId: number,
  subfundId: number,
  shareclassId: number | null,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setEmailListLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setEmailList: any
) => {
  setIsModalOpen(true);
  setEmailListLoading(true);
  let request = `?reportTypeId=${reportId}&fundId=${fundId}&subfundId=${subfundId}`;
  if (shareclassId) {
    request += `&shareclassId=${shareclassId}`;
  }
  http
    .get(`/disseminations/emails${request}`)
    .then(({ data }) => {
      setEmailList(data);
    })
    .finally(() => {
      setEmailListLoading(false);
    });
};
