import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MRT_Cell } from 'material-react-table';
import { COLOR } from '../../../../constants/theme';
import { PlatformType } from '../Platform.types';

export default function HandleDataCell(options: { cell: MRT_Cell<PlatformType> }) {
  const { cell } = options;
  if (cell.getValue()) {
    return <FontAwesomeIcon icon={faCheck} color={COLOR.GREEN} />;
  }
  return null;
}
