import axios from 'axios';

const { REACT_APP_API_HTTP_PROTOCOL, REACT_APP_API_HTTP_ENDPOINT, REACT_APP_API_ENDPOINT } =
  process.env;

const config = {
  baseURL: `${REACT_APP_API_HTTP_PROTOCOL}://${REACT_APP_API_ENDPOINT}/${REACT_APP_API_HTTP_ENDPOINT}`,
};

const http = axios.create(config);

export default http;
