import { faClipboardCheck, faStamp, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Toaster, toast } from 'react-hot-toast'
import Button from '../../components/button/Button'
import Layout from '../../components/layout/Layout'
import Loader from '../../components/loader/Loader'
import NotYet from '../../components/notYet/NotYet'
import Table from '../../components/table/Table'
import { COLOR } from '../../constants/theme'
import { NotificationContext } from '../../context/NotificationProvider'
import { UserContext } from '../../context/UserProvider'
import http from '../../services/http'

function Validation() {
  localStorage.setItem('localState', 'scope_validation')
  const { user } = useContext(UserContext)
  if (!user) {
    throw new Error(`ERROR: User is not connected, you can't access this page`)
  }
  const [loading, setLoading] = React.useState(false)
  const { notifications, gatherNotifications, render } = React.useContext(NotificationContext)
  const [count, setCount] = useState<number>(0)

  React.useEffect(() => gatherNotifications(setLoading), [])

  const approve = (element: any) => {
    element.changeScopeApproved = true
    render()
  }

  const decline = (element: any) => {
    element.changeScopeApproved = false
    render()
  }

  const head = React.useMemo(
    () => [
      {
        Header: 'Action type',
        accessor: 'c0',
      },
      {
        Header: 'Report type',
        accessor: 'c1',
      },
      {
        Header: 'Frequency',
        accessor: 'c2',
      },
      {
        Header: 'Fund',
        accessor: 'c3',
      },
      {
        Header: 'Subfund',
        accessor: 'c4',
      },
      {
        Header: 'CodeIsin',
        accessor: 'c5',
      },
      {
        Header: 'Shareclass',
        accessor: 'c6',
      },
      {
        Header: 'Decline',
        accessor: 'c7',
      },
      {
        Header: 'Approve',
        accessor: 'c8',
      },
    ],
    []
  )

  const tablesData = React.useMemo(
    () =>
      notifications.map((notification: any) => {
        return {
          userFullName: notification?.clientUserFullName,
          userEmail: notification?.clientUserEmail,
          changeId: notification?.changeId,
          data: notification?.changeScopeList?.map((change: any) => {
            return {
              c0: change?.changeScopeStatus === null ? 'ADD' : change?.changeScopeStatus,
              c1: change?.reportTypeName,
              c2: `${change?.oldFrequencyId ? `${change?.oldFrequencyLibelle} -> ` : ''}${
                change?.frequencyLibelle
              }`,
              c3: change?.fundName,
              c4: change?.subfundName,
              c5: change?.codeIsin ? change?.codeIsin : '/',
              c6: change?.shareclassName ? change?.shareclassName : '/',
              c7: (
                <IconContainer onClick={() => decline(change)}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    color={change.changeScopeApproved === false ? COLOR.RED : COLOR.GRAY}
                  />
                </IconContainer>
              ),
              c8: user.parentId ? (
                <IconContainer style={{ cursor: 'not-allowed' }}>
                  <FontAwesomeIcon
                    icon={faStamp}
                    color={change.changeScopeApproved ? COLOR.GREEN : COLOR.GRAY}
                  />
                </IconContainer>
              ) : (
                <IconContainer onClick={() => approve(change)}>
                  <FontAwesomeIcon
                    icon={faStamp}
                    color={change.changeScopeApproved ? COLOR.GREEN : COLOR.GRAY}
                  />
                </IconContainer>
              ),
              changeItemId: change.changeScopeId,
            }
          }),
        }
      }),
    [notifications]
  )

  if (loading) {
    return <Loader />
  }

  if (notifications.length === count) {
    return <NotYet text='No changes to validate' icon={faClipboardCheck} />
  }

  // const SOCKET_URL = 'ws://localhost:8081/websocket';
  return (
    <Layout>
      {/* <SockJsClient
        url={SOCKET_URL}
        topics={['/topic/message']}
        onConnect={onConnected}
        onDisconnect={console.log("Disconnected!")}
        onMessage={(msg: any) => onMessageReceived(msg)}
        debug={false}
      /> */}
      {tablesData.map((table: any) => {
        return (
          <div id={table.changeId} key={table.changeId}>
            <Toaster />
            <div style={{ marginBottom: 8 }}>
              {table?.userFullName}({table?.userEmail}) has submit a scope change request :
            </div>
            <Table fullWidth head={head} data={table.data} />
            <ApplyChangesContainer>
              {notifications
                .find(elem => elem.changeId === table.changeId)
                .changeScopeList.every((elem: any) => elem.changeScopeApproved !== null) && (
                <Button
                  text='Apply changes'
                  handleClick={() => {
                    if (!~table.data?.findIndex((c: any) => c.changeScopeApproved === null)) {
                      const body = {
                        changeId: table.changeId, //ok
                        changeItems: notifications[
                          notifications.findIndex(
                            notification => notification.changeId === table.changeId
                          )
                        ].changeScopeList.map(
                          (scopeChange: {
                            changeScopeId: number
                            changeScopeApproved: boolean
                          }) => ({
                            changeItemId: scopeChange.changeScopeId,
                            approved: scopeChange.changeScopeApproved,
                          })
                        ),
                      }
                      http.post('/scopes/validate', body).then(e => {
                        const x = document.getElementById(table.changeId)
                        if (x) {
                          toast.success('Changes applied')
                          x.style.display = 'none'
                          setCount(prev => prev + 1)
                        }
                      })
                    }
                  }}
                  disabled={!!~table.data?.findIndex((c: any) => c.changeScopeApproved === null)}
                />
              )}
            </ApplyChangesContainer>
          </div>
        )
      })}
    </Layout>
  )
}

const ApplyChangesContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 8px;
`

const IconContainer = styled.div`
  width: 24px;
  margin: auto;
  height: 24px;
  cursor: pointer;
`

export default Validation
