import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MRT_ColumnDef, MRT_TableState } from 'material-react-table'
import toast from 'react-hot-toast'
import { UserContext } from '../../context/UserProvider'
import Modal from '../../components/modal/Modal'
import { ThemeContext } from '../../context/ThemeProvider'
import { SelectInputSearchControlled } from '../../components/input/SelectInputSearchControlled'
import { OptionType } from '../../types/OptionType'
import MaterialTable from '../../components/table/MaterialTable/MaterialTable'
import DeliveryPlanning from './DeliveryPlanning/DeliveryPlanning'
import { RequestType } from './ProductionStatus.types'
import {
  HandleDefaultAggregatedCell,
  HandleMuiTableBodyCellPropsLevels,
  filtersStyle,
} from '../../components/table/MaterialTable/ColumnUtilities'
import HandleScopeCell from './components/HandleScopeCell'
import HandleDataSourceCell from './components/HandleDataSourceCell'
import HandleInProductionCell from './components/HandleInProductionCell'
import HandleControlCell from './components/HandleControlCell'
import HandleClientValidationCell from './components/HandleClientValidationCell'
import Handle3rdPartyCell from './components/Handle3rdPartyCell'
import { ProductionService } from '../../services/production/ProductionService'
import { ProductionStatusDashboardDto } from '../../types/dtos/production/productionDto'

function ProductionStatus() {
  localStorage.setItem('localState', 'production_status')
  const navigate = useNavigate()
  // Manage context
  const { user } = useContext(UserContext)
  const { mainColor, mainColorLight, mainColorVeryLight } = useContext(ThemeContext)
  // Manage data
  const [rawData, setRawData] = useState<ProductionStatusDashboardDto[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // Manage filters
  const [filterReportType, setFilterReportType] = useState<OptionType | null>(null)
  const [filterType, setFilterType] = useState<OptionType | null>(null)
  //  Manage all filters options
  const [allReportType, setAllReportType] = useState<OptionType[]>([])
  // Define paramsForValidation for the client validation page
  const [paramsForValidation, setParamsForValidation] = useState<RequestType | null>(null)
  //  Define initial config for the table
  const [initialState] = useState<Partial<MRT_TableState<ProductionStatusDashboardDto[]>>>({
    // sorting: [{ id: 'reportDate', desc: true }],
    columnVisibility: {
      reportTypeName: false,
    },
    grouping: ['reportTypeName'],
    density: 'compact',
  })

  // update data display
  useEffect(() => {
    ProductionService.getProductionStatusDashboardData()
      .then(resp => {
        setRawData(resp.data)
      })
      .catch(e => {
        toast.error(
          'Sorry, there was an error while fetching the data. Please refresh the page or try again later.'
        )
        console.log(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])
  // Manage the data that will be displayed according to previous filters
  useEffect(() => {
    //for report type
    const rawReportType = rawData
      .map(elem => elem.reportTypeName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllReportType(rawReportType)
  }, [isLoading])

  //Definitions for the filter bar
  const renderTopToolbar = (props: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', padding: '5px 5px' }}>
          <SelectInputSearchControlled
            customStyle={filtersStyle}
            value={filterReportType}
            options={allReportType}
            placeholder='Select a report type'
            onChangeCallback={option => {
              setFilterReportType(option)
              props.table.setColumnFilters((prev: any) => {
                const otherFilters = prev.filter((elem: any) => elem?.id !== 'reportTypeName')
                if (option) {
                  return [...otherFilters, { id: 'reportTypeName', value: option?.value }]
                }
                return [...otherFilters]
              })
            }}
          />
        </div>
      </div>
    )
  }

  const columns = useMemo<MRT_ColumnDef<ProductionStatusDashboardDto>[]>(() => {
    return [
      {
        header: 'Report type',
        accessorKey: 'reportTypeName',
        enableFilterMatchHighlighting: false,
        enableColumnActions: false,
        // enableSorting: false,
      },
      {
        header: 'Report date',
        accessorKey: 'reportDateFormatted',
        enableFilterMatchHighlighting: false,
        enableColumnActions: false,
        // enableSorting: false,
        AggregatedCell: (options: any) => HandleDefaultAggregatedCell(options),
        muiTableBodyCellProps: (options: any) =>
          HandleMuiTableBodyCellPropsLevels(options, mainColorLight, mainColorVeryLight),
      },
      {
        header: 'Scope',
        accessorKey: 'totalDeliverable',
        enableFilterMatchHighlighting: false,
        enableColumnActions: false,
        Cell: options => HandleScopeCell(options, setParamsForValidation),
      },
      {
        header: 'Data sources',
        accessorKey: 'inDataReceptionCount',
        enableFilterMatchHighlighting: false,
        enableColumnActions: false,
        Cell: options => HandleDataSourceCell(options, mainColor, navigate),
      },
      {
        header: 'In production',
        accessorKey: 'inProductionCount',
        enableFilterMatchHighlighting: false,
        enableColumnActions: false,
        Cell: options => HandleInProductionCell(options, setParamsForValidation),
      },
      {
        header: 'Control',
        accessorKey: 'inControlCount',
        enableFilterMatchHighlighting: false,
        enableColumnActions: false,
        Cell: options => HandleControlCell(options),
      },
      {
        header: 'Client validation',
        accessorKey: 'inClientValidationCount',
        enableFilterMatchHighlighting: false,
        enableColumnActions: false,
        Cell: options => HandleClientValidationCell(options, mainColor, navigate),
      },
      {
        header: '3rd party sent',
        accessorKey: 'inSendingCount',
        enableFilterMatchHighlighting: false,
        enableColumnActions: false,
        Cell: options => Handle3rdPartyCell(options),
      },
    ]
  }, [rawData])

  if (!user) {
    throw new Error(`ERROR: User is not connected, you can't access this page`)
  }

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={rawData}
        enableSelectAll={false}
        isLoading={isLoading}
        allowPagination={false}
        initialState={initialState}
        renderTopToolbar={renderTopToolbar}
      />
      {paramsForValidation?.reportLevel &&
        paramsForValidation.reportLevel &&
        paramsForValidation.reportTypeId && (
          <Modal
            isModalOpenned={paramsForValidation !== null}
            setIsModalOpenned={() => setParamsForValidation(null)}
            width='88vw'
            height='88vh'>
            <DeliveryPlanning
              reportDate={paramsForValidation?.reportDate}
              reportTypeId={paramsForValidation?.reportTypeId}
              reportLevel={paramsForValidation?.reportLevel}
              productionStatusId={paramsForValidation?.productionStatusId}
            />
          </Modal>
        )}
    </div>
  )
}

export default ProductionStatus
