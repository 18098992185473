import React, { useContext, useEffect, useState } from 'react'
import { faFolder } from '@fortawesome/free-regular-svg-icons'
import { ThemeContext } from '../../../../context/ThemeProvider'
import { ROUTES } from '../../../../constants/routes'
import * as S from './PlannedWidget.styles'
import { DashboardContext } from '../../../../context/DashboardProvider'
import { BigText, Row, StyledFontAwesomeIcon, StyledLink, HugeText } from '../Global.styles'
import Loader from '../../../../components/loader/Loader'

const WaveBaseComponent = React.memo(
  ({ waveHeight, color }: { waveHeight: number; color: string }) => {
    if (waveHeight === 100)
      return (
        <Row style={{ gap: '0px', alignItems: 'flex-end' }}>
          <S.WaveBase height={waveHeight} color={color} />
          <S.WaveBase height={waveHeight - 10} color={color} />
        </Row>
      )
    return <S.WaveBase height={waveHeight} color={color} />
  },
  (prev, next) => {
    return prev.waveHeight === next.waveHeight && prev.color === next.color
  }
)

function PlannedWidget() {
  // TODO: fill icon at 100%
  const [value, setValue] = useState<number>()
  const [height, setHeight] = useState<number>(0)
  const { plannedReports, updatePlannedReports } = useContext(DashboardContext)

  const { mainColor, mainColorLight } = useContext(ThemeContext)

  // TODO: fill widget when at 100%

  useEffect(() => {
    updatePlannedReports()
  }, [])
  useEffect(() => {
    if (plannedReports === null || plannedReports === undefined) return
    setValue(plannedReports)
    // Map the value to the height, from 0-100 to 0-76
    setHeight(plannedReports === 100 ? 100 : (plannedReports * 76) / 100)
  }, [plannedReports])

  if (plannedReports === null || plannedReports === undefined) return <Loader />

  if (plannedReports === -2) {
    return (
      <>
        <Row>
          <BigText>Unable to retrieve information</BigText>
        </Row>
        <StyledLink to={ROUTES.PRODUCTION_STATUS} state='production_status' color={mainColor}>
          {'Go to Production page >'}
        </StyledLink>
      </>
    )
  }

  return (
    <>
      <Row>
        <S.FolderContainer>
          <S.WaveContainer>
            <WaveBaseComponent waveHeight={height} color={mainColorLight} />
            <S.StyledWaveSvg color={mainColorLight} display={height === 100 ? 'none' : ''} />
            {/* <NumberBox style={{ position: 'absolute', bottom: 0, left: 0, right: 0, color: '#474747' }} color={mainColor}>
              {value}%
            </NumberBox> */}
          </S.WaveContainer>
          <StyledFontAwesomeIcon icon={faFolder} color={mainColor} />
        </S.FolderContainer>
        {plannedReports !== -1 ? (
          <S.TextContainer>
            <HugeText bold color={mainColor}>
              {value}%
            </HugeText>
            <BigText>Already generated</BigText>
          </S.TextContainer>
        ) : (
          <S.TextContainer>
            <BigText>No current planned reports</BigText>
          </S.TextContainer>
        )}
      </Row>
      <StyledLink to={ROUTES.PRODUCTION_STATUS} state='production_status' color={mainColor}>
        {'Go to Production page >'}
      </StyledLink>
    </>
  )
}

export default PlannedWidget
