import { MRT_Row } from 'material-react-table';
import React from 'react';
import { AllUpdates } from '../../ExistingServices.types';

export default function HandleSCMPeriodicityCell(options: { row: MRT_Row<AllUpdates> }) {
  const {
    row: { original },
  } = options;
  return (
    <div>
      {original.changeStatus === 'MODIFY'
        ? `${original.oldFrequency} -> ${original.frequency}`
        : `${original.frequency}`}
    </div>
  );
}
