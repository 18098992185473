import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'
import Separator from '../../components/separator/Separator'
import Text from '../../fragments/Text'
import Layout from '../../components/layout/Layout'
import { UserContext } from '../../context/UserProvider'
import http from '../../services/http'
import { ROUTES } from '../../constants/routes'

function Login() {
  const { t } = useTranslation(['Login', 'Error'])
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const { login } = useContext(UserContext)

  const doLog = () => {
    login(username, password)
    delete http.defaults.headers.common.isSSO
  }

  return (
    <Layout>
      <Container>
        <SubContainer>
          <div>
            <Text isTitle>{t('doLogin')}</Text>
            <Separator />

            <Input
              type='text'
              name='email'
              data={username}
              setter={(e: any) => setUsername(e.target.value)}
              placeholder={t('Email').toString()}
            />

            <Separator />

            <Input
              name='password'
              data={password}
              setter={(e: any) => setPassword(e.target.value)}
              type='password'
              placeholder={t('Mot de passe').toString()}
              onEnterCallback={doLog}
            />

            <Separator />

            <Button bgColor='#1e10bf' text={t('Login')} handleClick={doLog} />
          </div>
          <Separator />
          <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password ?</Link>
        </SubContainer>
      </Container>
    </Layout>
  )
}

export default Login

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const SubContainer = styled.div``
