import React from 'react'
import { MRT_Row } from 'material-react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { DeliverableValidationDto } from '../../../../types/dtos/FollowupDto'
import handleDocumentActions from '../utils/handleDocumentAction'
import { ProductionValidationDto } from '../../../../types/dtos/production/productionDto'

export default function HandleOpenCell(
  options: { row: MRT_Row<ProductionValidationDto> },
  mainColor: string
) {
  const {
    row: { original },
  } = options
  if (original.documentExtension === '.pdf')
    return (
      <FontAwesomeIcon
        cursor='pointer'
        icon={faUpRightFromSquare}
        color={mainColor}
        onClick={() => {
          handleDocumentActions([original.documentId], 'OPEN_IN_BROWSER')
        }}
      />
    )
}
