import React, { useContext, useMemo, useState } from 'react'
import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { Checkbox } from '@mui/material'
import MaterialTable from '../../components/table/MaterialTable/MaterialTable'
import ChangelogCell from '../narrativeValidation/components/ChangelogCell'
import { ChangelogNarrative } from '../narrativeValidation/components/narrativeValidationModal/NarrativeValidationModal'
import { NarrativeValidationScopeType } from '../narrativeValidation/NarrativeValidation'
import { TemplateValidationContext } from './context/TemplateValidationContextProvider'
import OpenPdfModalButton from './components/OpenPdfModalButton'
import OpenPdfModal from './components/OpenPdfModal/OpenPdfModal'
import * as S from '../narrativeValidation/NarrativeValidation.styles'
import Button from '../../components/button/Button'
import TemplateValidationApproveModal from './components/templateValidationApproveDeclineModal/TemplateValidationApproveModal'
import TemplateValidationDeclineModal from './components/templateValidationApproveDeclineModal/TemplateValidationDeclineModal'

export type TemplateValidationScopeType = {
  fundName: string
  fundId: number
  id: string
  filePath: string
}

const scopeListData: TemplateValidationScopeType[] = [
  {
    fundId: 1,
    fundName: 'Fund 1',
    id: 'LUXXXXXXXX12' + '-' + 'en' + '-' + 'lu',
    filePath: './KID_20221231_en_LU_4_LUXXXXXXXX55.pdf',
  },
  {
    fundId: 2,
    fundName: 'Fund 2',
    id: 'LUXXXXXXXX12' + '-' + 'en' + '-' + 'fr',
    filePath: './KID_en_LU_4_LU1372006947.pdf',
  },
]

function TemplateValidation() {
  localStorage.setItem('localState', 'template_validation')
  const [isTemplateValidationModalOpen, setIsTemplateValidationModalOpen] = React.useState(false)
  const [selectedRows, setSelectedRows] = React.useState<TemplateValidationScopeType[]>([])
  const [templateValidationData, setTemplateValidationData] =
    useState<TemplateValidationScopeType[]>(scopeListData)
  const [currentFilePath, setCurrentFilePath] = useState<string>('')
  const [approveModalOpenned, setApproveModalOpenned] = useState<boolean>(false)
  const [declineModalOpenned, setDeclineModalOpenned] = useState<boolean>(false)

  const {} = useContext(TemplateValidationContext)

  function CustomCheckbox(row: MRT_Row<TemplateValidationScopeType>) {
    return (
      <Checkbox
        onClick={() => handleRowSelection(row)}
        checked={selectedRows.some(selectedRow => {
          return selectedRow.id === row.original.id
        })}
      />
    )
  }

  function CustomParentCheckbox() {
    return (
      <Checkbox
        onClick={handleSelectAll}
        indeterminate={selectedRows.length > 0 && selectedRows.length < scopeListData.length}
        checked={selectedRows.length === scopeListData.length}
        sx={{
          '&.Mui-checked': { color: 'white' },
          '&.MuiCheckbox-root': { color: 'white', padding: '0px' },
        }}
      />
    )
  }

  const handleRowSelection = (row: MRT_Row<TemplateValidationScopeType>) => {
    const isRowAlreadySelected = selectedRows.some(selectedRow => {
      return selectedRow.id === row.original.id
    })

    if (isRowAlreadySelected) {
      setSelectedRows(prevState =>
        prevState.filter(selectedRow => {
          return selectedRow.id !== row.original.id
        })
      )
    } else {
      setSelectedRows(prevState => [...prevState, row.original])
    }
  }

  const handleSelectAll = () => {
    if (selectedRows.length < scopeListData.length) {
      const addRemainingRows = scopeListData.filter(scope => {
        return !selectedRows.some(selectedRow => {
          return selectedRow.id === scope.id
        })
      })

      setSelectedRows(prevState => [...prevState, ...addRemainingRows])
    } else if (selectedRows.length === scopeListData.length) {
      setSelectedRows([])
    }
  }

  const columns = useMemo<MRT_ColumnDef<TemplateValidationScopeType>[]>(() => {
    return [
      {
        header: 'Row selection',
        Header: () => CustomParentCheckbox(),
        Cell: ({ row }) => CustomCheckbox(row),
      },
      {
        header: 'Fund',
        accessorKey: 'fundName',
      },
      {
        header: 'Template',
        // @ts-ignore
        Cell: options =>
          OpenPdfModalButton(
            options.row.original as TemplateValidationScopeType,
            setIsTemplateValidationModalOpen,
            setCurrentFilePath
          ),
      },
    ]
  }, [scopeListData, selectedRows])

  return (
    <div>
      <OpenPdfModal
        isOpen={isTemplateValidationModalOpen}
        setIsOpen={setIsTemplateValidationModalOpen}
        filePath={currentFilePath}
      />
      <TemplateValidationApproveModal
        isModalOpenned={approveModalOpenned}
        setModalOpenned={setApproveModalOpenned}
        selectedRows={selectedRows}
        setTemplateValidationData={setTemplateValidationData}
      />
      <TemplateValidationDeclineModal
        isModalOpenned={declineModalOpenned}
        setModalOpenned={setDeclineModalOpenned}
        selectedRows={selectedRows}
        setTemplateValidationData={setTemplateValidationData}
      />
      <MaterialTable
        data={templateValidationData}
        columns={columns}
        isLoading={false}
        enableSelectAll={false}
        // initialState={initialStateCodeisin}
        // renderTopToolbar={renderTopToolbar}

        initialState={{}}
      />
      {selectedRows.length > 0 && (
        <S.ActionButtonsContainer>
          <Button
            text='Decline'
            secondary
            handleClick={() => {
              if (selectedRows.length) {
                setDeclineModalOpenned(true)
              }
            }}
          />
          <div style={{ marginRight: 8 }} />
          <Button
            text='Approve'
            handleClick={() => {
              if (selectedRows?.length) {
                setApproveModalOpenned(true)
              }
            }}
            // disabled={!selectedNodes?.length}
          />
        </S.ActionButtonsContainer>
      )}
    </div>
  )
}

export default TemplateValidation
