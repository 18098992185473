import React, { useState, useContext, useEffect, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'
import http from '../../../services/http'
import { ROUTES } from '../../../constants/routes'
import { UserContext } from '../../../context/UserProvider'
import { ReportTypeNeededData } from '../../../types/dtos/FollowupDto'
import { OptionType } from '../../../types/OptionType'
import { ThemeContext } from '../../../context/ThemeProvider'
import { SelectInputSearchControlled } from '../../../components/input/SelectInputSearchControlled'
import MaterialTable from '../../../components/table/MaterialTable/MaterialTable'
import {
  HandleDefaultAggregatedCell,
  HandleDefaultCodeIsinCell,
  HandleMuiTableBodyCellPropsLevels,
  RenderDefaultTopToolBar,
  filtersStyle,
} from '../../../components/table/MaterialTable/ColumnUtilities'
import HandleExtraColumnsCell from './components/HandleExtraColumnsCell'
import * as S from './DataSources.styles'
import { ProductionService } from '../../../services/production/ProductionService'
import { DataSourcesDto } from '../../../types/dtos/production/productionDto'

type LocationParams = {
  reportDate: string
  reportTypeId: number
  recurrent: boolean
}

function DataSources() {
  // Manage context
  const { user } = React.useContext(UserContext)
  const { mainColor, mainColorLight, mainColorVeryLight } = useContext(ThemeContext)
  // Manage data
  const [rawData, setRawData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // const [codeIsinLevel, setCodeIsinLevel] = useState<boolean>(true);
  const [reportLevel, setReportLevel] = useState<'Fund' | 'Subfund' | 'CodeIsin'>('CodeIsin')
  const [uniqueReportTypes, setUniqueReportTypes] = useState<string[]>([])
  // Manage filters
  const [filterSubClient, setFilterSubClient] = useState<OptionType | null>(null)
  const [filterFund, setFilterFund] = useState<OptionType | null>(null)
  const [filterSubfund, setFilterSubfund] = useState<OptionType | null>(null)
  const [filterCodeIsin, setFilterCodeIsin] = useState<OptionType | null>(null)
  //  Manage all filters options
  const [allSubClient, setAllSubClient] = useState<OptionType[]>([])
  const [allFund, setAllFund] = useState<OptionType[]>([])
  const [allSubfund, setAllSubfund] = useState<OptionType[]>([])
  const [allCodeIsin, setAllCodeIsin] = useState<OptionType[]>([])
  //  Define initial config for the table
  const [initialShareclassState] = useState({
    grouping: ['fundName', 'subfundName'],
    columnVisibility: {
      fundName: false,
      subfundName: false,
      subclientName: false,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
      { id: 'codeIsin', desc: false },
    ],
    density: 'compact',
    columnOrder: ['mrt-row-expand', 'fundName', 'subfundName', 'codeIsin'],
  })
  const [initialSubfundState] = useState({
    grouping: ['fundName'],
    columnVisibility: {
      fundName: false,
      subclientName: false,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
    ],
    density: 'compact',
    columnOrder: ['mrt-row-expand', 'fundName', 'subfundName'],
  })
  const navigate = useNavigate()

  // Test if a state is saved
  const savedState = localStorage.getItem('dataSources')
  let state: LocationParams
  state = useLocation().state as LocationParams
  // If it's saved, we used saved state
  if (savedState !== null) {
    state = JSON.parse(savedState)
  } else if (state !== null) {
    // Else we use location params and we store the state
    localStorage.setItem('dataSources', JSON.stringify(state))
  }

  if (!user) {
    throw new Error(`ERROR: User is not connected, you can't access this page`)
  }

  const goBack = () => {
    navigate(ROUTES.PRODUCTION_STATUS, { state: 'current' })
  }

  useEffect(() => {
    if (!state) goBack()

    ProductionService.getDataSources(state.reportDate, state.reportTypeId)
      .then(resp => {
        setRawData(resp.data)
        setIsLoading(false)
        if (resp.data.some(elem => !elem.codeIsin)) {
          setReportLevel('Subfund')
        } else {
          setReportLevel('CodeIsin')
        }
        setUniqueReportTypes(
          resp.data
            .map(elem => elem.dataTypeName)
            .filter(elem => elem !== null) // Filter out null values
            .filter((elem, index, array) => array.indexOf(elem) === index)
            .map(elem => elem as string) // Cast the remaining elements to strings
        )
        const rawShareclassData = resp.data.filter((elem: DataSourcesDto) => elem.codeIsin)
        const rawShareclassUniqueData = resp.data
          .filter((elem: DataSourcesDto) => elem.codeIsin)
          .filter(
            (elem: DataSourcesDto, index: number, array: DataSourcesDto[]) =>
              array.findIndex(
                (sb: DataSourcesDto) =>
                  sb.fundName === elem.fundName &&
                  sb.subfundName === elem.subfundName &&
                  sb.shareclassName === elem.shareclassName
              ) === index
          )
          .map((elem: DataSourcesDto) => ({
            codeIsin: elem.codeIsin,
            fundName: elem.fundName,
            shareclassName: elem.shareclassName,
            subfundName: elem.subfundName,
          }))

        rawShareclassData.forEach((elem: DataSourcesDto) => {
          rawShareclassUniqueData.forEach((sbElem: any) => {
            if (
              sbElem.fundName === elem.fundName &&
              sbElem.subfundName === elem.subfundName &&
              sbElem.shareclassName === elem.shareclassName
              // sbElem.subclientName === elem.subclientName
            ) {
              if (elem.dataTypeName) {
                sbElem[elem.dataTypeName] = elem.isComplete
              }
            }
          })
        })
        setRawData(rawShareclassUniqueData)
      })
      .catch(e => {
        toast.error('Failed to load data')
        console.log(e)
      })
      .finally(() => {
        setIsLoading(false)
      })

    // Remove localstorage after unmount
    return () => {
      localStorage.removeItem('dataSources')
    }
  }, [])

  const createMainColumns = () => {
    let mainColumns: MRT_ColumnDef<ReportTypeNeededData>[] = []
    switch (reportLevel) {
      case 'Subfund':
        mainColumns = [
          {
            header: '',
            accessorKey: 'subfundName',
            enableColumnActions: false,
            enableColumnFilter: false,
            filterFn: 'equalsString',
            AggregatedCell: (options: any) => HandleDefaultAggregatedCell(options),
            muiTableBodyCellProps: (options: any) =>
              HandleMuiTableBodyCellPropsLevels(options, mainColorLight, mainColorVeryLight),
          },
        ]
        break
      default:
        mainColumns = [
          {
            header: 'Subfund',
            accessorKey: 'subfundName',
            enableColumnActions: false,
            filterFn: 'equalsString',
          },
          {
            header: '',
            accessorKey: 'codeIsin',
            enableSorting: false,
            enableColumnActions: false,
            enableFilterMatchHighlighting: false,
            enableColumnFilter: false,
            filterFn: 'equalsString',
            Cell: (options: any) => HandleDefaultCodeIsinCell(options),
            AggregatedCell: (options: any) => HandleDefaultAggregatedCell(options),
            muiTableBodyCellProps: (options: any) =>
              HandleMuiTableBodyCellPropsLevels(options, mainColorLight, mainColorVeryLight),
          },
        ]
        break
    }
    return mainColumns
  }

  //@ts-ignore
  const columns = useMemo<MRT_ColumnDef<ReportTypeNeededData>[]>(() => {
    const extraColumns = uniqueReportTypes.map((column: string) => {
      return {
        header: `${column}`,
        accessorKey: `${column}`,
        columnDefType: 'display',
        Cell: (options: any) => HandleExtraColumnsCell(options, column),
      }
    })
    return [
      {
        header: 'Subclient',
        accessorKey: 'subclientName',
        enableSorting: false,
        enableColumnActions: false,
        filterFn: 'equalsString',
      },
      {
        header: 'Fund',
        accessorKey: 'fundName',
        enableColumnActions: false,
        enableColumnFilter: false,
        filterFn: 'equalsString',
      },
      ...createMainColumns(),
      ...extraColumns,
    ]
  }, [isLoading])

  //Definitions for the filter bar
  const renderTopToolbar = (options: { table: MRT_TableInstance }) => {
    const { table } = options
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <RenderDefaultTopToolBar
          table={table}
          isLoading={isLoading}
          reportLevel={reportLevel}
          filterFund={filterFund}
          filterSubfund={filterSubfund}
          filterCodeIsin={filterCodeIsin}
          setFilterFund={setFilterFund}
          setFilterSubfund={setFilterSubfund}
          setFilterCodeIsin={setFilterCodeIsin}
          allFund={allFund}
          allSubfund={allSubfund}
          allCodeIsin={allCodeIsin}
          preComponent={
            !!user &&
            user.hasSubclient && (
              <SelectInputSearchControlled
                isDisabled={isLoading}
                customStyle={filtersStyle}
                value={filterSubClient}
                options={allSubClient}
                placeholder='Select a subclient'
                onChangeCallback={option => {
                  setFilterCodeIsin(null)
                  setFilterSubfund(null)
                  setFilterFund(null)
                  setFilterSubClient(option)
                  table.setColumnFilters(() => {
                    if (option) {
                      return [{ id: 'subclientName', value: option?.label }]
                    }
                    return []
                  })
                }}
              />
            )
          }
        />
      </div>
    )
  }

  useEffect(() => {
    //for subclient
    const rawSubclient = rawData
      .map((elem: ReportTypeNeededData) => elem.subclientName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: string) => ({ label: elem, value: elem }))
    setAllSubClient(rawSubclient)
  }, [isLoading])

  useEffect(() => {
    //for fund
    const rawFund = rawData
      .filter((elem: ReportTypeNeededData) =>
        filterSubClient?.label ? elem.subclientName === filterSubClient.label : true
      )
      .map((elem: ReportTypeNeededData) => elem.fundName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: string) => ({ label: elem, value: elem }))
    setAllFund(rawFund)
  }, [isLoading, filterSubClient])
  useEffect(() => {
    //for subfund
    const rawsubFund = rawData
      .filter((elem: ReportTypeNeededData) =>
        filterSubClient?.label ? elem.subclientName === filterSubClient.label : true
      )
      .filter((elem: ReportTypeNeededData) =>
        filterFund?.label ? elem.fundName === filterFund.label : true
      )
      .map((elem: ReportTypeNeededData) => elem.subfundName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: string) => ({ label: elem, value: elem }))
    setAllSubfund(rawsubFund)
  }, [isLoading, filterSubClient, filterFund])
  useEffect(() => {
    //for codeIsin
    const rawCodeIsin = rawData
      .filter((elem: ReportTypeNeededData) =>
        filterSubClient?.label ? elem.subclientName === filterSubClient.label : true
      )
      .filter((elem: ReportTypeNeededData) =>
        filterFund?.value ? elem.fundName === filterFund.label : true
      )
      .filter((elem: ReportTypeNeededData) =>
        filterSubfund?.value ? elem.subfundName === filterSubfund.label : true
      )
      .map((elem: ReportTypeNeededData) => elem.codeIsin)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllCodeIsin(rawCodeIsin)
  }, [isLoading, filterSubClient, filterFund, filterSubfund])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Toaster />
      <div>
        <S.BackAndSelect>
          <div style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faArrowLeftLong} size='2x' color={mainColor} onClick={goBack} />
          </div>
        </S.BackAndSelect>
        {reportLevel === 'CodeIsin' && (
          <MaterialTable
            data={rawData}
            maxHeight={76}
            columns={columns}
            enableSelectAll={false}
            isLoading={isLoading}
            initialState={initialShareclassState}
            renderTopToolbar={renderTopToolbar}
          />
        )}
        {reportLevel === 'Subfund' && (
          <MaterialTable
            data={rawData}
            maxHeight={76}
            columns={columns}
            enableSelectAll={false}
            isLoading={isLoading}
            initialState={initialSubfundState}
            renderTopToolbar={renderTopToolbar}
          />
        )}
      </div>
    </div>
  )
}

export default DataSources
