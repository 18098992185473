import React, { useContext, useMemo, useState } from 'react'
import { Layout, Responsive, WidthProvider } from 'react-grid-layout'
import styled from 'styled-components'
import { headerHeight } from '../../components/header/Header'
import { COLOR } from '../../constants/theme'
import { UserContext } from '../../context/UserProvider'
import useDimensions from '../../hooks/useDimensions'

// This is needed for correct grid layout style
import 'react-grid-layout/css/styles.css'
import { DashboardContext } from '../../context/DashboardProvider'
import http from '../../services/http'
import FirstConnectionModal from './FirstConnectionModal'
import './react-grid-layout-override.css'
import DocumentValidationWidget from './widgets/DocumentValidationWidget/DocumentValidationWidget'
import LastTimeWidget from './widgets/LastTimeWidget/LastTimeWidget'
import NewsWidget from './widgets/NewsWidget'
import PlannedWidget from './widgets/PlannedWidget/PlannedWidget'
import ProductionStatusWidget from './widgets/ProductionStatusWidget/ProductionStatusWidget'
import Weather from './widgets/WeatherWidget/WeatherWidget'
import { CLIENT_WELLINGTON_ID, ROLES } from '../../constants/constants'
import WellingtonWidget from './widgets/WellingtonWidget/WellingtonWidget'

const Grid = WidthProvider(Responsive)

const Card = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`

const CardContent = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
`

const CardTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: ${COLOR.SEMI_LIGHT_GRAY};
  @media (max-width: 1000px) {
    font-size: 20px;
  }
`

type WidgetType =
  | 'Production Status'
  | "What's new ?"
  | 'Weather in'
  | 'Planned Reports'
  | 'Document Validation'
  | 'Since last time...'
  | 'Your RFI'

type LayoutWithWidgetType = Layout & { i: WidgetType }

type LayoutMap = { [idx: string]: LayoutWithWidgetType[] }

const mapWidgetToComponent = (widget: WidgetType) => {
  switch (widget) {
    case 'Production Status':
      return ProductionStatusWidget
    case "What's new ?":
      return NewsWidget
    case 'Weather in':
      return Weather
    case 'Your RFI':
      return WellingtonWidget
    case 'Planned Reports':
      return PlannedWidget
    case 'Document Validation':
      return DocumentValidationWidget
    case 'Since last time...':
      return LastTimeWidget
  }
}

const baseWidgetsLgWithParent: LayoutWithWidgetType[] = [
  { i: 'Planned Reports', x: 0, y: 0, w: 1, h: 1 },
  { i: 'Since last time...', x: 1, y: 0, w: 2, h: 1 },
  { i: 'Production Status', x: 0, y: 1, w: 3, h: 1 },
  { i: "What's new ?", x: 0, y: 2, w: 1.5, h: 1 },
  { i: 'Weather in', x: 1.5, y: 2, w: 1.5, h: 1 },
]

const baseWidgetsSmWithParent: LayoutWithWidgetType[] = [
  { i: 'Planned Reports', x: 0, y: 1, w: 1, h: 1 },
  { i: 'Since last time...', x: 1, y: 0, w: 1, h: 1 },
  { i: 'Production Status', x: 0, y: 2, w: 2, h: 1 },
  { i: "What's new ?", x: 0, y: 2, w: 2, h: 1 },
  { i: 'Weather in', x: 1.5, y: 4, w: 2, h: 1 },
]

const baseWidgetsLg: LayoutWithWidgetType[] = [
  { i: 'Planned Reports', x: 0, y: 0, w: 1, h: 1 },
  { i: 'Document Validation', x: 1, y: 0, w: 1, h: 1 },
  { i: 'Since last time...', x: 2, y: 0, w: 1, h: 2 },
  { i: 'Production Status', x: 0, y: 1, w: 2, h: 1 },
  { i: "What's new ?", x: 0, y: 2, w: 1.5, h: 1 },
  { i: 'Weather in', x: 1.5, y: 2, w: 1.5, h: 1 },
]

const baseWidgetsSm: LayoutWithWidgetType[] = [
  { i: 'Planned Reports', x: 0, y: 1, w: 1, h: 1 },
  { i: 'Document Validation', x: 1, y: 1, w: 1, h: 1 },
  { i: 'Since last time...', x: 0, y: 0, w: 2, h: 1 },
  { i: 'Production Status', x: 0, y: 2, w: 2, h: 1 },
  { i: "What's new ?", x: 0, y: 2, w: 2, h: 1 },
  { i: 'Weather in', x: 1.5, y: 4, w: 2, h: 1 },
]

const baseWidgetsLgForWellington: LayoutWithWidgetType[] = [
  { i: 'Planned Reports', x: 0, y: 0, w: 1, h: 1 },
  { i: 'Document Validation', x: 1, y: 0, w: 1, h: 1 },
  { i: 'Since last time...', x: 2, y: 0, w: 1, h: 2 },
  { i: 'Production Status', x: 0, y: 1, w: 2, h: 1 },
  { i: "What's new ?", x: 0, y: 2, w: 1.5, h: 1 },
  { i: 'Your RFI', x: 1.5, y: 2, w: 1.5, h: 1 },
]

const baseWidgetsSmForWellington: LayoutWithWidgetType[] = [
  { i: 'Planned Reports', x: 0, y: 1, w: 1, h: 1 },
  { i: 'Document Validation', x: 1, y: 1, w: 1, h: 1 },
  { i: 'Since last time...', x: 0, y: 0, w: 2, h: 1 },
  { i: 'Production Status', x: 0, y: 2, w: 2, h: 1 },
  { i: "What's new ?", x: 0, y: 2, w: 2, h: 1 },
  { i: 'Your RFI', x: 1.5, y: 4, w: 2, h: 1 },
]

const baseWidgets: LayoutMap = { lg: baseWidgetsLg, sm: baseWidgetsSm }
const baseWidgetsWithParent: LayoutMap = {
  lg: baseWidgetsLgWithParent,
  sm: baseWidgetsSmWithParent,
}
const baseWidgetsForWellington: LayoutMap = {
  lg: baseWidgetsLgForWellington,
  sm: baseWidgetsSmForWellington,
}

const breakpoints = { lg: 1000, sm: 960 }
const colsPerLayout: { [idx: string]: number } = { lg: 3, sm: 2 }
const rowsPerLayout: { [idx: string]: number } = { lg: 3, sm: 5 }

function Dashboard() {
  localStorage.setItem('localState', 'dashboard')
  const { user } = React.useContext(UserContext)

  // Various configurations for the grid
  const [layoutType, setLayoutType] = useState<'lg' | 'sm'>('lg')
  const rows = useMemo(() => {
    return rowsPerLayout[layoutType]
  }, [layoutType])
  const gridMargin = 20
  const gridHeight = useDimensions().height - headerHeight

  // We calculate rowHeight and force it to be at least 250px to avoid too small widgets
  const rowHeight = useMemo(() => {
    const calculatedRowHeight = (gridHeight - gridMargin * rows) / rows
    return Math.max(250, Math.floor(calculatedRowHeight))
  }, [gridHeight, rows])

  // Create widget array based on layout (we memoize widgets array to avoid re-rendering)
  const widgetArray = useMemo(() => {
    const layoutMap = () => {
      if (user?.clientId === CLIENT_WELLINGTON_ID) {
        return baseWidgetsForWellington[layoutType]
      }
      if (user?.hasParent) {
        return baseWidgetsWithParent[layoutType]
      }
      return baseWidgets[layoutType]
    }

    return Array.from(layoutMap()).map(widgetInfo => {
      const { i } = widgetInfo
      const Component = mapWidgetToComponent(i)
      const title = i === 'Weather in' ? `${i} ${user?.historicalLocation ?? 'Luxembourg'}` : i
      return (
        <Card key={i}>
          <CardContent>
            <CardTitle>{title}</CardTitle>
            <Component layout={layoutType} />
          </CardContent>
        </Card>
      )
    })
  }, [layoutType, user?.historicalLocation])

  // const [currentLayout, setCurrentLayout] = useState<LayoutMap>(baseWidgets)
  // const workingLayout = useRef(currentLayout)
  // Keep these function in case we need the drag & drop feature (needs reworking but you got the idea)
  //
  // const saveLayout = useCallback((layout: Layout[]) => {
  //   workingLayout.current = layout as LayoutWithWidgetType[]
  // }, [])

  // useEffect(() => {
  //   setTimeout(() => {
  //     currentLayout.forEach(item => {
  //       if (item.y + item.h > rows) {
  //         setCurrentLayout(workingLayout.current)
  //         return
  //       }
  //     })
  //   }, 50)
  // }, [currentLayout, rows])

  if (!user) {
    throw new Error(`ERROR: User is not connected, you can't access this page`)
  }

  const { showModal, setShowModal } = useContext(DashboardContext)
  const [checked, setChecked] = useState(false)

  const handleClose = () => {
    setShowModal(false)
    if (checked) {
      http.get('/client-users/toggle-hide-help-modal')
    }
  }

  const renderLayout = () => {
    if (user?.clientId === CLIENT_WELLINGTON_ID) {
      return baseWidgetsForWellington
    }
    if (user?.hasParent) {
      return baseWidgetsWithParent
    }
    return baseWidgets
  }

  return (
    <>
      <FirstConnectionModal
        isOpen={showModal ?? false}
        checked={checked}
        setChecked={setChecked}
        close={handleClose}
      />
      <Grid
        isDraggable={false}
        isBounded
        isResizable={false}
        autoSize
        layouts={renderLayout()}
        breakpoints={breakpoints}
        onBreakpointChange={newBreakpoint => {
          setLayoutType(newBreakpoint as 'lg' | 'sm')
        }}
        cols={colsPerLayout}
        // onDragStart={saveLayout}
        // onResizeStart={saveLayout}
        compactType='vertical'
        margin={[gridMargin, gridMargin]}
        maxRows={rows}
        rowHeight={rowHeight}
        containerPadding={[0, 0]}>
        {widgetArray}
      </Grid>
    </>
  )
}

export default Dashboard
