import { faClose } from '@fortawesome/free-solid-svg-icons'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../../context/ThemeProvider'
import ButtonWithIcon from '../button/ButtonWithIcon'
import Title from '../text/Title'
import Modal from './Modal'

type Props = {
  isModalOpenned: any
  closeModalHandler: any
  actionCallback: any
  text?: string
  children?: React.ReactNode
  title?: string
  width?: number
  height?: number
}

function ConfirmModal({
  isModalOpenned,
  closeModalHandler,
  actionCallback,
  text,
  children,
  title,
  width,
  height,
}: Props) {
  const { mainColorLight } = useContext(ThemeContext)

  return (
    <Modal
      isModalOpenned={isModalOpenned}
      setIsModalOpenned={closeModalHandler}
      width={width ?? 400}
      height={height ?? 125}>
      <Container>
        <Title>{title || 'Are you sure ?'}</Title>
        {text ? <div>{text}</div> : <></>}
        {children}
        <ButtonContainer>
          <ButtonWithIcon
            text='Confirm'
            onClick={() => actionCallback()}
            customButtonStyle={{ backgroundColor: mainColorLight }}
          />
          <ButtonWithIcon text='Cancel' icon={faClose} onClick={() => closeModalHandler()} />
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

export default ConfirmModal

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
