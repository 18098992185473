import http from '../http'
import {
  DataSourcesDto,
  DataSourcesSchema,
  DeliveryEstimationDto,
  DeliveryEstimationSchema,
  ProductionStatusDashboardDto,
  ProductionStatusDashboardSchema,
  ProductionValidationDto,
  ProductionValidationSchema,
} from '../../types/dtos/production/productionDto'

const baseUrl = '/production'

export const ProductionService = {
  getProductionStatusDashboardData: () => {
    return http
      .get<ProductionStatusDashboardDto[]>(`${baseUrl}/production-status-dashboard`)
      .then(response => {
        ProductionStatusDashboardSchema.array().parse(response.data)
        return response
      })
  },

  getAllProductionValidationDto: (
    reportDate: string,
    reportTypeId: number,
    fundId?: number,
    subfundId?: number,
    languageId?: number,
    countryId?: number
  ) => {
    return http
      .get<ProductionValidationDto[]>(`${baseUrl}/all-production-validation-dto`, {
        params: {
          reportDate,
          reportTypeId,
          fundId,
          subfundId,
          languageId,
          countryId,
        },
      })
      .then(response => {
        ProductionValidationSchema.array().parse(response.data)
        return response
      })
  },

  approveOrDeclineReports: (documentsIds: number[], approve: boolean, comment?: string) => {
    return http.put(`${baseUrl}/approve-or-decline-reports`, {
      documentsIds,
      approve,
      comment,
    })
  },

  getDeliveryEstimationDeadline: (
    reportDate: string,
    reportTypeId: number,
    productionStatusId?: number
  ) => {
    return http
      .get<DeliveryEstimationDto[]>(`${baseUrl}/delivery-estimation`, {
        params: {
          reportDate,
          reportTypeId,
          productionStatusId,
        },
      })
      .then(response => {
        DeliveryEstimationSchema.array().parse(response.data)
        return response
      })
  },

  getDataSources: (reportDate: string, reportTypeId: number) => {
    return http
      .get<DataSourcesDto[]>(`${baseUrl}/data-sources`, {
        params: {
          reportDate,
          reportTypeId,
        },
      })
      .then(response => {
        DataSourcesSchema.array().parse(response.data)
        return response
      })
  },

  getPortfolioDashboard(date: string) {
    return http.get(`${baseUrl}/portfolio-recon-dashboard`, {
      params: {
        date,
      },
    })
  },
}
