import { MRT_Column, MRT_Row } from 'material-react-table'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { SubscribedReport } from '../../../../types/dtos/SubscribedReportDto'
import { getEmails } from '../utilities/utilities'
import { Scopes } from '../DisseminationList.types'

export default function HandleReportAggregatedCell(
  rawsubfundData: any,
  options: { row: MRT_Row<Scopes>; column: MRT_Column<Scopes> },
  distinctSubscribedReports: SubscribedReport[],
  mainColor: string,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setEmailListLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setEmailList: any
) {
  const {
    row: { original },
  } = options
  const reportTypeId = distinctSubscribedReports.find(
    elem => elem.reportTypeShortName === options.column.id
  )?.reportTypeId
  if (options.row.groupingColumnId === 'subfundName') {
    if (
      reportTypeId &&
      rawsubfundData.findIndex(
        (elem: any) =>
          elem.fundId === original.fundId &&
          elem.subfundId === original.subfundId &&
          elem.reportTypeName === options.column.id
      ) !== -1
    ) {
      return (
        <FontAwesomeIcon
          icon={faInfo}
          color={mainColor}
          onClick={() => {
            getEmails(
              reportTypeId as number,
              original.fundId,
              original.subfundId,
              null,
              setIsModalOpen,
              setEmailListLoading,
              setEmailList
            )
          }}
          style={{ cursor: 'pointer' }}
        />
      )
    }
    return null
  }
  return null
}
