import React, { useContext, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'
import { faCircleCheck, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactCountryFlag from 'react-country-flag'
import { Tooltip } from '@mui/material'
import RegistrationMatrixService from '../../../services/RegistrationMatrixService/registrationMatrixService'
import {
  CountryRegistrationMatrixDto,
  RegistrationMatrixData,
  RegistrationMatrixStatusDto,
} from '../../../types/dtos/RegistrationMatrix/RegistrationMatrixDto'
import { ThemeContext } from '../../../context/ThemeProvider'
import { COLOR } from '../../../constants/theme'
import MaterialTable from '../../../components/table/MaterialTable/MaterialTable'
import { OptionType } from '../../../types/OptionType'
import {
  HandleDefaultAggregatedCell,
  HandleDefaultCodeIsinCell,
  RenderDefaultTopToolBar,
  HandleMuiTableBodyCellPropsLevels,
} from '../../../components/table/MaterialTable/ColumnUtilities'
import downloadService from '../../../services/DownloadService/downloadService'
import ButtonWithIcon from '../../../components/button/ButtonWithIcon'

const renderColumnHeader = (country: CountryRegistrationMatrixDto) => {
  if (country.countryIso2) {
    return (
      <Tooltip title={country.regulatorName}>
        <div>
          <ReactCountryFlag countryCode={country.countryIso2} svg />
          <span style={{ marginLeft: '5px' }}>{country.countryIso2.toUpperCase()}</span>
        </div>
      </Tooltip>
    )
  }
  return (
    <Tooltip title={country.regulatorName}>
      <div>${country.countryName}</div>
    </Tooltip>
  )
}

function RegistrationMatrix() {
  localStorage.setItem('localState', 'registration_matrix')
  // Manage context
  const { mainColor, mainColorLight, mainColorVeryLight } = useContext(ThemeContext)
  // Manage data
  const [matrix, setMatrix] = useState<RegistrationMatrixData[]>([])
  const [countries, setCountries] = useState<CountryRegistrationMatrixDto[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [reportLevel] = useState<'Fund' | 'Subfund' | 'CodeIsin'>('CodeIsin')
  // Manage filters
  const [filterFund, setFilterFund] = useState<OptionType | null>(null)
  const [filterSubfund, setFilterSubfund] = useState<OptionType | null>(null)
  const [filterCodeIsin, setFilterCodeIsin] = useState<OptionType | null>(null)
  //  Manage all filters options
  const [allFund, setAllFund] = useState<OptionType[]>([])
  const [allSubfund, setAllSubfund] = useState<OptionType[]>([])
  const [allCodeIsin, setAllCodeIsin] = useState<OptionType[]>([])
  // Display status
  const [initialState] = useState({
    grouping: ['fundName', 'subfundName'],
    columnVisibility: {
      fundName: false,
      subfundName: false,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
    ],
    density: 'compact',
  })

  //Definitions for the filter bar
  const renderTopToolbar = (options: { table: MRT_TableInstance }) => {
    const { table } = options
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <RenderDefaultTopToolBar
          table={table}
          isLoading={isLoading}
          reportLevel={reportLevel}
          filterFund={filterFund}
          filterSubfund={filterSubfund}
          filterCodeIsin={filterCodeIsin}
          setFilterFund={setFilterFund}
          setFilterSubfund={setFilterSubfund}
          setFilterCodeIsin={setFilterCodeIsin}
          allFund={allFund}
          allSubfund={allSubfund}
          allCodeIsin={allCodeIsin}
          afterComponent={
            <ButtonWithIcon
              customButtonStyle={{ marginLeft: 'auto' }}
              text='Export matrix'
              icon={faDownload}
              onClick={handleExportExcel}
            />
          }
        />
      </div>
    )
  }

  useEffect(() => {
    RegistrationMatrixService.getAllRegistrationMatrix()
      .then(resp => {
        const {
          data: { registrationMatrixDataList },
        } = resp
        registrationMatrixDataList.forEach((listElem: any) => {
          const oldCodeIsin = listElem.shareclassIsin
          listElem.codeIsin = oldCodeIsin
          delete listElem.shareclassIsin
        })
        setMatrix(registrationMatrixDataList)
        setCountries(resp.data.distinctCountryNameSet)
      })
      .catch(e => {
        toast.error('There was an error during data fetching')
        console.log(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const createColumns = () => {
    const columns: MRT_ColumnDef<RegistrationMatrixData>[] = countries.map(country => {
      let regulatorName = ''
      if (country.regulatorName) {
        regulatorName = ` - ${country.regulatorName}`
      }
      return {
        header: `${country.countryName}${regulatorName}`,
        Header: renderColumnHeader(country),
        columnDefType: 'display',
        Cell: options => {
          for (const [key, value] of Object.entries<RegistrationMatrixStatusDto>(
            options.row.original.registrationStatusMap
          )) {
            if (country.countryName === key) {
              if (value.deregistered) {
                return <div>De-registered</div>
              }
              return (
                <span>
                  <FontAwesomeIcon icon={faCircleCheck} color={mainColor} size='lg' />
                </span>
              )

              // }
            }
          }
        },
      }
    })

    return columns
  }

  // Manage the data that will be displayed according to previous filters
  useEffect(() => {
    //for fund
    const rawFund = matrix
      .map((elem: RegistrationMatrixData) => elem.fundName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllFund(rawFund)
  }, [isLoading])
  useEffect(() => {
    //for subfund
    const rawsubFund = matrix
      .filter((elem: RegistrationMatrixData) =>
        filterFund?.label ? elem.fundName === filterFund.label : true
      )
      .map((elem: RegistrationMatrixData) => elem.subfundName)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllSubfund(rawsubFund)
  }, [isLoading, filterFund])
  useEffect(() => {
    //for codeIsin
    const rawCodeIsin = matrix
      .filter((elem: RegistrationMatrixData) =>
        filterFund?.value ? elem.fundName === filterFund.label : true
      )
      .filter((elem: RegistrationMatrixData) =>
        filterSubfund?.value ? elem.subfundName === filterSubfund.label : true
      )
      .map((elem: RegistrationMatrixData) => elem.shareclassIsin)
      .filter((elem, index, array) => array.indexOf(elem) === index)
      .sort()
      .map((elem: any) => ({ label: elem, value: elem }))
    setAllCodeIsin(rawCodeIsin)
  }, [isLoading, filterFund, filterSubfund])

  const columns = useMemo<MRT_ColumnDef<RegistrationMatrixData>[]>(
    () => [
      {
        header: 'Fund name',
        accessorKey: 'fundName',
        filterFn: 'equalsString',
      },
      {
        header: 'Subfund name',
        accessorKey: 'subfundName',
        filterFn: 'equalsString',
      },
      {
        header: '',
        accessorKey: 'shareclassIsin',
        filterFn: 'equalsString',
        columnDefType: 'display',
        minSize: 600,
        Cell: (options: any) => HandleDefaultCodeIsinCell(options),
        AggregatedCell: (options: any) => HandleDefaultAggregatedCell(options),
        muiTableHeadCellProps: {
          align: 'left',
          sx: {
            color: COLOR.WHITE,
            backgroundColor: mainColor,
            boxSizing: 'border-box',
            fontSize: '18px',
            fontWeight: '15px',
          },
        },
        muiTableBodyCellProps: (options: any) =>
          HandleMuiTableBodyCellPropsLevels(options, mainColorLight, mainColorVeryLight),
      },
      ...createColumns(),
    ],

    [countries]
  )

  const handleExportExcel = () => {
    toast.promise(RegistrationMatrixService.exportExcel(), {
      loading: 'Exporting registration matrix...',
      success: resp => {
        let filename = 'RegistrationMatrix.xlsx'
        if (resp.headers['content-disposition']) {
          filename = resp.headers['content-disposition'].split('filename=')[1].replaceAll('"', '')
        }
        downloadService.downloadDoc(resp.data, filename)
        return 'Registration matrix exported successfully!'
      },
      error: e => {
        console.log(e)
        return 'An error occurred during registration matrix export.'
      },
    })
  }

  return (
    <div>
      <MaterialTable
        data={matrix}
        columns={columns}
        enableSelectAll={false}
        isLoading={isLoading}
        initialState={initialState}
        maxHeight={80}
        renderTopToolbar={renderTopToolbar}
      />
    </div>
  )
}

export default RegistrationMatrix
