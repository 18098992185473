import { z } from 'zod';
import http from '../http';

const changeScopeListSchema = z.object({
  changeScopeApproved: z.boolean().nullable(),
  changeScopeId: z.number(),
  changeScopeStatus: z.string(),
  codeIsin: z.string().nullable(),
  frequencyId: z.number().nullable(),
  frequencyLibelle: z.string(),
  fundId: z.number(),
  fundName: z.string(),
  oldFrequencyId: z.number().nullable(),
  oldFrequencyLibelle: z.string().nullable(),
  reportTypeId: z.number(),
  reportTypeName: z.string(),
  shareclassId: z.number().nullable(),
  shareclassName: z.string().nullable(),
  subfundId: z.number().nullable(),
  subfundName: z.string().nullable(),
});

export const scopesChangesSchema = z.object({
  changeId: z.number(),
  changeScopeList: changeScopeListSchema.array(),
  clientUserEmail: z.string().email(),
  clientUserFullName: z.string(),
  clientUserId: z.number(),
});

export type ScopesChangesDto = z.infer<typeof scopesChangesSchema>;

const ScopesService = {
  getScopesChanges: () => {
    return http.get<ScopesChangesDto[]>('/scopes/changes').then(resp => {
      scopesChangesSchema.array().parse(resp.data);
      return resp;
    });
  },
};

export default ScopesService;
