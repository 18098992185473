import React from 'react'
import ConfirmModal from '../../../../components/modal/ConfirmModal'
import { NarrativeValidationScopeType } from '../../NarrativeValidation'

type Props = {
  isModalOpenned: boolean
  setModalOpenned: React.Dispatch<React.SetStateAction<boolean>>
  selectedRows: NarrativeValidationScopeType[]
  setNarrativeValidationData: React.Dispatch<React.SetStateAction<NarrativeValidationScopeType[]>>
}

function NarrativeValidationApproveModal({
  isModalOpenned,
  setModalOpenned,
  selectedRows,
  setNarrativeValidationData,
}: Props) {
  return (
    <ConfirmModal
      width={350}
      height={150}
      title='Approve narratives'
      text={
        selectedRows.length === 1
          ? `1 document will be approved, are you sure ?`
          : `${selectedRows.length} documents will be approved, are you sure ?`
      }
      isModalOpenned={isModalOpenned}
      closeModalHandler={() => setModalOpenned(false)}
      actionCallback={() => {
        setModalOpenned(false)
        setNarrativeValidationData(prevState => {
          return prevState.filter(scope => {
            // Remove selected rows from scopeListData
            return !selectedRows.some(selectedRow => {
              return selectedRow.id === scope.id
            })
          })
        })
      }}
    />
  )
}

export default NarrativeValidationApproveModal
