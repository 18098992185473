import React, { useContext } from 'react'
import styled from 'styled-components'
import ProductionStatusKpi from './components/ProductionStatusKpi/ProductionStatusKpi'
import InvoicingKpi from '../Invoicing/InvoicingKpi'
import { UserContext } from '../../context/UserProvider'
import { ROLES } from '../../constants/constants'

function Kpi() {
  localStorage.setItem('localState', 'KPI')
  const { user } = useContext(UserContext)

  return (
    <KpiContainer>
      <KpiBox>
        <ProductionStatusKpi />
      </KpiBox>
      {/* {user?.roles.includes(ROLES.INVOICE) && (
        <KpiBox>
          <InvoicingKpi />
        </KpiBox>
      )} */}
    </KpiContainer>
  )
}

export default Kpi

const KpiContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
`

const KpiBox = styled.div`
  min-width: 40%;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`
