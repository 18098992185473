import React from 'react';
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MRT_Cell } from 'material-react-table';
import { COLOR } from '../../../constants/theme';
import { PortfolioType } from '../Portfolio.types';

export default function HandleRestOfColumnsCell(options: { cell: MRT_Cell<PortfolioType> }) {
  const { cell } = options;
  if (cell.getValue()) {
    return <FontAwesomeIcon icon={faCheckCircle} color={COLOR.GREEN} />;
  }
  return <FontAwesomeIcon icon={faWarning} color={COLOR.RED} />;
}
