import React, { useState, useEffect, useContext, useRef } from 'react'
import MaterialReactTable, {
  MRT_PaginationState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableInstance,
} from 'material-react-table'
import { ExpandedState } from '@tanstack/react-table'
import { Property } from 'csstype'
import { COLOR } from '../../../constants/theme'
import { ThemeContext } from '../../../context/ThemeProvider'

type MaterialTableProps = {
  data: any[]
  columns: any[]
  initialState: any
  isLoading: boolean
  maxHeight?: Property.MaxHeight<string | number>
  enableRowSelection?: boolean | ((row: MRT_Row) => boolean)
  enableSelectAll: boolean
  renderTopToolbar?: (props: any) => any
  setRowSelected?: any
  getRowId?: any
  changeListener?: boolean
  allowPagination?: boolean
}

function MaterialTable(props: MaterialTableProps) {
  const {
    data,
    columns,
    initialState,
    enableRowSelection,
    enableSelectAll,
    renderTopToolbar,
    setRowSelected,
    getRowId,
    isLoading,
    changeListener,
    maxHeight = undefined,
    allowPagination,
  } = props

  const { mainColor, mainColorLight, mainColorVeryLight } = useContext(ThemeContext)

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  })
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const tableRef = useRef<MRT_TableInstance>(null)

  useEffect(() => {
    // Remove the aggregated rows manually selected
    if (enableRowSelection) {
      if (
        Object.keys(rowSelection).length &&
        Object.keys(rowSelection).every(elem => elem.startsWith('fund'))
      ) {
        setRowSelection({})
      }
      setRowSelected(
        Object.keys(rowSelection)
          .filter((elem: string) => !elem.startsWith('fund'))
          //@ts-ignore
          .map((elem: string) => elem * 1)
      )
    }
  }, [rowSelection])

  useEffect(() => {
    setRowSelection({})
  }, [changeListener])

  const handleOnExpandedChange = (event: any) => {
    if (tableRef.current?.getIsAllRowsExpanded()) {
      setPagination(prev => ({ pageIndex: 0, pageSize: prev.pageSize }))
    }
    setExpanded(event)
  }

  const handleOnPaginationChange = (event: any) => {
    if (tableRef.current?.refs.tableContainerRef.current.scrollTop) {
      tableRef.current.refs.tableContainerRef.current.scrollTop = 0
    }
    setPagination(event)
  }

  return (
    <MaterialReactTable
      tableInstanceRef={tableRef}
      columns={columns}
      data={data}
      initialState={initialState}
      state={{ pagination, rowSelection, isLoading, expanded }} // pass react state to save the selected rows
      enableGrouping // allow group rows
      onRowSelectionChange={setRowSelection} // manage the selected rows
      onPaginationChange={handleOnPaginationChange} // manage the pages
      onExpandedChange={handleOnExpandedChange} //manage the changes when expanded is used
      enablePagination={allowPagination !== undefined ? allowPagination : true}
      getRowId={getRowId} // change the saved component in the state to the codeIsin
      enableColumnDragging={false} // remove grouping bar from the header
      enableStickyHeader // the header is show with the scroll
      enableStickyFooter={false}
      enableRowSelection={enableRowSelection} //enable select box for the rows
      enableSelectAll={enableSelectAll}
      renderTopToolbar={renderTopToolbar || null}
      selectAllMode='all'
      enableDensityToggle={false}
      // styles below
      defaultColumn={{
        minSize: 50,
        maxSize: 600,
        size: 120,
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: 'none',
        },
      }}
      muiTableContainerProps={{
        sx: {
          maxHeight: maxHeight ? `${maxHeight}vh` : '',
          scrollBehavior: 'smooth',
          maxWidth: '100%',
        },
      }}
      muiTableHeadRowProps={{
        sx: {
          boxSizing: 'border-box',
          alignItems: 'center',
        },
      }}
      muiTableHeadCellProps={{
        align: 'center',
        sx: {
          fontWeight: 600,
          backgroundColor: mainColor,
          color: COLOR.WHITE,
          boxSizing: 'border-box',
          fontSize: '18px',
          boxShadow: 'none',
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      }}
      muiTableBodyCellProps={({ row }) => {
        let backgroundColor = COLOR.WHITE
        if (row.depth === 0 && row.getIsGrouped()) {
          backgroundColor = mainColorLight
        } else if (row.depth === 1) {
          backgroundColor = mainColorVeryLight
        }
        return {
          align: 'center',
          sx: {
            overflow: 'inherit',
            padding: '0px',
            backgroundColor,
            boxShadow: 'none',
          },
        }
      }}
      muiTablePaginationProps={({ table }) => {
        return {
          showFirstButton: true,
          showLastButton: true,
          sx: {
            width: `${table.refs.tableContainerRef.current?.clientWidth}px`,
            padding: '0px',
            display: 'flex',
            justifyContent: 'center',
          },
        }
      }}
      muiSelectAllCheckboxProps={() => {
        return {
          sx: {
            '&.Mui-checked': { color: mainColorVeryLight },
            '&.MuiCheckbox-indeterminate': { color: mainColorVeryLight },
          },
        }
      }}
      muiSelectCheckboxProps={({ row }) => {
        if (!row.getCanSelect()) {
          return {
            sx: {
              display: 'none',
            },
          }
        }
        return {
          sx: {
            '&.Mui-checked': { color: mainColor },
            '&.MuiCheckbox-indeterminate': { color: mainColor },
          },
        }
      }}
      // @ts-ignore
      muiExpandButtonProps={({ row }) => {
        if (row?.subRows?.length === 0) {
          return {
            sx: {
              display: 'none',
            },
          }
        }
        return null
      }}
      muiTableFooterRowProps={() => {
        return {
          sx: {
            backgroundColor: mainColor,
            height: '39px',
          },
        }
      }}
      displayColumnDefOptions={{
        'mrt-row-select': {
          maxSize: 50,
          minSize: 50,
        },
        'mrt-row-expand': {
          maxSize: 50,
          minSize: 50,
        },
      }}
    />
  )
}

MaterialTable.defaultProps = {}

export default MaterialTable
