import React, { createContext } from 'react';
import http from '../services/http';
import ScopesService, { ScopesChangesDto } from '../services/ScopesService/scopesService';

export const NotificationContext = createContext<{
  notifications: any[];
  gatherNotifications: React.Dispatch<React.SetStateAction<any>>;
  render: any;
}>({
  notifications: [],
  gatherNotifications: () => undefined,
  render: () => undefined,
});

type Props = {
  children: React.ReactNode;
};

function NotificationProvider({ children }: Props) {
  const [notifications, setNotifications] = React.useState<ScopesChangesDto[]>([]);

  const gatherNotifications: any = (loadingCallback: any) => {
    loadingCallback(true);
    ScopesService.getScopesChanges()
      .then(resp => {
        setNotifications(resp.data);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        loadingCallback(false);
      });
  };

  const update = () => {
    setNotifications([...notifications]);
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        gatherNotifications,
        render: update,
      }}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;
