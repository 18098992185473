import { MRT_Row } from 'material-react-table'
import React from 'react'
import { Followup } from '../ProductionStatus.types'
import CheckIcon from '../../../components/icon/CheckIcon'
import { ProductionStatusDashboardDto } from '../../../types/dtos/production/productionDto'

export default function Handle3rdPartyCell(options: {
  row: MRT_Row<ProductionStatusDashboardDto>
}) {
  const {
    row: { original },
  } = options
  if (original.inSendingCount === original.totalDeliverable) {
    return <CheckIcon />
  }
  return <div>{original.inSendingCount}</div>
}
