import { z } from 'zod'

export const TreeSchema = z.object({
  fundId: z.number(),
  fundName: z.string(),
  subfundId: z.number(),
  subfundName: z.string(),
  codeIsin: z.string().nullable(),
  shareclassId: z.number().nullable(),
  shareclassName: z.string().nullable(),
})

export const TreeWithCountryLanguageSchema = TreeSchema.extend({
  countryId: z.number().nullable(),
  countryName: z.string().nullable(),
  countryIsoCode2: z.string().nullable(),
  languageId: z.number().nullable(),
  languageName: z.string().nullable(),
  languageIsoCode2: z.string().nullable(),
})

export type Tree = z.infer<typeof TreeSchema>
export type TreeWithCountryLanguage = z.infer<typeof TreeWithCountryLanguageSchema>

export const ScopeDtoSchema = z.object({
  clientId: z.number(),
  clientName: z.string(),
  subclientId: z.number().nullable().optional(),
  subclientName: z.string().nullable().optional(),
  fundId: z.number(),
  fundName: z.string(),
  subfundId: z.number(),
  subfundName: z.string().nullable(),
  codeIsin: z.string().nullable(),
  shareclassId: z.number().nullable(),
})

export type ScopeDto = z.infer<typeof ScopeDtoSchema>
