import React from 'react';
import { MRT_Row } from 'material-react-table';
import { InvoiceData } from '../InvoicingData.types';

export default function HandlePriceCell(
  options: { row: MRT_Row<InvoiceData> },
) {
  const {
    row: { original },
  } = options;
  return <div>{original.price}</div>;
}
