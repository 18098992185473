import { z } from 'zod'

export const SinceLastTimeSchema = z.object({
  statSentDocuments: z.number().nullable(),
  statValidatedDocuments: z.number().nullable(),
  statRejectedDocuments: z.number().nullable(),
  statScopeChanges: z.number().nullable(),
  lastConnectionTimestamp: z.string(),
})

export type SinceLastTimeDto = z.infer<typeof SinceLastTimeSchema>
