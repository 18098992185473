import { Modal, Radio, Slider, Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faDownload, faInfo } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast'
import { AxiosError, AxiosResponse } from 'axios/index'
import { useContext, useState } from 'react'
import { GedContext } from '../context/GedProvider'
import DownloadService from '../../../services/DownloadService/downloadService'
import * as S from './DownloadFinalDocsModal.styles'
import { ThemeContext } from '../../../context/ThemeProvider'
import Icon from '../../../components/icon/Icon'
import ProductionDocumentService from '../../../services/Document/productionDocumentService'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DownloadFinalDocsModal({ open, setOpen }: Props) {
  const { selectedRows, paginatedDocuments, allDocumentsIds } = useContext(GedContext)
  const { mainColor } = useContext(ThemeContext)
  const [splitZipStructure, setSplitZipStructure] = useState<boolean>(false)
  const [splitInSmallZipFiles, setSplitInSmallZipFiles] = useState<boolean>(false)
  const [maxZipFilesSize, setMaxZipFilesSize] = useState<number>()
  const [aggregateExcelReports, setAggregateExcelReports] = useState<boolean>(false)

  const bulkDownload = () => {
    let docIdArray = []
    // If no rows are selected, we download all documents
    if (selectedRows.length === 0) {
      docIdArray = allDocumentsIds
    } else {
      // Else we download the selected rows
      docIdArray = selectedRows
    }

    const fetchPromise = ProductionDocumentService.downloadByIds(
      docIdArray,
      splitZipStructure,
      maxZipFilesSize,
      aggregateExcelReports
    )

    toast.promise(
      fetchPromise,
      {
        loading: 'Downloading documents, please wait...',
        error: (error: AxiosError) => {
          console.log(error)
          if (error.response?.status === 405) {
            return 'Some reports cannot be aggregated. Please select report types that can be aggregated.'
          }
          return JSON.parse(JSON.stringify(error)).status === 404
            ? 'There is no documents to download.'
            : 'An error happened while downloading files, please try again.'
        },
        success: (resp: AxiosResponse) => {
          if (resp.headers['content-disposition']) {
            const filename = resp.headers['content-disposition']
              .split('filename=')[1]
              .replaceAll('"', '')
            DownloadService.downloadDoc(resp.data, filename)
          } else {
            throw new Error('File name is missing')
          }
          return 'Documents downloaded with success.'
        },
      },
      {
        success: {
          duration: 4000,
        },
      }
    )
  }

  // @ts-ignore
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}>
      <S.ModalBox>
        <S.ModalBody>
          <S.Title>Download documents</S.Title>

          {paginatedDocuments && (
            <div>
              You are going to download{' '}
              {selectedRows.length > 0 ? selectedRows.length : paginatedDocuments.totalElements}{' '}
              documents, are you sure you want to download it ?
            </div>
          )}
          <div>
            <span>Do you want to split zip structure ?</span>
            <Tooltip title='(Organize by fund - subfund - language/country)' arrow>
              <span>
                <Icon icon={faInfo} />
              </span>
            </Tooltip>
            <Radio
              onClick={() => {
                setSplitZipStructure(true)
              }}
              checked={splitZipStructure}
              style={{ color: mainColor }}
            />
            <span>Yes</span>
            <Radio
              onClick={() => {
                setSplitZipStructure(false)
              }}
              checked={!splitZipStructure}
              style={{ color: mainColor }}
            />
            <span>No</span>
          </div>
          <div>
            <span>Do you want to split documents in small zip files ?</span>
            <Tooltip
              title='Documents can be split into small zip files with a maximum size in mega bytes'
              arrow>
              <span>
                <Icon icon={faInfo} />
              </span>
            </Tooltip>
            <Radio
              onClick={() => {
                setSplitInSmallZipFiles(true)
                setMaxZipFilesSize(5)
              }}
              checked={splitInSmallZipFiles}
              style={{ color: mainColor }}
            />
            <span>Yes</span>
            <Radio
              onClick={() => {
                setSplitInSmallZipFiles(false)
                setMaxZipFilesSize(undefined)
              }}
              checked={!splitInSmallZipFiles}
              style={{ color: mainColor }}
            />
            <span>No</span>
          </div>
          {splitInSmallZipFiles && (
            <S.SliderContainer>
              <span>Max zip size (in mb)</span>
              <Slider
                value={maxZipFilesSize}
                min={5}
                max={50}
                step={5}
                marks
                onChange={(e, newValue) => {
                  setMaxZipFilesSize(newValue as number)
                }}
                style={{ width: '30%', color: mainColor }}
              />
              <span>{maxZipFilesSize} mb</span>
            </S.SliderContainer>
          )}
          <div>
            <span>Do you want to aggregate reports ?</span>
            <Tooltip
              title='It only works with excel files. It will aggregate all excel files into one excel file.'
              arrow>
              <span>
                <Icon icon={faInfo} />
              </span>
            </Tooltip>
            <Radio
              onClick={() => {
                setAggregateExcelReports(true)
              }}
              checked={aggregateExcelReports}
              style={{ color: mainColor }}
            />
            <span>Yes</span>
            <Radio
              onClick={() => {
                setAggregateExcelReports(false)
              }}
              checked={!aggregateExcelReports}
              style={{ color: mainColor }}
            />
            <span>No</span>
          </div>
          <S.ButtonsContainer>
            <S.DownloadBtn onClick={bulkDownload}>
              <FontAwesomeIcon icon={faDownload} />
              <span>Yes, download the documents</span>
            </S.DownloadBtn>
            <S.CloseButton
              onClick={() => {
                setOpen(false)
              }}>
              <FontAwesomeIcon icon={faClose} />
              <span>Cancel</span>
            </S.CloseButton>
          </S.ButtonsContainer>
        </S.ModalBody>
      </S.ModalBox>
    </Modal>
  )
}
