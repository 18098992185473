import { z } from 'zod'
import http from '../http'
import { DecModuleSchema } from '../../types/dtos/DecModule/DecModuleDto'
// import {ClientUserDto, ClientUserDtoSchema} from "../../types/dtos/ClientUser/ClientUserDto";

export const userSchema = z.object({
  backupCrmEmail: z.string().nullable(),
  backupCrmMobile: z.string().nullable(),
  backupCrmName: z.string().nullable(),
  backupCrmSurname: z.string().nullable(),
  blockedAccount: z.boolean().nullable(),
  clientHasLogoInDb: z.boolean(),
  clientId: z.number(),
  clientLogoUrl: z.string().nullable(),
  clientMainRgbColor: z.string().nullable(),
  clientName: z.string(),
  crmEmail: z.string().email().nullable(),
  crmMobile: z.string().nullable(),
  crmName: z.string().nullable(),
  crmSurname: z.string().nullable(),
  email: z.string().email(),
  firstName: z.string(),
  fullName: z.string(),
  hasParent: z.boolean(),
  hasSubclient: z.boolean(),
  hideDecHelpModal: z.boolean(),
  id: z.number(),
  lastName: z.string(),
  lastUpdate: z.string(),
  parentHasLogoInDb: z.boolean(),
  parentId: z.number().nullable(),
  parentLogoUrl: z.string().nullable(),
  parentMainRgbColor: z.string().nullable(),
  parentName: z.string().nullable(),
  roles: z.string().array(),
  salesEmail: z.string().email().nullable(),
  salesMobile: z.string().nullable(),
  salesName: z.string().nullable(),
  salesSurname: z.string().nullable(),
  historicalLocation: z.string().nullable().optional(),
  moduleAccessList: DecModuleSchema.array(),
})

export type UserDto = z.infer<typeof userSchema>

const UserService = {
  getUser() {
    return http.get<UserDto>('/user').then(resp => {
      userSchema.parse(resp.data)
      return resp
    })
  },
}
export default UserService
