import { PlatformType } from '../Platform.types'
import { OptionType } from '../../../../types/OptionType'

export const countHeaderItems = (
  data: PlatformType[],
  selectedData: 'staticData' | 'dynamicData' | 'documentData',
  filterPlatform: OptionType | null,
  filterFund: OptionType | null,
  filterSubfund: OptionType | null,
  filterCodeIsin: OptionType | null
) => {
  return data
    .filter(elem => elem[selectedData])
    .filter(platformElem =>
      filterPlatform ? platformElem.platformName === filterPlatform.label : true
    )
    .filter(fundElem => (filterFund ? fundElem.fundName === filterFund.label : true))
    .filter(subfundElem => (filterSubfund ? subfundElem.subfundName === filterSubfund.label : true))
    .filter(codeIsinElem =>
      filterCodeIsin ? codeIsinElem.codeIsin === filterCodeIsin.label : true
    ).length
}
