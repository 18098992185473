import styled from 'styled-components'
import { COLOR } from '../../constants/theme'

export const FloatLine = styled.div`
  font-size: 12px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: '';
    color: ${COLOR.SEMI_LIGHT_GRAY};
    border-top: 2px solid;
    margin: 0 20px 0 0;
    flex: 1 0 20px;
  }

  &::after {
    color: ${COLOR.SEMI_LIGHT_GRAY};
    margin: 0 0 0 20px;
  }
`
export const FloatLineEmpty = styled.div`
  font-size: 12px;
  padding-top: 5px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: '';
    color: ${COLOR.SEMI_LIGHT_GRAY};
    border-top: 2px solid;
    margin: 0 0px 0 0;
    flex: 1 0 0px;
  }

  &::after {
    color: ${COLOR.SEMI_LIGHT_GRAY};
    margin: 0 0 0 0px;
  }
`

export const HelpItem = styled.div`
  padding-left: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
export const CRMSection = styled.span``
