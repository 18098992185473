import React from 'react';
import { Checkbox as MuiCheckBox } from '@mui/material';
import { MRT_Row } from 'material-react-table';
import { AllUpdates, ScopeItem } from '../ExistingServices.types';
import { OptionType } from '../../../../types/OptionType';

export default function AggregatedCellForFrequency(
  options: { row: MRT_Row<ScopeItem> },
  allUpdates: AllUpdates[],
  defaultFrequency: OptionType | null,
  setAllUpdates: React.Dispatch<React.SetStateAction<AllUpdates[]>>
) {
  const { row } = options;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        minHeight: '30px',
      }}>
      <div
        style={{
          width: '400px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      />
      <div style={{ width: '400px' }}>
        <MuiCheckBox
          size='small'
          checked={row.getLeafRows().every(elem => {
            const { original } = elem;
            const allcheck = allUpdates.some(
              ({ fundId, subfundId, shareclassId, changeStatus }) =>
                fundId === original.fundId &&
                subfundId === original.subfundId &&
                (original.shareclassId ? shareclassId === original.shareclassId : true) &&
                (changeStatus === 'ADD' || changeStatus === 'MODIFY')
            );
            const allRemoved = allUpdates.some(
              ({ fundId, subfundId, shareclassId, changeStatus }) =>
                fundId === original.fundId &&
                subfundId === original.subfundId &&
                (original.shareclassId ? shareclassId === original.shareclassId : true) &&
                changeStatus === 'REMOVE'
            );
            if (original.reporting && allRemoved) {
              return false;
            }
            if (original.reporting || allcheck) {
              return true;
            }
            return false;
          })}
          indeterminate={
            row.getLeafRows().some(elem => {
              const { original } = elem;
              const allcheck = allUpdates.some(
                allElem =>
                  allElem.fundId === original.fundId &&
                  allElem.subfundId === original.subfundId &&
                  (original.shareclassId ? allElem.shareclassId === original.shareclassId : true) &&
                  (allElem.changeStatus === 'ADD' || allElem.changeStatus === 'MODIFY')
              );
              const allRemoved = allUpdates.some(
                allElem =>
                  allElem.fundId === original.fundId &&
                  allElem.subfundId === original.subfundId &&
                  (original.shareclassId ? allElem.shareclassId === original.shareclassId : true) &&
                  allElem.changeStatus === 'REMOVE'
              );
              if (original.reporting && allRemoved) {
                return false;
              }
              if (original.reporting || allcheck) {
                return true;
              }
              return false;
            }) &&
            !row.getLeafRows().every(elem => {
              const { original } = elem;
              const allcheck = allUpdates.some(
                allElem =>
                  allElem.fundId === original.fundId &&
                  allElem.subfundId === original.subfundId &&
                  (original.shareclassId ? allElem.shareclassId === original.shareclassId : true) &&
                  (allElem.changeStatus === 'ADD' || allElem.changeStatus === 'MODIFY')
              );
              const allRemoved = allUpdates.some(
                allElem =>
                  allElem.fundId === original.fundId &&
                  allElem.subfundId === original.subfundId &&
                  (original.shareclassId ? allElem.shareclassId === original.shareclassId : true) &&
                  allElem.changeStatus === 'REMOVE'
              );
              if (original.reporting && allRemoved) {
                return false;
              }
              if (original.reporting || allcheck) {
                return true;
              }
              return false;
            })
          }
          style={{
            padding: '0px',
            color: 'gray',
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              const outList: any[] = allUpdates;
              row.getLeafRows().forEach(leafElem => {
                const { original } = leafElem;
                const thereIsAdded = allUpdates.some(
                  elem =>
                    elem.fundId === original.fundId &&
                    elem.subfundId === original.subfundId &&
                    (original.shareclassId ? elem.shareclassId === original.shareclassId : true) &&
                    elem.changeStatus === 'ADD'
                );
                const thereIsModified = allUpdates.some(
                  elem =>
                    elem.fundId === original.fundId &&
                    elem.subfundId === original.subfundId &&
                    (original.shareclassId ? elem.shareclassId === original.shareclassId : true) &&
                    elem.changeStatus === 'MODIFY'
                );
                const whereIsRemoved = allUpdates.findIndex(
                  elem =>
                    elem.fundId === original.fundId &&
                    elem.subfundId === original.subfundId &&
                    (original.shareclassId ? elem.shareclassId === original.shareclassId : true) &&
                    elem.changeStatus === 'REMOVE'
                );
                if (!thereIsAdded && !thereIsModified && !original.reporting) {
                  outList.push({
                    ...original,
                    changeStatus: 'ADD',
                    frequencyId: defaultFrequency?.value,
                    frequency: defaultFrequency?.label,
                  });
                }
                if (whereIsRemoved !== -1) {
                  outList.splice(whereIsRemoved, 1);
                }
              });
              setAllUpdates([...outList]);
            }
            if (!e.target.checked) {
              const outList: any[] = allUpdates;
              row.getLeafRows().forEach(leafElem => {
                const { original } = leafElem;
                const whereIsSome = outList.findIndex(
                  elem =>
                    elem.fundId === original.fundId &&
                    elem.subfundId === original.subfundId &&
                    (original.shareclassId ? elem.shareclassId === original.shareclassId : true)
                );
                if (whereIsSome !== -1) {
                  outList.splice(whereIsSome, 1);
                }
                if (original.reporting) {
                  outList.push({
                    ...original,
                    changeStatus: 'REMOVE',
                  });
                }
              });
              setAllUpdates([...outList]);
            }
          }}
        />
      </div>
    </div>
  );
}
