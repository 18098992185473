import FileSaver from 'file-saver';
import toast from 'react-hot-toast';
import { base64ToArrayBuffer } from '../../../../helpers/helpers';
import http from '../../../../services/http';

export const monthNamer = (month: string) => {
  switch (month) {
    case '01':
      return 'January';
    case '02':
      return 'February';
    case '03':
      return 'March';
    case '04':
      return 'April';
    case '05':
      return 'May';
    case '06':
      return 'June';
    case '07':
      return 'July';
    case '08':
      return 'August';
    case '09':
      return 'September';
    case '10':
      return 'October';
    case '11':
      return 'November';
    case '12':
      return 'December';
    default:
      return 'Unknown';
  }
};

export const handleDownload = (id: number, docName: string, extension: string) => {
  http
    .get(`/invoices/${id}/download`)
    .then(({ data }) => {
      const blob = new Blob([base64ToArrayBuffer(data.documentByte)], {
        type: 'application/pdf',
      });
      FileSaver.saveAs(blob, docName + extension);
    })
    .catch(e => {
      console.log(e);
      toast.error('Failed to download invoice');
    });
};
