import React from 'react'
import { MRT_Row } from 'material-react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Delivery } from '../DeliveryPlanning.types'
import { COLOR } from '../../../../constants/theme'
import { DeliveryEstimationDto } from '../../../../types/dtos/production/productionDto'

export default function HandleStatusCell(
  options: { row: MRT_Row<DeliveryEstimationDto> },
  step: number
) {
  const {
    row: { original },
  } = options
  if (original.productionStatusId > step) {
    return <FontAwesomeIcon icon={faCheck} color={COLOR.GREEN} />
  }
  return <FontAwesomeIcon icon={faTimes} color={COLOR.RED} />
}
