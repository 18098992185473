import { faScrewdriverWrench, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Centered from '../../fragments/Centered';
import { ThemeContext } from '../../context/ThemeProvider';

type Props = {
  text?: string;
  icon?: IconDefinition;
  notAbsolute?: boolean;
};

function NotYetComponent({ icon, text }: Props) {
  const { mainColor } = useContext(ThemeContext);
  return (
    <>
      <FontAwesomeIcon icon={icon ?? faScrewdriverWrench} color={mainColor} size='2x' />
      <div style={{ marginTop: 8, fontSize: 20, userSelect: 'none' }}>
        {text ?? 'Not set up yet'}
      </div>
    </>
  );
}
function NotYet({ text, icon, notAbsolute = false }: Props) {
  return notAbsolute ? (
    <NotAbsolute>
      <NotYetComponent text={text} icon={icon} />
    </NotAbsolute>
  ) : (
    <Centered>
      <NotYetComponent text={text} icon={icon} />
    </Centered>
  );
}

const NotAbsolute = styled.div`
  text-align: center;
`;

export default NotYet;
