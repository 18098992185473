import { MRT_ColumnDef } from 'material-react-table'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { SelectInputSearchControlled } from '../../../components/input/SelectInputSearchControlled'
import MaterialTable from '../../../components/table/MaterialTable/MaterialTable'
import { CUSTOM_CLIENT_ID_10066 } from '../../../constants/constants'
import { ThemeContext } from '../../../context/ThemeProvider'
import { UserContext } from '../../../context/UserProvider'
import http from '../../../services/http'
import { OptionType } from '../../../types/OptionType'
import {
  HandleDefaultAggregatedCell,
  HandleMuiTableBodyCellPropsLevels,
  filtersStyle,
} from '../../../components/table/MaterialTable/ColumnUtilities'
import { InvoiceData } from './InvoicingData.types'
import HandlePriceAggregatedCell from './components/HandlePriceAggregatedCell'
import HandlePriceCell from './components/HandlePriceCell'
import HandleSumAggregatedCell from './components/HandleSumAggregatedCell'

import json from '../data.json'

function InvoicingData() {
  // Manage context
  const { user } = useContext(UserContext)
  const { mainColorLight, mainColorVeryLight } = useContext(ThemeContext)
  // Manage data
  const [rawShareclassData, setRawShareclassData] = useState<any[]>([])
  const [rawSubfundData, setRawSubfundData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [codeIsinLevel, setCodeIsinLevel] = useState<boolean>(true)
  const [reportLevel, setReportLevel] = useState<'Fund' | 'Subfund' | 'CodeIsin'>('CodeIsin')

  // Manage filters
  const [filterReportType, setFilterReportType] = useState<OptionType | null>(null)
  const [filterYear, setFilterYear] = useState<OptionType | null>(null)
  //  Manage all filters options
  const [allReportType, setAllReportType] = useState<OptionType[]>([])
  const [allYear, setAllYear] = useState<OptionType[]>([])
  // Define initial config for the table
  const [initialShareclassState] = useState({
    grouping: ['fundName', 'subfundName'],
    columnVisibility: {
      fundName: false,
      subfundName: false,
      reportType: false,
      year: false,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
    ],
    density: 'compact',
  })
  const [initialSubfundState] = useState({
    grouping: ['fundName'],
    columnVisibility: {
      fundName: false,
      subfundName: true,
      reportType: false,
      year: false,
    },
    sorting: [
      { id: 'fundName', desc: false },
      { id: 'subfundName', desc: false },
    ],
    density: 'compact',
  })

  localStorage.setItem('localState', 'data')

  useEffect(() => {
    // @ts-ignore
    if (!filterReportType || filterReportType?.shareclassLevel) {
      setCodeIsinLevel(true)
      setReportLevel('CodeIsin')
    } else {
      setCodeIsinLevel(false)
      setReportLevel('Subfund')
    }
  }, [filterReportType])

  const createMainBody = () => {
    let mainBody: MRT_ColumnDef<InvoiceData>[]
    switch (reportLevel) {
      case 'Subfund':
        mainBody = [
          {
            header: '',
            accessorKey: 'subfundName',
            enableSorting: false,
            enableGlobalFilter: false,
            enableColumnActions: false,
            AggregatedCell: (options: any) => HandleDefaultAggregatedCell(options),
          },
        ]
        break
      default:
        mainBody = [
          {
            header: 'Subfund',
            accessorKey: 'subfundName',
            enableSorting: true,
            enableGlobalFilter: true,
          },
          {
            header: '',
            accessorKey: 'shareclassName',
            enableSorting: true,
            enableColumnActions: false,
            AggregatedCell: (options: any) => HandleDefaultAggregatedCell(options),
            muiTableBodyCellProps: (options: any) =>
              HandleMuiTableBodyCellPropsLevels(options, mainColorLight, mainColorVeryLight),
          },
        ]
    }
    return mainBody
  }

  // @ts-ignore
  const columns = useMemo<MRT_ColumnDef<InvoiceData>[]>(() => {
    return [
      {
        header: 'Fund',
        accessorKey: 'fundName',
        enableGlobalFilter: true,
      },
      ...createMainBody(),
      {
        header: 'Report type',
        accessorKey: 'reportType',
      },
      {
        header: 'Year',
        accessorKey: 'year',
      },
      {
        header: 'Price (€)',
        accessorKey: 'price',
        enableColumnActions: false,
        enableColumnFilter: false,
        AggregatedCell: options =>
          HandlePriceAggregatedCell(options, rawSubfundData, filterReportType),
        Cell: options => HandlePriceCell(options),
      },
      {
        header: 'Sum (€)',
        columnDefType: 'display',
        AggregatedCell: options =>
          HandleSumAggregatedCell(options, rawSubfundData, filterReportType, reportLevel),
      },
    ]
  }, [rawShareclassData, rawSubfundData, filterReportType, reportLevel])

  //Definitions for the filter bar
  const renderTopToolbar = (props: any) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', padding: '5px 5px' }}>
        <SelectInputSearchControlled
          customStyle={filtersStyle}
          value={filterReportType}
          options={allReportType}
          placeholder='Select a report type'
          onChangeCallback={option => {
            setFilterReportType(option)
            props.table.setColumnFilters((prev: any) => {
              const otherFilters = prev.filter((elem: any) => elem?.id !== 'reportType')
              if (option) {
                return [...otherFilters, { id: 'reportType', value: option?.value }]
              }
              return [...otherFilters]
            })
          }}
        />
        <SelectInputSearchControlled
          customStyle={filtersStyle}
          value={filterYear}
          options={allYear}
          placeholder='Select a year'
          onChangeCallback={option => {
            setFilterYear(option)
            props.table.setColumnFilters((prev: any) => {
              const otherFilters = prev.filter((elem: any) => elem?.id !== 'year')
              if (option) {
                return [...otherFilters, { id: 'year', value: option?.value }]
              }
              return [...otherFilters]
            })
          }}
        />
      </div>
    )
  }

  useEffect(() => {
    if (user?.clientId === CUSTOM_CLIENT_ID_10066) {
      const opYears = [2022, 2021]
      setRawShareclassData(
        json
          .filter((elem: any) => elem.shareclassName)
          .map((elem: any) => {
            return { ...elem, year: opYears[0] }
          })
      )
      setRawSubfundData(
        json
          .filter((elem: any) => !elem.shareclassName)
          .map((elem: any) => {
            return { ...elem, year: opYears[1] }
          })
      )
      setAllReportType(
        json
          .map((elem: any) => elem.reportType)
          .filter((elem: string, index: number, array: string[]) => array.indexOf(elem) === index)
          .map((elem: string) => ({
            label: elem,
            value: elem,
            shareclassLevel: json.some(
              (subElem: any) => !!(subElem.reportType === elem && subElem.shareclassName)
            ),
          }))
      )
      setAllYear(opYears.map((elem: number) => ({ label: `${elem}`, value: elem })))
      setIsLoading(false)
    } else {
      http
        .get('/invoices/data')
        .then(resp => {
          const opYears = [2022, 2021]
          setRawShareclassData(
            resp.data
              .filter((elem: InvoiceData) => elem.shareclassName)
              .map((elem: InvoiceData) => {
                return { ...elem, year: opYears[0] }
              })
          )
          setRawSubfundData(
            resp.data
              .filter((elem: InvoiceData) => !elem.shareclassName)
              .map((elem: InvoiceData) => {
                return { ...elem, year: opYears[1] }
              })
          )
          setAllReportType(
            resp.data
              .map((elem: InvoiceData) => elem.reportType)
              .filter(
                (elem: string, index: number, array: string[]) => array.indexOf(elem) === index
              )
              .map((elem: string) => ({
                label: elem,
                value: elem,
                shareclassLevel: resp.data.some(
                  (subElem: InvoiceData) =>
                    !!(subElem.reportType === elem && subElem.shareclassName)
                ),
              }))
          )
          setAllYear(opYears.map((elem: number) => ({ label: `${elem}`, value: elem })))
        })
        .finally(() => setIsLoading(false))
    }
  }, [])

  return (
    <>
      {reportLevel === 'CodeIsin' && !filterReportType && (
        <MaterialTable
          data={rawShareclassData}
          columns={columns}
          enableSelectAll={false}
          initialState={initialShareclassState}
          isLoading={isLoading}
          maxHeight={80}
          renderTopToolbar={renderTopToolbar}
        />
      )}
      {reportLevel === 'CodeIsin' && filterReportType && (
        <MaterialTable
          data={rawShareclassData.filter(
            (elem: InvoiceData) => elem.reportType === filterReportType?.value
          )}
          columns={columns}
          enableSelectAll={false}
          initialState={codeIsinLevel ? initialShareclassState : initialSubfundState}
          isLoading={isLoading}
          maxHeight={80}
          renderTopToolbar={renderTopToolbar}
        />
      )}
      {reportLevel === 'Subfund' && (
        <MaterialTable
          data={rawSubfundData.filter(
            (elem: InvoiceData) => elem.reportType === filterReportType?.value
          )}
          columns={columns}
          enableSelectAll={false}
          initialState={initialSubfundState}
          isLoading={isLoading}
          maxHeight={80}
          renderTopToolbar={renderTopToolbar}
        />
      )}
    </>
  )
}

export default InvoicingData
