import { DecModuleDto } from '../types/dtos/DecModule/DecModuleDto'

const ROLES = {
  READONLY: 'READ_ONLY',
  MANAGE: 'MANAGE',
  INVOICE: 'INVOICE',
  CONTROL: 'CONTROL',
}

const LEVEL = {
  SUBFUND: 3,
  SHARECLASS: 4,
}

export const AO_EMAIl_ADRESS = 'dec@alphaomega.lu'

const CUSTOM_CLIENT_ID_7 = 7
const CUSTOM_CLIENT_ID_10066 = 10066

export const CLIENT_WELLINGTON_ID = 10170

const STANDARD_SCOPE_SELECTORS = ['fundName', 'subfundName', 'shareclassName']

const NARRSTATUS = [-1, -1, -1, -1]

const MODULES_LIST = {
  PRODUCTION_STATUS: 'PRODUCTION STATUS',
  PORTFOLIO: 'PORTFOLIO',
  SCOPE_MANAGEMENT: 'SCOPE MANAGEMENT',
  DOCUMENT_VALIDATION: 'DOCUMENT VALIDATION',
  LIBRARY: 'LIBRARY',
  INVOICING: 'INVOICING',
  KPI: 'KPI',
}

export {
  ROLES,
  LEVEL,
  CUSTOM_CLIENT_ID_7,
  CUSTOM_CLIENT_ID_10066,
  STANDARD_SCOPE_SELECTORS,
  NARRSTATUS,
  MODULES_LIST,
}
