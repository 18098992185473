import { MRT_Row } from 'material-react-table'
import React from 'react'
import CheckIcon from '../../../components/icon/CheckIcon'
import { Followup } from '../ProductionStatus.types'
import { ProductionStatusDashboardDto } from '../../../types/dtos/production/productionDto'

export default function HandleControlCell(options: { row: MRT_Row<ProductionStatusDashboardDto> }) {
  const {
    row: { original },
  } = options
  if (
    original.inDataReceptionCount === 0 &&
    original.inProductionCount === 0 &&
    original.inControlCount === 0
  ) {
    return <CheckIcon />
  }
  return <div>{original.inControlCount}</div>
}
