import styled from 'styled-components';
import { COLOR } from './constants/theme';

export const GenericBtnWithIcon = styled.div`
  display: inline-flex;
  cursor: pointer;
  background-color: ${COLOR.LIGHT_GRAY};
  padding: 5px 10px;
  border-radius: 5px;
  gap: 7px;
  align-items: center;
`;
