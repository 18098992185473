import toast from 'react-hot-toast'
import ProductionDocumentService from '../../../../services/Document/productionDocumentService'
import downloadService from '../../../../services/DownloadService/downloadService'

export default function handleDocumentActions(
  content: any,
  action: 'DOWNLOAD' | 'SEND_BY_EMAIL' | 'OPEN_IN_BROWSER'
) {
  if (action === 'DOWNLOAD') {
    ProductionDocumentService.downloadByIds(content).then(resp => {
      const filename = ProductionDocumentService.getFileNameFromHeader(resp)
      downloadService.downloadDoc(resp.data, filename)
    })
    // downloadZipFromDocumentList(content, false);
  } else if (action === 'SEND_BY_EMAIL') {
    const promise = ProductionDocumentService.sendDocumentsByEmail([content])
    toast.promise(promise, {
      loading: 'Sending email',
      success: 'The email was successfully sent',
      error: 'Error when sending the mail',
    })
  } else if (action === 'OPEN_IN_BROWSER') {
    ProductionDocumentService.downloadByIds(content, false).then(resp => {
      // @ts-ignore
      const bytes = new Uint8Array(resp.data)
      const blob = new Blob([bytes], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(blob)
      window.open(fileURL)
    })
  }
}
