import { Document, Page, pdfjs } from 'react-pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlassMinus, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import screenfull from 'screenfull'
import * as S from './PdfViewer.styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
}

type Props = {
  filePathInPublicFolder: string
  initialScale?: number
}

function PdfViewer({ filePathInPublicFolder, initialScale }: Props) {
  const [numPages, setNumPages] = useState<number>()
  const [scale, setScale] = useState<number>(initialScale || 1.2)
  // Create a function to convert the file to base64

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  return (
    <div style={{ height: 'auto', overflow: 'hidden', position: 'relative' }}>
      <S.AllPagesContainer id='pdfContainer' className='scroll'>
        <Document
          file={filePathInPublicFolder}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}>
          {Array.from(new Array(numPages), (_, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              scale={scale}
            />
          ))}
        </Document>
      </S.AllPagesContainer>
      <S.ButtonContainer>
        <S.Button
          leftButton
          onClick={() => {
            setScale(prevState => prevState + 0.05)
          }}>
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} color='white' />
        </S.Button>
        <S.Button
          leftButton={false}
          onClick={() => {
            setScale(prevState => prevState - 0.1)
          }}>
          <FontAwesomeIcon icon={faMagnifyingGlassMinus} color='white' />
        </S.Button>
      </S.ButtonContainer>
    </div>
  )
}

export default PdfViewer
