import axios from 'axios'
import { t } from 'i18next'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import DashboardService, {
  ProductionStatusDto,
} from '../services/DashboardService/dashboardService'
import http from '../services/http'
import { UserContext } from './UserProvider'
import { SinceLastTimeDto } from '../types/dtos/ClientUserStatistics/ClientUserStatisticsDto'
import { productionStatsService } from '../services/production/productionStatsService'

type NewsType = {
  link: string
  title: string
  pubDate: string
}

type HourForecast = {
  temp_c: number
  condition: {
    text: string
    code: number
  }
  feelslike_c: number
  chance_of_rain: string
}

type ForecastResponse = {
  forecast: {
    forecastday: {
      hour: HourForecast[]
    }[]
  }
}

type Context = {
  showModal: boolean | undefined
  setShowModal: any
  plannedReports: number | undefined
  updatePlannedReports: any
  productionStatus: ProductionStatusDto[] | undefined
  updateProductionStatus: any
  documentValidation: number | undefined
  updateDocumentValidation: any
  sinceLastTime: SinceLastTimeDto | undefined
  updateSinceLastTime: any
  newsFeed: any
  updateNewsFeed: any
  weather: { morning: HourForecast; afternoon: HourForecast } | undefined
  updateWeather: any
}

export const DashboardContext = createContext<Context>({
  showModal: undefined,
  setShowModal: undefined,
  plannedReports: undefined,
  updatePlannedReports: undefined,
  productionStatus: undefined,
  updateProductionStatus: undefined,
  documentValidation: undefined,
  updateDocumentValidation: undefined,
  sinceLastTime: undefined,
  updateSinceLastTime: undefined,
  newsFeed: undefined,
  updateNewsFeed: undefined,
  weather: undefined,
  updateWeather: undefined,
})

type Props = {
  children: React.ReactNode
}

function DashboardProvider({ children }: Props) {
  const { user } = useContext(UserContext)
  // get state of modal
  const [showModal, setShowModal] = useState<boolean>(false)
  useEffect(() => {
    if (!!user && !user?.hideDecHelpModal) {
      setShowModal(true)
    }
  }, [user?.hideDecHelpModal])
  // handle planned reports for dashboard
  const [plannedReports, setPlannedReports] = useState<number>()
  const updatePlannedReports = () => {
    productionStatsService
      .getRatioBetweenCompletedReportsAndPlannedReportsForThisMonth()
      .then(resp => {
        setPlannedReports(resp.data)
      })
      // -1 is in use for no current reports planned
      .catch(e => {
        setPlannedReports(-2)
        console.log(e)
      })
  }

  // handle production status for dashboard
  const [productionStatus, setProductionStatus] = useState<ProductionStatusDto[]>([])
  const updateProductionStatus = () => {
    productionStatsService
      .getProductionStatus()
      .then(resp => {
        setProductionStatus(resp.data)
      })
      .catch(e => {
        setProductionStatus([{ idStatus: -2, statusName: 'error', totalCount: -2 }])
        console.log(e)
      })
  }
  // handle documents validation for dashboard
  const [documentValidation, setDocumentValidation] = useState<number>()
  const updateDocumentValidation = () => {
    productionStatsService
      .getTotalNumberOfDocumentsInValidation()
      .then(resp => {
        setDocumentValidation(resp.data)
      })
      .catch(e => {
        setDocumentValidation(-2)
        console.log(e)
      })
  }

  //handle since last time for dashboard
  const [sinceLastTime, setSinceLastTime] = useState<SinceLastTimeDto>()
  const updateSinceLastTime = () => {
    DashboardService.getSinceLastTimeStatistics()
      .then(resp => {
        setSinceLastTime(resp.data)
      })
      .catch(e => {
        setSinceLastTime(undefined)
        console.log(e)
      })
    // TEMPORARY
    setSinceLastTime({
      statSentDocuments: 0,
      statValidatedDocuments: 0,
      statRejectedDocuments: 0,
      statScopeChanges: 0,
      lastConnectionTimestamp: Date.now().toLocaleString(),
    })
  }

  // handle news feed for dashboard
  const [newsFeed, setNewsFeed] = useState<NewsType[]>()
  const updateNewsFeed = () => {
    axios
      .get('https://search.cnbc.com/rs/search/combinedcms/view.xml?partnerId=wrss01&id=10000664')
      .then(resp => {
        const { data } = resp
        const start = data.indexOf('<item>')
        const end = data.indexOf('</channel>')
        const outValues: { link: string; title: string; pubDate: string }[] = []
        const outvalue = data.slice(start, end).trimStart().trimEnd().split('</item>')
        outvalue.splice(outvalue.length - 1, 1)
        outvalue.forEach((elem: any) => {
          outValues.push({
            link: elem.slice(elem.indexOf('<link>') + 6, elem.indexOf('</link>')),
            title: elem
              .slice(elem.indexOf('<title>') + 7, elem.indexOf('</title>'))
              .split('&apos;')
              .join("'"),
            pubDate: elem.slice(elem.indexOf('<pubDate>') + 9, elem.indexOf('</pubDate>')),
          })
        })
        setNewsFeed(outValues)
      })
      .catch(e => {
        setNewsFeed(undefined)
        console.log(e)
      })
  }

  // handle weather for dashboard
  const [weather, setWeather] = useState<{
    morning: HourForecast
    afternoon: HourForecast
  }>()
  const updateWeather = useCallback(() => {
    const morningHour = 8
    const afternoonHour = 16
    const location = user?.historicalLocation ?? 'Luxembourg'
    axios
      .get<ForecastResponse>('https://api.weatherapi.com/v1/forecast.json', {
        params: {
          key: process.env.REACT_APP_WEATHER_API_KEY,
          q: location,
          days: 1,
          aqi: 'no',
          alerts: 'no',
        },
      })
      .then(({ data }) => {
        setWeather({
          morning: data.forecast.forecastday[0].hour[morningHour],
          afternoon: data.forecast.forecastday[0].hour[afternoonHour],
        })
      })
      .catch(e => {
        setWeather(undefined)
        console.log(e)
      })
  }, [user?.historicalLocation])

  useEffect(() => {
    if (user) {
      updateProductionStatus()
      updateDocumentValidation()
      updatePlannedReports()
      updateSinceLastTime()
    }
    updateNewsFeed()
    updateWeather()
  }, [user])
  useEffect(() => {
    const interval = setInterval(() => {
      updateProductionStatus()
      updateDocumentValidation()
      updatePlannedReports()
      updateSinceLastTime()
    }, 300000)
    return () => clearInterval(interval)
  }, []) // time out to update values

  return (
    <DashboardContext.Provider
      value={{
        showModal,
        setShowModal,
        plannedReports,
        updatePlannedReports,
        productionStatus,
        updateProductionStatus,
        documentValidation,
        updateDocumentValidation,
        sinceLastTime,
        updateSinceLastTime,
        newsFeed,
        updateNewsFeed,
        weather,
        updateWeather,
      }}>
      {children}
    </DashboardContext.Provider>
  )
}

export default DashboardProvider
