import React, { useContext, useEffect, useState } from 'react'
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faFolderOpen,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { GedContext } from './context/GedProvider'
import DownloadIcon from '../../components/icon/DownloadIcon'
import Table from '../../components/table/Table'
import { capitalizeString } from '../../helpers/helpers'
import NotYet from '../../components/notYet/NotYet'
import Icon from '../../components/icon/Icon'
import { COLOR } from '../../constants/theme'
import { DeliverableValidationGedDto } from '../../types/dtos/DeliverableValidation/DeliverableValidationDto'
import { ThemeContext } from '../../context/ThemeProvider'
import Loader from '../../components/loader/Loader'
import downloadZipFromDocumentList from '../plannedprod/ReportValidation/utils/report_validation_utils'
import MailIcon from '../../components/icon/MailIcon'
import documentService from '../../services/Document/productionDocumentService'

type Props = {
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

export default function ReportValidationGed({ currentPage, setCurrentPage }: Props) {
  const {
    reloadData,
    deliverableValidationData,
    isLoading,
    setSelectedRowsValidation,
    selectedRowsValidation,
    paginatedReportValidation,
    filterState,
  } = useContext(GedContext)

  const { mainColor } = useContext(ThemeContext)

  useEffect(() => {
    reloadData(currentPage)
  }, [currentPage, filterState])

  const handleSendMail = (documentId: number) => {
    const promise = documentService.sendDocumentsByEmail([documentId])
    toast.promise(promise, {
      loading: 'Sending mail',
      success: 'The email was successfully sent',
      error: 'Error when sending the mail',
    })
  }

  /**
   * HEADER
   */
  const head = React.useMemo(() => {
    return [
      {
        Header: 'Report date',
        accessor: 'c1',
      },
      {
        Header: 'Report type',
        accessor: 'c2',
      },
      {
        Header: 'Fund',
        accessor: 'c3',
      },
      {
        Header: 'Subfund',
        accessor: 'c4',
      },
      {
        Header: 'Shareclass',
        accessor: 'c5',
      },
      {
        Header: 'ISIN',
        accessor: 'c6',
      },
      {
        Header: 'Language',
        accessor: 'c7',
      },
      {
        Header: 'Country',
        accessor: 'c8',
      },
      {
        Header: 'Extension',
        accessor: 'c9',
      },
      {
        Header: 'Download',
        accessor: 'c10',
      },
      {
        Header: 'Send',
        accessor: 'c11',
      },
    ]
  }, [])

  const dataFormatted = React.useMemo(() => {
    const resp = deliverableValidationData.map(row => {
      return {
        c0: row.documentId,
        c1: row.referenceDateFormatted,
        c2: row.reportTypeName,
        c3: row.fundName,
        c4: row.subfundName,
        c5: row.shareclassName,
        c6: row.codeIsin,
        c7: row.languageName ? capitalizeString(row.languageName) : '/',
        c8: row.countryName,
        c9: row.documentExtension,
        c10: (
          <DownloadIcon
            callback={() => {
              const docId = row.documentId as number
              downloadZipFromDocumentList([docId], false)
            }}
          />
        ),
        c11: (
          <MailIcon
            callback={() => {
              handleSendMail(row.documentId)
            }}
          />
        ),
      }
    })
    return resp
  }, [deliverableValidationData])

  return (
    <>
      {isLoading && <Loader />}
      {dataFormatted && paginatedReportValidation && (
        <>
          <Table
            fixedHeader
            head={head}
            data={dataFormatted}
            loading={isLoading}
            selectionState={[selectedRowsValidation, setSelectedRowsValidation]}
            handleSelectionOnClick={(row, cell) => {
              if (
                !selectedRowsValidation ||
                !setSelectedRowsValidation ||
                cell.column.Header === 'Download' ||
                cell.column.Header === 'Send'
              )
                return
              const alreadyPressed = selectedRowsValidation.find(id => id === +row.original.c0)
              setSelectedRowsValidation(
                alreadyPressed !== undefined
                  ? selectedRowsValidation.filter(id => id !== +row.original.c0)
                  : [...selectedRowsValidation, +row.original.c0]
              )
            }}
          />
          <Paginator>
            <div>
              {isLoading ? (
                <></>
              ) : paginatedReportValidation?.totalElements &&
                paginatedReportValidation?.totalElements > 0 ? (
                <>
                  Showing {paginatedReportValidation?.size * currentPage + 1} to{' '}
                  {paginatedReportValidation?.numberOfElements < 50
                    ? paginatedReportValidation?.totalElements
                    : paginatedReportValidation?.size * currentPage + 50}{' '}
                  of {paginatedReportValidation?.totalElements} elements
                </>
              ) : (
                <NotYet icon={faFolderOpen} text='No documents available' />
              )}
            </div>
            <div>
              {paginatedReportValidation?.totalPages > 1 && !isLoading && (
                <>
                  <FontAwesomeIcon
                    onClick={() => {
                      if (currentPage > 0 && !isLoading) setCurrentPage(currentPage - 1)
                    }}
                    style={{ cursor: currentPage > 0 ? 'pointer' : 'not-allowed' }}
                    color={currentPage > 0 ? mainColor : COLOR.GRAY}
                    icon={faChevronLeft}
                  />
                  <span style={{ marginLeft: 8, marginRight: 8 }}>{`${currentPage + 1} / ${
                    paginatedReportValidation?.totalPages
                  }`}</span>
                  <FontAwesomeIcon
                    onClick={() => {
                      if (currentPage + 1 < paginatedReportValidation?.totalPages && !isLoading)
                        setCurrentPage(currentPage + 1)
                    }}
                    style={{
                      cursor:
                        currentPage + 1 < paginatedReportValidation?.totalPages
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    color={
                      currentPage + 1 < paginatedReportValidation?.totalPages
                        ? mainColor
                        : COLOR.GRAY
                    }
                    icon={faChevronRight}
                  />
                </>
              )}
            </div>
          </Paginator>
        </>
      )}
      {!isLoading && dataFormatted.length <= 0 && (
        <NotYet icon={faFolderOpen} text='No documents available' />
      )}
    </>
  )
}

const Paginator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0px 10px;

  > span {
    margin-left: 8px;
    margin-right: 8px;
    user-select: none;
  }
`
