import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SingleSignOnService } from '../../services/SingleSignOnService/SingleSignOnService'
import http from '../../services/http'
import { ROUTES } from '../../constants/routes'
import UserService from '../../services/UserService/userService'
import AuthService from '../../services/AuthService/authService'

function SsoLogin() {
  const navigate = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const authCode = params.get('code')
    if (authCode != null) {
      SingleSignOnService.getTokenFromAuthCode(authCode).then(response => {
        const token = `Bearer ${response.data.access_token}`
        console.log(token)
        localStorage.setItem('access_token', token)
        http.defaults.headers.common.Authorization = token
        http.defaults.headers.common.isSSO = true

        AuthService.postSsoAuthenticate()

        navigate(ROUTES.DASHBOARD, {
          replace: true,
        })
      })
    }
  }, [])

  return (
    <div>
      <h1>Authenticating you ...</h1>
    </div>
  )
}

export default SsoLogin
