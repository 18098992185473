import { z } from 'zod';

const RegistrationMatrixDataSchema = z.object({
  clientName: z.string().nullable(),
  clientId: z.number().nullable(),
  fundName: z.string().nullable(),
  fundId: z.number().nullable(),
  subfundName: z.string().nullable(),
  subfundId: z.number().nullable(),
  shareclassIsin: z.string().nullable(),
  shareclassName: z.string().nullable(),
  shareclassId: z.number().nullable(),
  registrationStatusMap: z.object({}),
});

export type RegistrationMatrixData = z.infer<typeof RegistrationMatrixDataSchema>;

export const CountryRegistrationMatrixSchema = z.object({
  countryId: z.number(),
  countryIso2: z.string().nullable(),
  countryName: z.string(),
  regulatorId: z.number().nullable(),
  regulatorName: z.string().nullable(),
});

export const RegistrationMatrixDtoSchema = z.object({
  registrationMatrixDataList: z.array(RegistrationMatrixDataSchema),
  distinctCountryNameSet: z.array(CountryRegistrationMatrixSchema),
});

export const LanguageStatusSchema = z.object({
  langIso2: z.string().nullable(),
  langEnglishName: z.string().nullable(),
  langId: z.number().nullable(),
});

export const RegistrationMatrixStatusSchema = z.object({
  countryId: z.number().nullable(),
  countryIso2: z.string().nullable(),
  countryName: z.string().nullable(),
  deregistered: z.boolean().nullable(),
  deregistrationDate: z.date().nullable(),
  deregistrationDateFormatted: z.string().nullable(),
  languageList: z.array(LanguageStatusSchema),
  registered: z.boolean().nullable(),
  registrationDate: z.date().nullable(),
  registrationDateFormatted: z.string().nullable(),
  regulatorId: z.number().nullable(),
  regulatorName: z.string().nullable(),
});

export type LanguageStatusDto = z.infer<typeof LanguageStatusSchema>;
export type RegistrationMatrixStatusDto = z.infer<typeof RegistrationMatrixStatusSchema>;
export type CountryRegistrationMatrixDto = z.infer<typeof CountryRegistrationMatrixSchema>;
export type RegistrationMatrixDto = z.infer<typeof RegistrationMatrixDtoSchema>;
