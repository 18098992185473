import React, { useState, useEffect, useContext } from 'react'
import { CSSObjectWithLabel, StylesConfig } from 'react-select'
import { Checkbox as MuiCheckBox } from '@mui/material'
import { AllUpdates, ScopeItem } from '../ExistingServices.types'
import { OptionType } from '../../../../types/OptionType'
import { ThemeContext } from '../../../../context/ThemeProvider'
import { SelectInputSearchControlled } from '../../../../components/input/SelectInputSearchControlled'
import { COLOR } from '../../../../constants/theme'

function thereIsHandler(
  original: ScopeItem,
  allUpdates: AllUpdates[],
  action?: 'ADD' | 'REMOVE' | 'MODIFY' | 'ALL'
) {
  switch (action) {
    case 'ADD':
      return allUpdates.some(
        elem =>
          elem.fundId === original.fundId &&
          (original.subfundId ? elem.subfundId === original.subfundId : true) &&
          (original.shareclassId ? elem.shareclassId === original.shareclassId : true) &&
          elem.changeStatus === 'ADD'
      )
    case 'REMOVE':
      return allUpdates.some(
        elem =>
          elem.fundId === original.fundId &&
          (original.subfundId ? elem.subfundId === original.subfundId : true) &&
          (original.shareclassId ? elem.shareclassId === original.shareclassId : true) &&
          elem.changeStatus === 'REMOVE'
      )
    case 'MODIFY':
      return allUpdates.some(
        elem =>
          elem.fundId === original.fundId &&
          (original.subfundId ? elem.subfundId === original.subfundId : true) &&
          (original.shareclassId ? elem.shareclassId === original.shareclassId : true) &&
          elem.changeStatus === 'MODIFY'
      )
    case 'ALL':
      return allUpdates.some(
        elem =>
          elem.fundId === original.fundId &&
          (original.subfundId ? elem.subfundId === original.subfundId : true) &&
          (original.shareclassId ? elem.shareclassId === original.shareclassId : true) &&
          (elem.changeStatus === 'ADD' || elem.changeStatus === 'MODIFY')
      )
    default:
      return allUpdates.some(
        elem =>
          elem.fundId === original.fundId &&
          (original.subfundId ? elem.subfundId === original.subfundId : true) &&
          (original.shareclassId ? elem.shareclassId === original.shareclassId : true)
      )
  }
}

// style config for the select button
const filtersStyle: StylesConfig<OptionType, false> = {
  indicatorSeparator: provided => ({ ...provided, display: 'none' }),
  container: provided => ({
    ...provided,
    fontSize: '0.8rem',
    width: 'max-content',
    minWidth: '106px',
  }),
  dropdownIndicator: provided => ({ ...provided, padding: '0px 2px' }),
  valueContainer: provided => ({ ...provided, padding: '0px 2px' }),
}

export default function PeriodicityAndSubscribed(options: {
  original: ScopeItem
  allUpdates: AllUpdates[]
  setAllUpdates: any
  allReportsFrequency: OptionType[]
  reportLevel: any
  defaultFrequency: OptionType | null
}) {
  const {
    original,
    allUpdates,
    setAllUpdates,
    allReportsFrequency,
    reportLevel,
    defaultFrequency,
  } = options
  const { mainColor } = useContext(ThemeContext)
  const thereIsSome = thereIsHandler(original, allUpdates)
  const thereIsRemoved = thereIsHandler(original, allUpdates, 'REMOVE')
  const thereIsAdded = thereIsHandler(original, allUpdates, 'ADD')
  const thereIsModified = thereIsHandler(original, allUpdates, 'MODIFY')
  const handleInitialValue = () => {
    // if the value was modified and rerendered
    if (thereIsModified) {
      const frequency = allReportsFrequency.find(
        elem =>
          elem.value ===
          // @ts-ignore
          allUpdates.find(
            allElem =>
              allElem.fundId === original.fundId &&
              (original.subfundId ? allElem.subfundId === original.subfundId : true) &&
              (original.shareclassId ? allElem.shareclassId === original.shareclassId : true)
          ).frequencyId
      )
      if (frequency) {
        return frequency
      }
    }
    if (original.frequencyId) {
      if (!original.reporting) {
        return defaultFrequency
      }
      return allReportsFrequency.find(elem => elem.value === original.frequencyId)
    }
    if (thereIsAdded) {
      const frequency = allReportsFrequency.find(
        elem =>
          elem.value ===
          // @ts-ignore
          allUpdates.find(
            allElem =>
              allElem.fundId === original.fundId &&
              allElem.subfundId === original.subfundId &&
              (original.shareclassId ? allElem.shareclassId === original.shareclassId : true)
          ).frequencyId
      )
      if (frequency) {
        return frequency
      }
    }
    if (defaultFrequency) {
      return defaultFrequency
    }
    return null
  }
  // @ts-ignore
  const [reportFrequency, setReportFrequency] = useState<OptionType | null>(handleInitialValue)
  useEffect(() => {
    if (!thereIsSome) {
      // @ts-ignore
      setReportFrequency(handleInitialValue)
    }
  }, [allUpdates])

  return (
    <div
      style={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        style={{
          width: '400px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '2px 0px',
          minHeight: '26px',
        }}>
        {((original.reporting && !thereIsSome) ||
          (original.reporting && thereIsModified) ||
          (!original.reporting && thereIsAdded)) && (
          <SelectInputSearchControlled
            menuPortalTarget={document.body}
            removeClear
            customStyle={
              // @ts-ignore
              (() => {
                const controlStyle = {
                  control: (provided: CSSObjectWithLabel) => ({
                    ...provided,
                    maxHeight: '26px',
                    minHeight: '26px',
                  }),
                }
                if (!original.reporting) {
                  return {
                    ...filtersStyle,
                    ...controlStyle,
                    singleValue: provided => ({ ...provided, color: mainColor }),
                  }
                }
                if (reportFrequency?.value !== original.frequencyId) {
                  return {
                    ...filtersStyle,
                    ...controlStyle,
                    singleValue: provided => ({ ...provided, color: mainColor }),
                  }
                }
                return {
                  ...filtersStyle,
                  ...controlStyle,
                }
              })()
            }
            value={reportFrequency}
            options={allReportsFrequency}
            placeholder=''
            onChangeCallback={option => {
              setReportFrequency(option)
              setAllUpdates((prev: any) => {
                const oldSelected = [
                  ...prev.filter((elem: any) => {
                    if (reportLevel === 'CodeIsin') {
                      return !(
                        elem.fundId === original.fundId &&
                        elem.subfundId === original.subfundId &&
                        elem.shareclassId === original.shareclassId
                      )
                    }
                    return !(
                      elem.fundId === original.fundId && elem.subfundId === original.subfundId
                    )
                  }),
                ]
                const addedBefore = [
                  ...prev.filter((elem: any) => {
                    if (reportLevel === 'CodeIsin') {
                      return (
                        elem.fundId === original.fundId &&
                        elem.subfundId === original.subfundId &&
                        elem.shareclassId === original.shareclassId &&
                        elem.changeStatus === 'ADD'
                      )
                    }
                    return (
                      elem.fundId === original.fundId &&
                      elem.subfundId === original.subfundId &&
                      elem.changeStatus === 'ADD'
                    )
                  }),
                ]
                let newSelected = {}
                if (option?.value !== original.frequencyId) {
                  if (addedBefore.length !== 0) {
                    newSelected = {
                      ...original,
                      changeStatus: 'ADD',
                      frequencyId: option?.value,
                      frequency: option?.label,
                    }
                  } else {
                    newSelected = {
                      ...original,
                      changeStatus: 'MODIFY',
                      oldFrequencyId: original.frequencyId,
                      oldFrequency: original.frequency,
                      frequencyId: option?.value,
                      frequency: option?.label,
                    }
                  }
                }
                return Object.keys(newSelected).length
                  ? [...oldSelected, newSelected]
                  : [...oldSelected]
              })
            }}
          />
        )}
      </div>
      <div style={{ width: '400px' }}>
        <MuiCheckBox
          // HOTFIX TODO remove disable after frontback fix request for fund level
          disabled={reportLevel === 'Fund'}
          size='small'
          checked={
            (!!original.reporting && !thereIsRemoved) ||
            (!original.reporting && (thereIsAdded || thereIsModified))
          }
          style={{
            padding: '0px',
            color: (() => {
              if (
                (original.reporting && thereIsRemoved) ||
                (!original.reporting && thereIsAdded) ||
                (original.reporting && thereIsModified)
              ) {
                return mainColor
              }
              if (original.reporting && !thereIsRemoved) {
                return COLOR.GREEN
              }
              return 'gray'
            })(),
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAllUpdates((prev: any) => {
              const oldSelected = [
                ...prev.filter((elem: any) => {
                  if (reportLevel === 'CodeIsin') {
                    return !(
                      elem.fundId === original.fundId &&
                      elem.subfundId === original.subfundId &&
                      elem.shareclassId === original.shareclassId
                    )
                  }
                  return !(elem.fundId === original.fundId && elem.subfundId === original.subfundId)
                }),
              ]
              let newSelected = {}
              // check if has reporting and was not removed or has reporting and was modified
              if ((original.reporting && !thereIsSome) || (original.reporting && thereIsModified)) {
                newSelected = {
                  ...original,
                  changeStatus: 'REMOVE',
                  frequencyId: original.frequencyId,
                  frequency: original.frequency,
                }
                // @ts-ignore
                setReportFrequency(
                  allReportsFrequency.find(
                    elem => elem.value === original.frequencyId
                  ) as OptionType
                )
              }
              if (!original.reporting && !thereIsSome) {
                newSelected = {
                  ...original,
                  changeStatus: 'ADD',
                  frequencyId: defaultFrequency?.value,
                  frequency: defaultFrequency?.label,
                }
              }
              if (!e.target.checked && !thereIsRemoved && !original.reporting) {
                setReportFrequency(defaultFrequency)
              }
              return Object.keys(newSelected).length
                ? [...oldSelected, newSelected]
                : [...oldSelected]
            })
          }}
        />
      </div>
    </div>
  )
}
