import http from '../http'
import { ProductionStatusDto, ProductionStatusSchema } from '../DashboardService/dashboardService'

const baseUrl = '/production-stats'

export const productionStatsService = {
  getTotalNumberOfDocumentsInValidation() {
    return http.get<number>(`${baseUrl}/document-in-validation/total-number`)
  },
  getRatioBetweenCompletedReportsAndPlannedReportsForThisMonth() {
    return http.get<number>(`${baseUrl}/ratio-completed-planned-reports`)
  },
  getProductionStatus() {
    return http.get<ProductionStatusDto[]>(`${baseUrl}/count-by-production-step`).then(resp => {
      ProductionStatusSchema.array().parse(resp.data)
      return resp
    })
  },
}
