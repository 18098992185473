import { MRT_Cell, MRT_Column, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react'
import { ThemeContext } from '../../../context/ThemeProvider'
import { ChangelogNarrative } from './narrativeValidationModal/NarrativeValidationModal'
import { NarrativeValidationScopeType } from '../NarrativeValidation'

function ChangelogCell(
  row: NarrativeValidationScopeType,
  setIsNarrativeValidationModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentChangelogData: React.Dispatch<React.SetStateAction<ChangelogNarrative[]>>,
  setCurrentFilePath: React.Dispatch<React.SetStateAction<string>>
) {
  const { mainColor } = useContext(ThemeContext)

  return (
    <div
      onClick={() => {
        setIsNarrativeValidationModalOpen(true)
        setCurrentChangelogData(row.narratives)
        setCurrentFilePath(row.filePath)
      }}>
      <FontAwesomeIcon icon={faInfoCircle} style={{ color: mainColor }} />
    </div>
  )
}

export default ChangelogCell
