import { MRT_Row } from 'material-react-table'
import React, { useContext } from 'react'
import { Tooltip } from '@mui/material'
import { NavigateFunction } from 'react-router-dom'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Followup } from '../ProductionStatus.types'
import { COLOR } from '../../../constants/theme'
import CheckIcon from '../../../components/icon/CheckIcon'
import ButtonWithIcon from '../../../components/button/ButtonWithIcon'
import { ROUTES } from '../../../constants/routes'
import { UserContext } from '../../../context/UserProvider'
import { ProductionStatusDashboardDto } from '../../../types/dtos/production/productionDto'

export default function HandleClientValidationCell(
  options: { row: MRT_Row<ProductionStatusDashboardDto> },
  mainColor: string,
  navigate: NavigateFunction
) {
  const {
    row: { original },
  } = options
  const { user } = useContext(UserContext)
  if (original.clientValidationEnabled === false) {
    return (
      <Tooltip title='No validation necessary for this report type'>
        <div style={{ color: COLOR.GRAY }}>N/A</div>
      </Tooltip>
    )
  }
  if (
    original.inDataReceptionCount === 0 &&
    original.inProductionCount === 0 &&
    original.inControlCount === 0 &&
    original.inClientValidationCount === 0
  ) {
    return <CheckIcon />
  }
  return (
    <ButtonWithIcon
      text={`${original.inClientValidationCount}`}
      icon={user?.hasParent ? undefined : faChevronRight}
      textToLeft
      customButtonStyle={{ backgroundColor: 'transparent' }}
      customIconStyle={{ color: mainColor }}
      onClick={
        user?.hasParent
          ? undefined
          : () => {
              navigate(ROUTES.REPORT_VALIDATION, {
                state: {
                  reportDateFormatted: original.reportDateFormatted,
                  reportTypeName: original.reportTypeName,
                  reportDate: original.reportDate,
                  reportTypeId: original.reportTypeId,
                  reportLevel: original.reportLevel,
                },
              })
            }
      }
    />
  )
}
