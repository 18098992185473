import { faDownload } from '@fortawesome/free-solid-svg-icons'
import FileSaver from 'file-saver'
import React, { useCallback, useEffect, useState, useContext } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useCurrentPng } from 'recharts-to-png'
import styled from 'styled-components'
import Icon from '../../../../../components/icon/Icon'
import Loader from '../../../../../components/loader/Loader'
import Title from '../../../../../components/text/Title'
import { SubscribedReportContext } from '../../../../../context/SubscribedReportProvider'
import { getWeekdaysInMonth } from '../../../../../helpers/helpers'
import http from '../../../../../services/http'

type Props = {
  totalReportNumberDeliveredByType: any
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

function TotalReportNumberDeliveredByType({ totalReportNumberDeliveredByType }: Props) {
  const { distinctSubscribedReports } = useContext(SubscribedReportContext)
  const [isLoading, setIsLoading] = useState(true)

  const [getPng, { ref }] = useCurrentPng()

  const handleDownload = useCallback(async () => {
    const png = await getPng()
    if (png) {
      FileSaver.saveAs(png, 'TotalReportNumberDeliveredByType.png')
    }
  }, [getPng])

  useEffect(() => {
    if (totalReportNumberDeliveredByType) {
      setIsLoading(false)
    }
  }, [totalReportNumberDeliveredByType])

  const colors = [
    '#FC9F5B',
    '#ECE4B7',
    '#7DCFB6',
    '#FF6700',
    '#EBEBEB',
    '#C0C0C0',
    '#3A6EA5',
    '#004E98',
    '#22162B',
    '#451F55',
    '#724E91',
    '#E54F6D',
    '#F8C630',
    '#3A4F41',
    '#B9314F',
    '#D5A18E',
    '#FBACBE',
    '#B9D2B1',
  ]

  if (isLoading)
    return (
      <div style={{ position: 'relative', height: '100%' }}>
        <Loader />
      </div>
    )

  return (
    <Container>
      <TitleContainer>
        <Icon icon={faDownload} onClick={() => handleDownload()} pointer notCentered />
        <Title center>Number of total report delivered by type</Title>
      </TitleContainer>

      <ResponsiveContainer width='100%' height='80%'>
        <BarChart
          ref={ref}
          width={500}
          height={300}
          data={totalReportNumberDeliveredByType}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          {distinctSubscribedReports.map((e, i) => {
            return (
              <Bar
                dataKey={e.reportTypeName}
                stackId='a'
                fill={colors[i]}
                key={`${e.reportTypeName}_${e.reportTypeLevel}`}
              />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default TotalReportNumberDeliveredByType
