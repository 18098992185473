import { faDownload } from '@fortawesome/free-solid-svg-icons'
import FileSaver from 'file-saver'
import React, { useCallback, useEffect, useState, useContext } from 'react'
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { useCurrentPng } from 'recharts-to-png'
import styled from 'styled-components'
import Icon from '../../../../../components/icon/Icon'
import Loader from '../../../../../components/loader/Loader'
import NotYet from '../../../../../components/notYet/NotYet'
import Title from '../../../../../components/text/Title'
import { SubscribedReportContext } from '../../../../../context/SubscribedReportProvider'
import http from '../../../../../services/http'
import { ThemeContext } from '../../../../../context/ThemeProvider'

function ScopeRepartitionByReportType(props: { scopeRepartitionByReportType: any }) {
  const { scopeRepartitionByReportType } = props
  const [isloading, setIsloading] = useState(true)
  const [getPng, { ref }] = useCurrentPng()

  const { mainColor } = useContext(ThemeContext)

  useEffect(() => {
    if (scopeRepartitionByReportType.length !== 0) {
      setIsloading(false)
    }
  }, [scopeRepartitionByReportType])

  const handleDownload = useCallback(async () => {
    const png = await getPng()
    if (png) {
      FileSaver.saveAs(png, 'ScopeRepartitionByReportType.png')
    }
  }, [getPng])

  if (isloading)
    return (
      <div style={{ position: 'relative', height: '100%' }}>
        <Loader />
      </div>
    )

  // if (!scopeRepartitionByReportType.length)
  //   return (
  //     <div
  //       style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
  //       <NotYet notAbsolute />
  //     </div>
  //   )

  return (
    <Container>
      <TitleContainer>
        <Icon icon={faDownload} onClick={() => handleDownload()} pointer notCentered />
        <Title center>Scope repartition by report type</Title>
      </TitleContainer>
      <ResponsiveContainer width='100%' height='80%'>
        <RadarChart
          cx='50%'
          cy='50%'
          outerRadius='80%'
          data={scopeRepartitionByReportType}
          ref={ref}>
          <PolarGrid />
          <PolarAngleAxis dataKey='subject' />
          <PolarRadiusAxis />
          <Radar name='Qty' dataKey='value' stroke='#8884d8' fill={mainColor} fillOpacity={0.6} />
          <Tooltip />
        </RadarChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default ScopeRepartitionByReportType

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
