import { Box, Modal } from '@mui/material'
import { useContext } from 'react'
import * as S from './NarrativeValidationModal.styles'
import PdfViewer from '../../../../components/pdfViewer/PdfViewer'
import NarrativeBlock from '../narrativeBlock/NarrativeBlock'
import NarrativeValidationCommentWindow from './narrativeValidationCommentWindow/NarrativeValidationCommentWindow'
import { NarrativeContext } from '../../context/NarrativeContextProvider'

export type ChangelogNarrative = {
  id: string
  old: string
  new: string
}

type Props = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  changelogData: ChangelogNarrative[]
  filePath: string
}

// const changelogData = [
//   {
//     new: {
//       id: '1',
//       version: 'v1.7',
//       htmlString:
//         '<div id="1"><div class="changelog">Test 2</div><div class="changelog">Test 2</div></div>',
//     },
//     old: {
//       id: '2',
//       version: 'v1.0',
//       htmlString:
//         '<div>This key information document is accurate as at<delation path="" onclick="function(e){console.log("cc")}"> <span>18</span><strong>sfi</strong></delation><sup path="html[2]/body[4]/div[6]/div[4]/div[4]/div[4]/div[4]/div[4]/div[6]/sup[2]"><span class="to_remove" hash-id="-880111408423931590" id="115075c8-8409-4c3d-925b-229608ef868b--880111408423931590" path="html[2]/body[4]/div[6]/div[4]/div[4]/div[4]/div[4]/div[4]/div[6]/sup[2]">th</span></sup></div>',
//     },
//   },
//   {
//     new: {
//       id: '3',
//       version: 'v2.0',
//       htmlString:
//         '<div id="3"><div class="changelog">Welcome</div><div class="changelog">to</div><div class="changelog">the API</div></div>',
//     },
//     old: {
//       id: '4',
//       version: 'v1.7',
//       htmlString:
//         '<div id="4"><div class="changelog">Test 2</div><div class="changelog">Test 2</div></div>',
//     },
//   },
//   {
//     new: {
//       id: '5',
//       version: 'v2.1',
//       htmlString:
//         '<div id="5"><div class="changelog">Exciting</div><div class="changelog">new features</div></div>',
//     },
//     old: {
//       id: '6',
//       version: 'v2.0',
//       htmlString:
//         '<div id="6"><div class="changelog" onclick="function(e){console.log("Coucou")}">Welcome</div><div class="changelog">to</div><div class="changelog">the API</div></div>',
//     },
//   },
// ]

function NarrativeValidationModal({ isOpen, setIsOpen, changelogData, filePath }: Props) {
  const { isCommentWindowOpen } = useContext(NarrativeContext)

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}>
      <S.ModalContainer>
        <S.ModalBody>
          {isCommentWindowOpen && <NarrativeValidationCommentWindow />}
          <S.PdfFlexContainer>
            <S.DocumentContainer>
              <S.NarrativesRows>
                {changelogData.map(changelog => {
                  return (
                    <S.NarrativeChangelogContainer>
                      <NarrativeBlock htmlString={changelog.old} id={changelog.id} old />
                      <NarrativeBlock htmlString={changelog.new} id={changelog.id} old={false} />
                    </S.NarrativeChangelogContainer>
                  )
                })}
              </S.NarrativesRows>

              {/*<S.PartTitle>Document changelog</S.PartTitle>*/}
              {/*<div>V1.0 --{'>'} V1.7</div>*/}
              {/*<PdfViewer filePathInPublicFolder='./KID_20230628_en_LU_4_LU1023698746.pdf' />*/}
            </S.DocumentContainer>
            <S.DocumentContainer>
              <S.PartTitle>Final document</S.PartTitle>
              <div>V1.7</div>
              {/*<embed src={'./KID_20230628_en_LU_4_LU1023698746.pdf'} height={'100%'} />*/}
              <PdfViewer filePathInPublicFolder={filePath} initialScale={1.5} />
            </S.DocumentContainer>
          </S.PdfFlexContainer>

          {/*<embed src={myPDF} height={'100%'} width={'100%'} />*/}
        </S.ModalBody>
      </S.ModalContainer>
    </Modal>
  )
}

export default NarrativeValidationModal
