import React from 'react'
import { MRT_Row } from 'material-react-table'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import CheckIcon from '../../../components/icon/CheckIcon'
import { Followup, RequestType } from '../ProductionStatus.types'
import { ProductionStatusDashboardDto } from '../../../types/dtos/production/productionDto'
import ButtonWithIcon from '../../../components/button/ButtonWithIcon'

export default function HandleInProductionCell(
  options: {
    row: MRT_Row<ProductionStatusDashboardDto>
  },
  setParamsForValidation: React.Dispatch<React.SetStateAction<RequestType | null>>
) {
  const {
    row: { original },
  } = options
  if (original.inDataReceptionCount === 0 && original.inProductionCount === 0) {
    return <CheckIcon />
  }
  return (
    <>
      <span>{original.inProductionCount}</span>
      <ButtonWithIcon
        icon={faInfo}
        customButtonStyle={{ borderRadius: '25%', marginLeft: '5px', padding: '2px 5px' }}
        customIconStyle={{ color: 'gray', height: '10px' }}
        onClick={() => {
          setParamsForValidation({
            reportDate: original.reportDate,
            reportTypeId: original.reportTypeId,
            reportLevel: original.reportLevel,
            productionStatusId: 2,
          })
        }}
      />
    </>
  )
}
