import styled from 'styled-components'
import { COLOR } from '../../constants/theme'

export const ModalBox = styled.div`
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px 30px;
  border-radius: 5px;
  color: #4b525a;
  width: 40vw;

  //remove border on focus
  &:focus {
    outline: none;
  }
`

export const ModalBody = styled.div`
  height: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Title = styled.div`
  margin-bottom: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 1.2em;
`

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
`

export const CloseButton = styled.div`
  background-color: ${COLOR.GRAYLIGHT};
  padding: 5px 12px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  gap: 5px;
  align-items: center;
`
