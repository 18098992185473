import React from 'react'
import { MRT_Cell, MRT_TableInstance } from 'material-react-table'
import { Tooltip } from '@mui/material'
import { Row } from 'react-table'
import { DeliverableValidationDto } from '../../../../types/dtos/FollowupDto'
import { ProductionValidationDto } from '../../../../types/dtos/production/productionDto'

export default function HandleDocumentCell(
  options: {
    cell: MRT_Cell<ProductionValidationDto>
    table: MRT_TableInstance<ProductionValidationDto>
  },
  reportLevel: 'Fund' | 'Subfund' | 'CodeIsin'
) {
  const tooltipMessage = `${options.cell.renderValue()}`
  const { fundName, subfundName, codeIsin } = options.cell.row.original
  const keyId =
    reportLevel === 'CodeIsin'
      ? `fundName:${fundName}>subfundName:${subfundName}>codeIsin:${codeIsin}>countryWithLanguage:${options.cell.row.original.countryWithLanguage}`
      : `fundName:${fundName}>subfundName:${subfundName}>countryWithLanguage:${options.cell.row.original.countryWithLanguage}`

  const subrows = [...options.table.getGroupedRowModel().rowsById[keyId].subRows]

  subrows.sort((a, b) => {
    if (a.original.generationTimestamp === null) return -1
    if (b.original.generationTimestamp === null) return -1

    const dateA = new Date(a.original.generationTimestamp)
    const dateB = new Date(b.original.generationTimestamp)

    return dateB.getTime() - dateA.getTime()
  })

  // console.log(sortedSubrows)

  const originalIndex = subrows.findIndex(
    subRow => subRow.original.documentId === options.cell.row.original.documentId
  )

  const reverseIndex = subrows.length - originalIndex - 1

  return (
    <Tooltip title={tooltipMessage} placement='left'>
      <div>{`v${reverseIndex}`}</div>
    </Tooltip>
  )
}
