import React from 'react';
import styled from 'styled-components';
import { MOBILE_MEDIA } from '../../constants/theme';

type Props = {
  children: React.ReactNode;
  gap?: number;
};

function Layout({ children, gap }: Props) {
  return (
    <Container gap={gap}>
      {children}
      <PaddingBottom />
    </Container>
  );
}

const Container = styled.div<any>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 32px;
  box-sizing: border-box;
  width: 100%;
  ${e =>
    e.gap
      ? `gap: ${e.gap}px;
  ${MOBILE_MEDIA}{
      gap: 16px;
    }`
      : ''}

  ${MOBILE_MEDIA} {
    padding: 16px;
  }
`;

const PaddingBottom = styled.div`
  padding: 16px;
`;

export default Layout;
