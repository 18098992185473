import http from '../http'
import { ROUTES } from '../../constants/routes'

const {
  REACT_APP_SSO_CLIENT_ID,
  REACT_APP_SSO_CLIENT_SECRET,
  REACT_APP_SSO_REDIRECT_URI,
  REACT_APP_SSO_TOKEN_ENDPOINT,
  REACT_APP_SSO_REVOCATION_ENDPOINT,
  REACT_APP_SSO_END_SESSION_ENDPOINT,
} = process.env

export const SingleSignOnService = {
  getTokenFromAuthCode(authCode: string) {
    return http
      .post(
        `${REACT_APP_SSO_TOKEN_ENDPOINT}`,
        {
          code: authCode,
          grant_type: 'authorization_code',
          client_id: REACT_APP_SSO_CLIENT_ID,
          client_secret: REACT_APP_SSO_CLIENT_SECRET,
          redirect_uri: REACT_APP_SSO_REDIRECT_URI,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then(response => {
        // Store the tokens in localStorage
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
        localStorage.setItem('id_token', response.data.id_token)
        SingleSignOnService.scheduleTokenRefresh(response.data.expires_in)
        return response
      })
  },

  refreshAccessToken() {
    const refreshToken = localStorage.getItem('refresh_token')
    return http
      .post(
        `${REACT_APP_SSO_TOKEN_ENDPOINT}`,
        {
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
          client_id: REACT_APP_SSO_CLIENT_ID,
          client_secret: REACT_APP_SSO_CLIENT_SECRET,
          redirect_uri: REACT_APP_SSO_REDIRECT_URI,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then(response => {
        // Store the tokens in localStorage
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
        localStorage.setItem('id_token', response.data.id_token)

        http.defaults.headers.common.Authorization = response.data.access_token
        SingleSignOnService.scheduleTokenRefresh(response.data.expires_in)

        return response
      })
  },

  scheduleTokenRefresh(expiresInSeconds: number) {
    const timeToRefresh = expiresInSeconds - 5 // Refresh 5 seconds before expiration
    const delay = timeToRefresh * 1000 // Delay in milliseconds

    setTimeout(() => {
      SingleSignOnService.refreshAccessToken()
    }, delay)
  },

  revokeToken() {
    const accessToken = localStorage.getItem('access_token')

    if (accessToken) {
      // Revoke the access token
      http.post(
        `${REACT_APP_SSO_REVOCATION_ENDPOINT}`,
        {
          token: accessToken,
          client_id: REACT_APP_SSO_CLIENT_ID,
          client_secret: REACT_APP_SSO_CLIENT_SECRET,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
    }
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('id_token')
  },

  logout() {
    return http.post(
      `${REACT_APP_SSO_END_SESSION_ENDPOINT}`,
      {
        client_id: REACT_APP_SSO_CLIENT_ID,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  },
}
