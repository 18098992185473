import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import AverageTicketResponseTimeOnPeriod from './components/AverageTicketResponseTimeOnPeriod'
import ScopeRepartitionByReportType from './components/ScopeRepartitionByReportType'
import TicketNumberOnPeriod from './components/TicketNumberOnPeriod'
import TimelinessRespectDeadline from './components/TimelinessRespectDeadline'
import TotalReportNumberDeliveredByType from './components/TotalReportNumberDeliveredByType'
import { SubscribedReportContext } from '../../../../context/SubscribedReportProvider'
import http from '../../../../services/http'
import { getWeekdaysInMonth } from '../../../../helpers/helpers'

type AllPlannedProductionType = {
  codeIsin: string
  enabledReportTypeName: string
  frequencyId: string
  fundId: number
  fundName: string
  reportTypeId: number
  reportTypeName: string
  shareclassId: number
  shareclassName: string
  subfundId: number
  subfundName: string
}

type AllScopesType = {
  codeIsin: string
  frequency: string | null
  frequencyId: string | null
  fundId: number
  fundName: string
  reportFrequencyId: number | null
  reporting: string | null
  shareclassId: number
  shareclassName: string
  subfundId: number
  subfundName: string
}
type AllScopesKPIType = {
  subject: string
  value: number
  fullMark: number
}

const months = [
  {
    id: 1,
    libelle: 'January',
  },
  {
    id: 2,
    libelle: 'February',
  },
  {
    id: 3,
    libelle: 'March',
  },
  {
    id: 4,
    libelle: 'April',
  },
  {
    id: 5,
    libelle: 'May',
  },
  {
    id: 6,
    libelle: 'June',
  },
  {
    id: 7,
    libelle: 'July',
  },
  {
    id: 8,
    libelle: 'August',
  },
  {
    id: 9,
    libelle: 'September',
  },
  {
    id: 10,
    libelle: 'October',
  },
  {
    id: 11,
    libelle: 'November',
  },
  {
    id: 12,
    libelle: 'December',
  },
].splice(0, new Date().getMonth() + 1)

function ProductionStatusKpi() {
  const [scopeRepartitionByReportType, setScopeRepartitionByReportType] = useState<
    AllScopesKPIType[]
  >([])
  const [timelinessRespectDeadline, setTimelinessRespectDeadline] = useState<any[]>([])
  const [totalReportNumberDeliveredByType, setTotalReportNumberDeliveredByType] = useState<any[]>(
    []
  )
  const { distinctSubscribedReports } = useContext(SubscribedReportContext)

  useEffect(() => {
    const fetchScopes = async () => {
      const reducedRequest: AllScopesKPIType[] = []
      for (const report of distinctSubscribedReports) {
        const requestData = await http.get<AllScopesType[]>(`/scopes/${report.reportTypeId}`)
        const sum = requestData.data.reduce((accumulator, currentValue) => {
          if (currentValue.reporting) accumulator++
          return accumulator
        }, 0)
        reducedRequest.push({
          subject: report.reportTypeName,
          value: sum,
          fullMark: sum,
        })
      }
      setScopeRepartitionByReportType(reducedRequest.sort((a, b) => a.value - b.value))
    }
    if (distinctSubscribedReports) {
      // data for scopeRepartitionByReportType KPI
      fetchScopes()
    }
    // data for timelinessRespectDeadline and totalReportNumberDeliveredByType
    const fetchPlanned = async () => {
      const c1 = 'Total delivered on time'
      const c2 = 'Total overdue'
      const timelinessRespectDeadlineRequest = []
      const totalReportNumberDeliveredByTypeRequest = []
      for (let i = 0; i < months.length; i += 1) {
        const trd: any = {
          name: months[i].libelle,
          c2: 0,
        }
        const trndbt: any = {
          name: months[i].libelle,
        }
        const requestData = await http.get<AllPlannedProductionType[]>(
          `/plannedProduction/${months[i].id}`
        )
        requestData.data.forEach((e: any) => {
          switch (e.frequencyId) {
            // daily
            case '1': {
              const trdnb = getWeekdaysInMonth(months[i].id, new Date().getFullYear())
              trd[c1] = trd[c1] ? trd[c1] + trdnb : trdnb
              const trndbtnb = getWeekdaysInMonth(months[i].id, new Date().getFullYear())
              trndbt[e.reportTypeName] = trndbt[e.reportTypeName]
                ? trndbt[e.reportTypeName] + trndbtnb
                : trndbtnb
              break
            }

            // bi weekly
            case '6':
              trd[c1] = trd[c1] ? trd[c1] + 8 : 8
              trndbt[e.reportTypeName] = trndbt[e.reportTypeName] ? trndbt[e.reportTypeName] + 8 : 8

              break

            // weekly
            case '2':
              trd[c1] = trd[c1] ? trd[c1] + 4 : 4
              trndbt[e.reportTypeName] = trndbt[e.reportTypeName] ? trndbt[e.reportTypeName] + 4 : 4

              break

            //fortnightly
            case '7':
              trd[c1] = trd[c1] ? trd[c1] + 2 : 2
              trndbt[e.reportTypeName] = trndbt[e.reportTypeName] ? trndbt[e.reportTypeName] + 2 : 2

              break

            default:
              trd[c1] = trd[c1] ? trd[c1] + 1 : 1
              trndbt[e.reportTypeName] = trndbt[e.reportTypeName] ? trndbt[e.reportTypeName] + 1 : 1
              break
          }
        })
        timelinessRespectDeadlineRequest.push(trd)
        totalReportNumberDeliveredByTypeRequest.push(trndbt)
      }
      setTimelinessRespectDeadline(timelinessRespectDeadlineRequest)
      setTotalReportNumberDeliveredByType(totalReportNumberDeliveredByTypeRequest)
    }
    fetchPlanned()
  }, [distinctSubscribedReports])

  return (
    <Container>
      <Row>
        <Graph>
          <AverageTicketResponseTimeOnPeriod />
        </Graph>
        <Graph>
          <ScopeRepartitionByReportType
            scopeRepartitionByReportType={scopeRepartitionByReportType}
          />
        </Graph>
      </Row>
      <Row>
        <Graph>
          <TicketNumberOnPeriod />
        </Graph>
        <Graph>
          <TotalReportNumberDeliveredByType
            totalReportNumberDeliveredByType={totalReportNumberDeliveredByType}
          />
        </Graph>
      </Row>
      <Row>
        <Graph>
          <TimelinessRespectDeadline timelinessRespectDeadline={timelinessRespectDeadline} />
        </Graph>
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin: 8px;
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  row-gap: 16px;
`

const Row = styled.div`
  flex: 1;
  display: flex;
  column-gap: 16px;
`

const Graph = styled.div`
  flex: 1;
`

export default ProductionStatusKpi
