const ROUTES = {
  LOGIN: '/login',
  SSO_LOGIN: '/sso-login',
  SSO_USER_NOT_FOUND: '/sso-user-not-found',

  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot_password',
  DASHBOARD: '/dashboard',
  REPORT_VALIDATION: '/report_validation',
  DATA: '/data',
  PRODUCTION_STATUS: '/production_status',
  PORTFOLIO: '/portfolio',
  // SCOPE_MANAGEMENT: 'scope_management',
  SCOPE_MANAGEMENT_EXISTING_SERVICES: '/scope_management/existing_services',
  SCOPE_MANAGEMENT_PLATFORM: '/scope_management/platform',
  SCOPE_MANAGEMENT_DISSEMINATION_LIST: '/scope_management/dissemination_list',
  SCOPE_MANAGEMENT_REGISTRATION_MATRIX: '/scope_management/registration_matrix',

  SCOPE_VALIDATION: '/scope_validation',
  // INVOICING: '/invoicing',
  INVOICING_INVOICES: '/invoicing/invoices',
  INVOICING_DATA: '/invoicing/data',

  LIBRARY: '/library',
  DOCUMENT_VALIDATION: '/document_validation',
  NARRATIVE_VALIDATION: '/narrative_validation',
  TEMPLATE_VALIDATION: '/template_validation',
  KPI: '/kpi',
  SSO_LOGOUT: '/sso-logout',
}

export { ROUTES }
