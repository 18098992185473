import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import './index.css'
import { ProSidebarProvider } from 'react-pro-sidebar'
import UserProvider from './context/UserProvider'
import MenuProvider from './context/MenuProvider'
// import styled from 'styled-components';
import DashboardProvider from './context/DashboardProvider'
import SubscribedReportProvider from './context/SubscribedReportProvider'
import RssProvider from './context/RssProvider'
// require('dotenv').config()
import NotificationProvider from './context/NotificationProvider'
import { ThemeProvider } from './context/ThemeProvider'
import reportWebVitals from './reportWebVitals'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <MenuProvider>
      <ThemeProvider>
        <UserProvider>
          <SubscribedReportProvider>
            <RssProvider>
              <NotificationProvider>
                <ProSidebarProvider>
                  <App />
                </ProSidebarProvider>
              </NotificationProvider>
            </RssProvider>
          </SubscribedReportProvider>
        </UserProvider>
      </ThemeProvider>
    </MenuProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
