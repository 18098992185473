import { t } from 'i18next'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'
import Layout from '../../components/layout/Layout'
import Separator from '../../components/separator/Separator'
import { ROUTES } from '../../constants/routes'
import { COLOR, MOBILE_MEDIA } from '../../constants/theme'
import Text from '../../fragments/Text'
import { getExpFromJwt } from '../../helpers/helpers'
import http from '../../services/http'

function ResetPassword() {
  const [password, setPassword] = useState('')
  const [searchParams] = useSearchParams()
  const token = searchParams.get('access_token')
  const navigate = useNavigate()
  getExpFromJwt(token)

  const handleClick = () => {
    http
      .post('/reset-password', {
        password,
        token,
      })
      .then(({ data }: any) => {
        localStorage.setItem('access_token', data.token)
        navigate(ROUTES.DASHBOARD, { replace: true })
      })
      .catch(e => {
        toast(e.response.data.message)
      })
  }

  return (
    <Layout>
      <Container>
        <SubContainer>
          <Text>New password</Text>
          <Separator />
          <Input
            data={password}
            setter={(e: any) => setPassword(e.target.value)}
            type='password'
            onEnterCallback={handleClick}
          />
          <PasswordPolicy>
            Password policy requires 12 characters, including a special one
          </PasswordPolicy>
          <Separator />
          <Button text={t('Save')} handleClick={handleClick} />
        </SubContainer>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const SubContainer = styled.div`
  ${MOBILE_MEDIA} {
    width: 100%;
  }
`

const PasswordPolicy = styled.div`
  margin-top: 4px;
  font-size: 10px;
  color: ${COLOR.GRAY};
`

export default ResetPassword
