import { MRT_Row } from 'material-react-table'
import React from 'react'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { Followup, RequestType } from '../ProductionStatus.types'
import ButtonWithIcon from '../../../components/button/ButtonWithIcon'
import { ProductionStatusDashboardDto } from '../../../types/dtos/production/productionDto'

export default function HandleScopeCell(
  options: { row: MRT_Row<ProductionStatusDashboardDto> },
  setParamsForValidation: React.Dispatch<React.SetStateAction<RequestType | null>>
) {
  const {
    row: { original },
  } = options
  return (
    <>
      <span>{original.totalDeliverable}</span>
      <ButtonWithIcon
        icon={faInfo}
        customButtonStyle={{ borderRadius: '25%', marginLeft: '5px', padding: '2px 5px' }}
        customIconStyle={{ color: 'gray', height: '10px' }}
        onClick={() => {
          setParamsForValidation({
            reportDate: original.reportDate,
            reportTypeId: original.reportTypeId,
            reportLevel: original.reportLevel,
            productionStatusId: undefined,
          })
        }}
      />
    </>
  )
}
