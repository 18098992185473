import React, { ReactNode } from 'react'
import M from 'react-modal'
import { Property } from 'csstype'

type Props = {
  small?: boolean
  isModalOpenned: boolean
  setIsModalOpenned: any
  children: ReactNode
  width?: Property.Width<string | number>
  height?: Property.Height<string | number>
}

function Modal({ small, isModalOpenned, setIsModalOpenned, children, width, height }: Props) {
  return (
    <M
      isOpen={isModalOpenned}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={() => {
        setIsModalOpenned(false)
      }}
      style={{
        overlay: {
          zIndex: 3,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width ?? 'unset',
          height: height ?? 'unset',
        },
        // content: !small ? {
        //   top: '25%',
        //   left: '30%',
        //   transform: 'translate(-20%, -15%)'
        // } : {
        //   top: '60%',
        //   left: '60%',
        //   transform: 'translate(-75%, -75%)'
        // }
      }}>
      {children}
    </M>
  )
}

export default Modal
