import http from '../http'
import { LogoDto, LogoDtoSchema } from '../../types/dtos/LogoDto'

export const ClientService = {
  /**
   * Return the logo saved in the database for the connected client
   * @return Logo dto
   */
  getClientLogo() {
    return http.get<LogoDto>('/logo-dto').then(resp => {
      LogoDtoSchema.parse(resp.data)
      return resp
    })
  },

  /**
   * Return the logo saved in the database for the parent of the connected client
   * @return Logo dto
   */
  getParentClientLogo() {
    return http.get<LogoDto>('/parent-logo-dto').then(resp => {
      LogoDtoSchema.parse(resp.data)
      return resp
    })
  },
}
