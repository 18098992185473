import FundDto from '../types/dtos/FundDto';
import http from './http';
import { SubfundDto } from '../types/dtos/SubfundDto';
import ClientDto from '../types/dtos/ClientDto';

export const getFunds = async (reportTypeId?: number) => {
  const e = await http.get(reportTypeId ? `funds/${reportTypeId}` : `/funds/all-dto`);
  return e.data;
};

export const getSubfunds = async (fundId?: number) => {
  const e = await http.get(fundId ? `subfunds/${fundId}` : `subfunds/all-dto`);
  return e.data;
};

export const getShareclasses = async (subfundId?: number) => {
  const e = await http.get(subfundId ? `shareclass/${subfundId}` : `shareclass/all-dto`);
  return e.data;
};

export const getFundsByClient = (clientId: number) => {
  return http
    .get('funds-by-client', {
      params: {
        clientId,
      },
    })
    .then((resp: { data: FundDto }) => {
      return resp;
    })
    .catch(error => {
      return error.response;
    });
};

export const getSubfundsLightDto = (fundId?: number) => {
  if (fundId) {
    return http
      .get(`subfunds/${fundId}`, {
        params: {
          fundId,
        },
      })
      .then((resp: { data: SubfundDto }) => {
        return resp;
      })
      .catch(error => {
        return error.response;
      });
  }
  return http
    .get(`subfund/all-dto`)
    .then((resp: { data: SubfundDto }) => {
      return resp;
    })
    .catch(error => {
      return error.response;
    });
};

export const getClientAndSubclientsForConnectedUser = () => {
  return http
    .get(`client-with-subclients`)
    .then((resp: { data: ClientDto[] }) => {
      return resp;
    })
    .catch(error => {
      return error.response;
    });
};
