import React from 'react'
import { MRT_Row } from 'material-react-table'
import { Delivery } from '../DeliveryPlanning.types'
import { formatDate } from '../../../../helpers/helpers'
import { DeliveryEstimationDto } from '../../../../types/dtos/production/productionDto'

export default function HandleEstimatedDeadlineCell(options: {
  row: MRT_Row<DeliveryEstimationDto>
}) {
  const {
    row: { original },
  } = options
  if (
    original.productionStatusId > 1 &&
    original.enteringDate !== null &&
    original.clientFinalDeliveryDeadline !== null
  ) {
    return <>{formatDate(original.enteringDate, original.clientFinalDeliveryDeadline)}</>
  }
  return null
}
