import React, { useContext } from 'react';
import { Circles } from 'react-loader-spinner';
import Centered from '../../fragments/Centered';
import { ThemeContext } from '../../context/ThemeProvider';

function Loader() {
  const { mainColor } = useContext(ThemeContext);
  return (
    <Centered>
      <Circles color={mainColor} />
    </Centered>
  );
}

// const LoaderContainer = styled.div`
//     /* display: flex;
//     justify-content: center; */
//     position: absolute;
//     top: calc( 50% - 40px );
//     left: calc( 50% - 40px );

// `

export default Loader;
