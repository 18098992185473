import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { faEraser } from '@fortawesome/free-solid-svg-icons'
import { Collapse } from '@mui/material'
import { SelectInputSearchControlled } from '../../../components/input/SelectInputSearchControlled'
import { OptionType } from '../../../types/OptionType'
import structureService, { StructureService } from '../../../services/Structure/structureService'
import { GedContext } from '../context/GedProvider'
import FundDto from '../../../types/dtos/FundDto'
import { getFunds } from '../../../services/scopeService'
import { FundEntity } from '../../../types/entities/FundEntity'
import { SelectMultiInputSearchControlled } from '../../../components/input/SelectMultiInputSearchControlled'
import ButtonWithIcon from '../../../components/button/ButtonWithIcon'
import { SubscribedReportContext } from '../../../context/SubscribedReportProvider'
import * as S from './GedFilterSection.styles'
import { filtersStyle } from '../../../components/table/MaterialTable/ColumnUtilities'
import DateSelector from '../../../components/input/DatePicker'

type Props = {
  isSectionHidden: boolean
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

export default function GedFilterSection({ isSectionHidden, setCurrentPage }: Props) {
  const { clearFilters, languages, countries, setFilterState, filterState } = useContext(GedContext)

  const {
    selectedFund,
    selectedSubfund,
    selectedShareclass,
    selectedLanguage,
    selectedCountry,
    selectedShareclassCurrency,
    selectedHedged,
    selectedShareclassName,
    selectedReportType,
    selectedInvestorType,
    selectedDividendPolicy,
  } = filterState

  const { distinctSubscribedReports } = useContext(SubscribedReportContext)

  const [funds, setFunds] = useState<FundDto[]>([])
  const [subfunds, setSubfunds] = useState<OptionType[]>([])
  const [shareclasses, setShareclasses] = useState<OptionType[]>([])

  const [dividendPolicyOptions, setDividendPolicyOptions] = useState<OptionType[]>([])
  const [investorTypesOptions, setInvestorTypesOptions] = useState<OptionType[]>([])
  const [hedgedOptions, setHedgedOptions] = useState<OptionType[]>([])
  const [shareclassCcyOptions, setShareclassCcyOptions] = useState<OptionType[]>([])
  const [shareclassNameOptions, setShareclassNameOptions] = useState<OptionType[]>([])

  const [reportDay, setReportDay] = useState<string | undefined>()
  const [reportMonth, setReportMonth] = useState<string | undefined>()
  const [reportYear, setReportYear] = useState<string | undefined>()

  // first load page
  useEffect(() => {
    getFunds().then((r: FundEntity[]) => {
      setFunds(r)
    })
    return () => {
      setReportMonth(undefined)
      setReportYear(undefined)
      setReportDay(undefined)
      // TODO, enable clearFilters when the states updates are fixed to prevent multiple renders
      // clearFilters()
    }
  }, [])

  // get subfunds
  useEffect(() => {
    if (selectedFund) {
      const fundList = selectedFund?.map(fund => fund.value as number)
      StructureService.getAllSubfundsDtoByFundList(fundList)
        .then(({ data }) => {
          setSubfunds(
            data.map(subfund => {
              return {
                value: subfund.id,
                label: subfund.subfundName,
              }
            })
          )
        })
        .catch(error => {
          console.log(error)
          toast.error('There has been a problem during data fetching')
        })
    }
  }, [selectedFund])

  // get shareclasses
  useEffect(() => {
    if (filterState.selectedSubfund) {
      const subfundIdsList = filterState.selectedSubfund.map(option => option.value as number)
      StructureService.getAllShareclassDtoBySubfundList(subfundIdsList)
        .then(({ data }) => {
          setShareclasses(
            data.map(shareclass => {
              return {
                label: shareclass.codeIsin,
                value: shareclass.id,
              }
            })
          )
        })
        .catch(error => {
          console.log(error)
          toast.error('There has been a problem during data fetching')
        })
    }
  }, [selectedSubfund])

  // Fetch data to fill the following filters:
  // DividendPolicy,InvestorType,Hedged,ShareclassCurrency,ShareclassName
  useEffect(() => {
    const idFunds = selectedFund?.map(option => option.value as number)
    const idSubfunds = filterState.selectedSubfund?.map(option => option.value as number)

    StructureService.getAllDividendPolicies(idFunds, idSubfunds)
      .then(resp => {
        setDividendPolicyOptions(
          resp.data.map(divPolicy => {
            return {
              label: divPolicy,
              value: divPolicy,
            }
          })
        )
      })
      .catch(error => {
        console.log(error)
        toast.error('There was an error during data recuperation')
      })

    StructureService.getAllInvestorTypes(idFunds, idSubfunds)
      .then(resp => {
        setInvestorTypesOptions(
          resp.data.map(investorType => {
            return {
              label: investorType,
              value: investorType,
            }
          })
        )
      })
      .catch(error => {
        console.log(error)
        toast.error('There was an error during data recuperation')
      })

    StructureService.getAllHedged(idFunds, idSubfunds)
      .then(resp => {
        setHedgedOptions(
          resp.data.map(hedged => {
            return {
              label: hedged ? 'Yes' : 'No',
              value: hedged ? 1 : 0,
            }
          })
        )
      })
      .catch(error => {
        console.log(error)
        toast.error('There was an error during data recuperation')
      })

    StructureService.getAllShareclassesNames(idFunds, idSubfunds)
      .then(resp => {
        setShareclassNameOptions(
          resp.data.map(shareclassName => {
            return {
              label: shareclassName,
              value: shareclassName,
            }
          })
        )
      })
      .catch(error => {
        console.log(error)
        toast.error('There was an error during data recuperation')
      })

    StructureService.getAllCurrencies(idFunds, idSubfunds)
      .then(resp => {
        setShareclassCcyOptions(
          resp.data.map(currency => {
            return {
              label: currency.ccyName,
              value: currency.id,
            }
          })
        )
      })
      .catch(error => {
        console.log(error)
        toast.error('There was an error during data recuperation')
      })
  }, [selectedSubfund])

  const handleShareclassFilterChange = (inputValue: string) => {
    const fundIds = filterState.selectedFund?.map(option => option.value as number)
    const subfundIds = filterState.selectedSubfund?.map(option => option.value as number)

    if (inputValue.length >= 4 && selectedSubfund == null) {
      structureService
        .getAllShareclassDtoLike(undefined, inputValue, fundIds, subfundIds)
        .then(({ data }) => {
          setShareclasses(
            data.map(shareclass => {
              return {
                label: shareclass.codeIsin,
                value: shareclass.id,
              }
            })
          )
        })
        .catch(error => {
          console.log(error)
          toast.error('There has been a problem during data fetching')
        })
    }
  }

  return (
    <S.GedFilterSectionContainer>
      <Collapse in={!isSectionHidden} unmountOnExit>
        <S.FiltersContainer>
          <S.Row>
            <SelectMultiInputSearchControlled
              value={selectedFund}
              options={funds.map(fund => {
                return { label: fund.fundName, value: fund.id }
              })}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedFund: option,
                  selectedSubfund: null,
                  selectedShareclass: null,
                }))
                setShareclasses([])
              }}
              placeholder='Fund'
            />

            <SelectMultiInputSearchControlled
              options={subfunds}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedSubfund: option,
                  selectedShareclass: null,
                }))
                setShareclasses([])
              }}
              isDisabled={!selectedFund}
              placeholder='Subfund'
              value={selectedSubfund}
            />
            <SelectMultiInputSearchControlled
              options={shareclassNameOptions}
              placeholder='Shareclass'
              value={selectedShareclassName}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedShareclassName: option,
                }))
              }}
            />
            <SelectMultiInputSearchControlled
              options={shareclasses}
              onInputChangeCallback={inputValue => {
                handleShareclassFilterChange(inputValue)
              }}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedShareclass: option,
                }))
              }}
              onMenuCloseCallback={() => {
                setShareclasses([])
              }}
              value={selectedShareclass}
              placeholder='Code ISIN'
              noOptionsMessage='Input 4 chars to start searching'
            />
            <SelectMultiInputSearchControlled
              value={selectedReportType}
              // defaultValue={state ? [{ label: state.reportName, value: state.id }] : undefined}   DEPRICATED FOR NEW DASHBOARD
              options={distinctSubscribedReports.map(report => {
                return { label: report.reportTypeName, value: report.reportTypeId }
              })}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedReportType: option,
                }))
              }}
              placeholder='Report type'
            />

            <SelectMultiInputSearchControlled
              placeholder='Language'
              value={selectedLanguage}
              options={languages}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedLanguage: option,
                }))
              }}
            />
            <SelectMultiInputSearchControlled
              placeholder='Country'
              options={countries}
              value={selectedCountry}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedCountry: option,
                }))
              }}
            />
            <DateSelector
              reportDay={reportDay}
              setReportDay={setReportDay}
              reportMonth={reportMonth}
              setReportMonth={setReportMonth}
              reportYear={reportYear}
              setReportYear={setReportYear}
              onBlurCallBack={() => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedReferenceYear: reportYear ? Number(reportYear) : undefined,
                  selectedReferenceMonth: reportMonth ? Number(reportMonth) : undefined,
                  selectedReferenceDay: reportDay ? Number(reportDay) : undefined,
                }))
              }}
            />
          </S.Row>
          <S.Row>
            <SelectMultiInputSearchControlled
              options={dividendPolicyOptions}
              placeholder='Dividend policy'
              value={selectedDividendPolicy}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedDividendPolicy: option,
                }))
              }}
            />
            <SelectMultiInputSearchControlled
              options={investorTypesOptions}
              placeholder='Investor type'
              value={selectedInvestorType}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedInvestorType: option,
                }))
              }}
            />
            <SelectInputSearchControlled
              options={hedgedOptions}
              placeholder='Hedged'
              value={selectedHedged}
              customStyle={filtersStyle}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedHedged: option,
                }))
              }}
            />
            <SelectMultiInputSearchControlled
              options={shareclassCcyOptions}
              placeholder='Shareclass currency'
              value={selectedShareclassCurrency}
              onChangeCallback={option => {
                setCurrentPage(0)
                setFilterState(prevState => ({
                  ...prevState,
                  selectedShareclassCurrency: option,
                }))
              }}
            />
            <ButtonWithIcon
              text='Clear filters'
              icon={faEraser}
              onClick={() => {
                setReportDay('')
                setReportMonth('')
                setReportYear('')
                clearFilters()
                setShareclasses([])
              }}
            />
          </S.Row>
        </S.FiltersContainer>
      </Collapse>
    </S.GedFilterSectionContainer>
  )
}
