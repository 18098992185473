// @ts-nocheck
// Todo remove the ts-nocheck above and fix the errors

import parse, { domToReact, Element } from 'html-react-parser'
import React, { useContext, useEffect, useState } from 'react'
import * as S from './NarrativeBlock.styles'
import { NarrativeContext } from '../../context/NarrativeContextProvider'

type Props = {
  htmlString: string
  id: string
  old: boolean
}

function NarrativeBlock({ htmlString, old, id }: Props) {
  const html = parse(htmlString, {
    replace: domNode => {
      if (domNode instanceof Element && domNode.name === 'delation') {
        return (
          <delation
            onSelect={(e: { target: { innerHTML: any } }) => {
              console.log(e.target.innerHTML)
            }}>
            {domToReact(domNode.children)}
          </delation>
        )
      }
    },
  })

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

  const { setIsCommentWindowOpen, setSelectedText } = useContext(NarrativeContext)

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const selection = window.getSelection()
    setIsPopupOpen(true)
    if (selection && selection.toString().length > 0) {
      setSelectedText(selection.toString())
      setIsCommentWindowOpen(true)
    } else {
      setIsCommentWindowOpen(false)
    }
  }

  return (
    <S.NarrativeBlockContainer className='narrativeBlockContainer'>
      {/*<div dangerouslySetInnerHTML={htmlStringDoc}></div>*/}
      <S.versionNumber>{old ? 'Old' : 'New'}</S.versionNumber>
      {/*{htmlToRender}*/}
      <div onMouseUp={handleMouseUp}>{html}</div>
    </S.NarrativeBlockContainer>
  )
}

export default NarrativeBlock
