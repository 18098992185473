import React, { createContext, Dispatch, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import { OptionType } from '../../../types/OptionType'
import { DATA_FETCHING_ERROR } from '../../../constants/error_messages'
import ProductionDocumentService from '../../../services/Document/productionDocumentService'
import {
  DecProductionDocumentDto,
  DocumentDtoPageDto,
} from '../../../types/dtos/Ged/ProductionDocumentsPaginatedDto'

type GedFilter = {
  selectedFund: OptionType[] | null
  selectedSubfund: OptionType[] | null
  selectedShareclass: OptionType[] | null
  selectedReportType: OptionType[] | null
  selectedLanguage: OptionType[] | null
  selectedCountry: OptionType[] | null
  selectedReferenceDay: number | undefined
  selectedReferenceMonth: number | undefined
  selectedReferenceYear: number | undefined
  selectedDividendPolicy: OptionType[] | null
  selectedInvestorType: OptionType[] | null
  selectedHedged: OptionType | null
  selectedShareclassCurrency: OptionType[] | null
  selectedShareclassName: OptionType[] | null
}

type Context = {
  selectedRows: number[]
  setSelectedRows: Dispatch<React.SetStateAction<number[]>>
  selectedRowsValidation: number[]
  setSelectedRowsValidation: Dispatch<React.SetStateAction<number[]>>
  paginatedDocuments: DocumentDtoPageDto | undefined
  setPaginatedDocuments: Dispatch<React.SetStateAction<DocumentDtoPageDto | undefined>>
  getDocuments: (currentPage: number) => void
  getValidationDocuments: (currentPage: number) => void
  isLoading: boolean
  clearFilters: () => void
  languages: OptionType[]
  countries: OptionType[]
  allDocumentsIds: number[]
  allDeliverableValidationIds: number[]
  selectAll: () => void
  displayFinalDocuments: boolean
  setDisplayFinalDocuments: Dispatch<React.SetStateAction<boolean>>
  reloadData: (currentPage: number) => void
  deliverableValidationData: DecProductionDocumentDto[]
  paginatedReportValidation: DocumentDtoPageDto | null
  selectAllDeliverableValidation: () => void
  filterState: GedFilter
  setFilterState: Dispatch<React.SetStateAction<GedFilter>>
  filterInitialState: GedFilter
  sortList: SortValue[]
  setSortList: Dispatch<React.SetStateAction<SortValue[]>>
  sortAsc: boolean
  setSortAsc: Dispatch<React.SetStateAction<boolean>>
}

export type SortValue = {
  id: number
  displayName: string
  databaseName: string
}

// @ts-ignore
export const GedContext = createContext<Context>(null)

type Props = {
  children: React.ReactNode
}

type SelectedReport = {
  id: number
  reportName: string
}

type LocationState = {
  id: number
  reportName: string
}

export function GedProvider({ children }: Props) {
  const location = useLocation()
  // const state = location.state as LocationState  DEPRICATED FOR NEW DASHBOARD

  // Initialize report type depending on location state (Report type chosen in the dashboard)
  // const initialReportType: OptionType[] | null = state  DEPRICATED FOR NEW DASHBOARD
  //   ? [
  //       {
  //         label: state.reportName,
  //         value: state.id,
  //       },
  //     ]
  //   : null

  const filterInitialState: GedFilter = {
    selectedFund: null,
    selectedSubfund: null,
    selectedShareclass: null,
    selectedReportType: null,
    selectedLanguage: null,
    selectedCountry: null,
    selectedReferenceDay: undefined,
    selectedReferenceMonth: undefined,
    selectedReferenceYear: undefined,
    selectedDividendPolicy: null,
    selectedInvestorType: null,
    selectedHedged: null,
    selectedShareclassCurrency: null,
    selectedShareclassName: null,
  }

  // Paginated data
  const [paginatedDocuments, setPaginatedDocuments] = React.useState<DocumentDtoPageDto>()
  const [paginatedReportValidation, setPaginatedReportValidation] =
    useState<DocumentDtoPageDto | null>(null)

  // Data to display the table
  const [deliverableValidationData, setDeliverableValidationData] = useState<
    DecProductionDocumentDto[]
  >([])

  // Data to fill language and country select picker
  const [languages, setLanguages] = useState<OptionType[]>([])
  const [countries, setCountries] = useState<OptionType[]>([])

  // Selected rows of the final documents table (use the document ids)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  // Selected Rows of the validation documents table (use the deliverable validation ids)
  const [selectedRowsValidation, setSelectedRowsValidation] = useState<number[]>([])

  // Store all the possible ids of the tables (used for the select all)
  const [allDocumentsIds, setAllDocumentsIds] = useState<number[]>([])
  const [allDeliverableValidationIds, setAllDeliverableValidationIds] = useState<number[]>([])

  // Allow the display of a loader when api calls are made
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  // Used to know if we display the validation or the final documents
  const [displayFinalDocuments, setDisplayFinalDocuments] = useState<boolean>(true)

  // state that manages filters
  const [filterState, setFilterState] = useState<GedFilter>(filterInitialState)
  const {
    selectedFund,
    selectedSubfund,
    selectedShareclass,
    selectedCountry,
    selectedLanguage,
    selectedReferenceDay,
    selectedReferenceMonth,
    selectedReferenceYear,
    selectedDividendPolicy,
    selectedInvestorType,
    selectedReportType,
    selectedHedged,
    selectedShareclassCurrency,
    selectedShareclassName,
  } = filterState

  const [sortList, setSortList] = useState<SortValue[]>([])
  const [sortAsc, setSortAsc] = useState<boolean>(true)

  const reloadData = (currentPage: number) => {
    if (localStorage.getItem('localState') === 'library') {
      getDocuments(currentPage)
    } else {
      getValidationDocuments(currentPage)
    }
  }

  const getDocuments = (currentPage: number) => {
    // Erase the selected rows
    //setSelectedRows([])
    setIsLoading(true)

    const fund = selectedFund?.map(option => option.value as number)
    const subfund = selectedSubfund?.map(option => option.value as number)
    const shareclass = selectedShareclass?.map(option => option.value as number)
    const report = selectedReportType?.map(option => option.value as number)
    const reportDay = selectedReferenceDay
    const reportMonth = selectedReferenceMonth
    const reportYear = selectedReferenceYear
    const countryId = selectedCountry?.map(value => value.value as number)
    const languageId = selectedLanguage?.map(value => value.value as number)
    const dividendPolicy = selectedDividendPolicy?.map(value => value.value as string)
    const shareclassInvestorType = selectedInvestorType?.map(value => value.value as string)
    const sortListString = sortList.map(value => value.databaseName)
    const findHedged = () => {
      if (selectedHedged) {
        if (selectedHedged.value === 0) {
          return false
        }
        return true
      }
      return undefined
    }
    const hedged = findHedged()
    const currency = selectedShareclassCurrency?.map(value => value.value as number)
    const shareclassName = selectedShareclassName?.map(value => value.value as string)

    ProductionDocumentService.getAllDocumentsWithAllLanguagesAndCountries(
      currentPage,
      true,
      sortAsc,
      fund,
      subfund,
      shareclass,
      report,
      reportDay,
      reportMonth,
      reportYear,
      countryId,
      languageId,
      dividendPolicy,
      shareclassInvestorType,
      hedged,
      currency,
      shareclassName,
      sortListString
    )
      .then(({ data }) => {
        setPaginatedDocuments(data.documentDtoPage)

        setAllDocumentsIds(data.documentIdsList)
        // Fill languages
        const languagesList = data.languageDtoList.map(lang => {
          return {
            label: lang.languageName,
            value: lang.languageId,
          }
        })
        setLanguages(languagesList)

        // Fill countries
        const countriesList = data.countryDtoList.map(country => {
          return {
            label: country.countryName,
            value: country.countryId,
          }
        })
        setCountries(countriesList)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getValidationDocuments = (currentPage: number) => {
    setIsLoading(true)
    const fund = selectedFund?.map(option => option.value as number)
    const subfund = selectedSubfund?.map(option => option.value as number)
    const shareclass = selectedShareclass?.map(option => option.value as number)
    const report = selectedReportType?.map(option => option.value as number)
    const reportDay = selectedReferenceDay
    const reportMonth = selectedReferenceMonth
    const reportYear = selectedReferenceYear
    const countryId = selectedCountry?.map(value => value.value as number)
    const languageId = selectedLanguage?.map(value => value.value as number)
    const dividendPolicy = selectedDividendPolicy?.map(value => value.value as string)
    const shareclassInvestorType = selectedInvestorType?.map(value => value.value as string)
    const sortListString = sortList.map(value => value.databaseName)
    const findHedged = () => {
      if (selectedHedged) {
        if ((selectedHedged.value = 0)) {
          return false
        }
        return true
      }
      return undefined
    }
    const hedged = findHedged()
    const currency = selectedShareclassCurrency?.map(value => value.value as number)
    const shareclassName = selectedShareclassName?.map(value => value.value as string)

    ProductionDocumentService.getAllDocumentsWithAllLanguagesAndCountries(
      currentPage,
      false,
      sortAsc,
      fund,
      subfund,
      shareclass,
      report,
      reportDay,
      reportMonth,
      reportYear,
      countryId,
      languageId,
      dividendPolicy,
      shareclassInvestorType,
      hedged,
      currency,
      shareclassName,
      sortListString
    )
      .then(resp => {
        setAllDeliverableValidationIds(resp.data.documentIdsList)
        setDeliverableValidationData(resp.data.documentDtoPage.content)
        setPaginatedReportValidation(resp.data.documentDtoPage)
        // Fill languages
        const languagesList = resp.data.languageDtoList.map(lang => {
          return {
            label: lang.languageName,
            value: lang.languageId,
          }
        })
        setLanguages(languagesList)

        // Fill countries
        const countriesList = resp.data.countryDtoList.map(country => {
          return {
            label: country.countryName,
            value: country.countryId,
          }
        })
        setCountries(countriesList)
      })
      .catch(error => {
        console.log(error)
        toast.error(DATA_FETCHING_ERROR)
      })
      .finally(() => setIsLoading(false))
  }

  const clearFilters = async () => {
    setFilterState(filterInitialState)
  }

  const selectAll = () => {
    if (allDocumentsIds) {
      // @ts-ignore
      const rowsId: number[] = [...allDocumentsIds]

      // Deep copy of selected rows
      const selectedRowsCopy = [...selectedRows]

      if (rowsId) {
        // If all rows are selected, we unselect all
        if (selectedRowsCopy.length === rowsId.length) {
          setSelectedRows([])
        } else {
          // Else we select them
          setSelectedRows([...rowsId])
        }
      }
    }
  }

  const selectAllDeliverableValidation = () => {
    if (allDeliverableValidationIds) {
      // @ts-ignore
      const rowsId: number[] = [...allDeliverableValidationIds]

      // Deep copy of selected rows
      const selectedRowsCopy = [...selectedRowsValidation]

      if (rowsId) {
        // If all rows are selected, we unselect all
        if (selectedRowsCopy.length === rowsId.length) {
          setSelectedRowsValidation([])
        } else {
          // Else we select them
          setSelectedRowsValidation([...rowsId])
        }
      }
    }
  }

  return (
    <GedContext.Provider
      value={{
        selectedRows,
        setSelectedRows,
        paginatedDocuments,
        setPaginatedDocuments,
        // state,    DEPRICATED FOR NEW DASHBOARD
        getDocuments,
        isLoading,
        clearFilters,
        languages,
        countries,
        allDocumentsIds,
        selectAll,
        setDisplayFinalDocuments,
        displayFinalDocuments,
        reloadData,
        getValidationDocuments,
        deliverableValidationData,
        selectedRowsValidation,
        setSelectedRowsValidation,
        paginatedReportValidation,
        allDeliverableValidationIds,
        selectAllDeliverableValidation,
        filterState,
        setFilterState,
        filterInitialState,
        sortList,
        setSortList,
        sortAsc,
        setSortAsc,
      }}>
      {children}
    </GedContext.Provider>
  )
}
