import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useContext } from 'react';
import styled from 'styled-components';
import * as CSS from 'csstype';
import { ThemeContext } from '../../context/ThemeProvider';

type Props = {
  icon: IconProp;
  color?: string;
  pointer?: boolean;
  onClick?: () => void;
  notCentered?: boolean;
  size?: 1 | 2 | 3 | 4 | 5;
  customStyle?: CSSProperties;
};

function Icon({ icon, color, pointer, onClick, notCentered, size, customStyle }: Props) {
  const { mainColor } = useContext(ThemeContext);

  return (
    <IconContainer
      size={size ?? 1}
      cursor={pointer ? 'pointer' : 'default'}
      onClick={onClick}
      notCentered={notCentered ?? false}
      style={{ ...customStyle }}>
      <FontAwesomeIcon size={`${size ?? 1}x`} icon={icon} color={color || mainColor} />
    </IconContainer>
  );
}

const IconContainer = styled.div<{
  cursor: CSS.Property.Cursor;
  notCentered: boolean;
  size: number;
}>`
  width: ${({ size }) => size * 24}px;
  height: ${({ size }) => size * 24}px;
  cursor: ${({ cursor }) => cursor};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ notCentered }) => (notCentered ? '' : 'margin: auto;')}
`;

export default Icon;
