import styled from 'styled-components'

export const NarrativeValidationCommentWindowContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  width: 30%;
  height: 50%;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  border-radius: 5px;
`
export const NarrativeValidationCommentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 10%;
`

export const Body = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
`

export const Footer = styled.div`
  height: 10%;
`

export const CustomTextArea = styled.textarea`
  width: 100%;
  flex: 1;
  margin-bottom: 10px;
  resize: none;
`
