import React from 'react'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MRT_Row } from 'material-react-table'
import handleDocumentActions from '../utils/handleDocumentAction'
import { DeliverableValidationDto } from '../../../../types/dtos/FollowupDto'
import { ProductionValidationDto } from '../../../../types/dtos/production/productionDto'

export default function HandleDownloadAggregatedCell(
  options: { row: MRT_Row<ProductionValidationDto> },
  mainColor: string
) {
  const {
    row: { groupingColumnId, subRows },
  } = options
  if (groupingColumnId === 'fundName') {
    const returnList: number[] = []
    subRows?.forEach(subRow => {
      subRow.subRows?.forEach(codeIsinSubRow => {
        if (typeof codeIsinSubRow.original.productionValidationId === 'number') {
          returnList.push(codeIsinSubRow.original.productionValidationId)
        }
      })
    })
    return (
      <FontAwesomeIcon
        cursor='pointer'
        icon={faDownload}
        color={mainColor}
        onClick={() => {
          handleDocumentActions(returnList, 'DOWNLOAD')
        }}
      />
    )
  }
  if (groupingColumnId === 'subfundName') {
    const returnList: number[] = []
    subRows?.forEach(subRow => {
      if (typeof subRow.original.documentId === 'number') {
        returnList.push(subRow.original.documentId)
      }
    })
    return (
      <FontAwesomeIcon
        cursor='pointer'
        icon={faDownload}
        color={mainColor}
        onClick={() => {
          handleDocumentActions(returnList, 'DOWNLOAD')
        }}
      />
    )
  }
  if (groupingColumnId === 'codeIsin') {
    return null
  }
}
