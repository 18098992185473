import translation from './en/translation.json';
import Login from '../pages/login/i18n/login-en.json';
import Error from './en/errors.json';

const translationEN = {
  translation,
  Login,
  Error,
};

export default translationEN;
