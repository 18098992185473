import React from 'react';
import { MRT_Row } from 'material-react-table';
import { PlatformType } from '../Platform.types';

export default function HandledataAggregatedCell(
  options: { row: MRT_Row<PlatformType> },
  typeOfData: 'staticData' | 'dynamicData' | 'documentData'
) {
  const { row } = options;
  return (
    <div>
      {row
        .getLeafRows()
        .filter((elem: any) => !elem.id.startsWith('fund'))
        .filter((elem: any) => elem.original[typeOfData]).length || null}
    </div>
  );
}
