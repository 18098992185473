import { Modal } from '@mui/material'
import * as S from './OpenPdfModal.styles'
import PdfViewer from '../../../../components/pdfViewer/PdfViewer'

type Props = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  filePath: string
}

function OpenPdfModal({ isOpen, setIsOpen, filePath }: Props) {
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}>
      <S.ModalContainer>
        <S.ModalBody>
          {/*{isCommentWindowOpen && <NarrativeValidationCommentWindow />}*/}
          <S.PdfFlexContainer>
            <S.DocumentContainer>
              <S.PartTitle>Template</S.PartTitle>
              {/*<embed src={'./KID_20230628_en_LU_4_LU1023698746.pdf'} height={'100%'} />*/}
              <PdfViewer filePathInPublicFolder={filePath} initialScale={1.5} />
            </S.DocumentContainer>
          </S.PdfFlexContainer>

          {/*<embed src={myPDF} height={'100%'} width={'100%'} />*/}
        </S.ModalBody>
      </S.ModalContainer>
    </Modal>
  )
}

export default OpenPdfModal
