import React, { createContext } from 'react';

export const MenuContext = createContext<any>(null);

type Props = {
  children: React.ReactNode;
};

function MenuProvider({ children }: Props) {
  const [isMenuOpen, setisMenuOpen] = React.useState<boolean>(false);

  const toggleMenu = () => {
    console.log('a');
    setisMenuOpen(!isMenuOpen);
  };

  return <MenuContext.Provider value={{ isMenuOpen, toggleMenu }}>{children}</MenuContext.Provider>;
}

export default MenuProvider;
