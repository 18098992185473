import { z } from 'zod'
import http from '../http'

export const AuthSchema = z.object({
  clientId: z.number(),
  clientLogo: z.string().nullable(),
  email: z.string().email(),
  name: z.string(),
  role: z.string().array(),
  token: z.string(),
})

export type AuthDto = z.infer<typeof AuthSchema>

const AuthService = {
  postAuthenticate(username: string, password: string) {
    return http
      .post<AuthDto>(
        `/auth`,
        {
          username,
          password,
        },
        { headers: { 'Authorization ': undefined } }
      )
      .then(resp => {
        AuthSchema.parse(resp.data)
        return resp
      })
  },
  postSsoAuthenticate() {
    return http.post<AuthDto>(`/sso-auth`, {}).then(resp => {
      return resp
    })
  },
  putTokenInAxiosHeader(token: string) {
    http.defaults.headers.common.Authorization = token
  },
  removeTokenInAxiosHeader() {
    http.defaults.headers.common.Authorization = null
  },
}

export default AuthService
