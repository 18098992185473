import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { BigText, Text } from '../Global.styles'
import { COLOR } from '../../../../constants/theme'

export const ListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: 170px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
`

export const BigTextBlock = styled(BigText)`
  display: inline-block;
`

export const TextBlock = styled(Link)`
  display: inline-block;

  &:visited {
    text-decoration: none;
    color: black;
  }
`

export const TextContainer = styled(Text)<{ bottomBorder?: boolean; sideBorder?: boolean }>`
  display: flex;
  height: 84px;
  flex: 1 0 84px;
  justify-content: center;
  align-items: center;

  ${({ bottomBorder }) => bottomBorder && { borderBottom: `1px solid ${COLOR.LIGHT_GRAY}` }}
  ${({ sideBorder }) => sideBorder && { borderRight: `1px solid ${COLOR.LIGHT_GRAY}` }}
`
