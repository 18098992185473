import React from 'react'
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavigateFunction } from 'react-router-dom'
import { MRT_Row } from 'material-react-table'
import { COLOR } from '../../../constants/theme'
import ButtonWithIcon from '../../../components/button/ButtonWithIcon'
import { ROUTES } from '../../../constants/routes'
import { Followup } from '../ProductionStatus.types'
import { ProductionStatusDashboardDto } from '../../../types/dtos/production/productionDto'

export default function HandleDataSourceCell(
  options: { row: MRT_Row<ProductionStatusDashboardDto> },
  mainColor: string,
  navigate: NavigateFunction
) {
  const {
    row: { original },
  } = options
  if (original.inDataReceptionCount === 0) {
    return <FontAwesomeIcon icon={faCheck} color={COLOR.GREEN} />
  }
  return (
    <ButtonWithIcon
      text={`${original.inDataReceptionCount}`}
      icon={faChevronRight}
      textToLeft
      customButtonStyle={{ backgroundColor: 'transparent' }}
      customIconStyle={{ color: mainColor }}
      onClick={() => {
        navigate(ROUTES.DATA, {
          state: {
            reportTypeId: original.reportTypeId,
            reportDate: original.reportDate,
          },
        })
      }}
    />
  )
}
