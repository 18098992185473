import React from 'react';
import { MRT_Row } from 'material-react-table';
import { InvoiceData } from '../InvoicingData.types';
import { OptionType } from '../../../../types/OptionType';

export default function HandlePriceAggregatedCell(
  options: { row: MRT_Row<InvoiceData> },
  rawSubfundData:any[],
  filterReportType: OptionType | null
) {
  const {
    row: { groupingColumnId, groupingValue },
  } = options;
  if (groupingColumnId === 'fundName') {
    return null;
  }
  if (groupingColumnId === 'subfundName') {
    const subfundPrices = rawSubfundData
      .filter((elem: InvoiceData) => elem.subfundName === groupingValue)
      .filter((elem: InvoiceData) => {
        if (filterReportType) {
          return filterReportType.value === elem.reportType;
        }
        return true;
      });

    if (subfundPrices.length) {
      return (
        <div>{subfundPrices?.reduce((acc: number, current: any) => acc + current.price, 0)}</div>
      );
    }
    return null;
  }
}
