import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ROLES } from '../../constants/constants'
import { ROUTES } from '../../constants/routes'
import { COLOR } from '../../constants/theme'
import { UserContext } from '../../context/UserProvider'
import Button from '../button/Button'
import Notifications from './Notifications'

export const headerHeight = 80

function Header() {
  const location = useLocation()
  const { user, hasRole } = React.useContext(UserContext)
  const [titleText, setTitleText] = useState<string>('')

  const hasManageRole = user && hasRole(ROLES.MANAGE)
  const isOnDashboard = location.pathname === ROUTES.DASHBOARD
  const isNotOnLoginOrLogged = location.pathname !== ROUTES.LOGIN && !user

  useEffect(() => {
    setTitleText(() => {
      const title = localStorage
        .getItem('localState')
        ?.split('_')
        .map(elem => {
          const subItem = elem.split('')
          subItem[0] = subItem[0].toUpperCase()
          return subItem.join('')
        })
        .join(' ')
      return title as string
    })
  }, [location])

  return (
    <Container>
      <Title style={{ ...(!isOnDashboard && { fontSize: 32, textAlign: 'center' }) }}>
        {isOnDashboard ? `Welcome ${user?.firstName} !` : titleText}
      </Title>
      {hasManageRole && <Notifications />}
      {/* TODO: This needs to be moved from a button to some hyperlink below the recovery form */}
      {isNotOnLoginOrLogged && <Button text='Login' to='/login' />}
    </Container>
  )
}

export default Header

const Container = styled.div`
  display: flex;
  align-items: center;
  z-index: 5;
  height: ${headerHeight}px;
  min-height: ${headerHeight}px;
  padding: 0px 20px;
`

const Title = styled.div`
  flex: 1;
  font-size: 24px;
  font-weight: 600;
  color: ${COLOR.SEMI_LIGHT_GRAY};
`
