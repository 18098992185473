import React, { useContext } from 'react';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOR } from '../../constants/theme';
import { ThemeContext } from '../../context/ThemeProvider';

type Props = {
  callback?: () => void;
  disabled?: boolean;
};

function MailIcon({ callback, disabled }: Props) {
  const { mainColor } = useContext(ThemeContext);
  return (
    <>
      {disabled ? (
        <FontAwesomeIcon icon={faEnvelope} color={COLOR.GRAYLIGHT} />
      ) : (
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          icon={faEnvelope}
          color={mainColor}
          onClick={callback}
        />
      )}
    </>
  );
}

export default MailIcon;
