import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Modal } from '@mui/material'
import { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeProvider'
import * as S from './FirstConnectionModal.styles'

function FirstConnectionModal({
  isOpen,
  close,
  checked,
  setChecked,
}: {
  isOpen: boolean
  close: () => void
  checked: boolean
  setChecked: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { mainColor } = useContext(ThemeContext)
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        close()
      }}>
      <S.ModalBox>
        <S.ModalBody>
          <S.Title>Say hi to your new dashboard ! 🎉</S.Title>
          <div>Our fresh dashboard is here, and we're all ears to help you out.</div>
          <div>
            Find our contact info in the "Help" section of the sidebar, shoot us an email or ring us
            up on the dedicated English or French support lines.
          </div>
          <div>Let's rock this together !</div>
        </S.ModalBody>
        <S.BottomContainer>
          <Checkbox
            style={{ color: mainColor }}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <span style={{ fontSize: '0.9em' }}>Don't show this again</span>
          <S.CloseButton onClick={close} style={{ marginLeft: 'auto' }}>
            <FontAwesomeIcon icon={faClose} />
            <span>Cancel</span>
          </S.CloseButton>
        </S.BottomContainer>
      </S.ModalBox>
    </Modal>
  )
}

export default FirstConnectionModal
