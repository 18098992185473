import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR } from '../../constants/theme';
import Text from '../../fragments/Text';
import { ThemeContext } from '../../context/ThemeProvider';

type Props = {
  text: any;
  handleClick?: any;
  to?: string;
  fullWidth?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  bgColor?: string;
  style?: any;
};

function To({ to, children }: { to: any; children: any }) {
  return to ? <Link to={to}>{children}</Link> : <>{children}</>;
}

function Button({ text, handleClick, to, fullWidth, secondary, disabled, bgColor, style }: Props) {
  const { mainColor } = useContext(ThemeContext);

  return (
    <To to={to}>
      <Container
        style={style}
        bgColor={bgColor}
        secondary={secondary}
        disabled={disabled}
        fullWidth={fullWidth}
        onClick={handleClick || null}
        mainColor={mainColor}>
        <Text>{text}</Text>
      </Container>
    </To>
  );
}

export default Button;

const Container = styled.div<any>`
  ${({ secondary, bgColor }) =>
    secondary &&
    `
        background: ${COLOR.WHITE};
        color: ${bgColor};
        border: 1px solid ${bgColor};
        cursor: pointer;

    `}

  ${({ disabled }) =>
    disabled &&
    `
        background: ${COLOR.WHITE};
        color: ${COLOR.GRAY};
        cursor: not-allowed;
        border: 1px solid ${COLOR.GRAY};
    `}

    ${({ secondary, disabled, bgColor, mainColor }) =>
    !secondary &&
    !disabled &&
    `
        background: ${bgColor || mainColor};
        color: ${COLOR.WHITE};
        border: 1px solid ${bgColor || mainColor};
        cursor: pointer;

    `}
    padding: 4px 16px;
  height: 32px;
  min-width: 132px;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
