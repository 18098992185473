import React, { ReactNode } from 'react'
import { TableCellProps } from '@mui/material/TableCell'
import { MRT_Cell, MRT_Column, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { StylesConfig } from 'react-select'
import styled from 'styled-components'
import { COLOR } from '../../../constants/theme'
import { SelectInputSearchControlled } from '../../input/SelectInputSearchControlled'
import { OptionType } from '../../../types/OptionType'
import CountryWithFlagAndTooltip from '../../text/CountryWithFlagAndTooltip'
import LanguageWithTooltip from '../../text/LanguageWithTooltip'
import { ScopeItem } from '../../../pages/ScopeManagement/ExistingServices/ExistingServices.types'

export function HandleDefaultAggregatedCell(options: {
  cell: MRT_Cell
  column: MRT_Column
  row: MRT_Row
  table: MRT_TableInstance
}) {
  const {
    row: { groupingValue, subRows },
  } = options
  return <div>{`${groupingValue} (${subRows?.length})`}</div>
}

interface OriginalType {
  shareclassName: string
  codeIsin: string
  languageName: string | null
  languageIsoCode2: string | null
  countryName: string
  countryIsoCode2: string | null
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`

function RenderCellWithCountryLanguage(props: OriginalType) {
  const { countryIsoCode2, countryName, languageIsoCode2, languageName, shareclassName, codeIsin } =
    props

  let content = (
    <>
      {codeIsin} - {shareclassName}
    </>
  )

  if (countryIsoCode2 && countryName) {
    content = (
      <>
        {content} -{' '}
        <CountryWithFlagAndTooltip countryIsoCode2={countryIsoCode2} countryName={countryName} />
      </>
    )
  }

  if (languageIsoCode2 && languageName) {
    content = (
      <>
        {content} -{' '}
        <LanguageWithTooltip languageIsoCode2={languageIsoCode2} languageName={languageName} />
      </>
    )
  }

  return <Container>{content}</Container>
}

export function HandleDefaultCodeIsinCell<T extends OriginalType>(options: { row: MRT_Row<T> }) {
  const {
    row: {
      original: {
        shareclassName,
        codeIsin,
        languageName,
        countryName,
        languageIsoCode2,
        countryIsoCode2,
      },
    },
  } = options
  return (
    <RenderCellWithCountryLanguage
      shareclassName={shareclassName}
      codeIsin={codeIsin}
      languageName={languageName}
      languageIsoCode2={languageIsoCode2}
      countryName={countryName}
      countryIsoCode2={countryIsoCode2}
    />
  )
}

export const filtersStyle: StylesConfig<OptionType, false> = {
  indicatorSeparator: provided => ({ ...provided, display: 'none' }),
  container: provided => ({
    ...provided,
    fontSize: '0.8rem',
    width: 'max-content',
    minWidth: '106px',
  }),
  dropdownIndicator: provided => ({ ...provided, padding: '0px 2px' }),
  valueContainer: provided => ({ ...provided, padding: '0px 2px' }),
}

export function RenderDefaultTopToolBar(props: {
  table: MRT_TableInstance
  isLoading: boolean
  reportLevel: 'Fund' | 'Subfund' | 'CodeIsin'
  filterFund: OptionType | null
  setFilterFund: React.Dispatch<React.SetStateAction<OptionType | null>>
  allFund: OptionType[]
  filterSubfund: OptionType | null
  setFilterSubfund: React.Dispatch<React.SetStateAction<OptionType | null>>
  allSubfund: OptionType[]
  filterCodeIsin: OptionType | null
  setFilterCodeIsin: React.Dispatch<React.SetStateAction<OptionType | null>>
  allCodeIsin: OptionType[]
  preComponent?: ReactNode
  afterComponent?: ReactNode
  // endComponent?: ReactNode
}) {
  const {
    table: { setColumnFilters },
    isLoading,
    reportLevel,
    filterFund,
    filterSubfund,
    filterCodeIsin,
    setFilterFund,
    setFilterSubfund,
    setFilterCodeIsin,
    allFund,
    allSubfund,
    allCodeIsin,
    preComponent,
    afterComponent,
  } = props
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', padding: '5px 5px' }}>
      {preComponent}
      <SelectInputSearchControlled
        isDisabled={isLoading}
        customStyle={filtersStyle}
        value={filterFund}
        options={allFund}
        placeholder='Select a fund'
        onChangeCallback={option => {
          setFilterCodeIsin(null)
          setFilterSubfund(null)
          setFilterFund(option)
          setColumnFilters((prev: any) => {
            const otherFilters = prev
              .filter((elem: any) => elem?.id !== 'fundName')
              .filter((elem: any) => elem?.id !== 'subfundName')
              .filter((elem: any) => elem?.id !== 'codeIsin')
            if (option) {
              return [...otherFilters, { id: 'fundName', value: option?.label }]
            }
            return [...otherFilters]
          })
        }}
      />
      {(reportLevel === 'CodeIsin' || reportLevel === 'Subfund') && (
        <SelectInputSearchControlled
          customStyle={filtersStyle}
          isDisabled={isLoading}
          value={filterSubfund}
          options={allSubfund}
          placeholder='Select a subfund'
          onChangeCallback={option => {
            setFilterCodeIsin(null)
            setFilterSubfund(option)
            setColumnFilters((prev: any) => {
              const otherFilters = prev
                .filter((elem: any) => elem?.id !== 'subfundName')
                .filter((elem: any) => elem?.id !== 'codeIsin')
              if (option) {
                return [...otherFilters, { id: 'subfundName', value: option?.label }]
              }
              return [...otherFilters]
            })
          }}
        />
      )}
      {reportLevel === 'CodeIsin' && (
        <SelectInputSearchControlled
          isDisabled={isLoading}
          customStyle={filtersStyle}
          value={filterCodeIsin}
          options={allCodeIsin}
          placeholder='Select a code isin'
          onChangeCallback={option => {
            setFilterCodeIsin(option)
            setColumnFilters((prev: any) => {
              const otherFilters = prev.filter((elem: any) => elem?.id !== 'codeIsin')
              if (option) {
                return [...otherFilters, { id: 'codeIsin', value: option?.label }]
              }
              return [...otherFilters]
            })
          }}
        />
      )}
      {afterComponent}
    </div>
  )
}

RenderDefaultTopToolBar.defaultProps = {
  preComponent: null,
  afterComponent: null,
}

export function HandleMuiTableBodyCellPropsLevels(
  options: { row: MRT_Row },
  mainColorLight: string,
  mainColorVeryLight: string
): TableCellProps {
  const {
    row: { depth },
  } = options
  let backgroundColor = ''
  let paddingLeft = ''
  let minHeight = ''
  switch (depth) {
    case 4:
      backgroundColor = COLOR.WHITE
      paddingLeft = '100px'
      minHeight = '30px'
      break
    case 3:
      backgroundColor = COLOR.WHITE
      paddingLeft = '75px'
      break
    case 2:
      backgroundColor = COLOR.WHITE
      paddingLeft = '50px'
      break
    case 1:
      backgroundColor = mainColorVeryLight
      paddingLeft = '25px'
      break
    default:
      backgroundColor = mainColorLight
      paddingLeft = '0px'
  }
  return {
    align: 'left',
    sx: {
      boxSizing: 'border-box',
      padding: '0px',
      paddingLeft,
      backgroundColor,
      boxShadow: 'none',
      height: '30px',
    },
  }
}

export function HandleDisplayFundCell(options: { row: MRT_Row<ScopeItem> }) {
  const {
    row: {
      original: { fundName },
    },
  } = options

  return <div>{fundName}</div>
}
