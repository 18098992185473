import { faChevronLeft, faChevronRight, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import Table from '../../components/table/Table'
import NotYet from '../../components/notYet/NotYet'
import { COLOR } from '../../constants/theme'
import { GedContext } from './context/GedProvider'
import { DocumentDto } from '../../types/dtos/Document/DocumentDto'
import { capitalizeString, formatDate } from '../../helpers/helpers'
import DownloadIcon from '../../components/icon/DownloadIcon'
import DownloadService from '../../services/DownloadService/downloadService'
import MailIcon from '../../components/icon/MailIcon'
import http from '../../services/http'
import { ThemeContext } from '../../context/ThemeProvider'
import Loader from '../../components/loader/Loader'
import documentService from '../../services/Document/productionDocumentService'

type Props = {
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

export default function FinalDocumentGed({ currentPage, setCurrentPage }: Props) {
  const [sendByEmailDocuments, setSendByEmailDocuments] = React.useState<any>([])

  const { selectedRows, setSelectedRows, paginatedDocuments, isLoading } = useContext(GedContext)

  const { mainColor } = useContext(ThemeContext)

  /**
   * HEADER
   */
  const head = React.useMemo(() => {
    return [
      {
        Header: 'Report date',
        accessor: 'c1',
      },
      {
        Header: 'Report type',
        accessor: 'c2',
      },
      {
        Header: 'Fund',
        accessor: 'c3',
      },
      {
        Header: 'Subfund',
        accessor: 'c4',
      },
      {
        Header: 'Shareclass',
        accessor: 'c5',
      },
      {
        Header: 'ISIN',
        accessor: 'c6',
      },
      {
        Header: 'Language',
        accessor: 'c7',
      },
      {
        Header: 'Country',
        accessor: 'c8',
      },
      {
        Header: 'Extension',
        accessor: 'c9',
      },
      {
        Header: 'Download',
        accessor: 'c10',
      },
      {
        Header: 'Send',
        accessor: 'c11',
      },
    ]
  }, [])

  const data = React.useMemo(() => {
    if (paginatedDocuments === null) {
      return []
    }
    return paginatedDocuments?.content?.map((e, i: number) => {
      return {
        c0: e.documentId,
        c1: e.referenceDateFormatted,
        c2: e.reportTypeName,
        c3: e.fundName,
        c4: e.subfundName,
        c5: e.shareclassName,
        c6: e.codeIsin,
        c7: e.languageName ? capitalizeString(e.languageName) : '/',
        c8: e.countryName,
        c9: e.documentExtension,
        c10: (
          <DownloadIcon
            callback={() => {
              const docId = e.documentId
              documentService
                .downloadByIds([docId], false, undefined)
                .then(resp => {
                  if (resp.headers['content-disposition']) {
                    const filename = resp.headers['content-disposition']
                      .split('filename=')[1]
                      .replaceAll('"', '')
                    DownloadService.downloadDoc(resp.data, filename)
                  } else {
                    throw new Error('File name is missing')
                  }
                })
                .catch(error => {
                  console.log(error)
                })
            }}
          />
        ),
        c11: ~sendByEmailDocuments.findIndex((d: any) => d === e.documentId) ? (
          <MailIcon disabled />
        ) : (
          <MailIcon
            callback={() => {
              setSendByEmailDocuments([...sendByEmailDocuments, e.documentId])
              const formData = new FormData()
              formData.append('docIdArray', [e.documentId].toString())
              const promise = documentService.sendDocumentsByEmail([e.documentId])

              toast.promise(promise, {
                loading: 'Sending email. Please wait...',
                error: 'An error occurred while sending the email. Please try again',
                success: () => {
                  return 'The email was sent successfully'
                },
              })
            }}
          />
        ),
      }
    })
  }, [paginatedDocuments, sendByEmailDocuments])

  return (
    <>
      {isLoading && <Loader />}
      {paginatedDocuments && (
        <>
          <Table
            fixedHeader
            head={head}
            data={data}
            loading={isLoading}
            selectionState={[selectedRows, setSelectedRows]}
          />
          <Paginator>
            <div>
              {isLoading ? (
                <></>
              ) : paginatedDocuments?.totalElements && paginatedDocuments?.totalElements > 0 ? (
                <>
                  Showing {paginatedDocuments?.size * currentPage + 1} to{' '}
                  {paginatedDocuments?.numberOfElements < 50
                    ? paginatedDocuments?.totalElements
                    : paginatedDocuments?.size * currentPage + 50}{' '}
                  of {paginatedDocuments?.totalElements} elements
                </>
              ) : (
                <NotYet icon={faFolderOpen} text='No documents available' />
              )}
            </div>
            <div>
              {paginatedDocuments?.totalPages > 1 && !isLoading && (
                <>
                  <FontAwesomeIcon
                    onClick={() => {
                      if (currentPage > 0 && !isLoading) setCurrentPage(currentPage - 1)
                    }}
                    style={{ cursor: currentPage > 0 ? 'pointer' : 'not-allowed' }}
                    color={currentPage > 0 ? mainColor : COLOR.GRAY}
                    icon={faChevronLeft}
                  />
                  <span style={{ marginLeft: 8, marginRight: 8 }}>{`${currentPage + 1} / ${
                    paginatedDocuments?.totalPages
                  }`}</span>
                  <FontAwesomeIcon
                    onClick={() => {
                      if (currentPage + 1 < paginatedDocuments?.totalPages && !isLoading)
                        setCurrentPage(currentPage + 1)
                    }}
                    style={{
                      cursor:
                        currentPage + 1 < paginatedDocuments?.totalPages
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    color={
                      currentPage + 1 < paginatedDocuments?.totalPages ? mainColor : COLOR.GRAY
                    }
                    icon={faChevronRight}
                  />
                </>
              )}
            </div>
          </Paginator>
        </>
      )}
    </>
  )
}

const Paginator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0px 10px;

  > span {
    margin-left: 8px;
    margin-right: 8px;
    user-select: none;
  }
`
