import { MRT_Row, MRT_TableInstance } from 'material-react-table'
import React from 'react'
import { Tooltip } from '@mui/material'
import styled from 'styled-components'
import { DeliverableValidationDto } from '../../../../types/dtos/FollowupDto'
import CountryWithFlagAndTooltip from '../../../../components/text/CountryWithFlagAndTooltip'
import LanguageWithTooltip from '../../../../components/text/LanguageWithTooltip'

type props = {
  row: MRT_Row<DeliverableValidationDto>
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`

function RenderCellWithCountryLanguage({ row }: props) {
  const { countryIsoCode2, countryName, languageIsoCode2, languageName } = row.original
  let content = <></>

  if (countryIsoCode2 && countryName) {
    content = (
      <CountryWithFlagAndTooltip countryIsoCode2={countryIsoCode2} countryName={countryName} />
    )
  }

  if (languageIsoCode2 && languageName) {
    content = (
      <>
        {content} {countryIsoCode2 && countryName ? '-' : ''}{' '}
        <LanguageWithTooltip languageIsoCode2={languageIsoCode2} languageName={languageName} />
      </>
    )
  }

  return <Container>{content}</Container>
}

export default function HandleDocumentAggregatedCell(options: {
  row: MRT_Row<DeliverableValidationDto>
  table: MRT_TableInstance
}) {
  const { row, table } = options
  if (table.getIsAllRowsSelected()) {
    if (row.depth !== 3) {
      if (row.getCanSelect()) {
        row.toggleSelected(true)
      }
    }
  }
  if (row.groupingColumnId === 'codeIsin') {
    if (row.original.shareclassName) {
      return (
        <div>
          {row.groupingValue as string} - {row.original.shareclassName} ({row.subRows?.length})
        </div>
      )
    }
    return (
      <div>
        {row.groupingValue as string} ({row.subRows?.length})
      </div>
    )
  }

  if (row.groupingColumnId === 'countryWithLanguage') {
    if (row.original.shareclassName) {
      return (
        <div>
          <RenderCellWithCountryLanguage row={row} />
        </div>
      )
    }
    return (
      <div>
        {row.groupingValue as string} ({row.subRows?.length})
      </div>
    )
  }

  return <div>{`${row.groupingValue} (${row.subRows?.length})`}</div>
}
