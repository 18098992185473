import { pSBC } from '../helpers/pSBC'

const MAIN = '#FF6900'
const GRAY = '#b7b7b7'
//'#1b3d6d'//'#702F8A'

const COLOR = {
  MAIN, //'#FF6900',
  MAINLIGHT: pSBC(0.6, MAIN) ?? MAIN, //'#ffd6b9',
  MAINVERYLIGHT: pSBC(0.85, MAIN) ?? MAIN,
  WHITE: '#fff',
  BLACK: '#2D2926',
  GRAY, //'#e9e9e9',
  GRAYLIGHT: pSBC(0.75, GRAY) ?? GRAY,
  GRAYVERYLIGHT: pSBC(0.9, GRAY) ?? GRAY,
  GREEN: '#36d323',
  RED: '#eb0707',
  PASTEL_RED: '#EF7564',
  PASTEL_LIGHT_RED: '#F5D3CE',
  PASTEL_YELLOW: '#F5DD29',
  PASTEL_LIGHT_YELLOW: '#FAF3C0',
  PASTEL_GREEN: '#7BC86C',
  PASTEL_LIGHT_GREEN: '#D6ECD2',
  BORDER_LIGHT_GRAY: '#CECECE',
  LIGHT_GRAY: '#f0f0f0',
  VERY_LIGHT_GRAY: '#F9F9F9',
  SEMI_LIGHT_GRAY: '#4b525a',
  LIGHT_ORANGE: '#ffefeb',
  LIGHT_TABLE_GRAY: '#f9f9f9',
  LIGHT_BLUE: '#2684FF',
}

const DS = {
  SIZE: 8,
}

const MOBILE_MEDIA = '@media (max-width: 960px)'
const DESKTOP_MEDIA = '@media (min-width: 960px)'

export { COLOR, DS, MOBILE_MEDIA, DESKTOP_MEDIA }
