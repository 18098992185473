import { z } from 'zod';

export const SubfundDtoSchema = z.object({
  id: z.number(),
  subfundName: z.string(),
});

type SubfundDto = z.infer<typeof SubfundDtoSchema>;

export { type SubfundDto };
